import React, { useState } from "react";
import { Container, Form, Row, Col, Button, FormGroup } from "react-bootstrap";
import {
  addVisitorMarquee,
  deleteVisitor,
  getCustomerMarquee,
  getVisitorMarquee,
  UpdateVisitor,
} from "./MarqueeDetails.actions";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useEffect } from "react";
import Swal from "sweetalert";
import { isEmpty, find,isUndefined } from "lodash";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import jsPDF from "jspdf";
import logo from "../../../../../Images/logo.png";
import { baseURL, headers } from "../../../../../global/api";
const VisitorMarqueeList = (props) => {
      const VisitorData = isEmpty(props.visitorMarqueeList) ? [] : props.visitorMarqueeList.reverse();
      useEffect(() => {
        // props.getVisitorMarquee(1);
       // props.getMasterControl(1)

    }, []);
    return(
        <>
        <Container fluid>
            <MaterialTable
                        style={{zIndex: 0}}
                            title="Visitor"
                            columns={[
                              { title: 'Visitor Name', field: 'customer_name' },
                              { title: 'Visitor Phone', field: 'customer_phone' },
                              { title: 'Visitor Purpose', field: 'visitors_purpose' },
                              {title:'Expected Date ',field:'accepted_date'},
                              {title:'Expected Guests ',field:'accepted_guest'},
                              {title:'Event',field:'event'},
                              {title:'Sub Event',field:'sub_event'},
                              { title: 'Visitor Address', field: 'customer_address' },
                              { title: 'Visitor CNIC', field: 'customer_cnic' },
                              { title: 'Visitor CNIC', field: 'customer_email' },
                              {title:'Description',field:'desc'}
                              
                          ]}
                            // data={VisitorData}
                            data={(query) =>
                                new Promise((resolve, reject) => {
                                  console.log(query, "qr");
                                  let url = `${baseURL}visitor_customer/get_visitor_customer?`;
                                  url += "size=" + query.pageSize;
                                  url += "&page=" + (query.page + 1);
                                //   url += "&search=" + query.search;
                                  fetch(url, {
                                    method: "GET",
                                    headers: headers,
                                  })
                                    .then((response) => response.json())
                                    .then((result) => {
                                      resolve({
                                        data: result.data,
                                        page: result.page - 1,
                                        totalCount: result.total_results,
                                      });
                                    });
                                })
                              }
                            // data={(query) =>
                            //     new Promise((resolve, reject) => {
                            //         console.log(query,"qqqqqqqqq")
                            //         let url = `${baseURL}visitor_customer/get_visitor_customer?`;
                            //         url += "size=" + query.pageSize;
                            //         url += "&page=" + isUndefined(query.page)?1:query.page;
                            //         // url += "&search=" + query.search;
                            //         fetch(url, {
                            //             method: 'GET',
                            //             headers: headers,
                            //         })
                            //             .then((response) => response.json())
                            //             .then((result) => {
                            //                 resolve({
                            //                     data: result.data,
                            //                     page: result.page,
                            //                     totalCount: result.total_results,
                            //                 });
                            //             });
            
                            //     })
                            // }
                            options={{
                                actionsColumnIndex: -1,
                                filtering: true,
                                exportButton: true,
                                exportAllData: true,
                                pageSize:50,
                                emptyRowsWhenPaging:false,
                                pageSizeOptions:[20,50, 100, 150, 200],
                                exportPdf: (columns, data) => {
                                  const doc = new jsPDF();
                                  //render:rowData=>rowData.hall_name
                                  const columnTitles =[
                                    { title: 'Visitor Name', field: 'customer_name',render:rowData=>rowData.customer_name },
                                    { title: 'Visitor Phone', field: 'customer_phone',render:rowData=>rowData.customer_phone},
                                    { title: 'Visitor Purpose', field: 'visitors_purpose',render:rowData=>rowData.visitors_purpose},
                                    {title:'Expected Date ',field:'accepted_date',render:rowData=>rowData.accepted_date},
                                    {title:'Expected Guests ',field:'accepted_guest',render:rowData=>rowData.accepted_guest},
                                    {title:'Event',field:'event',render:rowData=>rowData.event},
                                    {title:'Sub Event',field:'sub_event',render:rowData=>rowData.sub_event},
                                    {title:'Description',field:'desc',render:rowData=>rowData.desc}
                                ]
                                    .map(columnDef => columnDef.title);
                                    const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                                    const companyAddress=`${process.env.REACT_APP_COMPANY_ADDRESS}`;
                                    const companyNtn=`${process.env.REACT_APP_COMPANY_NTN}`;
                                  const pdfData = data.map((rowData) => [rowData.customer_name, rowData.customer_phone,rowData.visitors_purpose,rowData.accepted_date,rowData.accepted_guest,rowData.event,rowData.sub_event,rowData.desc]);
                                  let content = {
                                    startY: 50,
                                    startX: 5,
                                    head: [columnTitles],
                                    body: pdfData
                                  };
                                  doc.addImage(logo, 'PNG', 10, 1, 40, 40);
                                  doc.setFontSize(20);
                                  doc.setFont(undefined,'bold')
                                  doc.text(companyName, 60, 22, 0, 0);
                                  doc.setFontSize(13);
                                  doc.setFont('TimesNewRoman','Normal')
                                  doc.text(companyAddress,60, 29, 0, 0);
                                  doc.text(companyNtn,60, 36, 0, 0);
                                  doc.autoTable(content);
                                  doc.save(`Visitor Table.pdf`);
                                },
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                },

                            }}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            {
                                                // console.log('newData', newData),
                                                 props.UpdateVisitor(newData, oldData)
                                            }
                                        }, 600);
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.deleteVisitor(oldData.visitor_customer_id);
                                            console.log(oldData, 'old')
                                        }, 600);
                                    }),
                            }}
                           /* detailPanel={(rowData) => {
                                return (
                                    <MaterialTable
                                    title="Menu Dishes"
                                    columns={stateDishes.columns}
                                    data={rowData.menu_data}
                                    options={{
                                        actionsColumnIndex: -1,
                                        filtering: true,
                                        exportButton: true,
                                        exportAllData: true,
                                        headerStyle: {
                                            position: 'sticky', top: 0,
                                            color: '#00BBBB',
                                            fontWeight: '550',
                                            onRowAdd: 'none',
                                        },
        
                                    }}
                                    editable={{
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise((resolve) => {
                                                setTimeout(() => {
                                                    resolve();
                                                    {
                                                        // console.log('newData', newData),
                                                        // props.UpdateHRMenu(newData, oldData)
                                                    }
                                                }, 600);
                                            }),
                                        onRowDelete: (oldData) =>
                                            new Promise((resolve) => {
                                                setTimeout(() => {
                                                    resolve();
                                                    props.deleteMenu(oldData.menu_id);
                                                    console.log(oldData, 'old')
                                                }, 600);
                                            }),
                                    }}
                                    

                                    />
                                )}}*/
                        />
        </Container>
        </>
    )
};

const mapDispatchToProps = (dispatch) => {
    return {
        getVisitorMarquee:(id)=>dispatch(getVisitorMarquee(id)),
        deleteVisitor: (menu_id) => dispatch(deleteVisitor(menu_id)),
        UpdateVisitor: (newData, oldData) => dispatch(UpdateVisitor(newData, oldData))
    };
  };
  const mapStateToProps = (state, ownProps) => ({
    addCustomer:state.MarqueeReducer.addCustomer,
    isAddingCustomerData:state.MarqueeReducer.isAddingCustomerData,
    isFetchingCustomerDataMarquee:state.MarqueeReducer.isFetchingCustomerDataMarquee,
    customerMarqueeList:state.MarqueeReducer.customerMarqueeList,
    visitorMarqueeList:state.MarqueeReducer.visitorMarqueeList,
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(VisitorMarqueeList);
