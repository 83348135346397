import React, { useState } from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useEffect } from "react";
import Swal from "sweetalert";
import Select from 'react-select';
import jsPDF from "jspdf";
import { isEmpty } from "lodash";
import { addTermAndCondition } from "./AddDetails/MarqueeDetails.actions";

const AddTermandConditions = (props) => {

  const [termCondition, setTermCondition] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  function uploadEvent() {
    props.addTermandCondition(
      {
        text: termCondition,
      },
      handleVisible,
      handleDangerVisible
    );
  }
      
  const clearStates = () => {
    setTermCondition("")

  };
  const handleVisible = () => {
    setAlertVisible(true);
    clearStates();
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };

  return (
    <>
      {props.isAddingMasterControlData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <Form>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Enter Term & Conditions:</b>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={12}
                    placeholder="Enter Term & Conditions....."
                    value={termCondition}
                    onChange={(e) => setTermCondition(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv">
              {termCondition=="" ? (
                <Button
                  className="sendButton"
                  style={{ backgroundColor: "black" }}
                  onClick={() => {
                    uploadEvent();
                  }}
                  disabled
                >
                  Save
                </Button>
              ) : (
                <Button
                  className="sendButton"
                  onClick={() => {
                    uploadEvent();
                  }}
                >
                  Save
                </Button>
              )}
            </div>
          </Form>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTermandCondition: (data, handleVisible, handleDangerVisible) =>
      dispatch(addTermAndCondition(data, handleVisible, handleDangerVisible)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingMasterControlData: state.MarqueeReducer.isFetchingMasterControlData,
  isAddingMasterControlData: state.MarqueeReducer.isAddingMasterControlData,
  masterControlsList: state.MarqueeReducer.masterControlsList,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTermandConditions);
