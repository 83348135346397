import API from "../../../../../global/api";
import { get, forEach, isEmpty, sumBy } from "lodash";
import jsPDF from "jspdf";
import logo from '../../../../../../src/Images/logo.png';
import moment from "moment";
import { ModelTraining } from "@mui/icons-material";
import { handleNoDataVisible } from "../../../../../global/globalFunctions";

export const requestAddEmployee = () => {
    return {
        type: "REQUEST_ADD_EMPLOYEE",
    };
};
export const successAddEmployee = (data) => {
    return {
        type: "SUCCESS_ADD_EMPLOYEE",
        payload: data,
    };
};
export const errorAddEmployee = () => {
    return {
        type: "ERROR_ADD_EMPLOYEE",
    };
};

export const addEmployee = (data, handleVisible, handleDangerVisible) => {
    const formData = new FormData();
    formData.append("uploadImage", get(data, 'uploadImage'));
    formData.append("uploadDocument", get(data, 'uploadDocument'));
    // formData.append("uploadCnicImage", get(data, 'uploadCnicImage'));

    formData.append("appointment_type", `${data.appointment_type}`);
    formData.append("name", `${data.name}`);
    formData.append("father_name", `${data.father_name}`);
    formData.append("dob", `${data.dob}`);
    formData.append("gender", `${data.gender}`);
    formData.append("phone", `${data.phone}`);
    formData.append("joining_date", `${data.joining_date}`);
    formData.append("emergency_contact", `${data.emergency_contact}`);
    formData.append("marital_status", `${data.marital_status}`);
    formData.append("cnic", `${data.cnic}`);
    // formData.append("joining_date", `${data.joining_date}`);
    formData.append("religion", `${data.religion}`);
    formData.append("report_to", `${data.report_to}`);

    formData.append("address", `${data.address}`);
    formData.append("allocation", `${data.allocation}`);

    formData.append("employee_type", `${data.employee_type}`);
    formData.append("ntn", `${data.ntn}`);
    formData.append("eobi", `${data.eobi}`);
    formData.append("social_security", `${data.social_security}`);
    formData.append("blood_group", `${data.blood_group}`);

    formData.append("department_id", `${data.department_id}`);
    formData.append("designation_id", `${data.designation_id}`);

    formData.append("probation_period", `${data.probation_period}`);
    formData.append("probation_period_end_date", `${data.probation_period_end_date}`);




    forEach(data.uploadCnicImage, (x, index) => {
        formData.append("uploadCnicImage", data.uploadCnicImage[index]);
    })
    // forEach(data.uploadDocument, (x, index) => {
    //     formData.append("uploadDocument", data.uploadDocument[index]);
    // })

    return (dispatch) => {
        dispatch(requestAddEmployee());
        API.post(`/employee/add_employee_data`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            let getData = res.data;
            dispatch(successAddEmployee(getData));
            handleVisible();
        }).catch((error) => {
            dispatch(errorAddEmployee());
            handleDangerVisible();
        })
    };
};

export const setCasualLeaves = (data) => {
    return {
        type: "SET_CASUAL_LEAVE",
        payload: data
    };
};

export const setSickLeaves = (data) => {
    return {
        type: "SET_SICK_LEAVE",
        payload: data
    };
};

export const setOtherLeaves = (data) => {
    return {
        type: "SET_OTHER_LEAVE",
        payload: data
    };
};
export const setAnnualLeaves = (data) => {
    return {
        type: "SET_ANNUAL_LEAVE",
        payload: data
    };
};
export const setPaternityLeaves = (data) => {
    return {
        type: "SET_PATERNITY_LEAVE",
        payload: data
    };
};
export const setShortLeaves = (data) => {
    return {
        type: "SET_SHORT_LEAVE",
        payload: data
    };
};
export const setSpecialLeaves = (data) => {
    return {
        type: "SET_SPECIAL_LEAVE",
        payload: data
    };
};
export const setMaternityLeaves = (data) => {
    return {
        type: "SET_MATERNITY_LEAVE",
        payload: data
    };
};

//Employee-Get
export const requestGetEmployee = () => {
    return {
        type: "REQUEST_GET_EMPLOYEE",
    };
};
export const successGetEmployee = (data) => {
    return {
        type: "SUCCESS_GET_EMPLOYEE",
        payload: data,
    };
};
export const errorGetEmployee = () => {
    return {
        type: "ERROR_GET_EMPLOYEE",
    };
};

export const getEmployeeData = () => {
    return (dispatch) => {
        dispatch(requestGetEmployee());
        API.get(`/employee/get_all_data`).then((res) => {
            let getData = res.data;
            console.log('getData', getData);
            dispatch(successGetEmployee(getData));
        }).catch((error) => {
            dispatch(errorGetEmployee());
        })
    };
};


//Employee-Get
export const requestGetActiveEmployee = () => {
    return {
        type: "REQUEST_GET_ACTIVE_EMPLOYEE",
    };
};
export const successGetActiveEmployee = (data) => {
    return {
        type: "SUCCESS_GET_ACTIVE_EMPLOYEE",
        payload: data,
    };
};
export const errorGetActiveEmployee = () => {
    return {
        type: "ERROR_GET_ACTIVE_EMPLOYEE",
    };
};

export const getEmployeeDataWithStatus = (status) => {
    return (dispatch) => {
        dispatch(requestGetActiveEmployee());
        API.get(`/employee/get_active_employee/${status}`).then((res) => {
            let getData = res.data;
            console.log('getData', getData);
            dispatch(successGetActiveEmployee(getData));
        }).catch((error) => {
            dispatch(errorGetActiveEmployee());
        })
    };
};

//EmployeeType-Add
export const requestAddEmployeeType = () => {
    return {
        type: "REQUEST_ADD_EMPLOYEE_TYPE",
    };
};
export const successAddEmployeeType = (data) => {
    return {
        type: "SUCCESS_ADD_EMPLOYEE_TYPE",
        payload: data,
    };
};
export const errorAddEmployeeType = () => {
    return {
        type: "ERROR_ADD_EMPLOYEE_TYPE",
    };
};
export const addHREmployeeType = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddEmployeeType());
        API.post(`/employee/add_employee_type`, data).then((res) => {
            handleVisible();
            let getData = res.data;
            dispatch(getHREmployeeType());
            dispatch(successAddEmployeeType(getData));
        }).catch((error) => {
            handleDangerVisible();
            dispatch(errorAddEmployeeType());
        })
    };
};


//EmployeeType-Get
export const requestGetEmployeeType = () => {
    return {
        type: "REQUEST_GET_EMPLOYEE_TYPE",
    };
};
export const successGetEmployeeType = (data) => {
    return {
        type: "SUCCESS_GET_EMPLOYEE_TYPE",
        payload: data,
    };
};
export const errorGetEmployeeType = () => {
    return {
        type: "ERROR_GET_EMPLOYEE_TYPE",
    };
};
export const getHREmployeeType = () => {
    return (dispatch) => {
        dispatch(requestGetEmployeeType());
        API.get(`/employee/get_employee_type`).then((res) => {
            let getData = res.data;
            console.log('getData', getData)
            dispatch(successGetEmployeeType(getData));
        }).catch((error) => {
            dispatch(errorGetEmployeeType());
        })
    };
};


//EmployeeType-Update
export const requestUpdateEmployeeType = () => {
    return {
        type: "REQUEST_UPDATE_EMPLOYEE_TYPE",
    };
};
export const successUpdateEmployeeType = (data) => {
    return {
        type: "SUCCESS_UPDATE_EMPLOYEE_TYPE",
        payload: data,
    };
};
export const errorUpdateEmployeeType = () => {
    return {
        type: "ERROR_UPDATE_EMPLOYEE_TYPE",
    };
};
export const UpdateHREmployeeType = (newData, oldData) => {
    console.log('newData', newData);
    // let hall_id=newData.hall_id;
    return (dispatch) => {
        dispatch(requestUpdateEmployeeType());
        // API.get(`/hall/add_hall/:${hall_id}`, newData).then((res) => {
        API.put(`/employee/update_employee_type/${oldData.employee_type_id}`, newData).then((res) => {
            let getData = res.data;
            dispatch(successUpdateEmployeeType([newData]));
            dispatch(getHREmployeeType());
        }).catch((error) => {
            dispatch(errorUpdateEmployeeType());
        })
    };
};


//EmployeeType-Delete
export const requestDeleteEmployeeType = () => {
    return {
        type: "REQUEST_DELETE_EMPLOYEE_TYPE",
    };
};
export const successDeleteEmployeeType = (data) => {
    return {
        type: "SUCCESS_DELETE_EMPLOYEE_TYPE",
        payload: data,
    };
};
export const errorDeleteEmployeeType = () => {
    return {
        type: "ERROR_DELETE_EMPLOYEE_TYPE",
    };
};
export const deleteHREmployeeType = (employee_type_id) => {
    return (dispatch) => {
        dispatch(requestDeleteEmployeeType());
        API.delete(`/employee/delete_employee_type/${employee_type_id}`).then((res) => {
            let getData = res.data;
            dispatch(successDeleteEmployeeType());
            dispatch(getHREmployeeType());
        }).catch((error) => {
            dispatch(errorDeleteEmployeeType());

        })
    };
};

//Hall-Add
export const requestAddHall = () => {
    return {
        type: "REQUEST_ADD_HALL",
    };
};
export const successAddHall = (data) => {
    return {
        type: "SUCCESS_ADD_HALL",
        payload: data,
    };
};
export const errorAddHall = () => {
    return {
        type: "ERROR_ADD_HALL",
    };
};
//add installmet
export const requestAddInstallment = () => {
    return {
        type: "REQUEST_ADD_INSTALLMENT",
    };
};
export const successAddInstallment = (data) => {
    return {
        type: "SUCCESS_ADD_INSTALLMENT",
        payload: data,
    };
};
export const errorAddInstallment = () => {
    return {
        type: "ERROR_ADD_INSTALLMENT",
    };
};
export const addInstallment = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddInstallment());
        API.post(`/booking_installment/add_booking_installment`, data).then((res) => {
            handleVisible();
            let getData = res.data;
            dispatch(successAddInstallment(getData));
        }).catch((error) => {
            handleDangerVisible();
            dispatch(errorAddInstallment());
        })
    };
};
//Menu add  requests
export const requestAddMenu = () => {
    return {
        type: "REQUEST_ADD_MENU",
    };
};
export const successAddMenu = (data) => {
    return {
        type: "SUCCESS_ADD_MENU",
        payload: data,
    };
};
export const errorAddMenu = () => {
    return {
        type: "ERROR_ADD_MENU",
    };
};

//Expense add  requests
export const requestAddExpense = () => {
    return {
        type: "REQUEST_ADD_EXPENSE",
    };
};
export const successAddExpense = (data) => {
    return {
        type: "SUCCESS_ADD_EXPENSE",
        payload: data,
    };
};
export const errorAddExpense = () => {
    return {
        type: "ERROR_ADD_EXPENSE",
    };
};
//Events requests
export const requestAddEvent = () => {
    return {
        type: "REQUEST_ADD_EVENT",
    };
};
export const successAddEvent = (data) => {
    return {
        type: "SUCCESS_ADD_EVENT",
        payload: data,
    };
};
export const errorAddEvent = () => {
    return {
        type: "ERROR_ADD_EVENT",
    };
};

//Add or remove list of marquee edit addos
export const editAddons = (data) => {
    console.log(data, "dataMarquee")
    return {
        type: "EDIT_ADDONS",
        payload: data
    };
};
//hide form
export const hideForm = (data) => {
    console.log(data, "hideFormdata")
    return {
        type: "SET_HIDE_FORM",
        payload: data
    };
};

//customer requestes
export const requestAddCustomermarquee = () => {
    return {
        type: "REQUEST_ADD_CUSTOMER_MARQUEE",
    };
};
export const successAddCustomermarquee = (data) => {
    return {
        type: "SUCCESS_ADD_CUSTOMER_MARQUEE",
        payload: data,
    };
};
export const errorAddCustomermarquee = () => {
    return {
        type: "ERROR_ADD_CUSTOMER_MARQUEE",
    };
};
//add customer
export const addCustomermarquee = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddCustomermarquee());
        API.post(`/customer/add_customer`, data).then((res) => {
            handleVisible();
            let getData = res.data;
            dispatch(successAddCustomermarquee(getData));
            dispatch(getCustomerMarquee());
        }).catch((error) => {
            handleDangerVisible();
            dispatch(errorAddCustomermarquee());
        })
    };
};
//get customer
export const getCustomerMarquee = () => {
    return (dispatch) => {
        dispatch(requestGetCustomerMarquee());
        API.get(`/customer/show_customer/`).then((res) => {
            let getData = res.data;
            dispatch(successGetCustomerMarquee(getData));
        }).catch((error) => {
            dispatch(errorGetCustomerMarquee());
        })
    };
};
export const requestGetCustomerMarquee = () => {
    return {
        type: "REQUEST_GET_CUSTOMER_MARQUEE",
    };
};
export const successGetCustomerMarquee = (data) => {
    return {
        type: "SUCCESS_GET_CUSTOMER_MARQUEE",
        payload: data,
    };
};
export const errorGetCustomerMarquee = () => {
    return {
        type: "ERROR_GET_CUSTOMER_MARQUEE",
    };
}

//Visitors Requests

export const requestAddVisitormarquee = () => {
    return {
        type: "REQUEST_ADD_VISITOR_MARQUEE",
    };
};
export const successAddVisitormarquee = (data) => {
    return {
        type: "SUCCESS_ADD_VISITOR_MARQUEE",
        payload: data,
    };
};
export const errorAddVisitormarquee = () => {
    return {
        type: "ERROR_ADD_VISITOR_MARQUEE",
    };
};
//add customer
export const addVisitorMarquee = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddVisitormarquee());
        API.post(`/visitor_customer/add_visitor_customer`, data).then((res) => {
            handleVisible();
            let getData = res.data;
            dispatch(successAddVisitormarquee(getData));
            dispatch(getVisitorMarquee())
        }).catch((error) => {
            handleDangerVisible();
            dispatch(errorAddVisitormarquee());
        })
    };
};
export const requestDeleteVisitor = () => {
    return {
        type: "REQUEST_DELETE_VISITOR",
    };
};
export const successDeleteVisitor = (data) => {
    return {
        type: "SUCCESS_DELETE_VISITOR",
        payload: data,
    };
};
export const errorDeleteVisitor = () => {
    return {
        type: "ERROR_DELETE_VISITOR",
    };
};
export const deleteVisitor = (visitor_id) => {
    return (dispatch) => {
        dispatch(requestDeleteVisitor());
        API.delete(`/visitor_customer/delete_visitor_customer/${visitor_id}`).then((res) => {
            let getData = res.data;
            dispatch(successDeleteVisitor());
            dispatch(getVisitorMarquee());
        }).catch((error) => {
            dispatch(errorDeleteVisitor());

        })
    };
};
export const requestUpdateVisitor = () => {
    return {
        type: "REQUEST_UPDATE_VISITOR",
    };
};
export const successUpdateVisitor = (data) => {
    return {
        type: "SUCCESS_UPDATE_VISITOR",
        payload: data,
    };
};
export const errorUpdateVisitor = () => {
    return {
        type: "ERROR_UPDATE_VISITOR",
    };
};
export const UpdateVisitor = (newData, oldData) => {
    console.log('newData', newData);
    // let hall_id=newData.hall_id;
    return (dispatch) => {
        dispatch(requestUpdateVisitor());
        // API.get(`/hall/add_hall/:${hall_id}`, newData).then((res) => {
        API.put(`/visitor_customer/update_visitor_customer/${oldData.visitor_customer_id}`, newData).then((res) => {
            let getData = res.data;
            dispatch(successUpdateVisitor([newData]));
            dispatch(getVisitorMarquee());
        }).catch((error) => {
            dispatch(errorUpdateVisitor());
        })
    };
};
//report
export const requestMarqueeExpenseReport = () => {
    return {
        type: "REQUEST_MARQUEE_EXPENSE_REPORT",
    };
};
export const successMarqueeExpenseReport = (data) => {
    return {
        type: "SUCCESS_MARQUEE_EXPENSE_REPORT",
        payload: data,
    };
};
export const errorMarqueeExpenseReport = () => {
    return {
        type: "ERROR_MARQUEE_EXPENSE_REPORT",
    };
};

export const SearchMarqueeExpenseReport = (id) => {
    return (dispatch) => {
        dispatch(requestMarqueeExpenseReport())
        API.get(`/expense_form/get_expense_by_booking/${id}`).then((res) => {
            let getData = res.data;
            if (isEmpty(getData)) {
                handleNoDataVisible();
            } else {
                let bookingData = getData[0].booking;
                console.log(bookingData, "rrrrr")
                const unit = "pt";
                const size = "A4"; // Use A1, A2, A3 or A4
                const orientation = "potrait"; // portrait or landscape

                const marginLeft = 40;
                const doc = new jsPDF(orientation, unit, size);

                doc.setFontSize(13);
                doc.setFont('TimesNewRoman', 'Normal')
                const title = "Expense details Report";
                const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
                const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
                const Host = bookingData.booking_name;
                const FunctionType = bookingData.event_shift_id == 1 ? "morning" : bookingData.event_shift_id == 2 ? "After noon" : bookingData.event_shift_id == 3 ? "evening" : "";
                const NoOfPerson = bookingData.booking_person;
                console.log(NoOfPerson)
                const ExtraPerson = 0;
                const totalAmount = bookingData.booking_total_price;
                const bookingDate = bookingData.booking_date;
                const personPrice = bookingData.charging_price
                const totalPersonPrice = NoOfPerson * personPrice;
                const decorationBill = bookingData.booking_data_price;
                const discount = bookingData.booking_discount;
                const totalBill = bookingData.booking_payable_price;

                //const Dates = !isEmpty(startDate) || !isEmpty(endDate) ? "Form: " + " " + startDate + "to: " + endDate : "Form: -  To: - ";
                //const accountNo = "Account #:  " + getData.map((x) => x.allLevelKey);
                //const accountTitle = "Account Title:  " + getData.map((x) => x.levelFiveName);
                const headers = [["Sr", "Item Name", "Quantity", "Item Price", "Total Price"]];
                console.log(getData[0].booking, "pppppp")
                const data = getData[0].expense_form_data.map((elt, index) => [index + 1, elt.product_label, elt.expense_form_quantity, elt.expense_form_data_price, elt.expense_form_total_price])

                let content = {
                    startY: 250,
                    startX: 10,
                    head: headers,
                    body: data
                };
                doc.addImage(logo, 'PNG', 40, 15, 100, 100);
                doc.setFontSize(20);
                doc.setFont(undefined, 'bold')
                doc.text(companyName, 150, 40, 0, 20);
                doc.setFontSize(13);
                doc.setFont('TimesNewRoman', 'Normal')
                doc.text(companyAddress, 150, 60, 0, 20);
                doc.text(companyNtn, 150, 80, 0, 20);
                doc.text(`Host Name: ${Host}`, marginLeft, 140, 0, 20);
                doc.text(`Function type: ${FunctionType}`, marginLeft, 160, 0, 20);
                doc.text(`No of person: ${NoOfPerson}`, marginLeft, 180, 0, 20);
                doc.text(`Extra person: ${ExtraPerson}`, marginLeft, 200, 0, 20);
                doc.text(`Booking Amount: ${totalAmount}`, marginLeft, 220, 0, 20);
                doc.text(`Booking Date: ${bookingDate}`, 400, 140, 0, 20);
                doc.text(`Person price: ${personPrice}`, 400, 160, 0, 20);
                doc.text(`Total person price: ${totalPersonPrice}`, 400, 180, 0, 20);
                doc.text(`Decoration: ${decorationBill}`, 400, 200, 0, 20);
                doc.text(`Discount: ${discount} ${bookingData.booking_discount_type == "Cash" ? "" : "%"}`, 400, 220, 0, 20);
                doc.text(`Payable Amount: ${totalBill}`, 240, 240, 0, 20);
                doc.setFontSize(18);
                doc.setFont('TimesNewRoman', 'bold')
                doc.text(title, 200, 120);
                doc.setFontSize(11);
                doc.autoTable(content);
                const totalEx = sumBy(data, "4");
                doc.text(`Total Expence: ${totalEx}`, 365, doc.lastAutoTable.finalY + 15, 0, 20);
                doc.setProperties({ title: `Marquee Expense Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf` })
                //ct  doc.output('dataurlnewwindow')
                var string = doc.output('datauristring', 'dddd');
                var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
                var x = window.open('Demand Report');
                x.document.open('Demand Report');
                x.document.write(embed);
                x.document.close();
            }
            // doc.save(`Marquee Expense Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`)}
            dispatch(successMarqueeExpenseReport(getData));
        }).catch((error) => {
            console.log(error, "rrrrrrrrrrrrrrrrrrrrrrr")
            dispatch(errorMarqueeExpenseReport());
        })
    }
}
// print menu
export const requestMarqueeMenuReport = () => {
    return {
        type: "REQUEST_MARQUEE_MENU_REPORT",
    };
};
export const successMarqueeMenuReport = (data) => {
    return {
        type: "SUCCESS_MARQUEE_MENU_REPORT",
        payload: data,
    };
};
export const errorMarqueeMenuReport = () => {
    return {
        type: "ERROR_MARQUEE_MENU_REPORT",
    };
};

export const SearchMarqueeMenuReport = (data) => {
    return (dispatch) => {
        //  dispatch(requestMarqueeExpenseReport()) 
        console.log(data, "dt")
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "potrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(13);
        doc.setFont('TimesNewRoman', 'Normal')
        const title = "Menu Report";
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
        const menuname = data.menu_name;
        const menuPrice = data.menu_price;
        const headers = [["Sr", "Dish", "DishPrice"]];
        // console.log(getData[0].booking,"pppppp")
        console.log(data.menu_data, "abc")
        const data1 = data.menu_data.map((elt, index) => [index + 1, elt.master_control_item.master_control_item_name, `${elt.menu_data_price}/Per Person`])

        let content = {
            startY: 200,
            startX: 10,
            head: headers,
            body: data1
        };
        doc.addImage(logo, 'PNG', 40, 5, 100, 100);
        doc.setFontSize(20);
        doc.setFont(undefined, 'bold')
        doc.text(companyName, 150, 40, 0, 20);
        doc.setFontSize(13);
        doc.setFont('TimesNewRoman', 'Normal')
        doc.text(companyAddress, 150, 60, 0, 20);
        doc.text(companyNtn, 150, 80, 0, 20);
        doc.setFontSize(15);
        doc.setFont(undefined, 'bold')
        doc.text(title, 240, 120);
        doc.setFont('TimesNewRoman', 'Normal')
        doc.setFontSize(12);
        doc.text(`Menu Name: ${menuname}`, 40, 160, 0, 0);
        doc.text(`Menu Price: ${menuPrice}`, 40, 180, 0, 0);
        doc.autoTable(content);
        doc.setFontSize(10);
        doc.text("EXTRA PERSON WILL BE CHARGED ACCORDING TO DECIDED FOR PER HEAD", 45, doc.lastAutoTable.finalY + 30, 0, 0);
        doc.text("LUNCH TIMING 12:00 PM TO 4:00 PM       DINNER TIMING 6:00 PM TO 10:00 PM", 45, doc.lastAutoTable.finalY + 50, 0, 0);
        doc.text("5% TAX WILL BE APPLICABLE ON TOTAL AMOUNT", 45, doc.lastAutoTable.finalY + 70, 0, 0);
        doc.text("25% ADVANCE PAYMENT WILL BE RECIVED AT THE TIME ON BOOKING", 45, doc.lastAutoTable.finalY + 90, 0, 0);
        doc.text("75% REMAINING PAYMENT WILL BE RECEIVED SEVEN DAYS PRIOR TO FUNCTION", 45, doc.lastAutoTable.finalY + 110, 0, 0);
        doc.text("WEAPONS AND PETS ARE NOT ALLOWED", 45, doc.lastAutoTable.finalY + 130, 0, 0);
        doc.setProperties({ title: `Menu Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf` })
        var string = doc.output('datauristring', 'dddd');
        var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
        var x = window.open('Demand Report');
        x.document.open('Demand Report');
        x.document.write(embed);
        x.document.close();
        //ct doc.output('dataurlnewwindow')
        // doc.save(`Menu Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`)
        // dispatch(successMarqueeExpenseReport(getData));
    }
}
//event print
export const requestMarqueeEventReport = () => {
    return {
        type: "REQUEST_MARQUEE_EVENT_REPORT",
    };
};
export const successMarqueeEventReport = (data) => {
    return {
        type: "SUCCESS_MARQUEE_EVENT_REPORT",
        payload: data,
    };
};
export const errorMarqueeEventReport = () => {
    return {
        type: "ERROR_MARQUEE_EVENT_REPORT",
    };
};

export const SearchMarqueeEventReport = (data, termAndCondition) => {
    console.log(data, termAndCondition, "abc1111111111");
    return (dispatch) => {
        //  dispatch(requestMarqueeExpenseReport()) 
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "potrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(13);
        doc.setFont('TimesNewRoman', 'Normal')
        const title = "Event Details";
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
        const eventDate = moment(data[0].booking_date).format('Do MMMM YYYY');
        const eventTime = data[0].event_shift.event_shift_name;
        const eventType = data[0].event.event_name;
        const eventHall = data[0].hall.hall_name;
        const hallprice = data[0].hall.hall_per_function_charges;
        const invoice = data[0].booking_id;
        const Subevent = data[0].sub_event.sub_event_name;
        const customerName = data[0].customer_table.customer_name;
        const customerAddress = data[0].customer_table.customer_address;
        const customerEmail = data[0].customer_table.customer_email;
        const customerPh = data[0].customer_table.customer_phone;
        const am = data[0].booking_person * data[0].charging_price;
        const damytax = data[0].booking_total_price / 100;
        console.log(damytax, "tax")
        const damytax1 = Number(damytax) * 5;
        console.log(damytax1, "tax1")
        const taxAmount = Number(data[0].booking_total_price) + damytax1;
        console.log(taxAmount, "tax3")
        // const headers = [["Dish", "Person", "Menu Price", "Amount"]];
        const headers = [["Dish"]];
        // console.log(getData[0].booking,"pppppp")
        // console.log(data,"abc")
        const dishes = data[0].menu
        console.log(dishes, "sssssssssssssssss")
        const data1 = dishes.menu_data.map((elt, index) => [elt.master_control_item.master_control_item_name])
        let content = {
            startY: 365,
            startX: 10,
            head: headers,
            body: data1
        }
        doc.addImage(logo, 'PNG', 40, 15, 100, 100);
        doc.setFontSize(20);
        doc.setFont(undefined, 'bold')
        doc.text(companyName, 150, 40, 0, 20);
        doc.setFontSize(13);
        doc.setFont('TimesNewRoman', 'Normal')
        doc.text(companyAddress, 150, 60, 0, 20);
        doc.text(companyNtn, 150, 80, 0, 20);
        doc.setFontSize(14);
        doc.setFont(undefined, 'bold')
        doc.text(title, 40, 240);
        doc.setFontSize(12);
        doc.setFont(undefined, 'normal')
        doc.text(`Event Date: ${eventDate}`, 40, 280, 0, 0);
        doc.text(`Event Time: ${eventTime}`, 40, 300, 0, 0);
        doc.text(`Menu Name: ${data[0].menu.menu_name}`, 40, 320, 0, 0);
        doc.text(`Total Person: ${data[0].booking_person}`, 370, 320, 0, 0);
        doc.text(`Menu Per Person Price: ${data[0].charging_price}`, 40, 340, 0, 0);
        doc.text(`Event Type: ${eventType}`, 370, 280, 0, 0)
        doc.text(`Hall name: ${eventHall}`, 40, 260, 0, 0)
        doc.text(`Sub Event Type: ${Subevent}`, 370, 300, 0, 0)
        doc.text(`Invoice no: ${invoice}`, 370, 260, 0, 0)
        doc.setFontSize(16);
        doc.setFont(undefined, 'bold')
        doc.text("Clients/ Customer", 40, 140, 0, 0);
        doc.setFontSize(12);
        doc.setFont(undefined, 'normal')
        doc.text(`Name: ${customerName}`, 40, 160, 0, 0);
        doc.text(`Address: ${customerAddress}`, 40, 180, 0, 0);
        doc.text(`Email: ${customerEmail}`, 40, 200, 0, 0);
        doc.text(`Mobile: ${customerPh}`, 40, 220, 0, 0);
        doc.setFontSize(12);
        doc.setFont(undefined, 'bold')
        doc.text(`MENU DETAILS`, 220, 355, 0, 0);
        doc.setFontSize(12);
        doc.setFont(undefined, 'normal')
        doc.autoTable(content);
        doc.setFontSize(12);
        doc.setFont(undefined, 'bold')
        doc.text("Additional Items", 240, doc.lastAutoTable.finalY + 15, 0, 0);
        console.log(data[0].booking_data, 'hm')
        const header2 = [["Description", "Amount"]];
        let addonsTotalPrice = sumBy(data[0].booking_data, 'booking_data_price')
        console.log(addonsTotalPrice, "total")
        const data2 = data[0].booking_data.map((elt, index) => [elt.master_control_item.master_control_item_name, elt.booking_data_price])
        let content1 = {
            startY: doc.lastAutoTable.finalY + 25,
            startX: 10,
            head: header2,
            body: data2
        }
        doc.autoTable(content1);  
        doc.setFontSize(10);
        doc.setFont(undefined, 'bold');
        doc.text("Hall Charges: ", 390, doc.lastAutoTable.finalY + 15, 0, 0);
        doc.text(`${Number(data[0].hall.hall_per_function_charges)}`, 510, doc.lastAutoTable.finalY + 15, 0, 0);
        doc.text("Menu Charges: ", 390, doc.lastAutoTable.finalY + 35, 0, 0);
        doc.text(`${am}`, 510, doc.lastAutoTable.finalY + 35, 0, 0);
        doc.text("Addition Charges: ", 390, doc.lastAutoTable.finalY + 55, 0, 0);
        doc.text(`${sumBy(data[0].booking_data, "booking_data_price")}`, 510, doc.lastAutoTable.finalY + 55, 0, 0);
        doc.text("Total Amount: ", 390, doc.lastAutoTable.finalY + 75, 0, 0);
        doc.text(`${data[0].booking_total_price}`, 510, doc.lastAutoTable.finalY + 75, 0, 0);
        doc.text("Discount: ", 390, doc.lastAutoTable.finalY + 95, 0, 0);
        doc.text(`${data[0].booking_discount}${data[0].booking_discount_type == "Cash" ? "" : "%"}`, 510, doc.lastAutoTable.finalY + 95, 0, 0);
        doc.text("Tax: ", 390, doc.lastAutoTable.finalY + 115, 0, 0);
        doc.text(`${data[0].tax}%`, 510, doc.lastAutoTable.finalY + 115, 0, 0);
        doc.text("Payable Amount: ", 390, doc.lastAutoTable.finalY + 135, 0, 0);
        doc.text(`${data[0].booking_payable_price}`, 510, doc.lastAutoTable.finalY + 135, 0, 0);
        doc.setFontSize(12);
        doc.setFont(undefined, 'bold');
        doc.text("Term And Condition", 45, doc.lastAutoTable.finalY + 15, 0, 0)
        doc.setFont(undefined, 'normal')
        doc.setFontSize(10);
        var splitText = doc.splitTextToSize(termAndCondition, 300);
        doc.text(splitText, 45, doc.lastAutoTable.finalY + 30, 0, 10)
        doc.setProperties({ title: `Marquee Booking Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf` })
        var string = doc.output('datauristring', 'dddd');
        var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
        var x = window.open('Demand Report');
        x.document.open('Demand Report');
        x.document.write(embed);
        x.document.close();
    }
}
// bookig Report for kitchen
export const requestMarqueeEventReportForKitchen = () => {
    return {
        type: "REQUEST_MARQUEE_EVENT_REPORT_FOR_KITCHEN",
    };
};
export const successMarqueeEventReportForKitchen = (data) => {
    return {
        type: "SUCCESS_MARQUEE_EVENT_REPORT_FOR_KITCHEN",
        payload: data,
    };
};
export const errorMarqueeEventReportForKitchen = () => {
    return {
        type: "ERROR_MARQUEE_EVENT_REPORT_FOR_KITCHEN",
    };
};

export const SearchMarqueeEventReportForKitchen = (data) => {
    console.log(data, "abc")
    return (dispatch) => {
        //  dispatch(requestMarqueeExpenseReport()) 
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "potrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(13);
        doc.setFont('TimesNewRoman', 'Normal')
        const title = "Event Details";
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
        const eventDate = moment(data[0].booking_date).format('Do MMMM YYYY');
        const eventTime = data[0].event_shift.event_shift_name;
        const eventType = data[0].event.event_name;
        const eventHall = data[0].hall.hall_name;
        const hallprice = data[0].hall.hall_per_function_charges;
        const invoice = data[0].booking_id;
        const Subevent = data[0].sub_event.sub_event_name;
        const customerName = data[0].customer_table.customer_name;
        const customerAddress = data[0].customer_table.customer_address;
        const customerEmail = data[0].customer_table.customer_email;
        const customerPh = data[0].customer_table.customer_phone;
        const am = data[0].booking_person * data[0].charging_price;
        const damytax = data[0].booking_total_price / 100;
        console.log(damytax, "tax")
        const damytax1 = Number(damytax) * 5;
        console.log(damytax1, "tax1")
        const taxAmount = Number(data[0].booking_total_price) + damytax1;
        console.log(taxAmount, "tax3")
        // const headers = [["Dish", "Person", "Menu Price", "Amount"]];
        const headers = [["Dish"]];
        // console.log(getData[0].booking,"pppppp")
        // console.log(data,"abc")
        const dishes = data[0].menu
        console.log(dishes, "sssssssssssssssss")
        const data1 = dishes.menu_data.map((elt, index) => [elt.master_control_item.master_control_item_name])
        let content = {
            startY: 365,
            startX: 10,
            head: headers,
            body: data1
        }
        doc.addImage(logo, 'PNG', 40, 15, 100, 100);
        doc.setFontSize(20);
        doc.setFont(undefined, 'bold')
        doc.text(companyName, 150, 40, 0, 20);
        doc.setFontSize(13);
        doc.setFont('TimesNewRoman', 'Normal')
        doc.text(companyAddress, 150, 60, 0, 20);
        doc.text(companyNtn, 150, 80, 0, 20);
        doc.setFontSize(14);
        doc.setFont(undefined, 'bold')
        doc.text(title, 40, 240);
        doc.setFontSize(12);
        doc.setFont(undefined, 'normal')
        doc.text(`Event Date: ${eventDate}`, 40, 280, 0, 0);
        doc.text(`Event Time: ${eventTime}`, 40, 300, 0, 0);
        doc.text(`Menu Name: ${data[0].menu.menu_name}`, 40, 320, 0, 0);
        doc.text(`Total Person: ${data[0].booking_person}`, 370, 320, 0, 0);
        // doc.text(`Menu Per Person Price: ${data[0].charging_price}`, 40, 340, 0, 0);
        doc.text(`Event Type: ${eventType}`, 370, 280, 0, 0)
        doc.text(`Hall name: ${eventHall}`, 40, 260, 0, 0)
        doc.text(`Sub Event Type: ${Subevent}`, 370, 300, 0, 0)
        doc.text(`Invoice no: ${invoice}`, 370, 260, 0, 0)
        doc.setFontSize(16);
        doc.setFont(undefined, 'bold')
        doc.text("Clients/ Customer", 40, 140, 0, 0);
        doc.setFontSize(12);
        doc.setFont(undefined, 'normal')
        doc.text(`Name: ${customerName}`, 40, 160, 0, 0);
        doc.text(`Address: ${customerAddress}`, 40, 180, 0, 0);
        doc.text(`Email: ${customerEmail}`, 40, 200, 0, 0);
        doc.text(`Mobile: ${customerPh}`, 40, 220, 0, 0);
        doc.setFontSize(12);
        doc.setFont(undefined, 'bold')
        doc.text(`MENU DETAILS`, 220, 355, 0, 0);
        doc.setFontSize(12);
        doc.setFont(undefined, 'normal')
        doc.autoTable(content);
        doc.setFontSize(12);
        doc.setFont(undefined, 'bold')
        doc.text("Additional Items", 240, doc.lastAutoTable.finalY + 15, 0, 0);
        console.log(data[0].booking_data, 'hm')
        const header2 = [["Description"]];
        let addonsTotalPrice = sumBy(data[0].booking_data, 'booking_data_price')
        console.log(addonsTotalPrice, "total")
        const data2 = data[0].booking_data.map((elt, index) => [elt.master_control_item.master_control_item_name])
        let content1 = {
            startY: doc.lastAutoTable.finalY + 25,
            startX: 10,
            head: header2,
            body: data2
        }
        doc.autoTable(content1);  
        doc.setFontSize(10);
        doc.setFont(undefined, 'bold');

        // doc.text("Hall Charges: ", 390, doc.lastAutoTable.finalY + 15, 0, 0);
        // doc.text(`${Number(data[0].hall.hall_per_function_charges)}`, 510, doc.lastAutoTable.finalY + 15, 0, 0);
        // doc.text("Menu Charges: ", 390, doc.lastAutoTable.finalY + 35, 0, 0);
        // doc.text(`${am}`, 510, doc.lastAutoTable.finalY + 35, 0, 0);
        // doc.text("Addition Charges: ", 390, doc.lastAutoTable.finalY + 55, 0, 0);
        // doc.text(`${sumBy(data[0].booking_data, "booking_data_price")}`, 510, doc.lastAutoTable.finalY + 55, 0, 0);
        // doc.text("Total Amount: ", 390, doc.lastAutoTable.finalY + 75, 0, 0);
        // doc.text(`${data[0].booking_total_price}`, 510, doc.lastAutoTable.finalY + 75, 0, 0);
        // doc.text("Discount: ", 390, doc.lastAutoTable.finalY + 95, 0, 0);
        // doc.text(`${data[0].booking_discount}${data[0].booking_discount_type == "Cash" ? "" : "%"}`, 510, doc.lastAutoTable.finalY + 95, 0, 0);
        // doc.text("Tax: ", 390, doc.lastAutoTable.finalY + 115, 0, 0);
        // doc.text(`${data[0].tax}%`, 510, doc.lastAutoTable.finalY + 115, 0, 0);
        // doc.text("Payable Amount: ", 390, doc.lastAutoTable.finalY + 135, 0, 0);
        // doc.text(`${data[0].booking_payable_price}`, 510, doc.lastAutoTable.finalY + 135, 0, 0);

        // doc.setFontSize(12);
        // doc.setFont(undefined, 'bold');
        // doc.text("Term And Condition", 45, doc.lastAutoTable.finalY + 15, 0, 0)
        // doc.setFont(undefined, 'normal')
        // doc.setFontSize(10);
        // var splitText = doc.splitTextToSize(termAndCondition, 300);
        // doc.text(splitText, 45, doc.lastAutoTable.finalY + 30, 0, 10)
        doc.setProperties({ title: `Marquee Booking Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf` })
        var string = doc.output('datauristring', 'dddd');
        var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
        var x = window.open('Demand Report');
        x.document.open('Demand Report');
        x.document.write(embed);
        x.document.close();
    }
}
//get customer
export const getVisitorMarquee = () => {
    return (dispatch) => {
        dispatch(requestGetVisitorMarquee());
        API.get(`/visitor_customer/get_visitor_customer`).then((res) => {
            let getData = res.data;
            dispatch(successGetVisitorMarquee(getData));
        }).catch((error) => {
            dispatch(errorGetVisitorMarquee());
        })
    };
};
export const requestGetVisitorMarquee = () => {
    return {
        type: "REQUEST_GET_VISITOR_MARQUEE",
    };
};
export const successGetVisitorMarquee = (data) => {
    return {
        type: "SUCCESS_GET_VISITOR_MARQUEE",
        payload: data,
    };
};
export const errorGetVisitorMarquee = () => {
    return {
        type: "ERROR_GET_VISITOR_MARQUEE",
    };
}
//dashboard
export const requestDashboardMarquee = () => {
    return {
        type: "REQUEST_DASHBOARD_MARQUEE",
    };
};
export const successDashboardMarquee = (data) => {
    return {
        type: "SUCCESS_DASHBOARD_MARQUEE",
        payload: data,
    };
};
export const errorDashboardMarquee = () => {
    return {
        type: "ERROR_DASHBOARD_MARQUEE",
    };
}
export const getDashboardMarquee = () => {
    return (dispatch) => {
        dispatch(requestDashboardMarquee());//http://localhost:3001/api/booking/get_booking_with_date
        API.post(`/booking/get_booking_with_date`).then((res) => {
            let getData = res.data;

            dispatch(successDashboardMarquee(getData));
        }).catch((error) => {
            console.log(error, "rrrrrrrrrrrrrrrrrrrrrr")
            dispatch(errorDashboardMarquee());
        })
    };
};
//add hall call
export const addHall = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddHall());
        API.post(`/hall/add_hall`, data).then((res) => {
            handleVisible();
            let getData = res.data;
            dispatch(getHall());
            dispatch(successAddHall(getData));
        }).catch((error) => {
            handleDangerVisible();
            dispatch(errorAddHall());
        })
    };
};
/*Menu*/
export const addMenu = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddMenu());
        API.post(`/menu/add_menu`, data).then((res) => {
            handleVisible();
            let getData = res.data;
            dispatch(getMenu(2));
            dispatch(successAddMenu(getData));
        }).catch((error) => {
            handleDangerVisible();
            dispatch(errorAddMenu());
        })
    };
};
export const deleteMenu = (id) => {
    return (dispatch) => {
        dispatch(requestDeleteMenu());
        API.delete(`/menu/delete_menu/${id}`).then((res) => {
            let getData = res.data;
            dispatch(successDeleteMenu());
            dispatch(getMenu(2));
        }).catch((error) => {
            dispatch(errorDeleteMenu());

        })
    };
};
export const getMenu = (id) => {
    return (dispatch) => {
        dispatch(requestGetMenu());
        console.log(id, 'id')
        API.get(`/menu/get_menu/${id}`).then((res) => {
            let getData = res.data;
            dispatch(successGetMenu(getData));
        }).catch((error) => {
            dispatch(errorGetMenu());
        })
    };
};
export const requestUpdateMenu = () => {
    return {
        type: "REQUEST_UPDATE_MENU",
    };
};
export const successUpdateMenu = (data) => {
    return {
        type: "SUCCESS_UPDATE_MENU",
        payload: data,
    };
};
export const errorUpdateMenu = () => {
    return {
        type: "ERROR_UPDATE_MENU",
    };
};
export const UpdateMenu = (newData, oldData) => {
    console.log('newData', newData);
    // let hall_id=newData.hall_id;
    return (dispatch) => {
        dispatch(requestUpdateMenu());
        // API.get(`/hall/add_hall/:${hall_id}`, newData).then((res) => {
        API.put(`/menu/update_menu/${oldData.menu_id}`, newData).then((res) => {
            let getData = res.data;
            dispatch(successUpdateMenu([newData]));
            dispatch(getMenu(2));
        }).catch((error) => {
            dispatch(errorUpdateMenu());
        })
    };
};
export const requestGetMenu = () => {
    return {
        type: "REQUEST_GET_MENU",
    };
};
export const successGetMenu = (data) => {
    return {
        type: "SUCCESS_GET_MENU",
        payload: data,
    };
};
export const errorGetMenu = () => {
    return {
        type: "ERROR_GET_MENU",
    };
}
export const requestDeleteMenu = () => {
    return {
        type: "REQUEST_DELETE_MENU",
    };
};
export const successDeleteMenu = (data) => {
    return {
        type: "SUCCESS_DELETE_MENU",
        payload: data,
    };
};
export const errorDeleteMenu = () => {
    return {
        type: "ERROR_DELETE_MENU",
    };
};

/*Expenses*/

export const addExpense = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddExpense());
        API.post(`/expense_form/add_expense_form`, data).then((res) => {
            handleVisible();
            let getData = res.data;
            dispatch(getExpense(2));
            dispatch(successAddExpense(getData));
        }).catch((error) => {
            handleDangerVisible();
            dispatch(errorAddExpense());
        })
    };
};
export const deleteExpense = (id) => {
    return (dispatch) => {
        dispatch(requestDeleteExpense());
        console.log(id, 'old1')
        API.delete(`/expense_form/delete_expense_form/${id}`).then((res) => {
            let getData = res.data;
            dispatch(successDeleteExpense());
            dispatch(getExpense(2));
        }).catch((error) => {
            dispatch(errorDeleteExpense());

        })
    };
};
export const getExpense = () => {
    return (dispatch) => {
        dispatch(requestGetExpense());
        API.get(`/expense_form/get_expense_form`).then((res) => {
            let getData = res.data;
            dispatch(successGetExpense(getData));
        }).catch((error) => {
            dispatch(errorGetExpense());
        })
    };
};
export const requestUpdateExpence = () => {
    return {
        type: "REQUEST_UPDATE_EXPENSE",
    };
};
export const successUpdateExpence = (data) => {
    return {
        type: "SUCCESS_UPDATE_EXPENSE",
        payload: data,
    };
};
export const errorUpdateExpence = () => {
    return {
        type: "ERROR_UPDATE_EXPENSE",
    };
};
export const UpdateExpence = (newData, oldData) => {
    console.log('newData', newData);
    // let hall_id=newData.hall_id;
    return (dispatch) => {
        dispatch(requestUpdateExpence());
        // API.get(`/hall/add_hall/:${hall_id}`, newData).then((res) => {
        API.put(`/expense_form/update_expense_form/${oldData.expense_form_data_id}`, newData).then((res) => {
            let getData = res.data;
            dispatch(successUpdateExpence([newData]));
            dispatch(getExpense());
        }).catch((error) => {
            dispatch(errorUpdateExpence());
        })
    };
};
export const requestGetExpense = () => {
    return {
        type: "REQUEST_GET_EXPENSE",
    };
};
export const successGetExpense = (data) => {
    return {
        type: "SUCCESS_GET_EXPENSE",
        payload: data,
    };
};
export const errorGetExpense = () => {
    return {
        type: "ERROR_GET_EXPENSE",
    };
}
export const requestDeleteExpense = () => {
    return {
        type: "REQUEST_DELETE_EXPENSE",
    };
};
export const successDeleteExpense = (data) => {
    return {
        type: "SUCCESS_DELETE_EXPENSE",
        payload: data,
    };
};
export const errorDeleteExpense = () => {
    return {
        type: "ERROR_DELETE_EXPENSE",
    };
};
/*Mater Controls */
export const addMasterControl = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddHall());
        API.post(`/master_control_item/add_master_control_item`, data).then((res) => {
            handleVisible();
            let getData = res.data;
            dispatch(getMasterControl(2));
            dispatch(successAddMasterControl(getData));
        }).catch((error) => {
            handleDangerVisible();
            dispatch(errorAddMasterControl());
        })
    };
};
export const deleteMasterControl = (id) => {
    return (dispatch) => {
        dispatch(requestDeleteEvent());
        API.delete(`/master_control_item/delete_master_control_item/${id}`).then((res) => {
            let getData = res.data;
            dispatch(successDeleteEvent());
            dispatch(getMasterControl(2));
        }).catch((error) => {
            dispatch(errorDeleteMasterControl());

        })
    };
};
export const getMasterControl = (id) => {
    return (dispatch) => {
        dispatch(requestGetMasterControl());
        API.get(`/master_control_item/get_master_control_item/${id}`).then((res) => {
            let getData = res.data;
            dispatch(successGetMasterControl(getData));
        }).catch((error) => {
            dispatch(errorGetMasterControl());
        })
    };
};
export const requestUpdateMasterControl = () => {
    return {
        type: "REQUEST_UPDATE_MASTER_CONTROL",
    };
};
export const successUpdateMasterControl = (data) => {
    return {
        type: "SUCCESS_UPDATE_MASTER_CONTROL",
        payload: data,
    };
};
export const errorUpdateMasterControl = () => {
    return {
        type: "ERROR_UPDATE_MASTER_CONTROL",
    };
};
export const UpdateMasterControl = (newData, oldData) => {
    console.log('newData', newData);
    // let hall_id=newData.hall_id;
    return (dispatch) => {
        dispatch(requestUpdateMasterControl());
        // API.get(`/hall/add_hall/:${hall_id}`, newData).then((res) => {
        API.put(`/master_control_item/update_master_control_item/${oldData.master_control_item_id}`, newData).then((res) => {
            let getData = res.data;
            dispatch(successUpdateMasterControl([newData]));
            dispatch(getMasterControl(2));
        }).catch((error) => {
            dispatch(errorUpdateMasterControl());
        })
    };
};
export const requestGetMasterControl = () => {
    return {
        type: "REQUEST_GET_MASTER_CONTROL",
    };
};
export const successGetMasterControl = (data) => {
    return {
        type: "SUCCESS_GET_MASTER_CONTROL",
        payload: data,
    };
};
export const errorGetMasterControl = () => {
    return {
        type: "ERROR_GET_MASTER_CONTROL",
    };
}
/*Events*/
export const addEvent = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddHall());
        API.post(`/event/add_event`, data).then((res) => {
            handleVisible();
            let getData = res.data;
            dispatch(getEvent(2));
            dispatch(successAddEvent(getData));
        }).catch((error) => {
            handleDangerVisible();
            dispatch(errorAddEvent());
        })
    };
};
export const deleteEvent = (event_id) => {
    return (dispatch) => {
        dispatch(requestDeleteEvent());
        API.delete(`/event/delete_event/${event_id}`).then((res) => {
            let getData = res.data;
            dispatch(successDeleteEvent());
            dispatch(getEvent(2));
        }).catch((error) => {
            dispatch(errorDeleteEvent());

        })
    };
};
//event get
export const getEvent = (id) => {
    return (dispatch) => {
        dispatch(requestGetEvent());
        API.get(`/event/get_event/${id}`).then((res) => {
            let getData = res.data;
            dispatch(successGetEvent(getData));
        }).catch((error) => {
            dispatch(errorGetEvent());
        })
    };
};
//update evet
export const requestUpdateEvent = () => {
    return {
        type: "REQUEST_UPDATE_EVENT",
    };
};
export const successUpdateEvent = (data) => {
    return {
        type: "SUCCESS_UPDATE_EVENT",
        payload: data,
    };
};
export const errorUpdateEvent = () => {
    return {
        type: "ERROR_UPDATE_EVENT",
    };
};
export const UpdateEvent = (newData, oldData) => {
    console.log('newData', newData);
    // let hall_id=newData.hall_id;
    return (dispatch) => {
        dispatch(requestUpdateEvent());
        // API.get(`/hall/add_hall/:${hall_id}`, newData).then((res) => {
        API.put(`/event/update_event/${oldData.event_id}`, newData).then((res) => {
            let getData = res.data;
            dispatch(successUpdateEvent([newData]));
            dispatch(getEvent(2));
        }).catch((error) => {
            dispatch(errorUpdateEvent());
        })
    };
};
export const requestGetEvent = () => {
    return {
        type: "REQUEST_GET_EVENT",
    };
};
export const successGetEvent = (data) => {
    return {
        type: "SUCCESS_GET_EVENT",
        payload: data,
    };
};
export const errorGetEvent = () => {
    return {
        type: "ERROR_GET_EVENT",
    };
}
/**/
/*Bookigs */
/*Book Events*/
export const requestAddBooking = () => {
    return {
        type: "REQUEST_ADD_EVENT",
    };
};
export const successAddBooking = (data) => {
    return {
        type: "SUCCESS_ADD_EVENT",
        payload: data,
    };
};
export const errorAddBooking = () => {
    return {
        type: "ERROR_ADD_EVENT",
    };
};
export const addBooking = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddBooking());
        API.post(`/booking/add_booking`, data).then((res) => {
            console.log(res, "ressssssssss")
            handleVisible(res.data.message);
            let getData = res.data;
            dispatch(getBooking(2));
            dispatch(successAddBooking(getData));
        }).catch((error) => {
            console.log(error, "errrrrrrrrr")
            handleDangerVisible(error.response.data.message);
            dispatch(errorAddBooking());
        })
    };
};
export const updateBooking = (data, id, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddBooking());
        API.put(`/booking/update_booking/${id}`, data).then((res) => {
            handleVisible(res.data.message);
            let getData = res.data;
            dispatch(getBooking(2));
            //dispatch(successAddBooking(getData));
        }).catch((error) => {
            handleDangerVisible(error.response.data.message);
            //dispatch(errorAddBooking());
        })
    };
};
export const deleteBooking = (event_id) => {
    return (dispatch) => {
        dispatch(requestDeleteEvent());
        API.delete(`/booking/delete_booking/${event_id}`).then((res) => {
            let getData = res.data;
            dispatch(successDeleteEvent());
            dispatch(getBooking());
        }).catch((error) => {
            dispatch(errorDeleteEvent());

        })
    };
};
export const getBooking = () => {
    return (dispatch) => {
        dispatch(requestGetBooking());
        API.get(`/booking/get_booking`).then((res) => {
            let getData = res.data;
            dispatch(successGetBooking(getData));
        }).catch((error) => {
            dispatch(errorGetBooking());
        })
    };
};
export const requestGetBooking = () => {
    return {
        type: "REQUEST_GET_BOOKING",
    };
};
export const successGetBooking = (data) => {
    return {
        type: "SUCCESS_GET_BOOKING",
        payload: data,
    };
};
export const errorGetBooking = () => {
    return {
        type: "ERROR_GET_BOOKING",
    };
}
export const getBookingStatus = () => {
    return (dispatch) => {
        dispatch(requestGetBookingStatus());
        API.get(`/booking/get_confirmed_booking`).then((res) => {
            let getData = res.data;
            dispatch(successGetBookingStatus(getData));
        }).catch((error) => {
            dispatch(errorGetBookingStatus());
        })
    };
};
export const requestGetBookingStatus = () => {
    return {
        type: "REQUEST_GET_BOOKING_STATUS",
    };
};
export const successGetBookingStatus = (data) => {
    return {
        type: "SUCCESS_GET_BOOKING_STATUS",
        payload: data,
    };
};
export const errorGetBookingStatus = () => {
    return {
        type: "ERROR_GET_BOOKING_STATUS",
    };
}
//get bookig by id
export const requestGetBookingById = () => {
    return {
        type: "REQUEST_GET_BOOKING_BY_ID",
    };
};
export const successGetBookingById = (data) => {
    return {
        type: "SUCCESS_GET_BOOKING_BY_ID",
        payload: data,
    };
};
export const errorGetBookingById = () => {
    return {
        type: "ERROR_GET_BOOKING_BY_ID",
    };
};
export const getBookingById = (id) => {
    return (dispatch) => {
        dispatch(requestGetBookingById());
        API.get(`/booking/get_booking_by_id/${id}`).then((res) => {
            let getData = res.data;
            dispatch(successGetBookingById(getData));
        }).catch((error) => {
            dispatch(errorGetBookingById());
        })
    };
};
// 
export const requestGetBookingByIdForEdit = () => {
    return {
        type: "REQUEST_GET_BOOKING_BY_ID_FOR_EDIT",
    };
};
export const successGetBookingByIdForEdit = (data) => {
    return {
        type: "SUCCESS_GET_BOOKING_BY_ID_FOR_EDIT",
        payload: data,
    };
};
export const errorGetBookingByIdForEdit = () => {
    return {
        type: "ERROR_GET_BOOKING_BY_ID_FOR_EDIT",
    };
};
export const getBookingByIdForEdit = (id) => {
    return (dispatch) => {
        dispatch(requestGetBookingByIdForEdit());
        API.get(`/booking/get_booking_with_installment_total/${id}`).then((res) => {
            let getData = res.data;
            dispatch(successGetBookingByIdForEdit(getData));
        }).catch((error) => {
            console.log(error, "errrrrrrrrrrr")
            dispatch(errorGetBookingByIdForEdit());
        })
    };
};
//complete bookig
export const requestcompleteBooking = () => {
    return {
        type: "REQUEST_COMPLETE_BOOKING",
    };
};
export const successcompleteBooking = () => {
    return {
        type: "SUCCESS_COMPLETE_BOOKING",
        //  payload: data,
    };
};
export const errorcompleteBooking = () => {
    return {
        type: "ERROR_COMPLETE_BOOKING",
    };
};
export const completeBooking = (id,handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestcompleteBooking());
        API.put(`booking/update_booking_status/${id}`).then((res) => {
            console.log(res.data,"ressssssssss")
             handleVisible(res.data.message);
            //  let getData = res.data;
            // dispatch(getBooking(2));
            dispatch(successcompleteBooking());
        }).catch((error) => {
            console.log(error,"errrrrrrr")
            handleDangerVisible(error.response.data.message);
            dispatch(errorcompleteBooking());
        })
    };
};
//get customer by id
export const requestGetCustomerById = () => {
    return {
        type: "REQUEST_GET_CUSTOMER_BY_ID",
    };
};
export const successGetCustomerById = (data) => {
    console.log(data, "check2")
    return {
        type: "SUCCESS_GET_CUSTOMER_BY_ID",
        payload: data,
    };
};
export const errorGetCustomerById = () => {
    return {
        type: "ERROR_GET_CUSTOMER_BY_ID",
    };
};
export const getCustomerById = (id) => {
    return (dispatch) => {
        dispatch(requestGetCustomerById());//booking_installment/get_instalment_by_cusomerId/:id
        API.get(`/booking_installment/get_instalment_by_cusomerId/${id}`).then((res) => {
            let getData = res.data;
            console.log(getData, 'check1')
            dispatch(successGetCustomerById(getData));
        }).catch((error) => {
            dispatch(errorGetCustomerById());
        })
    };
};
//get bookig by customer id
export const requestGetBookingByCustomerId = () => {
    return {
        type: "REQUEST_GET_BOOKING_BY_CUSTOMER_ID",
    };
};
export const successGetBookingByCustomerId = (data) => {
    console.log(data, "check2")
    return {
        type: "SUCCESS_GET_BOOKING_BY_CUSTOMER_ID",
        payload: data,
    };
};
export const errorGetBookingByCustomerId = () => {
    return {
        type: "ERROR_GET_BOOKING_BY_CUSTOMER_ID",
    };
};
export const getBookingByCustomerId = (id) => {
    return (dispatch) => {
        dispatch(requestGetBookingByCustomerId());//booking_installment/get_instalment_by_cusomerId/:id
        API.get(`/booking/get_booking_with_customerId/${id}`).then((res) => {
            let getData = res.data;
            console.log(getData, 'check1')
            dispatch(successGetBookingByCustomerId(getData));
        }).catch((error) => {
            dispatch(errorGetBookingByCustomerId());
        })
    };
};
//update customer
export const requestUpdateCustomer = () => {
    return {
        type: "REQUEST_UPDATE_CUSTOMER",
    };
};
export const successUpdateCustomer = (data) => {
    return {
        type: "SUCCESS_UPDATE_CUSTOMER",
        payload: data,
    };
};
export const errorUpdateCustomer = () => {
    return {
        type: "ERROR_UPDATE_CUSTOMER",
    };
};
export const UpdateCustomer = (newData, oldData) => {
    console.log('newData', newData);
    // let hall_id=newData.hall_id;
    return (dispatch) => {
        dispatch(requestUpdateCustomer());
        // API.get(`/hall/add_hall/:${hall_id}`, newData).then((res) => {
        API.put(`/customer/update_customer/${oldData.customer_id}`, newData).then((res) => {
            let getData = res.data;
            dispatch(successUpdateCustomer([newData]));
            dispatch(getCustomerMarquee());
        }).catch((error) => {
            dispatch(errorUpdateCustomer());
        })
    };
};
//booking edit
export const setEditBooking = (data) => {
    console.log('data', data)
    return {
        type: "SET_EDIT_BOOKING",
        payload: data
    };
};
export const setEditBookingCustomer = (data) => {
    console.log('data', data)
    return {
        type: "SET_EDIT_BOOKING_CUSTOMER",
        payload: data
    };
};
//Hall-Get

export const requestGetHall = () => {
    return {
        type: "REQUEST_GET_HALL",
    };
};
export const successGetHall = (data) => {
    return {
        type: "SUCCESS_GET_HALL",
        payload: data,
    };
};
export const errorGetHall = () => {
    return {
        type: "ERROR_GET_HALL",
    };
};
export const getHall = () => {
    return (dispatch) => {
        dispatch(requestGetHall());
        API.get(`/hall/get_hall`).then((res) => {
            let getData = res.data;
            dispatch(successGetHall(getData));
        }).catch((error) => {
            dispatch(errorGetHall());
        })
    };
};

//Hall-Update
export const requestUpdateHall = () => {
    return {
        type: "REQUEST_UPDATE_HALL",
    };
};
export const successUpdateHall = (data) => {
    return {
        type: "SUCCESS_UPDATE_HALL",
        payload: data,
    };
};
export const errorUpdateHall = () => {
    return {
        type: "ERROR_UPDATE_HALL",
    };
};

export const UpdateHall = (newData, oldData) => {
    console.log('newData', newData);
    // let hall_id=newData.hall_id;
    return (dispatch) => {
        dispatch(requestUpdateHall());
        // API.get(`/hall/add_hall/:${hall_id}`, newData).then((res) => {
        API.put(`/hall/update_hall/${oldData.hall_id}`, newData).then((res) => {
            let getData = res.data;
            dispatch(successUpdateHall([newData]));
            dispatch(getHall());
        }).catch((error) => {
            dispatch(errorUpdateHall());
        })
    };
};

export const requestDeleteEvent = () => {
    return {
        type: "REQUEST_DELETE_EVENT",
    };
};
export const successDeleteEvent = (data) => {
    return {
        type: "SUCCESS_DELETE_EVENT",
        payload: data,
    };
};
export const errorDeleteEvent = () => {
    return {
        type: "ERROR_DELETE_EVENT",
    };
};
//MAster Control
export const requestAddMasterControl = () => {
    return {
        type: "REQUEST_ADD_MASTER_CONTROL",
    };
};
export const successAddMasterControl = (data) => {
    return {
        type: "SUCCESS_ADD_MASTER_CONTROL",
        payload: data,
    };
};
export const errorAddMasterControl = () => {
    return {
        type: "ERROR_ADD_MASTER_CONTROL",
    };
};

export const requestDeleteMasterControl = () => {
    return {
        type: "REQUEST_DELETE_MASTER_CONTROL",
    };
};
export const successDeleteMasterControl = (data) => {
    return {
        type: "SUCCESS_DELETE_MASTER_CONTROL",
        payload: data,
    };
};
export const errorDeleteMasterControl = () => {
    return {
        type: "ERROR_DELETE_MASTER_CONTROL",
    };
};
//Hall-Delete
export const requestDeleteHall = () => {
    return {
        type: "REQUEST_DELETE_HALL",
    };
};
export const successDeleteHall = (data) => {
    return {
        type: "SUCCESS_DELETE_HALL",
        payload: data,
    };
};
export const errorDeleteHall = () => {
    return {
        type: "ERROR_DELETE_HALL",
    };
};
export const deleteHall = (hall_id) => {
    return (dispatch) => {
        dispatch(requestDeleteHall());
        API.delete(`/hall/delete_hall/${hall_id}`).then((res) => {
            let getData = res.data;
            dispatch(successDeleteHall());
            dispatch(getHall());
        }).catch((error) => {
            dispatch(errorDeleteHall());

        })
    };
};
//delete customer
export const requestDeleteCustomer = () => {
    return {
        type: "REQUEST_DELETE_CUSTOMER",
    };
};
export const successDeleteCustomer = (data) => {
    return {
        type: "SUCCESS_DELETE_CUSTOMER",
        payload: data,
    };
};
export const errorDeleteCustomer = () => {
    return {
        type: "ERROR_DELETE_CUSTOMER",
    };
};
export const deleteCustomer = (customer_id) => {
    return (dispatch) => {
        dispatch(requestDeleteCustomer());
        API.delete(`/customer/delete_customer/${customer_id}`).then((res) => {
            let getData = res.data;
            dispatch(successDeleteCustomer());
            dispatch(getCustomerMarquee());
        }).catch((error) => {
            dispatch(errorDeleteCustomer());

        })
    };
};
//SubEvent-Add
export const requestAddSubEvent = () => {
    return {
        type: "REQUEST_ADD_DEPARTMENT",
    };
};
export const successAddSubEvent = (data) => {
    return {
        type: "SUCCESS_ADD_DEPARTMENT",
        payload: data,
    };
};
export const errorAddSubEvent = () => {
    return {
        type: "ERROR_ADD_DEPARTMENT",
    };
};

export const addSubEvent = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddSubEvent());
        API.post(`/sub_event/add_sub_event`, data).then((res) => {
            let getData = res.data;
            dispatch(getSubEvent(2));
            dispatch(successAddSubEvent(getData));
            handleVisible();
        }).catch((error) => {
            dispatch(errorAddSubEvent());
            handleDangerVisible()
        })
    };
};


//SubEvent-Update
export const requestUpdateSubEvent = () => {
    return {
        type: "REQUEST_UPDATE_SUBEVENT",
    };
};
export const successUpdateSubEvent = (data) => {
    return {
        type: "SUCCESS_UPDATE_SUBEVENT",
        payload: data,
    };
};
export const errorUpdateSubEvent = () => {
    return {
        type: "ERROR_UPDATE_SUBEVENT",
    };
};

export const updateSubEvent = (newData, oldData) => {
    return (dispatch) => {
        dispatch(requestUpdateSubEvent());

        API.put(`/sub_event/update_sub_event/${oldData.sub_event_id}`, newData).then((res) => {
            let getData = res.data;
            dispatch(successUpdateSubEvent([newData]));
            dispatch(getSubEvent(2));
        }).catch((error) => {
            dispatch(errorUpdateSubEvent());
        })
    };
};
//SubEvent-delete
export const requestDeleteSubEvent = () => {
    return {
        type: "REQUEST_DELETE_DEPARTMENT",
    };
};
export const successDeleteSubEvent = (data) => {
    return {
        type: "SUCCESS_DELETE_DEPARTMENT",
        payload: data,
    };
};
export const errorDeleteSubEvent = () => {
    return {
        type: "ERROR_DELETE_DEPARTMENT",
    };
};

export const deleteSubEvent = (subeventId) => {
    // console.log('newData', newData);
    // let hall_id=newData.hall_id;
    return (dispatch) => {
        dispatch(requestDeleteSubEvent());
        // API.get(`/hall/add_hall/:${hall_id}`, newData).then((res) => {
        API.delete(`/sub_event/delete_sub_event/${subeventId}`).then((res) => {
            let getData = res.data;
            dispatch(successDeleteSubEvent());
            dispatch(getSubEvent(2));
        }).catch((error) => {
            dispatch(errorDeleteSubEvent());
        })
    };
};

//SubEvent-Get
export const requestgetSubEvent = () => {
    return {
        type: "REQUEST_GET_DEPARTMENT",
    };
};
export const successgetSubEvent = (data) => {
    return {
        type: "SUCCESS_GET_SUBEVENT",
        payload: data,
    };
};
export const errorgetSubEvent = () => {
    return {
        type: "ERROR_GET_SUBEVENT",
    };
};

export const getSubEvent = (id) => {
    return (dispatch) => {
        dispatch(requestgetSubEvent());
        API.get(`/sub_event/get_sub_event/${id}`).then((res) => {
            let getData = res.data;
            dispatch(successgetSubEvent(getData));
        }).catch((error) => {
            dispatch(errorgetSubEvent());
        })
    };
};
// SubEvent-Get by Event Id
export const requestgetSubEventByEventId = () => {
    return {
        type: "REQUEST_GET_SUBEVENT_BY_EVENTID",
    };
};
export const successgetSubEventByEventId = (data) => {
    return {
        type: "SUCCESS_GET_SUBEVENT_BY_EVENTID",
        payload: data,
    };
};
export const errorgetSubEventByEventId = () => {
    return {
        type: "ERROR_GET_SUBEVENT_BY_EVENTID",
    };
};

export const getSubEventByEventId = (id) => {
    return (dispatch) => {
        dispatch(requestgetSubEventByEventId());
        API.get(`/sub_event/get_sub_event_with_eventId/${id}`).then((res) => {
            let getData = res.data;
            dispatch(successgetSubEventByEventId(getData));
        }).catch((error) => {
            dispatch(errorgetSubEventByEventId());
        })
    };
};
//SubEvent-Get
export const requestgetSubEventByHallId = () => {
    return {
        type: "REQUEST_GET_DEPARTMENT_BY_HALLID",
    };
};
export const successgetSubEventByHallId = (data) => {
    return {
        type: "SUCCESS_GET_SUBEVENT_BY_HALLID",
        payload: data,
    };
};
export const errorgetSubEventByHallId = () => {
    return {
        type: "ERROR_GET_SUBEVENT_BY_HALLID",
    };
};

export const getSubEventByHallId = (hallId) => {
    return (dispatch) => {
        dispatch(requestgetSubEventByHallId());
        API.get(`/department/get_department_by_hallId/${hallId}`).then((res) => {
            let getData = res.data;
            dispatch(successgetSubEventByHallId(getData));
        }).catch((error) => {
            dispatch(errorgetSubEventByHallId());
        })
    };
};
//Designation
export const requestAddDesignation = () => {
    return {
        type: "REQUEST_ADD_DESIGNATION",
    };
};
export const successAddDesignation = (data) => {
    return {
        type: "SUCCESS_ADD_DESIGNATION",
        payload: data,
    };
};
export const errorAddDesignation = () => {
    return {
        type: "ERROR_ADD_DESIGNATION",
    };
};

export const addHRDesignation = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddDesignation());
        API.post(`/designation/add_designation`, data).then((res) => {
            let getDesignationData = res.data;
            dispatch(GetHRDesignation());
            dispatch(successAddDesignation(getDesignationData));
            handleVisible();
        }).catch((error) => {
            dispatch(errorAddDesignation());
            handleDangerVisible();
        })
    };
};

export const requestDeleteDesignation = () => {
    return {
        type: "REQUEST_DELETE_DESIGNATION",
    };
};
export const successDeleteDesignation = (designationId) => {
    return {
        type: "SUCCESS_DELETE_DESIGNATION",
        designation_id: designationId,
    };
};
export const errorDeleteDesignation = () => {
    return {
        type: "ERROR_DELETE_DESIGNATION",
    };
};

export const deleteDesignation = (designation_id) => {
    return dispatch => {
        dispatch(requestDeleteDesignation());
        API.delete(`/designation/delete_designation/${designation_id}`)
            .then(res => {
                dispatch(GetHRDesignation());
                dispatch(successDeleteDesignation(designation_id))
            }).catch((error) =>
                dispatch(errorDeleteDesignation()));
    }
};
//Get-Image

export const requestGetImageForAdress = () => {
    return {
        type: "REQUEST_GET_IMAGE_HR",
    };
};
export const successGetImageForAdress = (data) => {
    return {
        type: "SUCCESS_GET_IMAGE_HR",
        payload: data,
    };
};

export const errorGetImageForAdress = () => {
    return {
        type: "ERROR_GET_IMAGE_HR",
    };
};

export const GetImageForAdress = (address) => {
    return (dispatch) => {
        dispatch(requestGetImageForAdress());
        API.get(`/employee/get_image_data_by_path/${address}`).then((res) => {
            let getLeaveData = res.data;
            console.log('getLeaveData', getLeaveData)
            dispatch(successGetImageForAdress(getLeaveData));
        }).catch((error) => {
            dispatch(errorGetImageForAdress());
        })
    };

}
export const UpdateRequestHR = () => {
    return {
        type: "UPDATE_REQUEST_HR",
    };
};
export const UpdateSuccessHR = (data) => {
    return {
        type: "UPDATE_SUCCESS_HR",
        payload: data,
    };
};

export const UpdateErrorHR = () => {
    return {
        type: "UPDATE_ERROR_HR",
    };
};

export const HRUpdateEmployee = (data, oldData, handleVisible, handleDangerVisible) => {
    const formData = new FormData();
    formData.append("uploadImage", get(data, 'uploadImage'));
    formData.append("uploadCnicImage", get(data, 'uploadCnicImage'));
    formData.append("uploadDocument", get(data, 'uploadDocument'));

    formData.append("appointment_type", `${data.appointment_type}`);
    formData.append("name", `${data.name}`);
    formData.append("father_name", `${data.father_name}`);
    formData.append("dob", `${data.dob}`);
    formData.append("gender", `${data.gender}`);
    formData.append("phone", `${data.phone}`);
    formData.append("joining_date", `${data.joining_date}`);
    formData.append("emergency_contact", `${data.emergency_contact}`);
    formData.append("marital_status", `${data.marital_status}`);
    formData.append("cnic", `${data.cnic}`);
    formData.append("uploadImage", `${data.uploadImage}`);
    formData.append("address", `${data.address}`);

    formData.append("religion", `${data.religion}`);
    // formData.append("machine_id", `${data.machine_id}`);
    formData.append("report_to", `${data.report_to}`);

    formData.append("allocation", `${data.allocation}`);

    formData.append("active_status", `${data.active_status}`);
    formData.append("employee_type", `${data.employee_type}`);
    formData.append("ntn", `${data.ntn}`);
    formData.append("eobi", `${data.eobi}`);
    formData.append("social_security", `${data.social_security}`);
    formData.append("blood_group", `${data.blood_group}`);

    formData.append("department_id", `${data.department_id}`);
    formData.append("designation_id", `${data.designation_id}`);
    formData.append("probation_period", `${data.probation_period}`);
    formData.append("probation_period_end_date", `${data.probation_period_end_date}`);


    // forEach(data.uploadCnicImage, (x, index) => {
    //     formData.append("uploadCnicImage", data.uploadCnicImage[index]);
    //   })
    //   forEach(data.uploadDocument, (x, index) => {
    //     formData.append("uploadDocument", data.uploadDocument[index]);
    //   })

    // formData.append("department", `${data.department}`);
    // formData.append("designation", `${data.designation}`);
    // formData.append("probation_period", `${data.probation_period}`);
    // formData.append("probation_period_end_date", `${data.probation_period_end_date}`);


    return (dispatch) => {
        dispatch(UpdateRequestHR());
        API.put(`/employee/up_employee/${oldData.employeeId}`, formData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((res) => {
            let getData = res.data;
            dispatch(getEmployeeData())
            dispatch(UpdateSuccessHR(getData));
            handleVisible();
        }).catch((error) => {
            dispatch(UpdateSuccessHR());
            handleDangerVisible();
        })
    };
};

// export const HRUpdateEmployee= (data, oldData) => {
//     console.log(oldData, "olddddd");
//     const formData = new FormData();
//     formData.append("uploadImage", get(data, 'uploadImage'));
//     formData.append("Name", `${data.Name}`);
//     formData.append("FatherName", `${data.FatherName}`);
//     formData.append("Dob", `${data.Dob}`);
//     formData.append("Gender", `${data.Gender}`);
//     formData.append("Phone", `${data.Phone}`);
//     formData.append("Address", `${data.Address}`);
//     formData.append("cnic", `${data.cnic}`);
//     formData.append("Salary", `${data.Salary}`);
//     formData.append("joiningDate", `${data.joiningDate}`);
//     formData.append("bankAccount", `${data.bankAccount}`);
//     formData.append("basicSalary", `${data.basicSalary}`);
//     formData.append("medicalAllowance", `${data.medicalAllowance}`);
//     formData.append("specialAllowance", `${data.specialAllowance}`);
//     forEach(data.uploadCnicImage, (x, index) => {
//         formData.append("uploadCnicImage", data.uploadCnicImage[index]);
//       })
//     // formData.append("uploadCnicImage", get(data, 'uploadCnicImage'));
//     formData.append("uploadDocument", get(data, 'uploadDocument'));
//     formData.append("arrivalTime", `${data.arrivalTime}`);
//     formData.append("breakIn", `${data.breakIn}`);
//     formData.append("breakOut", `${data.breakOut}`);
//     formData.append("holiday", `${data.holiday}`);
//     formData.append("department_id", `${data.department_id}`);
//     return (dispatch) => {
//         dispatch(UpdateRequestHR());
//         API.put(`/employee/update_employee/${oldData.employeeId}`, formData, {
//             headers: {
//                 'Content-Type': 'multipart/form-data'
//             }
//         }).then((res) => {
//             let getData = res.data;
//             dispatch(getEmployeeData())
//             dispatch(UpdateSuccessHR(getData));
//         }).catch((error) => {
//             dispatch(UpdateSuccessHR());
//         })
//     };
// };


export const requestDeleteHR = () => {
    return {
        type: "REQUEST_DELETE_HR",
    };
};
export const successDeleteHR = (employeeId) => {
    return {
        type: "SUCCESS_DELETE_HR",
        employeeId: employeeId,
    };
};
export const errorDeleteHR = () => {
    return {
        type: "ERROR_DELETE_HR",
    };
};

export const deleteHR = (employeeId) => {
    return dispatch => {
        dispatch(requestDeleteHR());
        API.delete(`/employee/delete_data/${employeeId}`)
            .then(res => {
                dispatch(getEmployeeData());
                dispatch(successDeleteHR(employeeId))
            }).catch((error) =>
                dispatch(errorDeleteHR()));
    }
};

//HR Policy
export const requestGetHRPolicy = () => {
    return {
        type: "REQUEST_GET_HR_POLICY",
    };
};
export const successGetHRPolicy = (data) => {
    return {
        type: "SUCCESS_GET_HR_POLICY",
        payload: data,
    };
};
export const errorGetHRPolicy = () => {
    return {
        type: "ERROR_GET_HR_POLICY",
    };
};

export const GetHRPolicy = () => {
    return (dispatch) => {
        dispatch(requestGetHRPolicy());
        API.get(`/policy/get_policy`).then((res) => {
            let getLeaveData = res.data;
            console.log('getLeaveData', getLeaveData)
            dispatch(successGetHRPolicy(getLeaveData));
        }).catch((error) => {
            dispatch(errorGetHRPolicy());
        })
    };
};

export const requestAddLeave = () => {
    return {
        type: "REQUEST_ADD_LEAVE",
    };
};
export const successAddLeave = (data) => {
    return {
        type: "SUCCESS_ADD_LEAVE",
        payload: data,
    };
};
export const errorAddLeave = () => {
    return {
        type: "ERROR_ADD_LEAVE",
    };
};

export const addHRLeave = (data) => {
    return (dispatch) => {
        dispatch(requestAddLeave());
        API.put(`/policy/update_policy`, data).then((res) => {
            let getLeaveData = res.data;
            dispatch(GetHRPolicy());
            dispatch(successAddLeave(getLeaveData));
        }).catch((error) => {
            dispatch(errorAddLeave());
        })
    };
};



export const requestAddSalary = () => {
    return {
        type: "REQUEST_ADD_SALARY",
    };
};
export const successAddSalary = (data) => {
    return {
        type: "SUCCESS_ADD_SALARY",
        payload: data,
    };
};
export const errorAddSalary = () => {
    return {
        type: "ERROR_ADD_SALARY",
    };
};

export const addSalary = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddSalary());
        API.post(`/salary/add_salary`, data)
            .then((res) => {
                //let getData = res.data;
                dispatch(successAddSalary(data));
                handleVisible();
            })
            .catch((error) => {
                dispatch(errorAddSalary());
                handleDangerVisible();
            });
    };
};


export const requestGetSalaryById = () => {
    return {
        type: "REQUEST_GET_SALARY_BY_ID",
    };
};
export const successGetSalaryById = (data) => {
    console.log('SUCCESS_GET_SALARY_BY_ID', data)
    return {
        type: "SUCCESS_GET_SALARY_BY_ID",
        payload: data,
    };
};
export const errorGetSalaryById = () => {
    return {
        type: "ERROR_GET_SALARY_BY_ID",
    };
};

export const getSalaryById = (employeeId) => {
    return (dispatch) => {
        dispatch(requestGetSalaryById());
        API.get(`/salary/get_salary/${employeeId}`).then((res) => {
            let getData = res.data;
            dispatch(successGetSalaryById(getData));
        }).catch((error) => {
            dispatch(errorGetSalaryById());
        })
    };
}


export const requestUpdateSalary = () => {
    return {
        type: "REQUEST_UPDATE_SALARY",
    };
};
export const successUpdateSalary = (data) => {
    return {
        type: "SUCCESS_UPDATE_SALARY",
        payload: data,
    };
};
export const errorUpdateSalary = () => {
    return {
        type: "ERROR_UPDATE_SALARY",
    };
};

export const updateSalary = (data, id, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestUpdateSalary());
        API.put(`/salary/update_salary/${id}`, data)
            .then((res) => {
                //let getData = res.data;
                dispatch(successUpdateSalary(data));
                handleVisible();
            })
            .catch((error) => {
                dispatch(errorUpdateSalary());
                handleDangerVisible();
            });
    };
};
export const setSalaryFields = (data = { key: "", value: "" }) => {
    return {
        type: "SET_SALARY_FIELDS",
        payload: data
    };
}
export const setEditEmployeeFields = (data) => {
    console.log('data', data)
    return {
        type: "SET_EDIT_EMPLOYEE_FIELDS",
        payload: data
    };
}
export const requestGetDesignation = () => {
    return {
        type: "REQUEST_GET_DESIGNATION",
    };
};
export const successGetDesignation = (data) => {
    return {
        type: "SUCCESS_GET_DESIGNATION",
        payload: data,
    };
};
export const errorGetDesignation = () => {
    return {
        type: "ERROR_GET_DESIGNATION",
    };
};

export const GetHRDesignation = () => {
    return (dispatch) => {
        dispatch(requestGetDesignation());
        API.get(`/designation/get_designation`).then((res) => {
            let getData = res.data;
            dispatch(successGetDesignation(getData));
        }).catch((error) => {
            dispatch(errorGetDesignation());
        })
    };
};


export const requestBackupDatabase = () => {
    return {
        type: "REQUEST_BACKUP_DATABASE",
    };
};
export const succesBackupDatabase = (payload) => {
    return {
        type: "SUCCESS_BACKUP_DATABASE",
        data: payload
    };
};
export const errorBackupDatabase = () => {
    return {
        type: "ERROR_BACKUP_DATABASE",
    };
};

export const BackupDatabase = () => {
    return dispatch => {
        dispatch(requestBackupDatabase());
        API.get(`/backup/get_backup`)
            .then(res => {
                dispatch(succesBackupDatabase(res.data))
            }).catch((error) =>
                dispatch(errorBackupDatabase()));
    }
};


export const requestGetEmployeeById = () => {
    return {
        type: "REQUEST_GET_EMPLOYEE_BY_ID",
    };
};
export const successGetEmployeeById = (data) => {
    return {
        type: "SUCCESS_GET_EMPLOYEE_BY_ID",
        payload: data,
    };
};
export const errorGetEmployeeById = () => {
    return {
        type: "ERROR_GET_EMPLOYEE_BY_ID",
    };
};

export const getEmployeeDataById = (id) => {
    return (dispatch) => {
        dispatch(requestGetEmployeeById());
        API.get(`/employee/get_data_by_id/${id}`).then((res) => {
            let getData = res.data;
            dispatch(successGetEmployeeById(getData));
        }).catch((error) => {
            dispatch(errorGetEmployeeById());
        })
    };
};



export const requestGenerateSalary = () => {
    return {
        type: "REQUEST_GENERATE_SALARY",
    };
};
export const successGenerateSalary = (data) => {
    return {
        type: "SUCCESS_GENERATE_SALARY",
        payload: data,
    };
};
export const errorGenerateSalary = () => {
    return {
        type: "ERROR_GENERATE_SALARY",
    };
};

export const generateSalary = (pData, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestGenerateSalary());
        API.post(`/salary/genrate_salary_with_employee_id`, pData).then((res) => {
            let getData = res.data.result;
            let getAttendanceData = res.data.attendance_logs
            console.log(getData, 'indsal')
            console.log(getAttendanceData, 'attdata')
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "portrait"; // portrait or landscape

            const marginLeft = 50 - 30;
            const marginLeft1 = 150;
            const marginLeft2 = 190 - 40;

            const marginLeft3 = 340;
            const doc = new jsPDF(orientation, unit, size);

            doc.setFontSize(10);

            const title = `${process.env.REACT_APP_COMPANY_NAME}`;
            const companyName = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
            const empCode = `Employee Code : ${getData.employee_id}`;
            const empName = `Employee Name : ${getData.employee_name}`;
            const fatherName = `Father Name : ${getData.father_name}`;
            const dept = `SubEvent : `;
            const desig = `Designation : ${getData.designation}`;
            const pic = `P\nH\nO\nT\nO`;
            const daily_report = `Employee Salary`;
            const from = `From : ${pData.start_date}`;
            const to = `To : ${pData.end_date}`;
            const basicSalary = `Basic Salary : ${getData.basic_salary}`;
            const totalAllowance = `Total Allowances : ${getData.all_allowance}`;
            const medicalAllowance = `MA : ${getData.medicial_allowance}`;
            const accomodationAllowance = `AA : ${getData.accomodation_allowance}`;
            const petrolAllowance = `PA : ${getData.petrol_allowance}`;
            const otherAllowance = `OA : ${getData.other_allowance}`;
            const specialAllowance = `SA : ${getData.special_allowance}`;
            const lateTimeDeduction = `Late Deduction : ${getData.lateTime_amount}`
            const overTimeBonus = `Over Time : ${getData.overtime_amount}`;
            const grossSalary = `Gross Salary : ${getData.gross_salary}`;
            const netPayableSalary = `Payable Salary : ${getData.net_payable_salary}`;

            const columns = [['Date', 'In Time', 'Break Out', 'Break In', 'Out Time', 'Late Min', 'Over Time']];

            const data = getAttendanceData.map((x) => [moment(x.attendance_date).format('LL'), x.attendance_in_time, x.break_out_time, x.break_in_time, x.attendance_out_time]);
            console.log(data, 'getAttendanceData.map((x)=>[moment(x.attendance_date).format("LL")')

            let content = {
                startY: 325,
                startX: 5,
                margin: { left: 5, right: 5 },
                theme: 'grid',
                cellWidth: 'wrap',
                head: columns,
                body: data,
                headStyles: { halign: 'center', valign: 'middle', lineWidth: 0.5, /*lineColor: [255, 255, 255], fontSize: 88*/ },
                bodyStyles: { valign: 'middle', halign: 'center', fontSize: 10, rowPageBreak: 'avoid' },
                didDrawCell: function (data) {
                    let rows = data.table.body[0].cells[0];
                    console.log(rows, 'row')
                    // rows.push({ 
                    //   colSpan: 2
                    // });
                    // if (data.row.index === rows.length - 1) { doc.setFillColor(239, 154, 154); }
                }
            };

            doc.addImage(logo, 'SVG', 40, 15, 100, 100);
            doc.setFont(undefined, 'bold');
            doc.setFontSize(16.5);
            doc.setLineWidth(2);
            doc.line(400, 55, 150, 55);
            doc.text(title, marginLeft1, 50);
            doc.setFont(undefined, 'normal');
            doc.setFontSize(10);
            doc.text(companyName, marginLeft1, 75);



            doc.setLineWidth(2);
            doc.rect(7, 125, 580, 190);
            doc.setFillColor(245, 245, 245);
            doc.rect(333, 143, 20, 80, 'F')
            doc.addImage(logo, 'SVG', 355, 140, 75, 75);

            doc.setLineWidth(1);
            doc.line(445, 165, 570, 165);
            doc.setFont(undefined, 'bold');
            doc.text(daily_report, 450, 160, 0, 20);


            doc.setFont(undefined, 'normal');
            doc.text(from, 450, 180, 0, 20);
            doc.text(to, 450, 195, 0, 20);
            doc.text(pic, marginLeft3, 160, 0, 20);
            doc.text(empCode, marginLeft, 150, 0, 20);
            doc.text(empName, marginLeft, 165, 0, 20);
            doc.text(fatherName, marginLeft, 180, 0, 20);
            doc.text(dept, marginLeft, 195, 0, 20);
            doc.text(desig, marginLeft, 210, 0, 20);

            doc.text(medicalAllowance, marginLeft, 225, 0, 20);
            doc.text(otherAllowance, marginLeft + 55, 225, 0, 20);
            doc.text(accomodationAllowance, marginLeft + 110, 225, 0, 20);
            doc.text(petrolAllowance, marginLeft + 165, 225, 0, 20);
            doc.text(specialAllowance, marginLeft + 220, 225, 0, 20);

            doc.text(totalAllowance, marginLeft, 240, 0, 20);
            doc.text(basicSalary, marginLeft + 120, 240, 0, 20);
            doc.text(grossSalary, marginLeft + 240, 240, 0, 20);
            doc.text(netPayableSalary, marginLeft + 360, 240, 0, 20);

            doc.text(lateTimeDeduction, marginLeft, 255, 0, 20);
            doc.text(overTimeBonus, marginLeft + 120, 255, 0, 20);


            doc.autoTable(content);
            doc.save(`Individual Salary (${getData.employee_name}).pdf`);


            handleVisible();
            dispatch(successGenerateSalary(getData));
        }).catch((error) => {
            dispatch(errorGenerateSalary());
            console.log({ error })
            handleDangerVisible();
        })
    };
};

//Shift Control
export const requestGetShiftControl = () => {
    return {
        type: "REQUEST_GET_SHIFT_CONTROL",
    };
};
export const successGetShiftControl = (data) => {
    return {
        type: "SUCCESS_GET_SHIFT_CONTROL",
        payload: data,
    };
};
export const errorGetShiftControl = () => {
    return {
        type: "ERROR_GET_SHIFT_CONTROL",
    };
};

export const getShiftControl = () => {
    return (dispatch) => {
        dispatch(requestGetShiftControl());
        API.get(`/shift_control/get_shift_control`).then((res) => {
            let getData = res.data;
            dispatch(successGetShiftControl(getData));
        }).catch((error) => {
            dispatch(errorGetShiftControl());
        })
    };
};

export const requestAddShiftControl = () => {
    return {
        type: "REQUEST_ADD_SHIFT_CONTROL",
    };
};
export const successAddShiftControl = (data) => {
    return {
        type: "SUCCESS_ADD_SHIFT_CONTROL",
        payload: data,
    };
};
export const errorAddShiftControl = () => {
    return {
        type: "ERROR_ADD_SHIFT_CONTROL",
    };
};

export const addShiftControl = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddShiftControl());
        API.post(`/shift_control/add_shift_control`, data)
            .then((res) => {
                //let getData = res.data;
                dispatch(successAddShiftControl(data));
                handleVisible();
                dispatch(getShiftControl());
            })
            .catch((error) => {
                dispatch(errorAddShiftControl());
                handleDangerVisible();
            });
    };
};

export const requestDeleteShiftControl = () => {
    return {
        type: "REQUEST_DELETE_SHIFT_CONTROL",
    };
};
export const successDeleteShiftControl = (shiftId) => {
    return {
        type: "SUCCESS_DELETE_SHIFT_CONTROL",
        shift_control_id: shiftId,
    };
};
export const errorDeleteShiftControl = () => {
    return {
        type: "ERROR_DELETE_SHIFT CONTROL",
    };
};

export const deleteShiftControl = (shiftId) => {
    return dispatch => {
        dispatch(requestDeleteShiftControl());
        API.delete(`/shift_control/delete_shift_control/${shiftId}`)
            .then(res => {
                dispatch(getShiftControl());
                dispatch(successDeleteShiftControl(shiftId))
            }).catch((error) =>
                dispatch(errorDeleteShiftControl()));
    }
};

export const requestUpdateShiftControl = () => {
    return {
        type: "REQUEST_UPDATE_SHIFT_CONTROL",
    };
};
export const successUpdateShiftControl = (data) => {
    return {
        type: "SUCCESS_UPDATE_SHIFT_CONTROL",
        payload: data,
    };
};
export const errorUpdateShiftControl = () => {
    return {
        type: "ERROR_UPDATE_SHIFT_CONTROL",
    };
};

export const updateShiftControl = (data, oldData) => {
    return (dispatch) => {
        dispatch(requestUpdateShiftControl());
        API.put(`/shift_control/update_shift_control/${oldData.shift_control_id}`, data)
            .then((res) => {
                //let getData = res.data;
                dispatch(getShiftControl());
                dispatch(successUpdateShiftControl(data));
            })
            .catch((error) => {
                dispatch(errorUpdateShiftControl());
            });
    };
};

//Fine & Penalties
export const requestGetFine = () => {
    return {
        type: "REQUEST_GET_FINE",
    };
};
export const successGetFine = (data) => {
    return {
        type: "SUCCESS_GET_FINE",
        payload: data,
    };
};
export const errorGetFine = () => {
    return {
        type: "ERROR_GET_FINE",
    };
};

export const getFine = () => {
    return (dispatch) => {
        dispatch(requestGetFine());
        API.get(`/fine_plenties/get_fine_and_plenties`).then((res) => {
            let getData = res.data;
            dispatch(successGetFine(getData));
        }).catch((error) => {
            dispatch(errorGetFine());
        })
    };
};

export const requestAddFine = () => {
    return {
        type: "REQUEST_ADD_FINE",
    };
};
export const successAddFine = (data) => {
    return {
        type: "SUCCESS_ADD_FINE",
        payload: data,
    };
};
export const errorAddFine = () => {
    return {
        type: "ERROR_ADD_FINE",
    };
};

export const addFine = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddFine());
        API.post(`/fine_plenties/add_fine_and_plenties`, data)
            .then((res) => {
                //let getData = res.data;
                dispatch(getFine());
                dispatch(successAddFine(data));
                handleVisible();
            })
            .catch((error) => {
                dispatch(errorAddFine());
                handleDangerVisible();
            });
    };
};

export const requestDeleteFine = () => {
    return {
        type: "REQUEST_DELETE_FINE",
    };
};
export const successDeleteFine = (fineId) => {
    return {
        type: "SUCCESS_DELETE_FINE",
        fine_and_plenties_id: fineId,
    };
};
export const errorDeleteFine = () => {
    return {
        type: "ERROR_DELETE_FINE",
    };
};

export const deleteFine = (fineId) => {
    return dispatch => {
        dispatch(requestDeleteFine());
        API.delete(`/fine_plenties/delete_fine_and_plenties/${fineId}`)
            .then(res => {
                dispatch(getFine());
                dispatch(successDeleteFine(fineId))
            }).catch((error) =>
                dispatch(errorDeleteFine()));
    }
};

export const requestUpdateFine = () => {
    return {
        type: "REQUEST_UPDATE_FINE",
    };
};
export const successUpdateFine = (data) => {
    return {
        type: "SUCCESS_UPDATE_FINE",
        payload: data,
    };
};
export const errorUpdateFine = () => {
    return {
        type: "ERROR_UPDATE_FINE",
    };
};

export const updateFine = (data, oldData) => {
    return (dispatch) => {
        dispatch(requestUpdateFine());
        API.put(`/fine_plenties/update_fine_and_plenties/${oldData.fine_and_plenties_id}`, data)
            .then((res) => {
                //let getData = res.data;
                dispatch(getFine());
                dispatch(successUpdateFine(data));
            })
            .catch((error) => {
                dispatch(errorUpdateFine());
            });
    };
};
//Master Control
export const requestGetMasterAccounts = () => {
    return {
        type: "REQUEST_GET_MASTER_ACCOUNTS",
    };
};
export const successGetMasterAccounts = (data) => {
    return {
        type: "SUCCESS_GET_MASTER_ACCOUNTS",
        payload: data,
    };
};
export const errorGetMasterAccounts = () => {
    return {
        type: "ERROR_GET_MASTER_ACCOUNTS",
    };
};

export const getMasterAccounts = () => {
    return (dispatch) => {
        dispatch(requestGetMasterAccounts());
        API.get(`/levelFive/get-acc-ledger-user`).then((res) => {
            let getData = res.data;
            dispatch(successGetMasterAccounts(getData));
        }).catch((error) => {
            dispatch(errorGetMasterAccounts());
        })
    };
};
export const requestAddAccounts = () => {
    return {
        type: "REQUEST_ADD_MASTER_ACCOUNTS",
    };
};
export const successAddAccounts = (data) => {
    return {
        type: "SUCCESS_ADD_MASTER_ACCOUNTS",
        payload: data,
    };
};
export const errorAddAccounts = () => {
    return {
        type: "ERROR_ADD_MASTER_ACCOUNTS",
    };
};

export const addAccounts = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddAccounts());
        API.put(`/master_control/update_master_control`, data)
            .then((res) => {
                //let getData = res.data;
                dispatch(successAddAccounts(data));
                dispatch(resetMasterData());
                handleVisible();
            })
            .catch((error) => {
                dispatch(errorAddAccounts());
                handleDangerVisible();
            });
    };
};
export const resetMasterData = () => {
    return {
        type: "RESET_MASTER_DATA",
    };
};

//LT Advance
export const requestGetLTAccount = () => {
    return {
        type: "REQUEST_GET_LT_ACCOUNT",
    };
};
export const successGetLTAccount = (data) => {
    return {
        type: "SUCCESS_GET_LT_ACCOUNT",
        payload: data,
    };
};
export const errorGetLTAccount = () => {
    return {
        type: "ERROR_GET_LT_ACCOUNT",
    };
};

export const getLTAccount = () => {
    return (dispatch) => {
        dispatch(requestGetLTAccount());
        API.get(`/master_control/get_master_control`).then((res) => {
            let getData = res.data;
            dispatch(successGetLTAccount(getData));
        }).catch((error) => {
            dispatch(errorGetLTAccount());
        })
    };
};
export const requestAddLTAccounts = () => {
    return {
        type: "REQUEST_ADD_LT_ACCOUNT",
    };
};
export const successAddLTAccounts = (data) => {
    return {
        type: "SUCCESS_ADD_LT_ACCOUNT",
        payload: data,
    };
};
export const errorAddLTAccounts = () => {
    return {
        type: "ERROR_ADD_LT_ACCOUNT",
    };
};

export const addLTAccount = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddLTAccounts());
        API.post(`/long_term_adv/add_long_term_advance`, data)
            .then((res) => {
                //let getData = res.data;
                dispatch(successAddLTAccounts(data));
                handleVisible();
            })
            .catch((error) => {
                dispatch(errorAddLTAccounts());
                handleDangerVisible();
            });
    };
};
//ST ADVANCE
export const requestAddSTAccounts = () => {
    return {
        type: "REQUEST_ADD_ST_ACCOUNT",
    };
};
export const successAddSTAccounts = (data) => {
    return {
        type: "SUCCESS_ADD_ST_ACCOUNT",
        payload: data,
    };
};
export const errorAddSTAccounts = () => {
    return {
        type: "ERROR_ADD_ST_ACCOUNT",
    };
};

export const addSTAccount = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddSTAccounts());
        API.post(`/short_term_adv/add_short_term_advance`, data)
            .then((res) => {
                //let getData = res.data;
                dispatch(successAddSTAccounts(data));
                handleVisible();
            })
            .catch((error) => {
                dispatch(errorAddSTAccounts());
                handleDangerVisible();
            });
    };
};

// Get LT Ledger
export const requestGetInfoLTLedger = () => {
    return {
        type: "REQUEST_GET_INFO_LT_LEDGER",
    };
};
export const successGetInfoLTLedger = (data) => {
    return {
        type: "SUCCESS_GET_INFO_LT_LEDGER",
        payload: data,
    };
};
export const errorGetInfoLTLedger = () => {
    return {
        type: "ERROR_GET_INFO_LT_LEDGER",
    };
};

export const getInfoLTLedger = (data) => {
    return (dispatch) => {
        dispatch(requestGetInfoLTLedger());
        API.post(`/long_term_adv/get_lt_employee_ledger`, data).then((res) => {
            let getData = res.data.lt_ledger;
            console.log(res, 'ledg')
            dispatch(successGetInfoLTLedger(getData));
        }).catch((error) => {
            dispatch(errorGetInfoLTLedger());
        })
    };
};
//Get ST Advance
export const requestGetInfoSTLedger = () => {
    return {
        type: "REQUEST_GET_INFO_ST_LEDGER",
    };
};
export const successGetInfoSTLedger = (data) => {
    return {
        type: "SUCCESS_GET_INFO_ST_LEDGER",
        payload: data,
    };
};
export const errorGetInfoSTLedger = () => {
    return {
        type: "ERROR_GET_INFO_ST_LEDGER",
    };
};

export const getInfoSTLedger = (data) => {
    return (dispatch) => {
        dispatch(requestGetInfoSTLedger());
        API.post(`/short_term_adv/get_st_employee_ledger`, data).then((res) => {
            let getData = res.data.st_ledger;
            console.log(res, 'ledg')
            dispatch(successGetInfoSTLedger(getData));
        }).catch((error) => {
            dispatch(errorGetInfoSTLedger());
        })
    };
};
//search booki
export const requestSearchBooking = () => {
    return {
        type: "REQUEST_SEARCH_BOOKING",
    };
};
export const successSearchBooking = (data) => {
    return {
        type: "SUCCESS_SEARCH_BOOKING",
        payload: data,
    };
};
export const errorSearchBooking = () => {
    return {
        type: "ERROR_SEARCH_BOOKING",
    };
};
export const SearchBooking = (data, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestSearchBooking());
        API.post(`/event_shift/get_shifts_from_hall_id`, data).then((res) => {
            let getData = res.data;
            dispatch(successSearchBooking(getData));
        }).catch((error) => {
            dispatch(errorSearchBooking());
        })
    };
};
export const getBookingShifts = (data, bookingAviliable) => {
    return (dispatch) => {
        dispatch(requestGetBookingShifts());
        API.post(`/event_shift/get_shifts_from_hall_id`, data).then((res) => {
            let getData = res.data;
            console.log(getData, "bbbbbbbbbbbbbbbbbbbbbbbbbbbb")
            if (getData.result == false) {
                console.log(getData, "bbbbbbbbbbbbbbbbbbbbbbbbbbbb1111111111111")
                bookingAviliable()
            }
            else {
                hideForm(true)
                // dispatch(successGetBookingShifts(getData));
            }
            dispatch(successGetBookingShifts(getData))
        }).catch((error) => {
            dispatch(errorGetBookingShifts());
        })
    };
};
export const requestGetBookingShifts = () => {
    return {
        type: "REQUEST_GET_BOOKING_SHIFTS",
    };
};
export const successGetBookingShifts = (data) => {
    return {
        type: "SUCCESS_GET_BOOKING_SHIFTS",
        payload: data,
    };
};
export const errorGetBookingShifts = () => {
    return {
        type: "ERROR_GET_BOOKING_SHIFTS",
    };
}

//get profit by id
export const requestGetProfitByBooking = () => {
    return {
        type: "REQUEST_GET_PROFIT_BY_BOOKING",
    };
};
export const successGetProfitByBooking = (data) => {
    return {
        type: "SUCCESS_GET_PROFIT_BY_BOOKING",
        payload: data,
    };
};
export const errorGetProfitByBooking = () => {
    return {
        type: "ERROR_GET_PROFIT_BY_BOOKING",
    };
};
export const getProfitByBooking = (id) => {
    return (dispatch) => {
        dispatch(requestGetProfitByBooking());
        API.post(`/booking/get_marquee_profit_from_bookingId`, { booking_id: id }).then((res) => {
            let getData = res.data;

            // console.log(getData, "getData");

            // const unit = "pt";
            // const size = "A4";
            // const orientation = "potrait";

            // const marginLeft = 40;
            // const doc = new jsPDF(orientation, unit, size);

            // doc.setFontSize(11);

            // const title = "Inventory Details";
            // const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
            // const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
            // const companyNTN = `STRN#${process.env.REACT_APP_COMPANY_NTN}`;
            // const headers = [["Total Cost", "Total Expense", "Total Cost"]];

            // const data = [[getData.total_cost, getData.total_expense, getData.total_profit]]
            // console.log(data,"data")
            // let content = {
            //     startY: 200,
            //     startX: 10,
            //     head: headers,
            //     body: data
            // };
            // doc.addImage(logo, 'PNG', 40, 15, 100, 100);
            // doc.text(`Company Name: ${companyName}`, 200, 60, 0, 20);
            // doc.text(title, marginLeft, 170);
            // doc.autoTable(content);
            // doc.save(`Profit Booking#${id}.pdf`)

            dispatch(successGetProfitByBooking(getData));
        }).catch((error) => {
            dispatch(errorGetProfitByBooking());
        })
    };
};


//get profit by date
export const requestGetProfitByDate = () => {
    return {
        type: "REQUEST_GET_PROFIT_BY_DATE",
    };
};
export const successGetProfitByDate = (data) => {
    return {
        type: "SUCCESS_GET_PROFIT_BY_DATE",
        payload: data,
    };
};
export const errorGetProfitByDate = () => {
    return {
        type: "ERROR_GET_PROFIT_BY_DATE",
    };
};
export const getProfitByDate = (data) => {
    return (dispatch) => {
        dispatch(requestGetProfitByDate());
        API.post(`/booking/get_marquee_profit_from_date`, data).then((res) => {
            let getData = res.data;

            // console.log(getData, "getData");

            // const unit = "pt";
            // const size = "A4";
            // const orientation = "potrait";

            // const marginLeft = 40;
            // const doc = new jsPDF(orientation, unit, size);

            // doc.setFontSize(11);

            // const title = "Inventory Details";
            // const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
            // const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
            // const companyNTN = `STRN#${process.env.REACT_APP_COMPANY_NTN}`;
            // const headers = [["Total Cost", "Total Expense", "Total Cost"]];

            // const data = [[getData.total_cost, getData.total_expense, getData.total_profit]]
            // console.log(data,"data")
            // let content = {
            //     startY: 200,
            //     startX: 10,
            //     head: headers,
            //     body: data
            // };
            // doc.addImage(logo, 'PNG', 40, 15, 100, 100);
            // doc.text(`Company Name: ${companyName}`, 200, 60, 0, 20);
            // doc.text(title, marginLeft, 170);
            // doc.autoTable(content);
            // doc.save(`Profit Booking#${id}.pdf`)

            dispatch(successGetProfitByDate(getData));
        }).catch((error) => {
            dispatch(errorGetProfitByDate());
        })
    };
};
//
export const requestSearchMarqueeReport = () => {
    return {
        type: "REQUEST_SEARCH_MARQUEE_REPORT",
    };
};
export const successSearchMarqueeReport = (data) => {
    return {
        type: "SUCCESS_SEARCH_MARQUEE_REPORT",
        payload: data,
    };
};
export const errorSearchMarqueeReport = () => {
    return {
        type: "ERROR_SEARCH_MARQUEE_REPORT",
    };
};

export const searchMarqueeReport = (data, startDate, endDate) => {
    return (dispatch) => {
        dispatch(requestSearchMarqueeReport());
        API.post(`/booking/booking_report`, data).then((res) => {
            let getData = res.data;
            const unit = "pt";
            const size = "A4";
            const orientation = "potrait";

            const marginLeft = 40;
            const doc = new jsPDF(orientation, unit, size);

            doc.setFontSize(11);

            const title = "Installment Details";
            const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
            const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
            const companyNTN = `STRN#${process.env.REACT_APP_COMPANY_NTN}`;
            const headers = [["Booking Id", "Booking Name", "Payable Price", "Paid Amount", "Remaining Price"]];

            const data = getData.map((x) => [x.booking_id, x.booking_name, x.booking_payable_price, x.installment_total, x.booking_remaining_price]);
            console.log(data, "data")
            let content = {
                startY: 200,
                startX: 10,
                head: headers,
                body: data
            };
            doc.addImage(logo, 'PNG', 40, 15, 100, 100);
            doc.text(`Company Name: ${companyName}`, 200, 60, 0, 20);
            doc.text(title, marginLeft, 170);
            doc.autoTable(content);
            // doc.save(`Installment Details.pdf`)
            doc.setProperties({
                title: `Installment Details ${moment(new Date()).format(
                    "YYYY-MM-DD"
                )}.pdf`,
            });
            var string = doc.output('datauristring', 'dddd');
            var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
            var x = window.open('Demand Report');
            x.document.open('Demand Report');
            x.document.write(embed);
            x.document.close();
            dispatch(successSearchMarqueeReport(getData));
        }).catch((error) => {
            dispatch(errorSearchMarqueeReport());
        })
    };
};
export const requestAccountIntegration = () => {
    return {
        type: "REQUEST_ACCOUNT_INTERGRATION",
    };
};
export const successAccountIntegration = (data) => {
    return {
        type: "SUCCESS_ACCOUNT_INTERGRATION",
        payload: data,
    };
};
export const errorAccountIntegration = () => {
    return {
        type: "ERROR_ACCOUNT_INTERGRATION",
    };
};
export const accountIntegration = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAccountIntegration());
        API.post(`/marquee_master_control`, data).then((res) => {
            let getData = res.data;
            // dispatch(getMasterControl(2));
            dispatch(successAccountIntegration(getData));
            handleVisible();
        }).catch((error) => {
            handleDangerVisible();
            dispatch(errorAccountIntegration());
        })
    };
};

// get 
export const requestGetAccountIntegration = () => {
    return {
        type: "REQUEST_GET_ACCOUNT_INTERGRATION",
    };
};
export const successGetAccountIntegration = (data) => {
    return {
        type: "SUCCESS_GET_ACCOUNT_INTERGRATION",
        payload: data,
    };
};
export const errorGetAccountIntegration = () => {
    return {
        type: "ERROR_GET_ACCOUNT_INTERGRATION",
    };
};

export const getAccountIntegration = () => {
    return (dispatch) => {
        dispatch(requestGetAccountIntegration());
        API.get(`/marquee_master_control`).then((res) => {
            let getData = res.data;
            console.log('getData', getData);
            dispatch(successGetAccountIntegration(getData));
        }).catch((error) => {
            dispatch(errorGetAccountIntegration());
        })
    };
};
// 
export const requestAddTermAndCondition = () => {
    return {
        type: "REQUEST_ADD_TERMANDCONDITION",
    };
};
export const successAddTermAndCondition = (data) => {
    return {
        type: "SUCCESS_ADD_TERMANDCONDITION",
        payload: data,
    };
};
export const errorAddTermAndCondition = () => {
    return {
        type: "ERROR_ADD_TERMANDCONDITION",
    };
};
export const addTermAndCondition = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddTermAndCondition());
        API.post(`/booking/add_term_and_condition`, data).then((res) => {
            console.log(res, "ressssssssss")
            handleVisible(res.data.message);
            let getData = res.data;
            dispatch(successAddTermAndCondition(getData));
        }).catch((error) => {
            console.log(error, "errrrrrrrrr")
            handleDangerVisible(error.response.data.message);
            dispatch(errorAddTermAndCondition());
        })
    };
};

export const requestGetTermAndCondition = () => {
    return {
        type: "REQUEST_GET_TERMANDCONDITION",
    };
};
export const successGetTermAndCondition = (data) => {
    return {
        type: "SUCCESS_GET_TERMANDCONDITION",
        payload: data,
    };
};
export const errorGetTermAndCondition = () => {
    return {
        type: "ERROR_GET_TERMANDCONDITION",
    };
};

export const getTermAndCondition = () => {
    return (dispatch) => {
        dispatch(requestGetTermAndCondition());
        API.get(`/booking/get_term_and_condition`).then((res) => {
            let getData = res.data;
            console.log('getData', getData);
            dispatch(successGetTermAndCondition(getData));
        }).catch((error) => {
            dispatch(errorGetTermAndCondition());
        })
    };
};

export const requestGetInstallmentHistoryPdf = () => {
    return {
        type: "REQUEST_GET_INSTALLMENT_HISTORY_PDF",
    };
};
export const successGetInstallmentHistoryPdf = (data) => {
    return {
        type: "SUCCESS_GET_INSTALLMENT_HISTORY_PDF",
        payload: data,
    };
};
export const errorGetInstallmentHistoryPdf = () => {
    return {
        type: "ERROR_GET_INSTALLMENT_HISTORY_PDF",
    };
};

export const getInstallmentHistoryPdf = (id) => {
    return (dispatch) => {
        dispatch(requestGetInstallmentHistoryPdf());
        API.get(`/booking_installment/get_instalment_by_cusomerId/${id}`).then((res) => {
            let getData = res.data.data;

            console.log(getData, "getData");

            const unit = "pt";
            const size = "A4";
            const orientation = "potrait";

            const marginLeft = 40;
            const doc = new jsPDF(orientation, unit, size);

            doc.setFontSize(11);

            const title = "Installment History";
            const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
            const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
            const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
            const headers = [["Installment Date", "Total Price", "Installment Price","Remaining Price"]];

            const data =getData.map((elt, index) =>  [elt.booking_installment_date, elt.opening_balance, elt.installment_price,elt.closing_balance])
            console.log(data,"data")
            let content = {
                startY: 190,
                startX: 10,
                head: headers,
                body: data
            };
            doc.addImage(logo, 'PNG', 40, 5, 100, 100);
            doc.setFontSize(20);
            doc.setFont(undefined, 'bold')
            doc.text(companyName, 150, 40, 0, 20);
            doc.setFontSize(13);
            doc.setFont('TimesNewRoman', 'Normal')
            doc.text(companyAddress, 150, 60, 0, 20);
            doc.text(companyNtn, 150, 80, 0, 20);
            doc.setFontSize(15);
            doc.setFont(undefined, 'bold')
            doc.text(title, 230, 120);
            doc.setFont('TimesNewRoman', 'Normal')
            doc.setFontSize(12);
            doc.text(`Booking Name: ${getData[0].booking.booking_name}`,marginLeft,150)
            doc.text(`Booking Date: ${getData[0].booking.booking_date}`,350,150)

            doc.text(`Booking Person: ${getData[0].booking.booking_payable_price}`,marginLeft,170)
            doc.text(`Booking Total Price: ${getData[0].booking.booking_payable_price}`,350,170)

            doc.autoTable(content);
            // doc.save(`Installment History.pdf`)
            doc.setProperties({ title: `Installment History ${moment(new Date()).format("YYYY-MM-DD")}.pdf` })
            var string = doc.output('datauristring', 'dddd');
            var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
            var x = window.open('Installment History');
            x.document.open('Installment History');
            x.document.write(embed);
            x.document.close();
            dispatch(successGetInstallmentHistoryPdf(getData));
        }).catch((error) => {
            dispatch(errorGetInstallmentHistoryPdf());
        })
    };
};