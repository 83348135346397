import React, { useState } from 'react'
import { getBooking, getBookingByCustomerId, getBookingById, getCustomerById, getCustomerMarquee, getInstallmentHistoryPdf, SearchMarqueeEventReport } from './MarqueeDetails.actions';
import { connect } from "react-redux";
import { Container, Form, Row, Col, Button, FormGroup } from "react-bootstrap";
import { isEmpty, find, sumBy, pick } from "lodash";
import Select from "react-select";
import { useEffect } from "react";

import MaterialTable from 'material-table';

import { Link } from 'react-router-dom';
const InstallmentHistory = (props) => {
  // const navigate = useNavigate();
  function ViewHistoy(id) {
    props.getCustomerById(id)

    console.log(props.customerByIdList, 'check')
  }
  const [customerId, setCustomerId] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [bookingName, setBookingName] = useState("");
  const handleChangeCustomer = (selectedOption) => {
    console.log(selectedOption, "sop");
    setCustomerId(selectedOption.value);
    setCustomerName(selectedOption.label);
    setBookingId("");
    setBookingName("")
    props.getBookingByCustomerId(selectedOption.value)
  };

  const handleChangeBooking = (selectedOption) => {
    console.log(selectedOption, "sop");
    setBookingId(selectedOption.value);
    setBookingName(selectedOption.label);
  };
  const getCustomerMarqueeList =
    !isEmpty(props.customerMarqueeList) &&
    props.customerMarqueeList.map((x) => {
      let data = {
        value: x.customer_id,
        label: `${x.customer_name}/${x.customer_phone}`,
      };
      return data;
    });
  const getBookingByCustomerId =
    !isEmpty(props.bookingByCustomerId) &&
    props.bookingByCustomerId.map((x) => {
      let data = {
        value: x.booking_id,
        label: `${x.booking_id} / ${x.booking_name} / ${x.booking_date}`,
      };
      return data;
    });

  useEffect(() => {
    props.getCustomerMarquee();
  }, []);
  return (
    <>
      <Container fluid>
        <Form>
          <Row>
            <Col xl="6" lg="6" md="6" xs="6">
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Select Customer *</b>
                </Form.Label>
                <Select
                  isDisabled={isEmpty(props.customerMarqueeList)}
                  placeholder="Select Customer..."
                  onChange={handleChangeCustomer}
                  options={getCustomerMarqueeList}
                />
              </Form.Group>
            </Col>
            <Col xl="6" lg="6" md="6" xs="6">
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Select booking *</b>
                </Form.Label>
                <Select
                  isDisabled={isEmpty(props.bookingByCustomerId) || customerId == ""}
                  value={{ label: bookingName }}
                  placeholder="Select booking..."
                  onChange={handleChangeBooking}
                  options={getBookingByCustomerId}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="sendDiv">
            <Link to="/user/Installment-Report-Table">
              {bookingId == "" ?
                <Button className="sendButton" disabled style={{ backgroundColor: 'black' }} onClick={() => { ViewHistoy(bookingId); }} >View History</Button> :

                <Button className="sendButton" onClick={() => {
                  ViewHistoy(bookingId);
                }} >

                  View History</Button>
              } </Link>

          </div>
          <div className="sendDiv">
            {bookingId == "" ?
              <Button className="sendButton" disabled style={{ backgroundColor: 'black' }} onClick={() => { ViewHistoy(bookingId); }} >Generate History</Button> :

              <Button className="sendButton" onClick={() => {
                props.getInstallmentHistoryPdf(bookingId);
              }} >

                Generate History</Button>
            }

          </div>

        </Form>
      </Container>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    // getBooking: () => dispatch(getBooking()),
    // getBookingById:(id) => dispatch(getBookingById(id)),
    getCustomerMarquee: () => dispatch(getCustomerMarquee()),
    getBookingByCustomerId: (id) => dispatch(getBookingByCustomerId(id)),
    getCustomerById: (id) => dispatch(getCustomerById(id)),
    getInstallmentHistoryPdf: (id) => dispatch(getInstallmentHistoryPdf(id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  // bookingList: state.MarqueeReducer.bookingList,
  // bookingByIdList: state.MarqueeReducer.bookingByIdList,
  isFetchingBookingByCustomerId: state.MarqueeReducer.isFetchingBookingByCustomerId,
  bookingByCustomerId: state.MarqueeReducer.bookingByCustomerId,
  customerByIdList: state.MarqueeReducer.customerByIdList,
  isFetchingCustomerByIdData: state.MarqueeReducer.isFetchingCustomerByIdData,
  customerMarqueeList: state.MarqueeReducer.customerMarqueeList,
});

export default connect(mapStateToProps, mapDispatchToProps)(InstallmentHistory);

