import API from "../../../../../global/api";
//Add Vendor Call

export const addVendor = (data,handleVisible,
  handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddVendor());
    API.post("vendor/add_vendor", data)
      .then((res) => {
        dispatch(getVendor())
        dispatch(successAddVendor(data));
        handleVisible()
      })
      .catch((error) => {
        dispatch(errorAddVendor());
        handleDangerVisible()
      });
  };
};

export const requestAddVendor = () => {
  return {
    type: "REQUEST_ADD_VENDOR",
  };
};

export const successAddVendor = (data) => {
  return {
    type: "SUCCESS_ADD_VENDOR",
    payload: data,
  };
};

export const errorAddVendor = () => {
  return {
    type: "ERROR_ADD_VENDOR",
  };
};

//Get Vendor Call

export const getVendor = () => {
  return (dispatch) => {
    dispatch(requestGetVendor());
    API.get("vendor/get_vendor")
      .then((res) => {
        dispatch(successGetVendor(res.data));
      })
      .catch((error) => {
        dispatch(errorGetVendor());
      });
  };
};

export const requestGetVendor = () => {
  return {
    type: "REQUEST_GET_VENDOR",
  };
};

export const successGetVendor = (data) => {
  return {
    type: "SUCCESS_GET_VENDOR",
    payload: data,
  };
};

export const errorGetVendor = () => {
  return {
    type: "ERROR_GET_VENDOR",
  };
};

//Update Vendor Call

export const updateVendor = (newData, oldData) => {
  return (dispatch) => {
    dispatch(requestUpdateVendor());
    API.put(`vendor/update_vendor/${oldData.vendor_id}`, {
      vendor_name: newData.vendor_name,
      vendor_cnic: newData.vendor_cnic,
      vendor_phone: newData.vendor_phone,
      vendor_address: newData.vendor_address,
    })
      .then((res) => {
        dispatch(getVendor());
        dispatch(successUpdateVendor([newData]));
      })
      .catch((error) => {
        dispatch(errorUpdateVendor());
      });
  };
};

export const requestUpdateVendor = () => {
  return {
    type: "REQUEST_UPDATE_VENDOR",
  };
};

export const successUpdateVendor = (data) => {
  return {
    type: "SUCCESS_UPDATE_VENDOR",
    payload: data,
  };
};

export const errorUpdateVendor = () => {
  return {
    type: "ERROR_UPDATE_VENDOR",
  };
};

//Delete Vendor Call

export const deleteVendor = (id) => {
  return (dispatch) => {
    dispatch(requestDeleteVendor());
    API.delete(`vendor/delete_vendor/${id}`)
      .then((res) => {
        dispatch(getVendor());
        dispatch(successDeleteVendor(id));
      })
      .catch((error) => {
        dispatch(errorDeleteVendor());
      });
  };
};

export const requestDeleteVendor = () => {
  return {
    type: "REQUEST_DELETE_VENDOR",
  };
};

export const successDeleteVendor = (id) => {
  return {
    type: "SUCCESS_DELETE_VENDOR",
    userId: id,
  };
};

export const errorDeleteVendor = () => {
  return {
    type: "ERROR_DELETE_VENDOR",
  };
};
