import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  FormGroup,
  Modal,
  Alert,
} from "react-bootstrap";
import "../production-styles.css";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import {
  getArticle,
  getArticleData,
  addConversionCost,
} from "./Conversion.actions";
import { getArticleIds } from "../AddArticleMaterialFolder/AddArticleMaterial.actions";
import { isEmpty } from "lodash";

const AddConversionCost = (props) => {
  const [articleId, setArticleId] = useState();
  const getArticleListData = props.getArticleList.map((x) => {
    let data = { value: x.articleId, label: x.itemName };
    return data;
  });
  const handleChangeArticle = (selectedOption) => {
    setArticleId(selectedOption.value);
    props.getArticleData(selectedOption.value);
  };

  function saveConversionCost() {
    props.addConversionCost({
      // articleId: articleId,
      // materialCost: Number(props.getArticleListData.materialCost),
      // labourCost: Number(props.getArticleListData.labourCost),
      // conversioncost: Number(props.getArticleListData.materialCost) + Number(props.getArticleListData.labourCost)
      articleData: props.ArticleIds.map((x) => {
        let data = {
          articleId: x,
          materialCost: Number(props.getArticleListData.materialCost),
          labourCost: Number(props.getArticleListData.labourCost),
          conversioncost:
            Number(props.getArticleListData.materialCost) +
            Number(props.getArticleListData.labourCost),
        };
        return data;
      }),
    });
  }
  useEffect(() => {
    props.getArticle();
    props.getArticleIds();
  }, []);
  return (
    <>
      {props.isFetchingDemand || props.isFetchingInventoryAll ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {/* {props.isFetchingSectorsSummary ? 'Loading...' : 'Loading...'} */}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            {props.isAddingConversionCost && (
              <Alert variant={"success"}>Added Data...</Alert>
            )}
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Select Article *</b>
                  </Form.Label>
                  <Select
                    placeholder="Select Article..."
                    onChange={(e) => {
                      handleChangeArticle(e);
                    }}
                    value={{ label: articleId }}
                    options={getArticleListData}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="6" lg="6" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Total Material Cost</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Cost...."
                    name="materialCost"
                    value={props.getArticleListData.materialCost}
                    readOnly
                  />
                </Form.Group>
              </Col>

              <Col xl="6" lg="6" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Total Labour Cost</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Cost...."
                    name="labourCost"
                    value={props.getArticleListData.labourCost}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Total Conversion Cost</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Cost...."
                    name="productCost"
                    value={
                      Number(props.getArticleListData.materialCost) +
                      Number(props.getArticleListData.labourCost)
                    }
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv">
              <Button
                disabled={isEmpty(articleId)}
                className="sendButton"
                onClick={() => saveConversionCost()}
                style={{
                  marginLeft: "2%",
                  color: "white",
                  width: "20%",
                  backgroundColor: "black",
                  border: "1px solid black",
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getArticle: () => dispatch(getArticle()),
    getArticleData: (id) => dispatch(getArticleData(id)),
    addConversionCost: (data) => dispatch(addConversionCost(data)),
    getArticleIds: () => dispatch(getArticleIds()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  getArticleList: state.COnversionCostReducer.getArticleList,
  getArticleListData: state.COnversionCostReducer.getArticleListData,
  //ArticleIds: state.productionReducer.ArticleIds,
  ArticleIds: state.AddArticleReducer.ArticleIds,
  isAddingConversionCost: state.COnversionCostReducer.isAddingConversionCost,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddConversionCost);
