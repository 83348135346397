import React, { useState } from 'react'
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { addEvent, getEvent, deleteEvent, getDashboardMarquee, getBooking, searchMarqueeReport, /*UpdateHREvent */} from './MarqueeDetails.actions';
import { connect } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import { useEffect } from 'react';
import Swal from 'sweetalert';
import { isEmpty } from "lodash";
import Select from "react-select";
import { Scheduler } from "@aldabil/react-scheduler";

  
const DashboardReport = (props) => {
    // const [eventName, setEventName] = useState();
    const [bookingStatus, setBookingStatus] = useState("");
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    // const [eventDescription, setEventDescription] = useState();
    // const [submit, setSubmit] = useState(false);
    // const [visibleAlert, setAlertVisible] = useState(false);
    // const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
    // const eventData = isEmpty(props.eventList) ? [] : props.eventList.reverse();
    // const [state, setState] = React.useState({
    //     columns: [
    //         { title: 'Event Name', field: 'event_name' },
    //         { title: 'Event Description', field: 'event_desc' },
    //     ],
    // });
    // const clearStates = () => {
    //     setEventName();
    //     setEventDescription();
    //     setSubmit(false);

    // }
    // const handleVisible = () => {
    //     setAlertVisible(true)
    //     clearStates();
    //     setTimeout(() => {
    //         setAlertVisible(false)
    //     }, 9000);
    //     Swal({
    //         title: "Success",
    //         text: "Added successfully...",
    //         icon: "success",
    //         button: "Ok",
    //     });
    // }

    // const handleDangerVisible = () => {
    //     setDangerVisibleAlert(true)
    //     setTimeout(() => {
    //         setDangerVisibleAlert(false)
    //     }, 9000);
    //     Swal({
    //         title: "Error",
    //         text: "Something went wrong...",
    //         icon: "error",
    //         button: "Ok",
    //     });
    // }
    const handleChangeBookingById = (selectedOption) => {
        console.log(selectedOption, "sop");
        setBookingStatus(selectedOption.label);
        // setBookingName(selectedOption.label);
        // props.getBookingById(selectedOption.value)
      };
      const exportPDF = () => {
        props.searchMarqueeReport(
          {
            startedDate: !isEmpty(startDate) ? startDate : "",
            endDate: !isEmpty(endDate) ? endDate : "",
            booking_status: bookingStatus,
          },
        //   startDate,
        //   endDate
        );
      };
      const EVENTS= [
        {
          status_id: 1,
          title: "Confirmed",
        },
        {
            status_id: 2,
            title: "Complete",
        },
      ]
      const getBookingList =
        !isEmpty(EVENTS) &&
        EVENTS.map((x) => {
          let data = { value: x.status_id, label: x.title };
          return data;
        });

    useEffect(() => {
        // props.getEvent(2);
        // props.getDashboardMarquee();
        props.getBooking();
    }, []);
    return (
        <>
            {(props.isFetchingEventData || props.isFetchingDashboardMarquee) ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                    </Button>
                </div>
            ) : (
                <Container fluid>
                     <Row>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Start Date </b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> End Date</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col lg="4" md="12" sm="12">
             
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Select Status *</b>
                    </Form.Label>
                    <Select
                    //   isDisabled={isEmpty(props.bookingList)}
                      placeholder="Select Status..."
                      onChange={handleChangeBookingById}
                      options={getBookingList}
                    />
                  </Form.Group>
               
              </Col>
            </Row>
            <div className="sendDiv">
                  {/* {item == "" ? (
                    <Button
                      disabled
                      style={{ backgroundColor: "black" }}
                      className="sendButton"
                    >
                      Generate Ledger Report
                    </Button>
                  ) : ( */}
                    <Button className="sendButton" onClick={() => exportPDF()}>
                      Generate Report
                    </Button>
                  {/* )} */}
                </div>
                </Container>
            )}
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        searchMarqueeReport: (data) => dispatch(searchMarqueeReport(data)),
        // getEvent: (id) => dispatch(getEvent(id)),
        // //UpdateHREvent: (newData, oldData) => dispatch(UpdateHREvent(newData, oldData)),
        // deleteEvent: (event_id) => dispatch(deleteEvent(event_id)),
        // getDashboardMarquee:()=> dispatch(getDashboardMarquee()),
        getBooking: () => dispatch(getBooking()),
    };
};
const mapStateToProps = (state, ownProps) => ({
    bookingList: state.MarqueeReducer.bookingList,
    isFetchingBookingData: state.MarqueeReducer.isFetchingBookingData,
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardReport);

