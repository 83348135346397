import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import ImageUploader from "react-images-upload";
import { getCatalogCall, deleteCatalog, updateCatalog } from "./catalog.action";

const ViewCatelog = (props) => {
  useEffect(() => {
    props.getCatalogCall();
  }, []);

  return (
    <>
      {props.isFetchingGetCatalog ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialTable
              title="Catalog Details"
              columns={[
                { title: "Catalog #", field: "name" },
                // { title: 'Brand Description', field: 'description' },
              ]}
              data={props.catalogInfo}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                exportButton: true,
                exportAllData: true,
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.updateCatalog(newData, oldData);
                    }, 600);
                  }),

                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteCatalog(oldData.catelogId);
                    }, 600);
                  }),
              }}
            />
          </div>
        </Container>
      )}{" "}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCatalogCall: () => dispatch(getCatalogCall()),
    deleteCatalog: (catelogId) => dispatch(deleteCatalog(catelogId)),
    updateCatalog: (newData, oldData) =>
      dispatch(updateCatalog(newData, oldData)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  catalogInfo: state.catalogReducer.catalogInfo,
  isFetchingGetCatalog: state.catalogReducer.isFetchingGetCatalog,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewCatelog);
