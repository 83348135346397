import API from "../../../../../global/api";
import { forEach } from "lodash";

//Add Catalog Call

export const addCatalogCall = (data) => {
  console.log(data, "iipp");
  const formData = new FormData();
  formData.append("name", `${data.name}`);
  forEach(data.imagefront, (x, index) => {
    formData.append("imagefront", data.imagefront[index]);
    console.log(data.imagefront[index], "qqrr");
  });
  forEach(data.imageback, (x, index) => {
    formData.append("imageback", data.imageback[index]);
  });
  return (dispatch) => {
    dispatch(requestAddCatalog());
    API.post("catelog/catelog-add-data", formData)
      .then((res) => {
        dispatch(successAddCatalog(data));
      })
      .catch((error) => {
        dispatch(errorAddCatalog());
      });
  };
};

export const requestAddCatalog = () => {
  return {
    type: "REQUEST_ADD_CATALOG",
  };
};

export const successAddCatalog = (data) => {
  return {
    type: "SUCCESS_ADD_CATALOG",
    payload: data,
  };
};

export const errorAddCatalog = () => {
  return {
    type: "ERROR_ADD_CATALOG",
  };
};

//Get Catalog Call

export const getCatalogCall = () => {
  return (dispatch) => {
    dispatch(requestGetCatalog());
    API.get("catelog/get-catelog-data")
      .then((res) => {
        dispatch(successGetCatalog(res.data));
      })
      .catch((error) => {
        dispatch(errorGetCatalog());
      });
  };
};

export const requestGetCatalog = () => {
  return {
    type: "REQUEST_GET_CATALOG",
  };
};

export const successGetCatalog = (data) => {
  return {
    type: "SUCCESS_GET_CATALOG",
    payload: data,
  };
};

export const errorGetCatalog = () => {
  return {
    type: "ERROR_GET_CATALOG",
  };
};

export const requestDeleteCatalog = () => {
  return {
    type: "REQUEST_DELETE_CATALOG",
  };
};

export const successDeleteCatalog = (catelogId) => {
  return {
    type: "SUCCESS_DELETE_CATALOG",
    catelogId: catelogId,
  };
};

export const errorDeleteCatalog = () => {
  return {
    type: "ERROR_DELETE_CATALOG",
  };
};
export const deleteCatalog = (catelogId) => {
  return (dispatch) => {
    dispatch(requestDeleteCatalog());
    API.delete(`/Catalog/${catelogId}`)
      .then((res) => {
        dispatch(getCatalogCall());
        dispatch(successDeleteCatalog(catelogId));
      })
      .catch((error) => {
        dispatch(errorDeleteCatalog());
      });
  };
};

// update

export const requestUpdateCatalog = () => {
  return {
    type: "REQUEST_UPDATE_CATALOG",
  };
};

export const successUpdateCatalog = (data) => {
  return {
    type: "SUCCESS_UPDATE_CATALOG",
    payload: data,
  };
};

export const errorUpdateCatalog = () => {
  return {
    type: "ERROR_UPDATE_CATALOG",
  };
};

export const updateCatalog = (newData, oldData) => {
  return (dispatch) => {
    dispatch(requestUpdateCatalog());
    API.put(`/update/${oldData.catelogId}`, {
      name: newData.name,
    })
      .then((res) => {
        dispatch(getCatalogCall());
        dispatch(successUpdateCatalog([newData]));
      })
      .catch((error) => {
        dispatch(errorUpdateCatalog());
      });
  };
};
