import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import 'jspdf-autotable';
import ImageUploader from 'react-images-upload';
import { deleteBrand, getBrandCall, updateBrand } from "./brand.action";





const ViewBrand = (props) => {


    useEffect(() => {
      props.getBrandCall()
    }, []);

    return (
        <Container fluid>
                 {props.isFetchingGetBrand ? (
              <div className="loader-div">
                <Spinner
                  variant="primary"
                  animation="grow"
                  size="xl"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            ) : (
            <div className="main">
            <MaterialTable
                            title="Brand Details"
                            columns={[
                                { title: 'Brand', field: 'brandName' },
                                { title: 'Brand Description', field: 'brandDescription' },
                            ]}
                            data={props.brandInfo}
                            options={{
                                actionsColumnIndex: -1,
                                filtering: true,
                                exportButton: true,
                                exportAllData: true,
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                },

                            }}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.updateBrand(newData, oldData)
                                        }, 600);
                                    }),

                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                              props.deleteBrand(oldData.brandId);
                                        }, 600);
                                    }),
                            }}
                        />

            </div>
            )}
        </Container>

    )
}

const mapDispatchToProps = (dispatch) => {
    return {
      getBrandCall: () => dispatch(getBrandCall()),
      deleteBrand: (brandId) => dispatch(deleteBrand(brandId)),
      updateBrand: (newData, oldData) => dispatch(updateBrand(newData, oldData)),
    };
};
const mapStateToProps = (state, ownProps) => ({
  brandInfo: state.brandReducer.brandInfo,
  isFetchingGetBrand: state.brandReducer.isFetchingGetBrand,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewBrand);