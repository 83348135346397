import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import Select from "react-select";

import { rotateAndSkewTextDegreesAndTranslate } from "pdf-lib";
import { findAllByAltText } from "@testing-library/dom";
import { deleteVendor, getVendor, updateVendor } from "./vendor.action";

const ViewVendors= (props) => {
  useEffect(() => {
    props.getVendor();
  }, []);

  return (
    <>
      {props.isFetchingGetVendor ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          {console.log(props.vendorInfo,"props.vendorInfo")}
          <div className="main">
            <MaterialTable
              title="Vendor Details"
              columns={[
                { title: "Name", field: "vendor_name" },
                { title: "Phone", field: "vendor_phone" },
                { title: "Address", field: "vendor_address" },
                { title: "CNIC", field: "vendor_cnic" },
              ]}
              data={props.vendorInfo}
              options={{
                actionsColumnIndex: -1,
                filtering: false,
                exportButton: true,
                exportAllData: true,
                pageSize:50,
                paging:true,
                emptyRowsWhenPaging:false,
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      {
                        props.updateVendor(newData, oldData);
                      }
                    }, 600);
                  }),

                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteVendor(oldData.vendor_id);
                    }, 600);
                  }),
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVendor: () => dispatch(getVendor()),
    updateVendor: (newData, oldData) =>
      dispatch(updateVendor(newData, oldData)),
    deleteVendor: (id) => dispatch(deleteVendor(id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  vendorInfo: state.vendorReducer.vendorInfo,
  isFetchingGetVendor: state.vendorReducer.isFetchingGetVendor,
});
export default connect(mapStateToProps, mapDispatchToProps)(ViewVendors);
