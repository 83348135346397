import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import {
  addSubEvent,
  getSubEvent,
  getEvent,
  updateSubEvent,
  deleteSubEvent,
  getDashboardMarquee,
  accountIntegration,
  getAccountIntegration,
} from "./MarqueeDetails.actions";
import Select from "react-select";
import Swal from "sweetalert";
import jsPDF from "jspdf";
import logo from "../../../../../Images/logo.png";
import { filter } from "lodash";
import { getLevel5Data } from "../../accountsLevels/accountsLevel.action";

const AccountIntegration = (props) => {
  const [cashAccountId, setCashAccountId] = useState("");
  const [cashAccountName, setCashAccountName] = useState("");
  const [saleAccountId, setSaleAccountId] = useState("");
  const [saleAccountName, setSaleAccountName] = useState("");
  const [bankAccountId, setBankAccountId] = useState("");
  const [bankAccountName, setBankAccountName] = useState("");

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  function uploadSubEvent() {
    props.accountIntegration(
      {
        saleLevelFiveId: saleAccountId,
        cashLevelFiveId: cashAccountId,
        bankLevelFiveId: bankAccountId
      },
      handleVisible,
      handleDangerVisible
    );
  }
  // [(!isEmpty(props.EventList)) & (console.log('props.EventList', props.EventList))]

  const clearStates = () => {
    setCashAccountName("")
    setSaleAccountName("")
    setBankAccountName("")
  };

  /*const [state, setState] = React.useState({
        columns: [
            { title: 'SubEvent Name', field: 'sub_event_name' },
            { title: 'SubEvent Description', field: 'sub_event_desc' },
            { title: 'Event Name', field: 'event.event_name', editable: 'never' },
        ],
    });*/

  const handleVisible = () => {
    setAlertVisible(true);
    // clearStates();
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };
  const cashAccountNoTop =
    !isEmpty(props.level5List) &&
    props.level5List
      // .filter((x) => x.levelfiveData.levelFourId == level4Id)
      .map((y) => {
        let data = {
          value: y.levelfiveData.levelFiveId,
          label:
            y.levelfiveData.allLevelKey + " / " + y.levelfiveData.levelFiveName,
          values: y.levelFour.levelFourId
        };
        return data;
      });
  const changeCashAccount = (selectedOption, type) => {
    setCashAccountId(selectedOption.value)
    setCashAccountName(selectedOption.label)
  };

  const changeSaleAccount = (selectedOption, type) => {
    setSaleAccountId(selectedOption.value)
    setSaleAccountName(selectedOption.label)
  };
  const changeBankAccount = (selectedOption, type) => {
    setBankAccountId(selectedOption.value)
    setBankAccountName(selectedOption.label)
  };

  useEffect(() => {
    props.getLevel5Data()
    props.getAccountIntegration()
    setCashAccountId(!isEmpty(props.accountIntegrationData)&&props.accountIntegrationData.cash[0].levelFiveId)
    setCashAccountName(!isEmpty(props.accountIntegrationData)&&props.accountIntegrationData.cash[0].levelFiveName)
    setSaleAccountId(!isEmpty(props.accountIntegrationData)&&props.accountIntegrationData.sale[0].levelFiveId)
    setSaleAccountName(!isEmpty(props.accountIntegrationData)&&props.accountIntegrationData.sale[0].levelFiveName)
    setBankAccountId(!isEmpty(props.accountIntegrationData)&&props.accountIntegrationData.bank[0].levelFiveId)
    setBankAccountName(!isEmpty(props.accountIntegrationData)&&props.accountIntegrationData.bank[0].levelFiveName)
  }, []);
  console.log(props.accountIntegrationData, "accountIntegration", props.accountIntegrationDataLevelFive)
  return (
    <>
      {props.isFetchinglevel5List || props.isFetchingAccountIntegration? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {/* {props.isFetchingwareHouseList ? 'Loading...' : 'Loading...'} */}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <Row style={{ zIndex: 2 }}>
                  <Col lg="6" sm="12" >
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label><b>Select Cash Account</b></Form.Label>
                      <Select
                        // style={{zIndex}}
                        // value={{ label: cashAccountName }}
                        defaultValue={{
                          value: !isEmpty(props.accountIntegrationData)&&props.accountIntegrationData.cash[0].levelFiveId,
                          label: !isEmpty(props.accountIntegrationData)&&props.accountIntegrationData.cash[0].levelFiveName
                        }}
                        placeholder="Select Cash Account..."
                        onChange={(changeCashAccount)}
                        options={filter(cashAccountNoTop, x => x.values == 1)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" sm="12" >
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label><b>Select Sales Account</b></Form.Label>
                      <Select
                        // style={{zIndex}}
                        // value={{ label: saleAccountName }}
                        defaultValue={{
                          value: !isEmpty(props.accountIntegrationData)&&props.accountIntegrationData.sale[0].levelFiveId,
                          label: !isEmpty(props.accountIntegrationData)&&props.accountIntegrationData.sale[0].levelFiveName
                        }}
                        // defaultValue={{ label: !isEmpty(props.accountIntegration) && props.accountIntegration.data[0].levelfive[1].levelFiveName }}
                        placeholder="Select Sale Account..."
                        onChange={(changeSaleAccount)}
                        options={filter(cashAccountNoTop, x => x.values == 8 || x.values == 7)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row style={{ zIndex: 1 }}>
                  <Col lg="6" sm="12" >
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label><b>Select Bank Account</b></Form.Label>
                      <Select
                        // style={{zIndex}}
                        // value={{ label: bankAccountName }}
                        defaultValue={{
                          value: !isEmpty(props.accountIntegrationData)&&props.accountIntegrationData.bank[0].levelFiveId,
                          label: !isEmpty(props.accountIntegrationData)&&props.accountIntegrationData.bank[0].levelFiveName
                        }}
                        placeholder="Select Bank Account..."
                      
                        onChange={(changeBankAccount)}
                        options={filter(cashAccountNoTop, x => x.values == 2)}
                      />
                    </Form.Group>
                  </Col>
                </Row>



                <div className="sendDiv">
                  {cashAccountName == "" || saleAccountName == "" || bankAccountName == "" ? (
                    <Button
                      className="sendButton"
                      onClick={uploadSubEvent}
                      style={{ backgroundColor: "black" }}
                      disabled
                    >
                      Save
                    </Button>
                  ) : (
                    <Button className="sendButton" onClick={uploadSubEvent}>
                      Save
                    </Button>
                  )}
                </div>
              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLevel5Data: () => dispatch(getLevel5Data()),
    getAccountIntegration: () => dispatch(getAccountIntegration()),
    accountIntegration: (data, handleVisible, handleDangerVisible) => dispatch(accountIntegration(data, handleVisible, handleDangerVisible)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  level5List: state.accountsLevelReducer.level5List,
  isFetchinglevel5List: state.accountsLevelReducer.isFetchinglevel5List,
  isFetchingAccountIntegration: state.MarqueeReducer.isFetchingAccountIntegration,
  accountIntegration: state.MarqueeReducer.accountIntegration,
  accountIntegrationData: state.MarqueeReducer.accountIntegrationData,
  accountIntegrationDataLevelFive: state.MarqueeReducer.accountIntegrationDataLevelFive,
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountIntegration);
