const initialState = {
    isFetchingAddSize: false,
    isFetchingGetSize: false,
    sizeDetail: {
        size: "",
        description: "",
    },
    sizeInfo: [],

}


const sizeReducer = (state = initialState, action) => {
    switch (action.type) {
           //Size
        case "REQUEST_ADD_SIZE":
            return { ...state, isFetchingAddSize: true };

        case "SUCCESS_ADD_SIZE":
            return {
                ...state, isFetchingAddSize: false,
                sizeDetail: {
                    size: action.payload.sizeName,
                    description: action.payload.sizeDescription,
                }
            };

        case "ERROR_ADD_SIZE":
            return { ...state, isFetchingAddSize: false };

        //Get Size
        case "REQUEST_GET_SIZE":
            return { ...state, isFetchingGetSize: true };

        case "SUCCESS_GET_SIZE":
            return {
                ...state, isFetchingGetSize: false,
                sizeInfo: action.payload.data,
            };

        case "ERROR_GET_SIZE":
            return { ...state, isFetchingGetSize: false };

        default:
            return state;
    }
}

export default sizeReducer;
