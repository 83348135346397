import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { isNull } from "lodash";
import AddCatalog from "./addCatalog";
import ViewCatalog from "./viewCatalog";

const TotalCatalogData = (props) => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  return (
    <>
      {/* <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 92) ?
                    "AddCatalog" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 93) ?
                        "CatalogList" :  ""} id="AddCatalog" className="mb-3"> */}
      <Tabs defaultActiveKey={"AddCatalog"} id="AddCatalog" className="mb-3">
        <Tab
          eventKey="AddCatalog"
          title="Add Catalog"
          // disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 92) ? false : true}
        >
          <AddCatalog />
        </Tab>
        <Tab
          eventKey="CatalogList"
          title="Catalog List"
          //    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 93) ? false : true}
        >
          <ViewCatalog />
        </Tab>
      </Tabs>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TotalCatalogData);
