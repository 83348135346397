import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  FormGroup,
  Modal,
  Alert,
} from "react-bootstrap";
import "../production-styles.css";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import { isEmpty } from "lodash";
import {
  getArticle,
  getArticleData,
  addAdminTotalCost,
} from "./AddAdminCost.actions";
import { getArticleIds } from "../AddArticleMaterialFolder/AddArticleMaterial.actions";

const AddConversionCost = (props) => {
  const [articleId, setArticleId] = useState();
  const [adminCost, setAdminCost] = useState();
  const getArticleListData = props.getArticleList.map((x) => {
    let data = { value: x.articleId, label: x.itemName };
    return data;
  });
  const handleChangeArticle = (selectedOption) => {
    setArticleId(selectedOption.value);
    props.getArticleData(selectedOption.value);
  };

  function saveConversionCost() {
    props.addAdminTotalCost({
      articleData: props.ArticleIds.map((x) => {
        let data = {
          articleId: x,
          conversioncost: Number(props.getArticleListData.conversionCost),
          adminCost: adminCost,
          totalAdminCost:
            (Number(props.getArticleListData.conversionCost) / 100) *
            Number(adminCost),
          totalCost:
            !isEmpty(props.getArticleListData) &&
            Number(props.getArticleListData.conversionCost) +
              (Number(props.getArticleListData.conversionCost) / 100) *
                Number(adminCost),
        };
        return data;
      }),
    });
  }
  useEffect(() => {
    props.getArticle();
    props.getArticleIds();
  }, []);
  return (
    <>
      {props.isFetchingArticle ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {/* {props.isFetchingSectorsSummary ? 'Loading...' : 'Loading...'} */}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            {props.isAddingAddAdminTotalCost && (
              <Alert variant={"success"}>Added Data...</Alert>
            )}
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Select Article *</b>
                  </Form.Label>
                  <Select
                    placeholder="Select Article..."
                    onChange={(e) => {
                      handleChangeArticle(e);
                    }}
                    options={getArticleListData}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="6" lg="6" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Add Admin Cost *</b>
                  </Form.Label>
                  <Form.Control
                    min={0}
                    type="number"
                    placeholder="Cost...."
                    name="materialCost"
                    onChange={(e) => setAdminCost(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col xl="6" lg="6" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Total Conversion Cost</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Cost...."
                    name="labourCost"
                    value={
                      !isEmpty(props.getArticleListData) &&
                      Number(props.getArticleListData.conversionCost)
                    }
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl="6" lg="6" md="6" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Total Admin Cost</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Cost...."
                    name="productCost"
                    value={
                      (Number(props.getArticleListData.conversionCost) / 100) *
                      Number(adminCost)
                    }
                    readOnly
                  />
                </Form.Group>
              </Col>

              <Col xl="6" lg="6" md="6" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Total Cost</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Cost...."
                    name="productCost"
                    value={
                      !isEmpty(props.getArticleListData) &&
                      Number(props.getArticleListData.conversionCost) +
                        (Number(props.getArticleListData.conversionCost) /
                          100) *
                          Number(adminCost)
                    }
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv">
              {props.isAddingAddAdminTotalCost ? (
                <div className="loader-div">
                  <Spinner
                    variant="primary"
                    animation="grow"
                    size="xl"
                    role="status"
                    aria-hidden="true"
                  />
                </div>
              ) : (
                <Button
                  disabled={isEmpty(articleId) || isEmpty(adminCost)}
                  className="sendButton"
                  onClick={() => saveConversionCost()}
                  style={{
                    marginLeft: "2%",
                    color: "white",
                    width: "20%",
                    backgroundColor: "black",
                    border: "1px solid black",
                  }}
                >
                  Save
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getArticle: () => dispatch(getArticle()),
    getArticleData: (id) => dispatch(getArticleData(id)),
    getArticleIds: () => dispatch(getArticleIds()),
    addAdminTotalCost: (data) => dispatch(addAdminTotalCost(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  getArticleList: state.AddAdminCostReducer.getArticleList,
  getArticleListData: state.AddAdminCostReducer.getArticleListData,
  // ArticleIds: state.productionReducer.ArticleIds,
  ArticleIds: state.AddArticleReducer.ArticleIds,
  isAddingAddAdminTotalCost:
    state.AddAdminCostReducer.isAddingAddAdminTotalCost,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddConversionCost);
