import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import AddArticleMaterial from "./AddArticleMaterialFolder/AddArticleMaterial";
import AddArticles from "./AddArticleFolder/AddArticles";
import AddLabour from "./AddLabourFolder/AddLabour";
import AddConversionCost from "./ConversionCostFolder/AddConversionCost";
import AddArticleMaterialTable from "./AddArticleMaterialTable/AddArticleMaterialTable";
import AddAdminCost from "./AdminCost/AddAdminCost";
import { getArticleIds } from "./AddArticleMaterialFolder/AddArticleMaterial.actions";
import { isEmpty } from "lodash";

const Articles = (props) => {
  console.log(props.ArticleIds, "articlessIdsssssssss");
  useEffect(() => {
    props.getArticleIds();
  }, []);
  return (
    <>
      <Tabs defaultActiveKey="AddArticle" id="AddArticle" className="mb-3">
        <Tab eventKey="AddArticle" title="Add Article">
          <AddArticles />
        </Tab>
        {/* {!isEmpty(props.ArticleIds) ? ( */}
        <Tab eventKey="AddArticleMaterial" title="Add Article Material">
          <AddArticleMaterial />
        </Tab>
        {/* ) : (
          <Tab
            eventKey="AddArticleMaterial"
            title="Add Article Material"
            disabled
          ></Tab>
        )} */}
        {/* {!isEmpty(props.ArticleIds) ? ( */}
        <Tab eventKey="AddLabour" title="Add Labour">
          <AddLabour />
        </Tab>
        {/* ) : (
          <Tab eventKey="AddLabour" title="Add Labour" disabled></Tab>
        )} */}
        {/* {!isEmpty(props.ArticleIds) ? ( */}
        <Tab eventKey="ConversionCost" title="Conversion Cost">
          <AddConversionCost />
        </Tab>
        {/* ) : (
          <Tab eventKey="ConversionCost" title="Conversion Cost" disabled></Tab>
        )} */}
        {/* {!isEmpty(props.ArticleIds) ? ( */}
        <Tab eventKey="AdminCost" title="Admin Cost">
          <AddAdminCost />
        </Tab>
        {/* ) : (
          <Tab eventKey="AdminCost" title="Admin Cost" disabled></Tab>
        )} */}
        {/* {!isEmpty(props.ArticleIds) ? ( */}
        <Tab
          eventKey="AddAricleMaterialTable"
          title="Add Aricle Material Table"
        >
          <AddArticleMaterialTable />
        </Tab>
        {/* ) : (
          <Tab
            eventKey="AddAricleMaterialTable"
            title="Add Aricle Material Table"
            disabled
          ></Tab>
        )} */}
      </Tabs>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getArticleIds: () => dispatch(getArticleIds()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  ArticleIds: state.AddArticleReducer.ArticleIds,
});

export default connect(mapStateToProps, mapDispatchToProps)(Articles);
