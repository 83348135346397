import React, { useState } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  Card,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, get, find, isUndefined, filter, sumBy } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import "./style.css";
import Select from "react-select";
import MaterialTable from "material-table";
import {MTableToolbar} from 'material-table';
import {
  addCashPayment,
  submitCashPayment,
  deleteCashPayment,
  resetFunction,
} from "./Voucher.action";
import Swal from 'sweetalert';
import moment from 'moment';
import {previewVoucher} from '../../../../global/globalFunctions'
import { mergeNames } from "../../../../global/globalFunctions";
const CashPayment = (props) => {

  const [accountNo, setaccountNo] = useState('');
  const [selectedAccount, setSelectedAccount] = useState();
  const [cashAccountTitle, setCashAccountTitle] = useState('');
  const [cashAccountTitleKey, setCashAccountTitleKey] = useState();
  const [voucher, setVoucher] = useState();
  const [level4Id, setLevel4Id] = useState(1);
  const [datestate, setDatestate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [level4Label, setLevel4Lablel] = useState('');

  const handleVisible = (data) => {
    setAlertVisible(true)
    setTimeout(() => {
      setAlertVisible(false)
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      buttons: ["Cancel", "Print Voucher"]
    }).then((willPrint) => {
      if (willPrint) {
        //subPage()
        previewVoucher(data, "Pending");
      } else {
         // setShowDialog(false)
      }
    })
  }

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true)
    setTimeout(() => {
      setDangerVisibleAlert(false)
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  }
  console.log(datestate, "sssssssssssssssss");
  const [values, setValues] = useState({
    voucherDescription: "",
    voucherAmount: "",
    PONo: "",
    billNo: "",
    accountTittle: "",
    // payTo: "",
    levelFiveId: "",
    voucherDate: "",
    accountType: "2",
    account: "",
    credit:"",
    debit:"",
    crossAccount:""
  });
  console.log(values, "vvvvvvvvvvvvvvvvvvvv");

  // const inputEvent = (event) => {
  //   setValues({
  //     ...values,
  //     [event.target.name]: event.target.value,
  //   });
  //   setDatestate(event.target.value);
  // };

  const inputEvent = (event) => {
    event.target.name == "voucherDate" &&

      setDatestate(event.target.value);
   if(event.target.name == "voucherAmount" ) {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
      debit:Number(event.target.value)
    })
   }
   else {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
   }
  };

  const inputEventAccount = (selectedOption, type) => {
    setValues({
      levelFiveId: selectedOption.value,
    });
  };

  const inputEvent1Account = (selectedOption) => {
    setValues({
      ...values,
      ["levelFiveId"]: selectedOption.value,
      ["accountTittle"]: get(
        find(
          props.level5List,
          (x) => x.levelfiveData.levelFiveId == selectedOption.value
        ),
        "levelfiveData.allLevelKey",
        0
      ) +" / "+get(
        find(
          props.level5List,
          (x) => x.levelfiveData.levelFiveId == selectedOption.value
        ),
        "levelfiveData.levelFiveName",
        0
      ),
    });
  };

  const cashAccountNo =
    !isEmpty(props.level5List) &&
    props.level5List.map((x) => {
      let data = {
        value: x.levelfiveData.levelFiveId,
        label:
          x.levelfiveData.allLevelKey + " / " + x.levelfiveData.levelFiveName,
      };
      return data;
    });

  const cashAccountNoTop =
    !isEmpty(props.level5List) &&
    props.level5List
      .filter((x) => x.levelfiveData.levelFourId == level4Id)
      .map((y) => {
        let data = {
          value: y.levelfiveData.levelFiveId,
          label:
            y.levelfiveData.allLevelKey + " / " + y.levelfiveData.levelFiveName,
        };
        return data;
      });

  const handleChangeAccountNo = (selectedOption) => {
    setaccountNo(selectedOption.value);
    setCashAccountTitle(`${find(
      props.level5List,
      (x) => x.levelfiveData.levelFiveId == selectedOption.value
    ).levelfiveData.allLevelKey} / ${find(
      props.level5List,
      (x) => x.levelfiveData.levelFiveId == selectedOption.value
    ).levelfiveData.levelFiveName}`);
    setCashAccountTitleKey(
      find(
        props.level5List,
        (x) => x.levelfiveData.levelFiveId == selectedOption.value
      ).levelfiveData.allLevelKey
    );
  };

  const getLevelFourList =
    !isEmpty(props.level4List) &&
    props.level4List.map((x) => {
      let data = {
        value: x.levelFourData.levelFourId,
        label: x.levelFourData.allLevlKey + " / " + x.levelFourData.levelFourName,
      };
      return data;
    });
  const handleChangeLevel4 = (selectedOption) => {
    setLevel4Id(selectedOption.value);
    setLevel4Lablel(selectedOption.label);
  };

  const cash = props.cashPayment;
  const addPayment = () => {
    let newVal = values;
    if (!isEmpty(datestate)) newVal.voucherDate = datestate;
    props.submitCashPayment(newVal);
    setValues({
       voucherDescription: values.voucherDescription,
      voucherAmount: "",
      // PONo: "",
       billNo: values.billNo,
      // accountTittle: "",
      // // payTo: "",
      // levelFiveId: "",
      // voucherDate: "",
       accountType: "2",
      // account: "",
      // credit:"",
      // debit:"",
      // crossAccount:""
    });
    setLevel4Lablel("")
  };

  const add = () => {
    console.log(cash, "cashi")
    let dt= cash.map((x) => {
      return x.data;
    })
    let cashData= [{
      voucherDescription: mergeNames(dt, 'voucherDescription'),
      voucherAmount: sumBy(cash,item => Number(item.data.voucherAmount)),
      PONo: "",
      billNo: mergeNames(dt, 'billNo'),
      accountTittle: cashAccountTitle,
      levelFiveId:accountNo,
      voucherDate: datestate,
      accountType: "1",
      crossAccount: mergeNames(dt, 'voucherDescription'),
      credit: sumBy(cash,item => Number(item.data.voucherAmount)),
      debit:""
  }]


    props.addCashPayment({
      voucherTypeId: "1",
      levelFiveId: accountNo,
      totalAmount: sumBy(cash,item => Number(item.data.voucherAmount)),
      cashAccTittle: cashAccountTitle,
      voucher: voucher,
      data: [...dt, ...cashData],
      voucherType: "CPV",
      credit_or_debit: "1",
    }, handleVisible, handleDangerVisible);
    props.resetFunction();
  };
  return (
    <>
      {props.isFetchinglevel5List || props.isFetchinglevel4List ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Card style={{ padding: "3%" }}>
              <Row>
                <Col lg="12">
                  <div className="text-white">
                    <Row>
                      <Col lg="4" sm="12">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Date *</Form.Label>
                          <Form.Control
                            type="date"
                            name="voucherDate"
                            value={datestate}
                            //value={values.voucherDate}
                            onChange={inputEvent}
                            max={moment(new Date()).format("YYYY-MM-DD")}
                            disabled={!isEmpty(props.cashPayment)}
                          />
                        </Form.Group>
                      </Col>

                      {/* <Col lg="4" sm="12">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Select Level4</Form.Label> */}
                      {/* <Select
                          value = {{label: level4Label}}
                            className="selectOptionColor"
                            placeholder="Select Level 4..."
                            disabled={true}
                            isOptionDisabled={(option) => option.disabled}
                           // onChange={(e) => handleChangeLevel4(e)}
                            //options={getLevelFourList}
                          /> */}
                      {/* <Form.Control
                            type="text"
                            name="selectlevel4"
                            value={level4Label}
                            //value={values.voucherDate}
                           
                            disabled={true}
                          />
                        </Form.Group>
                      </Col> */}
                    </Row>

                  </div>
                </Col>
              </Row>

              <div style={{ padding: "1% 0" }}>
                <Row>
                  <Col lg="6" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Account *</Form.Label>
                      <Select
                        value={{ label: values.accountTittle }}
                        placeholder="Select Cash Account No..."
                        onChange={(inputEventAccount, inputEvent1Account)}
                        options={filter(cashAccountNo, x => x.value !== accountNo)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Amount Type"
                        name="accountType"
                        value="Debit"
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Cash A/C No *</Form.Label>
                      <Select
                        value={{ label: cashAccountTitle }}
                        className="selectOptionColor"
                        isDisabled={isUndefined(level4Id)}
                        placeholder="Select Cash Account No..."
                        onChange={handleChangeAccountNo}
                        options={filter(cashAccountNoTop, x => x.value !== values.levelFiveId)}
                      />
                    </Form.Group>
                  </Col>

                  <Col lg="6" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Amount Type"
                        value="Credit"
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <div style={{ padding: "1% 0" }}>
                <Row>
                  <Col lg="2" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Bill</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Bill No"
                        id="billno"
                        name="billNo"
                        value={values.billNo}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="7" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter description"
                        id="descriptionID"
                        name="voucherDescription"
                        value={values.voucherDescription}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="3" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Amount *</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Amount"
                        id="amountID"
                        name="voucherAmount"
                        value={values.voucherAmount}
                        min={0}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <Row>
                <Col lg="6" sm="12" className="text-center">
                {isEmpty(values.voucherAmount) || isEmpty(values.accountTittle) ||isEmpty(cashAccountTitle)?
                  <Button
                    className="sendButtonVoucher"
                    style={{backgroundColor:'black'}}
                    disabled
                    onClick={() => {
                      addPayment();
                    }}
                  >
                    ADD
                  </Button>:
                  <Button
                  className="sendButtonVoucher"
                  // style={{backgroundColor:'black'}}
                  // disabled
                  onClick={() => {
                    addPayment();
                  }}
                >
                  ADD
                </Button>
                  }
                </Col>
                <Col lg="6" sm="12">
                  {isEmpty(cash)?
                  <Button 
                  className="sendButtonVoucher" 
                  style={{backgroundColor:'black'}}
                  disabled onClick={add}>
                    POST
                  </Button>:
                  <Button 
                  className="sendButtonVoucher" 
                  onClick={add}>
                    POST
                  </Button>}
                </Col>
              </Row>

              <div className="table">
                <MaterialTable
                  title={`VOUCHER TOTAL : ${sumBy(cash,item => Number(item.data.voucherAmount))}`}
                  columns={[
                    { title: "Date", field: "data.voucherDate" },
                    { title: "Account Name", field: "data.accountTittle" },
                    { title: "Bill No", field: "data.billNo" },
                    { title: "Description", field: "data.voucherDescription" },
                    { title: "Amount", field: "data.voucherAmount" },
                    {
                      title: "DR/CR",
                      field: "data.accountType",
                      render: (rowData) =>
                        rowData.data.accountType == 1
                          ? "Credit"
                          : rowData.data.accountType == 2
                            ? "Debit"
                            : "---",
                    },
                  ]}
                  data={cash}
                  options={{
                    actionsColumnIndex: -1,
                    paging: true,
                    pageSize: 200, // make initial page size
                    emptyRowsWhenPaging: false, // To avoid of having empty rows
                    pageSizeOptions: [50, 100, 150, 200],
                    headerStyle: {
                      position: "sticky",
                      top: 0,
                      color: "#00BBBB",
                      fontWeight: "550",
                      onRowAdd: "none",
                    },
                  }}
                  // components={{
                  //   Toolbar: props => (
                  //     <div>
                  //       <MTableToolbar {...props} />
                  //       <div style={{padding: '0px 10px'}}>
                  //         <h>Afrax</h1>
                  //       </div>
                  //     </div>
                  //   ),
                  // }}
                  editable={{
                    onRowDelete: (oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                          props.deleteCashPayment(oldData);
                        }, 600);
                      }),
                  }}
                />
              </div>
            </Card>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    addCashPayment: (data, handleVisible, handleDangerVisible) => dispatch(addCashPayment(data, handleVisible, handleDangerVisible)),
    submitCashPayment: (data) => dispatch(submitCashPayment(data)),
    deleteCashPayment: (id) => dispatch(deleteCashPayment(id)),
    resetFunction: () => dispatch(resetFunction()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  cashPayment: state.VoucherReducer.cashPayment,
  level5List: state.accountsLevelReducer.level5List,
  isFetchinglevel5List: state.accountsLevelReducer.isFetchinglevel5List,
  level4List: state.accountsLevelReducer.level4List,
  isFetchinglevel4List: state.accountsLevelReducer.isFetchinglevel4List,
});

export default connect(mapStateToProps, mapDispatchToProps)(CashPayment);
