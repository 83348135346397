import API from '../../../../global/api';
import { forEach } from "lodash";

//Add Color Call

export const addColor = (data) => {
    return (dispatch) => {
        dispatch(requestAddColor())
        API.post('size-color/color-add-data', data).then((res) => {
            dispatch(successAddColor(data))
        }).catch((error) => {
            dispatch(errorAddColor())
        })
    }
}

export const requestAddColor = () => {
    return {
        type: "REQUEST_ADD_COLOR"
    }
}

export const successAddColor = (data) => {
    return {
        type: "SUCCESS_ADD_COLOR",
        payload: data,
    }
}

export const errorAddColor = () => {
    return {
        type: "ERROR_ADD_COLOR"
    }
}



//Get Color Call

export const getColorCall = () => {
    return (dispatch) => {
        dispatch(requestGetColor())
        API.get('size-color/get-color-data').then((res) => {
            dispatch(successGetColor(res.data))
        }).catch((error) => {
            dispatch(errorGetColor())
        })
    }
}

export const requestGetColor = () => {
    return {
        type: "REQUEST_GET_COLOR"
    }
}

export const successGetColor = (data) => {
    return {
        type: "SUCCESS_GET_COLOR",
        payload: data,
    }
}

export const errorGetColor = () => {
    return {
        type: "ERROR_GET_COLOR"
    }
}



//Get Division Call

export const getDivisionCall = () => {
    return (dispatch) => {
        dispatch(requestGetDivision())
        API.get('division/get_all_division').then((res) => {
            dispatch(successGetDivision(res.data))
        }).catch((error) => {
            dispatch(errorGetDivision())
        })
    }
}

export const requestGetDivision = () => {
    return {
        type: "REQUEST_GET_DIVISION"
    }
}

export const successGetDivision = (data) => {
    return {
        type: "SUCCESS_GET_DIVISION",
        payload: data,
    }
}

export const errorGetDivision = () => {
    return {
        type: "ERROR_GET_DIVISION"
    }
}



//Add Customer Call

export const addCustomerCall = (data) => {
    return (dispatch) => {
        dispatch(requestAddCustomer())
        API.post('customer/add_customer', data).then((res) => {
            dispatch(successAddCustomer(data))
        }).catch((error) => {
            dispatch(errorAddCustomer())
        })
    }
}

export const requestAddCustomer = () => {
    return {
        type: "REQUEST_ADD_CUSTOMER"
    }
}

export const successAddCustomer = (data) => {
    return {
        type: "SUCCESS_ADD_CUSTOMER",
        payload: data,
    }
}

export const errorAddCustomer = () => {
    return {
        type: "ERROR_ADD_CUSTOMER"
    }
}



//Get Customer Call

export const getCustomerCall = () => {
    return (dispatch) => {
        dispatch(requestGetCustomer())
        API.get('customer/show_customer').then((res) => {
            dispatch(successGetCustomer(res.data))
        }).catch((error) => {
            dispatch(errorGetCustomer())
        })
    }
}

export const requestGetCustomer = () => {
    return {
        type: "REQUEST_GET_CUSTOMER"
    }
}

export const successGetCustomer = (data) => {
    return {
        type: "SUCCESS_GET_CUSTOMER",
        payload: data,
    }
}

export const errorGetCustomer = () => {
    return {
        type: "ERROR_GET_CUSTOMER"
    }
}



//Update Customer Call

export const updateCustomerCall = (newData, oldData) => {
    return (dispatch) => {
        dispatch(requestUpdateCustomer())
        API.put(`customer/update_customer/${oldData.customerId}`,
            {
                customerCompany: newData.customerCompany,
                customerPerson: newData.customerPerson,
                customerPhone: newData.customerPhone,
                customerEmail: newData.customerEmail,
                customerWeb: newData.customerWeb,
                customerContry: newData.customerContry,
                customerCity: newData.customerCity,
                divisionId: newData.divisionId,
            }).then((res) => {
                dispatch(getCustomerCall())
                dispatch(successUpdateCustomer([newData]))
            }).catch((error) => {
                dispatch(errorUpdateCustomer())
            })
    }
}

export const requestUpdateCustomer = () => {
    return {
        type: "REQUEST_UPDATE_CUSTOMER"
    }
}

export const successUpdateCustomer = (data) => {
    return {
        type: "SUCCESS_UPDATE_CUSTOMER",
        payload: data,
    }
}

export const errorUpdateCustomer = () => {
    return {
        type: "ERROR_UPDATE_CUSTOMER"
    }
}



//Delete Customer Call

export const deleteCustomerCall = (id) => {
    return (dispatch) => {
        dispatch(requestDeleteCustomer())
        API.delete(`customer/del_customer/${id}`).then((res) => {
            dispatch(getCustomerCall())
            dispatch(successDeleteCustomer(id))
        }).catch((error) => {
            dispatch(errorDeleteCustomer())
        })
    }
}

export const requestDeleteCustomer = () => {
    return {
        type: "REQUEST_DELETE_CUSTOMER"
    }
}

export const successDeleteCustomer = (id) => {
    return {
        type: "SUCCESS_DELETE_CUSTOMER",
        userId: id,
    }
}

export const errorDeleteCustomer = () => {
    return {
        type: "ERROR_DELETE_CUSTOMER"
    }
}



//Add Brand Call

export const addBrandCall = (data) => {
    return (dispatch) => {
        dispatch(requestAddBrand())
        API.post('brand/add_brand', data).then((res) => {
            dispatch(successAddBrand(data))
        }).catch((error) => {
            dispatch(errorAddBrand())
        })
    }
}

export const requestAddBrand = () => {
    return {
        type: "REQUEST_ADD_BRAND"
    }
}

export const successAddBrand = (data) => {
    return {
        type: "SUCCESS_ADD_BRAND",
        payload: data,
    }
}

export const errorAddBrand = () => {
    return {
        type: "ERROR_ADD_BRAND"
    }
}



//Get Brand Call

export const getBrandCall = () => {
    return (dispatch) => {
        dispatch(requestGetBrand())
        API.get('brand/show_brand').then((res) => {
            dispatch(successGetBrand(res.data))
        }).catch((error) => {
            dispatch(errorGetBrand())
        })
    }
}

export const requestGetBrand = () => {
    return {
        type: "REQUEST_GET_BRAND"
    }
}

export const successGetBrand = (data) => {
    return {
        type: "SUCCESS_GET_BRAND",
        payload: data
    }
}

export const errorGetBrand = () => {
    return {
        type: "ERROR_GET_BRAND"
    }
}



//Add Product Call

export const addProductCall = (data) => {
    return (dispatch) => {
        dispatch(requestAddProduct())
        API.post('product/add_mein_product', data).then((res) => {
            dispatch(successAddProduct(data))
        }).catch((error) => {
            dispatch(errorAddProduct())
        })
    }
}

export const requestAddProduct = () => {
    return {
        type: "REQUEST_ADD_PRODUCT"
    }
}

export const successAddProduct = (data) => {
    return {
        type: "SUCCESS_ADD_PRODUCT",
        payload: data,
    }
}

export const errorAddProduct = () => {
    return {
        type: "ERROR_ADD_PRODUCT"
    }
}



//Get Product Call

export const getProductCall = () => {
    return (dispatch) => {
        dispatch(requestGetProduct())
        API.get('product/show_all_main_product').then((res) => {
            dispatch(successGetProduct(res.data))
        }).catch((error) => {
            dispatch(errorGetProduct())
        })
    }
}

export const requestGetProduct = () => {
    return {
        type: "REQUEST_GET_PRODUCT"
    }
}

export const successGetProduct = (data) => {
    return {
        type: "SUCCESS_GET_PRODUCT",
        payload: data
    }
}

export const errorGetProduct = () => {
    return {
        type: "ERROR_GET_PRODUCT"
    }
}



//Add Sub Product Call

export const addSubProductCall = (data) => {
    return (dispatch) => {
        dispatch(requestAddSubProduct())
        API.post('product/add_sub_product', data).then((res) => {
            dispatch(successAddSubProduct(data))
        }).catch((error) => {
            dispatch(errorAddSubProduct())
        })
    }
}

export const requestAddSubProduct = () => {
    return {
        type: "REQUEST_ADD_SUB_PRODUCT"
    }
}

export const successAddSubProduct = (data) => {
    return {
        type: "SUCCESS_ADD_SUB_PRODUCT",
        payload: data,
    }
}

export const errorAddSubProduct = () => {
    return {
        type: "ERROR_ADD_SUB_PRODUCT"
    }
}



//Get Sub Product Call

export const getSubProductCall = () => {
    return (dispatch) => {
        dispatch(requestGetSubProduct())
        API.get('product/show_all_sub_product').then((res) => {
            dispatch(successGetSubProduct(res.data))
        }).catch((error) => {
            dispatch(errorGetSubProduct())
        })
    }
}

export const requestGetSubProduct = () => {
    return {
        type: "REQUEST_GET_SUB_PRODUCT"
    }
}

export const successGetSubProduct = (data) => {
    return {
        type: "SUCCESS_GET_SUB_PRODUCT",
        payload: data
    }
}

export const errorGetSubProduct = () => {
    return {
        type: "ERROR_GET_SUB_PRODUCT"
    }
}



//Add Catalog Call

export const addCatalogCall = (data) => {
    console.log(data, "iipp")
    const formData = new FormData();
    formData.append("name", `${data.name}`);
    forEach(data.imagefront, (x, index) => {
        formData.append("imagefront", data.imagefront[index]);
        console.log(data.imagefront[index], "qqrr")
    })
    forEach(data.imageback, (x, index) => {
        formData.append("imageback", data.imageback[index]);
    })
    return (dispatch) => {
        dispatch(requestAddCatalog())
        API.post('catelog/catelog-add-data', formData).then((res) => {
            dispatch(successAddCatalog(data))
        }).catch((error) => {
            dispatch(errorAddCatalog())
        })
    }
}

export const requestAddCatalog = () => {
    return {
        type: "REQUEST_ADD_CATALOG"
    }
}

export const successAddCatalog = (data) => {
    return {
        type: "SUCCESS_ADD_CATALOG",
        payload: data,
    }
}

export const errorAddCatalog = () => {
    return {
        type: "ERROR_ADD_CATALOG"
    }
}



//Get Catalog Call

export const getCatalogCall = () => {
    return (dispatch) => {
        dispatch(requestGetCatalog())
        API.get('catelog/get-catelog-data').then((res) => {
            dispatch(successGetCatalog(res.data))
        }).catch((error) => {
            dispatch(errorGetCatalog())
        })
    }
}

export const requestGetCatalog = () => {
    return {
        type: "REQUEST_GET_CATALOG"
    }
}

export const successGetCatalog = (data) => {
    return {
        type: "SUCCESS_GET_CATALOG",
        payload: data
    }
}

export const errorGetCatalog = () => {
    return {
        type: "ERROR_GET_CATALOG"
    }
}



//Get Sub Product Detail Call

export const addSubProductDetailCall = (data) => {
    return (dispatch) => {
        dispatch(requestAddSubProductDetail())
        API.post('product/add_sub_product', data).then((res) => {
            dispatch(successAddSubProductDetail(data))
        }).catch((error) => {
            dispatch(errorAddSubProductDetail())
        })
    }
}

export const requestAddSubProductDetail = () => {
    return {
        type: "REQUEST_ADD_SUB_PRODUCT_DETAIL"
    }
}

export const successAddSubProductDetail = (data) => {
    return {
        type: "SUCCESS_ADD_SUB_PRODUCT_DETAIL",
        payload: data,
    }
}

export const errorAddSubProductDetail = () => {
    return {
        type: "ERROR_ADD_SUB_PRODUCT_DETAIL"
    }
}



//Add Process Call

export const addProcessCall = (data) => {
    return (dispatch) => {
        dispatch(requestAddProcess())
        API.post('categoryProcess/add_data', data).then((res) => {
            dispatch(successAddProcess(data))
        }).catch((error) => {
            dispatch(errorAddProcess())
        })
    }
}

export const requestAddProcess = () => {
    return {
        type: "REQUEST_ADD_PROCESS"
    }
}

export const successAddProcess = (data) => {
    return {
        type: "SUCCESS_ADD_PROCESS",
        payload: data,
    }
}

export const errorAddProcess = () => {
    return {
        type: "ERROR_ADD_PROCESS"
    }
}



//Get Process Call

export const getProcessCall = () => {
    return (dispatch) => {
        dispatch(requestGetProcess())
        API.get('categoryProcess/get_all_data').then((res) => {
            dispatch(successGetProcess(res.data))
        }).catch((error) => {
            dispatch(errorGetProcess())
        })
    }
}

export const requestGetProcess = () => {
    return {
        type: "REQUEST_GET_PROCESS"
    }
}

export const successGetProcess = (data) => {
    return {
        type: "SUCCESS_GET_PROCESS",
        payload: data
    }
}

export const errorGetProcess = () => {
    return {
        type: "ERROR_GET_PROCESS"
    }
}



//Add Vendors Call

export const addVendorsCall = (data) => {
    return (dispatch) => {
        dispatch(requestAddVendors())
        API.post('productionVendor/register_production_vendor', data).then((res) => {
            dispatch(getVendorsCall())
            dispatch(successAddVendors(data))
        }).catch((error) => {
            dispatch(errorAddVendors())
        })
    }
}

export const requestAddVendors = () => {
    return {
        type: "REQUEST_ADD_VENDORS"
    }
}

export const successAddVendors = (data) => {
    return {
        type: "SUCCESS_ADD_VENDORS",
        payload: data,
    }
}

export const errorAddVendors = () => {
    return {
        type: "ERROR_ADD_VENDORS"
    }
}



//Get Vendors Call

export const getVendorsCall = () => {
    return (dispatch) => {
        dispatch(requestGetVendors())
        API.get('productionVendor/get_production_vendor').then((res) => {
            dispatch(successGetVendors(res.data))
        }).catch((error) => {
            dispatch(errorGetVendors())
        })
    }
}

export const requestGetVendors = () => {
    return {
        type: "REQUEST_GET_VENDORS"
    }
}

export const successGetVendors = (data) => {
    return {
        type: "SUCCESS_GET_VENDORS",
        payload: data
    }
}

export const errorGetVendors = () => {
    return {
        type: "ERROR_GET_VENDORS"
    }
}



//Update Vendors Call

export const updateVendorsCall = (newData, oldData) => {
    return (dispatch) => {
        dispatch(requestUpdateVendors())
        API.put(`productionVendor/update_production_vendor/${oldData.productionVendorId}`,
            {
                firstName: newData.firstName,
                lastName: newData.lastName,
                // username: newData.username,
                businessName: newData.businessName,
                companyName: newData.companyName,
                phone: newData.phone,
                email: newData.email
            }).then((res) => {
                dispatch(getVendorsCall())
                dispatch(successUpdateVendors([newData]))
            }).catch((error) => {
                dispatch(errorUpdateVendors())
            })
    }
}

export const requestUpdateVendors = () => {
    return {
        type: "REQUEST_UPDATE_VENDORS"
    }
}

export const successUpdateVendors = (data) => {
    return {
        type: "SUCCESS_UPDATE_VENDORS",
    }
};

export const errorUpdateVendors = () => {
    return {
        type: "ERROR_UPDATE_VENDORS"
    }
}


//Delete Vendors Call

export const deleteVendorsCall = (id) => {
    return (dispatch) => {
        dispatch(requestDeleteVendors())
        API.delete(`productionVendor/delete_production_vendor/${id}`).then((res) => {
            dispatch(getVendorsCall())
            dispatch(successDeleteVendors(id))
        }).catch((error) => {
            dispatch(errorDeleteVendors())
        })
    }
}

export const requestDeleteVendors = () => {
    return {
        type: "REQUEST_DELETE_VENDORS"
    }
}

export const successDeleteVendors = (id) => {
    return {
        type: "SUCCESS_DELETE_VENDORS",
        userId: id,
    }
}

export const errorDeleteVendors = () => {
    return {
        type: "ERROR_DELETE_VENDORS"
    }
}








export const requestGetArticleIds = () => {
    return {
        type: "REQUEST_GET_ARTICLE_IDS"
    }
}

export const successGetArticleIds = (data) => {
    return {
        type: "SUCCESS_GET_ARTICLE_IDS",
        payload: data,
    }
}

export const errorGetArticleIds = () => {
    return {
        type: "ERROR_GET_ARTICLE_IDS"
    }
};


export const getArticleIds = () => {
    return (dispatch) => {
        // // dispatch(requestGetArticleIds())
        // API.get('/dummy').then((res) => {
        //     let getData = res.data;
        //     dispatch(successGetArticleIds(getData))
        // })
        // // }).catch((error) => {
        // //     dispatch(errorGetArticleIds())
        // // })

        dispatch(successGetArticleIds())
    }
};


