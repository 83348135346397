import API from "../../../../../global/api";
//Add Customer Call

export const addCustomerCall = (data) => {
  return (dispatch) => {
    dispatch(requestAddCustomer());
    API.post("customer/add_customer", data)
      .then((res) => {
        dispatch(successAddCustomer(data));
      })
      .catch((error) => {
        dispatch(errorAddCustomer());
      });
  };
};

export const requestAddCustomer = () => {
  return {
    type: "REQUEST_ADD_CUSTOMER",
  };
};

export const successAddCustomer = (data) => {
  return {
    type: "SUCCESS_ADD_CUSTOMER",
    payload: data,
  };
};

export const errorAddCustomer = () => {
  return {
    type: "ERROR_ADD_CUSTOMER",
  };
};

//Get Customer Call

export const getCustomerCall = () => {
  return (dispatch) => {
    dispatch(requestGetCustomer());
    API.get("customer/show_customer")
      .then((res) => {
        dispatch(successGetCustomer(res.data));
      })
      .catch((error) => {
        dispatch(errorGetCustomer());
      });
  };
};

export const requestGetCustomer = () => {
  return {
    type: "REQUEST_GET_CUSTOMER",
  };
};

export const successGetCustomer = (data) => {
  return {
    type: "SUCCESS_GET_CUSTOMER",
    payload: data,
  };
};

export const errorGetCustomer = () => {
  return {
    type: "ERROR_GET_CUSTOMER",
  };
};

//Update Customer Call

export const updateCustomerCall = (newData, oldData) => {
  return (dispatch) => {
    dispatch(requestUpdateCustomer());
    API.put(`customer/update_customer/${oldData.customer_id}`, {
      customerCompany: newData.customerCompany,
      customerPerson: newData.customerPerson,
      customerPhone: newData.customerPhone,
      customerEmail: newData.customerEmail,
      customerWeb: newData.customerWeb,
      customerContry: newData.customerContry,
      customerCity: newData.customerCity,
      divisionId: newData.divisionId,
    })
      .then((res) => {
        dispatch(getCustomerCall());
        dispatch(successUpdateCustomer([newData]));
      })
      .catch((error) => {
        dispatch(errorUpdateCustomer());
      });
  };
};

export const requestUpdateCustomer = () => {
  return {
    type: "REQUEST_UPDATE_CUSTOMER",
  };
};

export const successUpdateCustomer = (data) => {
  return {
    type: "SUCCESS_UPDATE_CUSTOMER",
    payload: data,
  };
};

export const errorUpdateCustomer = () => {
  return {
    type: "ERROR_UPDATE_CUSTOMER",
  };
};

//Delete Customer Call

export const deleteCustomerCall = (id) => {
  return (dispatch) => {
    dispatch(requestDeleteCustomer());
    API.delete(`customer/del_customer/${id}`)
      .then((res) => {
        dispatch(getCustomerCall());
        dispatch(successDeleteCustomer(id));
      })
      .catch((error) => {
        dispatch(errorDeleteCustomer());
      });
  };
};

export const requestDeleteCustomer = () => {
  return {
    type: "REQUEST_DELETE_CUSTOMER",
  };
};

export const successDeleteCustomer = (id) => {
  return {
    type: "SUCCESS_DELETE_CUSTOMER",
    userId: id,
  };
};

export const errorDeleteCustomer = () => {
  return {
    type: "ERROR_DELETE_CUSTOMER",
  };
};
