import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import Select from "react-select";
import "jspdf-autotable";
import Spinner from "react-bootstrap/Spinner";

import {
  getOrderCall,
  getArticleByOrder,
  getDataByOrderArticle,
} from "./ProductionDetail.action";
const ProductionDetail = (props) => {
  //get order
  console.log(props.orderInfo, "pppppppppppppppppppppppppppppppp");

  const getOrder = props.orderInfo.map((x) => {
    let data = { label: x.orderId, value: x.orderId };
    return data;
  });

  const getArticleByOrderIdList =
    !isEmpty(props.articleByOrderId) &&
    props.articleByOrderId[0].order_articles.map((x) => {
      let data = { value: x.articleId, label: x.article.itemName };
      return data;
    });
  const receivejob = [
    // { value: "Local", label: "Local" },
    { value: "select1", label: "select1" },
    { value: "select2", label: "select2" },
  ];
  const setreceivingjobInfo = () => {};

  const [orderNum, setOrderNum] = useState("");
  const [articleId, setAricleId] = useState();
  //change order
  const handleChangeOrder = (selectedOption) => {
    setOrderNum(selectedOption.value);

    props.getArticleByOrder(selectedOption.value);
  };
  //change Article
  const handleChangeArticle = (selectedOption) => {
    setAricleId(selectedOption.value);

    props.getDataByOrderArticle(selectedOption.value);
  };
  const sendData = () => {};

  useEffect(() => {
    props.getOrderCall();
  }, []);

  return (
    <>
      {props.isFetchingGetOrder || props.isFetchingGetArticleByOrder ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        <b> Select Order *</b>
                      </Form.Label>

                      <Select
                        placeholder="Select Order..."
                        value={{ label: orderNum }}
                        onChange={(e) => {
                          handleChangeOrder(e);
                        }}
                        options={getOrder}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>
                        <b> Select Article *</b>
                      </Form.Label>

                      <Select
                        placeholder="Select Article..."
                        value={{ label: articleId }}
                        onChange={(e) => {
                          handleChangeArticle(e);
                        }}
                        options={getArticleByOrderIdList}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="sendDiv">
                  {props.isAddingArticleMaterial ? (
                    <div className="loader-div">
                      <Spinner
                        variant="primary"
                        animation="grow"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                      />
                    </div>
                  ) : (
                    <Button
                      className="sendButton"
                      disabled={isEmpty(orderNum) || isEmpty(articleId)}
                      onClick={() => sendData()}
                      style={{
                        marginLeft: "2%",
                        color: "white",
                        width: "20%",
                        backgroundColor: "black",
                        border: "1px solid black",
                      }}
                    >
                      Save
                    </Button>
                  )}
                </div>
              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getArticleByOrder: (data) => dispatch(getArticleByOrder(data)),
    getOrderCall: () => dispatch(getOrderCall()),
    getDataByOrderArticle: (data) => dispatch(getDataByOrderArticle(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  orderInfo: state.OrderCreationReducer.orderInfo,
  isFetchingGetOrder: state.OrderCreationReducer.isFetchingGetOrder,
  articleByOrderId: state.productionDetailReducer.articleByOrderId,
  isFetchingGetArticleByOrder:
    state.productionDetailReducer.isFetchingGetArticleByOrder,
  materialByArticleId: state.OrderCreationReducer.materialByArticleId,
  isFetchingGetMaterialByOrder:
    state.OrderCreationReducer.isFetchingGetMaterialByOrder,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductionDetail);
