
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import { isEmpty, get } from "lodash";
import { getEmployeeData, getEmployeeDataWithStatus, getInfoSTLedger, getStLedger } from "./HRDetails.actions";
import Select from 'react-select';
import { Redirect, Link } from "react-router-dom";
import MaterialTable from "material-table";
import { appendNonSignificantZeros } from "../../../../../global/globalFunctions";
import { baseURL, headers } from "../../../../../global/api";


const HRSTLedger = (props) => {

  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [item, setItem] = useState();
  console.log(item, 'item')
 /* const [state, setState] = React.useState({
    columns: [
      { title: 'Account Name', field: 'acc_name' },
      { title: 'Account Type', field: 'acc_type' },
      { title: 'Opening Balance', field: 'opening_balance' },
      { title: 'Credit Value', field: 'credit_value' },
      { title: 'Debit Value', field: 'debit_value' },
      { title: 'Closing Balance', field: 'closing_balance' },
      { title: 'Date', field: 'short_term_ledger_date' }
    ],
  });*/

  // const getEmployeeList = !isEmpty(props.employeeList) ? props.employeeList.map((x) => { let data = { value: x.employeeId, label: `${x.employeeId} ${x.name}` }; return data }) : [];
  // !isEmpty(getEmployeeList) && getEmployeeList.push({ value: "All", label: "All" });

  const getEmployeeList = !isEmpty(props.activeEmployeeList) && props.activeEmployeeList.map((x) => { let data = { value: x.employeeId, label: `${appendNonSignificantZeros(x.employeeId)}-${x.name}` }; return data })
  !isEmpty(getEmployeeList) && getEmployeeList.push({ value: "All", label: "All" });

  const handleChangeEmployee = (selectedOption) => {
    setItem(selectedOption.value);
  };

  const STLedger = props.stLedger;
  console.log(STLedger, 'stledger')
  const exportPDF = () => {
    props.getStLedger({
      startedDate: !isEmpty(startDate) ? startDate : "",
      endDate: !isEmpty(endDate) ? endDate : "",
    });
    setShow(true);
   }
  useEffect(() => {
    // props.getEmployeeData();
    // props.getEmployeeDataWithStatus(1);

  }, []);

  function searchData() {
    props.getInfoSTLedger({
      startedDate: !isEmpty(startDate) ? startDate : "",
      endDate: !isEmpty(endDate) ? endDate : "",
      employee_id : item,
    });

  }

  return (
    <>
      {props.isFetchingStLedger ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isFetchingStLedger ? 'Loading...' : 'Loading...'}

          </Button>
        </div>
      ) : 
       (
         <Container fluid>
           <div className="main">
           <Row>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Start Date </b>

                  </Form.Label>
                  <Form.Control
                    type="date"
                    onChange={(e) => setStartDate(e.target.value)}
                  >
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> End Date</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    onChange={(e) => setEndDate(e.target.value)}
                  >
                  </Form.Control>
                </Form.Group>
              </Col>
           
            </Row>

            <Row>

               <Col lg="12" sm="12" className="text-center">
                {startDate == ""||endDate=="" ?
                  <Button disabled style={{ backgroundColor: "black" }} className="sendButton">Search</Button> :

                    <Button className="sendButton" onClick={()=>exportPDF()}>Search</Button>

                }
              </Col> 
              
              </Row>
              {show&&
            <MaterialTable
              title={`Short Term Advance Report ${startDate} - ${endDate}`}
              columns={[
                { title: 'Emloyee Id', field: 'employee_id' },
                { title: 'Emloyee Name', field: 'employee_name' },
                {title:'Department Name',field:'departmentName'},
                {title:'Designation Name',field:'designationName'},
                
               // { title: 'Account Type', field: 'acc_type' },
              //  { title: 'Deduction Amount', field: 'short_term_deduction_value' },
              //   { title: 'Description', field: 'short_term_description' },
              {title:'Opening Balance',field:'opening_balance'},
                { title: 'Advance Given', field: 'credit_value' },
                { title: 'Advance Deduction', field: 'debit_value' },
                { title: 'Closing Balance', field: 'closing_balance' },
                // { title: 'Date', field: 'short_term_ledger_date' }
              ]}
              data={props.stLedger}
              // data={
              //   // STLedger
              //   (query) =>
              //   new Promise((resolve, reject) => {
              //       let url = `${baseURL}short_term_adv/get_st_employee_ledger?`;
              //       url += "size=" + query.pageSize;
              //       url += "&page=" + (query.page + 1);
              //       url += "&search=" + query.search;
              //       fetch(url, {
              //           method: 'GET',
              //           headers: headers,
              //       })
              //           .then((response) => response.json())
              //           .then((result) => {
              //               resolve({
              //                   data: result.data,
              //                   page: result.page - 1,
              //                   totalCount: result.total_results,
              //               });
              //           });
              //   })

              // }
              options={{
                actionsColumnIndex: -1,
                filtering: false,
                exportButton: true,
                exportAllData: true,
                headerStyle: {
                  position: 'sticky', top: 0,
                  color: '#00BBBB',
                  fontWeight: '550',
                  onRowAdd: 'none',
                },

              }}
            // editable={{
            //   onRowUpdate: (newData, oldData) =>
            //     new Promise((resolve) => {
            //       setTimeout(() => {
            //         resolve();
            //         {
            //           props.updateFine(newData, oldData)
            //         }
            //       }, 600);
            //     }),
            //   onRowDelete: (oldData) =>
            //     new Promise((resolve) => {
            //       setTimeout(() => {
            //         resolve();
            //         props.deleteFine(oldData.fine_and_plenties_id);
            //         console.log(oldData, 'old')
            //       }, 600);
            //     }),
            // }}
            />}
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEmployeeData: () => dispatch(getEmployeeData()),
    // getInfoSTLedger: (data) => dispatch(getInfoSTLedger(data)),
    getStLedger: (data) => dispatch(getStLedger(data)),
    // searchAccountLedger: (data) => dispatch(searchAccountLedger(data)),
    // searchAccountLedgerReport: (data, startDate, endDate) => dispatch(searchAccountLedgerReport(data, startDate, endDate)),
    getEmployeeDataWithStatus: (status) => dispatch(getEmployeeDataWithStatus(status)),

  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingStLedger:state.HrReducer.isFetchingStLedger,
  stLedger:state.HrReducer.stLedger,
  employeeList: state.HrReducer.employeeList,
  isFetchingEmployeeData: state.HrReducer.isFetchingEmployeeData,
  stLedger: state.HrReducer.stLedger,
  isFetchingSTLedger: state.HrReducer.isFetchingSTLedger,
  level5LedgerData: state.AccountLegderReducer.level5LedgerData,
  searchAccountLedgerList: state.AccountLegderReducer.searchAccountLedgerList,
  isFetchingLevel5LedgerData: state.AccountLegderReducer.isFetchingLevel5LedgerData,
  isFetchingSearchAccountLedger: state.AccountLegderReducer.isFetchingSearchAccountLedger,

  activeEmployeeList: state.HrReducer.activeEmployeeList,
  isFetchingActiveEmployeeData: state.HrReducer.isFetchingActiveEmployeeData,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HRSTLedger);