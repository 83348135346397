const initialState = {
    isFetchingAddCatalog: false,
    isFetchingGetCatalog: false,
    catalogDetail: {
        catalog: "",
        Frontimage: [],
        Backimage: [],
    },
    catalogInfo: [],

}


const catalogReducer = (state = initialState, action) => {
    switch (action.type) {
           //Catalog
           case "REQUEST_ADD_CATALOG":
            return { ...state, isFetchingAddCatalog: true };

        case "SUCCESS_ADD_CATALOG":
            return {
                ...state, isFetchingAddCatalog: false,
                catalogDetail: {
                    catalog: action.payload.name,
                    Frontimage: action.payload.imagefront,
                    Backimage: action.payload.imageback,
                },
            };

        case "ERROR_ADD_CATALOG":
            return { ...state, isFetchingAddCatalog: false };

        //Get Catalog
        case "REQUEST_GET_CATALOG":
            return { ...state, isFetchingGetCatalog: true };

        case "SUCCESS_GET_CATALOG":
            return {
                ...state, isFetchingGetCatalog: false,
                catalogInfo: action.payload.data,
            };

        case "ERROR_GET_CATALOG":
            return { ...state, isFetchingGetCatalog: false };

        default:
            return state;
    }
}

export default catalogReducer;
