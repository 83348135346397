const initialState = {
    isFetchingAddBrand: false,
    isFetchingGetBrand: false,
    brandDetail: {
        brand: "",
        description: "",
    },
    brandInfo: [],

}


const brandReducer = (state = initialState, action) => {
    switch (action.type) {
             //Brand
        case "REQUEST_ADD_BRAND":
            return { ...state, isFetchingAddBrand: true };

        case "SUCCESS_ADD_BRAND":
            return {
                ...state, isFetchingAddBrand: false,
                brandDetail: {
                    brand: action.payload.name,
                    description: action.payload.description,
                },
            };

        case "ERROR_ADD_BRAND":
            return { ...state, isFetchingAddBrand: false };

        //Get Brand
        case "REQUEST_GET_BRAND":
            return { ...state, isFetchingGetBrand: true };

        case "SUCCESS_GET_BRAND":
            return {
                ...state, isFetchingGetBrand: false,
                brandInfo: action.payload.data,
            };

        case "ERROR_GET_BRAND":
            return { ...state, isFetchingGetBrand: false };


        default:
            return state;
    }
}

export default brandReducer;
