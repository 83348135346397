import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  Alert,
  Tabs,
  Tab,
} from "react-bootstrap";
import "./add-levels.css";
import {
  AddLevel4Name,
  getLevel4Data,
  getLevel1Data,
  getLevel2Data,
  getLevel3Data,
  getLevelOneName,
  getLevelTwoName,
} from "./accountsLevel.action";
import { connect } from "react-redux";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../../../../Images/logo.png";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import ReactExport from "react-export-excel";
import { Link } from "react-router-dom";
import { sortBy } from "lodash";

const Level4Tap = (props) => {
  const level4Data = sortBy(props.level4List, [
    "props.level4List",
    "levelFourData.allLevlKey",
  ]);
  /*const [state, setState] = React.useState({
        columns: [
            { title: 'Level 1 Name', field: 'levelFourData.level_one.name', render: rowData => rowData.levelFourData.level_one.name + " /" + rowData.levelFourData.level_one.key },
            { title: 'Level 2 Name', field: 'levelFourData.level_two.levelTwoName', render: rowData => rowData.levelFourData.level_two.levelTwoName + " /" + rowData.levelFourData.level_one.key + "-" + rowData.levelFourData.level_two.levelTwoKey },
            { title: 'Level 3 Name', field: 'levelFourData.level_three.levelThreeName', render: rowData => rowData.levelFourData.level_two.levelTwoName + " /" + rowData.levelFourData.level_one.key + "-" + rowData.levelFourData.level_two.levelTwoKey + "-" + rowData.levelFourData.level_three.levelThreeKey },
            { title: 'Level 4 Code', field: 'levelFourData.allLevlKey', editable: 'never' },
            { title: 'Level 4 Name', field: 'levelFourData.levelFourName' },
        ],
    });*/

  useEffect(() => {
    // props.getLevel1Data();
    // props.getLevel2Data();
    // props.getLevel3Data();
    props.getLevel4Data();
  }, []);
  return (
    <>
      {props.isFetchinglevel4List ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div className="sendDiv">
              <Link to="/user/level4">
                {" "}
                <Button className="sendButton">Back</Button>
              </Link>
            </div>
            <MaterialTable
              title="Level 3 Details"
              // columns={state.columns}
              columns={[
                {
                  title: "Level 1 Name",
                  field: "levelFourData.level_one.name",
                  render: (rowData) =>
                    rowData.levelFourData.level_one.name +
                    " /" +
                    rowData.levelFourData.level_one.key,
                },
                {
                  title: "Level 2 Name",
                  field: "levelFourData.level_three.levelThreeName",
                  render: (rowData) =>
                    rowData.levelFourData.level_three.levelThreeName +
                    " /" +
                    rowData.levelFourData.level_one.key +
                    "-" +
                    rowData.levelFourData.level_two.levelTwoKey,
                },
                //   { title: 'Level 2 Name', field: 'levelFourData.level_three.levelThreeName', render: rowData => rowData.levelFourData.level_two.levelTwoName + " /" + rowData.levelFourData.level_one.key + "-" + rowData.levelFourData.level_two.levelTwoKey + "-" + rowData.levelFourData.level_three.levelThreeKey },
                // { title: 'Level 3 Code', field: 'levelFourData.allLevlKey', editable: 'never' },
                { title: "Level 3 Name", field: "levelFourData.levelFourName" },
              ]}
              data={level4Data}
              options={{
                actionsColumnIndex: -1,
                exportButton: true,
                pageSize: 200,
                pageSizeOptions: [30, 50, 100],
                emptyRowsWhenPaging: false,
                exportPdf: (columns, data) => {
                  const doc = new jsPDF();

                  const columnTitles = [
                    {
                      title: "Level 1 Name",
                      field: "levelFourData.level_one.name",
                      render: (rowData) =>
                        rowData.levelFourData.level_one.name +
                        " /" +
                        rowData.levelFourData.level_one.key,
                    },
                    {
                      title: "Level 2 Name",
                      field: "levelFourData.level_two.levelTwoName",
                      render: (rowData) =>
                        rowData.levelFourData.level_two.levelTwoName +
                        " /" +
                        rowData.levelFourData.level_one.key +
                        "-" +
                        rowData.levelFourData.level_two.levelTwoKey,
                    },
                    {
                      title: "Level 3 Name",
                      field: "levelFourData.level_three.levelThreeName",
                      render: (rowData) =>
                        rowData.levelFourData.level_two.levelTwoName +
                        " /" +
                        rowData.levelFourData.level_one.key +
                        "-" +
                        rowData.levelFourData.level_two.levelTwoKey +
                        "-" +
                        rowData.levelFourData.level_three.levelThreeKey,
                    },
                    {
                      title: "Level 4 Code",
                      field: "levelFourData.allLevlKey",
                      editable: "never",
                    },
                    {
                      title: "Level 4 Name",
                      field: "levelFourData.levelFourName",
                    },
                  ].map((columnDef) => columnDef.title);

                  // const pdfData = data.map(rowData =>
                  //   state.columns.map(columnDef => (console.log(rowData.levelTwoData[columnDef.field], "eeeee"), columnDef.field == 'voucherDate' ? moment(rowData[columnDef.field]).format("YYYY-MM-DD") : rowData[columnDef.field])),
                  // );

                  const pdfData = data.map((elt) => [
                    elt.levelFourData.level_one.name,
                    elt.levelFourData.level_two.levelTwoName,
                    elt.levelFourData.level_three.levelThreeName,
                    elt.levelFourData.allLevlKey,
                    elt.levelFourData.levelFourName,
                  ]);
                  let content = {
                    startY: 50,
                    startX: 5,
                    head: [columnTitles],
                    body: pdfData,
                  };
                  // doc.addImage(logo, "PNG", 10, 11, 30, 30);
                  doc.text(
                    `Company Name: ${process.env.REACT_APP_COMPANY_NAME}`,
                    100,
                    20,
                    0,
                    20
                  );
                  doc.text("Level4 Report", 30, 45, 0, 20);
                  doc.autoTable(content);

                  doc.save(`data.pdf`);
                },
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "red",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLevel1Data: () => dispatch(getLevel1Data()),
    getLevel2Data: () => dispatch(getLevel2Data()),
    getLevel3Data: () => dispatch(getLevel3Data()),
    getLevel4Data: () => dispatch(getLevel4Data()),
    AddLevel4Name: (data) => dispatch(AddLevel4Name(data)),
    getLevelOneName: (data) => dispatch(getLevelOneName(data)),
    getLevelTwoName: (data) => dispatch(getLevelTwoName(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  levelTow: state.accountsLevelReducer.levelTow,
  levelThree: state.accountsLevelReducer.levelThree,
  level1List: state.accountsLevelReducer.level1List,
  level2List: state.accountsLevelReducer.level2List,
  level3List: state.accountsLevelReducer.level3List,
  level4List: state.accountsLevelReducer.level4List,
  isFetchinglevel4List: state.accountsLevelReducer.isFetchinglevel4List,
  isAddingLevel4: state.accountsLevelReducer.isAddingLevel4,
  isNotAddLevel4: state.accountsLevelReducer.isNotAddLevel4,
});

export default connect(mapStateToProps, mapDispatchToProps)(Level4Tap);
