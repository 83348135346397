import API from "../../../../../global/api";
import { forEach } from "lodash";

//Add Sub Product Call

export const addSubProductCall = (data) => {
  return (dispatch) => {
    dispatch(requestAddSubProduct());
    API.post("product/add_sub_product", data)
      .then((res) => {
        dispatch(successAddSubProduct(data));
      })
      .catch((error) => {
        dispatch(errorAddSubProduct());
      });
  };
};

export const requestAddSubProduct = () => {
  return {
    type: "REQUEST_ADD_SUB_PRODUCT",
  };
};

export const successAddSubProduct = (data) => {
  return {
    type: "SUCCESS_ADD_SUB_PRODUCT",
    payload: data,
  };
};

export const errorAddSubProduct = () => {
  return {
    type: "ERROR_ADD_SUB_PRODUCT",
  };
};

//Get Sub Product Call

export const getSubProductCall = () => {
  return (dispatch) => {
    dispatch(requestGetSubProduct());
    API.get("product/show_all_sub_product")
      .then((res) => {
        dispatch(successGetSubProduct(res.data));
      })
      .catch((error) => {
        dispatch(errorGetSubProduct());
      });
  };
};

export const requestGetSubProduct = () => {
  return {
    type: "REQUEST_GET_SUB_PRODUCT",
  };
};

export const successGetSubProduct = (data) => {
  return {
    type: "SUCCESS_GET_SUB_PRODUCT",
    payload: data,
  };
};

export const errorGetSubProduct = () => {
  return {
    type: "ERROR_GET_SUB_PRODUCT",
  };
};

// delete subProduct
export const requestDeleteSubProduct = () => {
  return {
    type: "REQUEST_DELETE_SUB_PRODUCT",
  };
};

export const successDeleteSubProduct = (subProId) => {
  return {
    type: "SUCCESS_DELETE_SUB_PRODUCT",
    subProId: subProId,
  };
};

export const errorDeleteSubProduct = () => {
  return {
    type: "ERROR_DELETE_SUB_PRODUCT",
  };
};
export const deleteSubProduct = (subProId) => {
  return (dispatch) => {
    dispatch(requestDeleteSubProduct());
    API.delete(`/subProduct/${subProId}`)
      .then((res) => {
        dispatch(getSubProductCall());
        dispatch(successDeleteSubProduct(subProId));
      })
      .catch((error) => {
        dispatch(errorDeleteSubProduct());
      });
  };
};

// update

export const requestUpdateSubProduct = () => {
  return {
    type: "REQUEST_UPDATE_SUB_PRODUCT",
  };
};

export const successUpdateSubProduct = (data) => {
  return {
    type: "SUCCESS_UPDATE_SUB_PRODUCT",
    payload: data,
  };
};

export const errorUpdateSubProduct = () => {
  return {
    type: "ERROR_UPDATE_SUB_PRODUCT",
  };
};

export const updateSubProduct = (newData, oldData) => {
  return (dispatch) => {
    dispatch(requestUpdateSubProduct());
    API.put(`/update/${oldData.subProId}`, {
      subProductName: newData.subProductName,
      subProductDescription: newData.subProductDescription,
    })
      .then((res) => {
        dispatch(getSubProductCall());
        dispatch(successUpdateSubProduct([newData]));
      })
      .catch((error) => {
        dispatch(errorUpdateSubProduct());
      });
  };
};
