import { isEmpty, isNull, uniqBy } from "lodash";

const initialState = {

     isFetchingSettingData: false,
    // casualLeaves: 0,
    // maternityLeaves: 0,
    // sickLeaves: 0,
    // otherLeaves: 0,
    // annualLeaves: 0,
    // paternityLeaves: 0,
    // shortLeaves: 0,
    // specialLeaves: 0,
    setting:"",
   
  
};


const AccountConfiguration = (state = initialState, action) => {
    // console.log(action, "Awww")
    switch (action.type) {



    
        case "REQUEST_GET_SETTING":
            return { ...state, isFetchingSettingData: true };
        case "SUCCESS_GET_SETTING":
            return {
                ...state,
                setting: action.payload.result[0].invoiceVoucher,
              
                isFetchingSettingData: false,
            };
        case "ERROR_GET_SETTING":
            return { ...state, isFetchingSettingData: false };


        case "SET_SETTING":
            // console.log("maternityLeaves", state.maternityLeaves)
            return { ...state, setting: action.payload };

              default:
            return state;
    }
};
export default AccountConfiguration;
