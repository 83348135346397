import API from '../../../../../global/api';
import { forEach } from "lodash";

//Add Color Call

export const addColor = (data) => {
    return (dispatch) => {
        dispatch(requestAddColor())
        API.post('size-color/color-add-data', data).then((res) => {
            dispatch(successAddColor(data))
            dispatch(getColorCall())
        }).catch((error) => {
            dispatch(errorAddColor())
        })
    }
}

export const requestAddColor = () => {
    return {
        type: "REQUEST_ADD_COLOR"
    }
}

export const successAddColor = (data) => {
    return {
        type: "SUCCESS_ADD_COLOR",
        payload: data,
    }
}

export const errorAddColor = () => {
    return {
        type: "ERROR_ADD_COLOR"
    }
}



//Get Color Call

export const getColorCall = () => {
    return (dispatch) => {
        dispatch(requestGetColor())
        API.get('size-color/get-color-data').then((res) => {
            dispatch(successGetColor(res.data))
        }).catch((error) => {
            dispatch(errorGetColor())
        })
    }
}

export const requestGetColor = () => {
    return {
        type: "REQUEST_GET_COLOR"
    }
}

export const successGetColor = (data) => {
    return {
        type: "SUCCESS_GET_COLOR",
        payload: data,
    }
}

export const errorGetColor = () => {
    return {
        type: "ERROR_GET_COLOR"
    }
}
// delete color
export const requestDeleteColor = () => {
    return {
      type: "REQUEST_DELETE_COLOR",
    };
  };
  
  export const successDeleteColor = (colorId) => {
    return {
      type: "SUCCESS_DELETE_COLOR",
      colorId: colorId,
    };
  };
  
  export const errorDeleteColor = () => {
    return {
      type: "ERROR_DELETE_COLOR",
    };
  };
  export const deleteColor = (colorId) => {
    return (dispatch) => {
      dispatch(requestDeleteColor());
      API.delete(`/color/${colorId}`)
        .then((res) => {
          dispatch(getColorCall());
          dispatch(successDeleteColor(colorId));
        })
        .catch((error) => {
          dispatch(errorDeleteColor());
        });
    };
  };
  
  // update
  
  export const requestUpdateColor = () => {
    return {
      type: "REQUEST_UPDATE_COLOR",
    };
  };
  
  export const successUpdateColor = (data) => {
    return {
      type: "SUCCESS_UPDATE_COLOR",
      payload: data,
    };
  };
  
  export const errorUpdateColor = () => {
    return {
      type: "ERROR_UPDATE_COLOR",
    };
  };
  
  export const updateColor = (newData, oldData) => {
    return (dispatch) => {
      dispatch(requestUpdateColor());
      API.put(`/update/${oldData.colorId}`, {
        colorName: newData.colorName,
        colorDescription: newData.colorDescription,
      })
        .then((res) => {
          dispatch(getColorCall());
          dispatch(successUpdateColor([newData]));
        })
        .catch((error) => {
          dispatch(errorUpdateColor());
        });
    };
  };
  