import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import AddCategory from "./AddCategory";
import CategoryTableList from "./CategoryTableList";
import AddWarehouse from "./AddWarehouse";
import WareHouseTableList from "./WareHouseTableList";
import {isNull } from "lodash";
import AddMasterControlInventory from "./AddMasterControlInventory";
import ViewMasterControlInventory from "./ViewMasterControlInventory";
const TotalInventoryMasterControl = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 111) ?
                    "AddMasterControl" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 112) ?
                        "ViewMasterControl" :""} id="AddMasterControl" className="mb-3">
                <Tab eventKey="AddMasterControl" title="Add Inventory Master Control"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 111) ? false : true}>  
                    <AddMasterControlInventory />
                </Tab>
                <Tab eventKey="InventoryUpload" title="View Inventory Master Control"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 112) ? false : true}> 
                    <ViewMasterControlInventory/>
                </Tab>
                {/* <Tab eventKey="InventoryStockList" title="Inventory Stock List"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 113) ? false : true}> 
                    <StockList/>
                </Tab>
                <Tab eventKey="InventoryReport" title="Inventory Report"
                disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 114) ? false : true}> 
                    <InventoryReport/>
                </Tab> */}
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalInventoryMasterControl);