import React, { useState } from "react";
import { Container, Form, Row, Col, Button, FormGroup } from "react-bootstrap";
import {
  addVisitorMarquee,
  deleteVisitor,
  getCustomerMarquee,
  getEvent,
  getSubEvent,
  getSubEventByEventId,
  getVisitorMarquee,
  UpdateVisitor,
} from "./MarqueeDetails.actions";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useEffect } from "react";
import Swal from "sweetalert";
import { isEmpty, find } from "lodash";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import jsPDF from "jspdf";
import logo from "../../../../../Images/logo.png";
const AddVisitorMarquee = (props) => {
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerCNIC, setCustomerCNIC] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [visitorPurpose, setVisitorPurpose] = useState("");
  const [visitorDesc, setVisitorDesc] = useState("");
  const [eventId, setEventId] = useState("");
  const [eventName, setEventName] = useState("");
  const [subEventId, setSubEventId] = useState("");
  const [subEventName, setSubEventName] = useState("");
  const [bookingDate, setBookingDate] = useState("");
  const [bookingPerson, SetBookingPerson] = useState(1);
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [submit, setSubmit] = useState(false);
  function uploadCustomer() {
    props.addVisitorMarquee(
      {
        customer_name: customerName,
        customer_phone: customerPhone,
        customer_address: customerAddress,
        customer_email: customerEmail,
        customer_cnic: customerCNIC,
        visitors_purpose: visitorPurpose,
        accepted_date: bookingDate,
        accepted_guest: bookingPerson,
        event: eventName,
        sub_event: subEventName,
        desc: visitorDesc
      },
      handleVisible,
      handleDangerVisible
    );
  }
  const handleVisible = () => {
    setAlertVisible(true);
    clearStates();
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };
  const clearStates = () => {
    setCustomerAddress("");
    setCustomerCNIC("");
    setCustomerEmail("");
    setCustomerName("");
    setCustomerPhone("");
    setVisitorPurpose("");
    setVisitorDesc("");
    setEventId("");
    setEventName("");
    setSubEventId("");
    setSubEventName("");
    setBookingDate("");
    SetBookingPerson(1);
    setSubmit(false);
  };
  const handleChangeEvent = (selectedOption) => {
    console.log(selectedOption, "sop");
    setEventId(selectedOption.value);
    setEventName(selectedOption.label);
    props.getSubEventByEventId(selectedOption.value)
  };
  const getEventList =
    !isEmpty(props.eventList) &&
    props.eventList.map((x) => {
      let data = { value: x.event_id, label: x.event_name };
      return data;
    });
  const getSubEventsList =
    !isEmpty(props.subEventsListByEventID) &&
    props.subEventsListByEventID.map((x) => {
      let data = { value: x.sub_event_id, label: x.sub_event_name };
      return data;
    });;
  const handleChangeSubEvent = (selectedOption) => {
    console.log(selectedOption, "sop");
    setSubEventId(selectedOption.value);
    setSubEventName(selectedOption.label);
  };
  const VisitorData = isEmpty(props.visitorMarqueeList)
    ? []
    : props.visitorMarqueeList.reverse();
  useEffect(() => {
    // props.getVisitorMarquee(1);
    props.getEvent(2)
    // props.getMasterControl(1)
  }, []);
  return (
    <>
      {props.isFetchingEventData || props.isFetchingSubEventDataByEventID ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />

          </Button>
        </div>
      ) : (
        <Container fluid>
          <Form>
            <Row>
              <Col lg="6" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Expected Function Date *</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={bookingDate}
                    // disabled
                    placeholder="Expected Function Date:...."
                    onChange={(e) => {
                      setBookingDate(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col lg="6" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Expected Guests:</b>
                  </Form.Label>
                  <Form.Control
                    value={bookingPerson}
                    type="number"
                    placeholder="Expected Guests:...."
                    onChange={(e) => {
                      SetBookingPerson(e.target.value)
                    }}
                    // max={hallCapacity}
                    min={1}
                  />
                </Form.Group>
              </Col>

            </Row>
            <Row>
              <Col lg="6" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select Event *</b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(props.eventList)}
                    value={{ label: eventName }}
                    placeholder="Select Event..."
                    onChange={handleChangeEvent}
                    options={getEventList}
                  />
                </Form.Group>
              </Col>
              <Col lg="6" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select SubEvent *</b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(getSubEventsList) || eventName == ""}
                    value={{ label: subEventName }}
                    placeholder="Select SubEvent..."
                    onChange={handleChangeSubEvent}
                    options={getSubEventsList}
                  />
                </Form.Group>
              </Col>

            </Row>
            <Row>
              <Col lg="6" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Enter Visitor Name *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Visitor Name...."
                    value={customerName}
                    onChange={(e) => setCustomerName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg="6" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Enter Visitor Phone *</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Visitor Phone...."
                    value={customerPhone}
                    onChange={(e) => setCustomerPhone(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Enter Visitor Purpose *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Visitor Purpose...."
                    value={visitorPurpose}
                    onChange={(e) => setVisitorPurpose(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg="6" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Enter Visitor CNIC </b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    minLength={13}
                    maxLength={13}
                    placeholder="Visitor CNIC without dashes...."
                    value={customerCNIC}
                    onChange={(e) => setCustomerCNIC(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Enter Visitor Address :</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Visitor Address...."
                    value={customerAddress}
                    onChange={(e) => setCustomerAddress(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg="6" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Enter Visitor Email :</b>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Visitor Email...."
                    value={customerEmail}
                    onChange={(e) => setCustomerEmail(e.target.value)}
                  />
                </Form.Group>
              </Col>

            </Row>
            <Row>
              <Col lg="6" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Description </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Description...."
                    value={visitorDesc}
                    onChange={(e) => setVisitorDesc(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="sendDiv">
              {customerName == "" ||
                customerPhone == "" ||
                visitorPurpose == "" ||
                bookingDate == "" ||
                bookingPerson == "" ||
                eventId == "" ||
                subEventId == "" ? (
                <Button
                  className="sendButton"
                  disabled
                  style={{ backgroundColor: "black" }}
                  onClick={() => {
                    uploadCustomer();
                    // setSubmit(true);
                  }}
                >
                  Save
                </Button>
              ) : (
                <Button
                  className="sendButton"
                  onClick={() => {
                    uploadCustomer();
                    // setSubmit(true);
                  }}
                >
                  Save
                </Button>
              )}
            </div>
          </Form>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addVisitorMarquee: (data, handleVisible, handleDangerVisible) =>
      dispatch(addVisitorMarquee(data, handleVisible, handleDangerVisible)),
    getVisitorMarquee: (id) => dispatch(getVisitorMarquee(id)),
    deleteVisitor: (menu_id) => dispatch(deleteVisitor(menu_id)),
    UpdateVisitor: (newData, oldData) =>
      dispatch(UpdateVisitor(newData, oldData)),
    getEvent: (id) => dispatch(getEvent(id)),
    getSubEvent: (id) => dispatch(getSubEvent(id)),
    getSubEventByEventId: (id) => dispatch(getSubEventByEventId(id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  addCustomer: state.MarqueeReducer.addCustomer,
  isAddingCustomerData: state.MarqueeReducer.isAddingCustomerData,
  isFetchingCustomerDataMarquee:
    state.MarqueeReducer.isFetchingCustomerDataMarquee,
  customerMarqueeList: state.MarqueeReducer.customerMarqueeList,
  visitorMarqueeList: state.MarqueeReducer.visitorMarqueeList,
  eventList: state.MarqueeReducer.eventList,
  isFetchingEventData: state.MarqueeReducer.isFetchingEventData,
  subEventsList: state.MarqueeReducer.subEventsList,
  subEventsListByEventID: state.MarqueeReducer.subEventsListByEventID,
  isFetchingSubEventDataByEventID: state.MarqueeReducer.isFetchingSubEventDataByEventID,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddVisitorMarquee);
