import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import {
  isEmpty,
  isUndefined,
  get,
  flatMap,
  map,
  groupBy,
  difference,
} from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import {
  getOrderCall,
  getOrderArticleCall,
  getArticleByOrder,
  getDataByOrderArticle,
} from "./OrderCreation.actions";

const AddMaterialRequired = (props) => {
  const getOrder = props.orderInfo.map((x) => {
    let data = { label: x.orderId, value: x.orderId };
    return data;
  });

  const [orderNum, setOrderNum] = useState("");
  const [articleId, setAricleId] = useState();

  const setOrder = (selectedOption) => {
    setOrderNum(selectedOption.value);
    props.getArticleByOrder(selectedOption.value);
  };

  const handleChangeArticleId = (selectedOption) => {
    setAricleId(selectedOption.value);
    props.getDataByOrderArticle(selectedOption.value);
  };
  const dataArticle = "01";

  const getArticleByOrderIdList =
    !isEmpty(props.articleByOrderId) &&
    props.articleByOrderId[0].order_articles.map((x) => {
      let data = { value: x.articleId, label: x.article.itemName };
      return data;
    });
  console.log(getArticleByOrderIdList, "uuuuuuuuuuuuuuuuuuuuuu");

  const getMaterialData = !isEmpty(props.materialByArticleId)
    ? props.materialByArticleId[0].material_costs
    : [];

  /* const [stateArticle, setStateArticle] = useState({
        columns: [
            { title: 'Material Name', field: 'inventory.productName'},
            { title: 'UOM', field: 'inventory.uom' },
            { title: 'Material Quantity', field: 'inventory.currentBlnc' },
            {title: "Product Price", field: 'inventory.productPrice'}
        ],
    });*/

  useEffect(() => {
    props.getOrderCall();
  }, []);

  return (
    <>
      {props.isFetchingGetArticle || props.isFetchingGetArticle ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <Row>
                  <Col lg="12" md="12" xs="12" styles={{ zIndex: -1 }}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Order *</b>
                      </Form.Label>
                      <Select
                        value={{ label: orderNum }}
                        placeholder="Select Order #..."
                        onChange={(e) => {
                          setOrder(e);
                        }}
                        options={getOrder}
                        // onBlur={()=>sendData()}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg="12" md="12" xs="12" styles={{ zIndex: -1 }}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Article *</b>
                      </Form.Label>
                      <Select
                        value={{ label: articleId }}
                        placeholder="Select Article #..."
                        onChange={(e) => {
                          handleChangeArticleId(e);
                        }}
                        options={getArticleByOrderIdList}
                        // onBlur={()=>sendData()}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <MaterialTable
                  title={`Order # ${
                    !isUndefined(props.orderArticleInfo) &&
                    props.orderArticleInfo.orderId
                  } Details`}
                  columns={[
                    { title: "Material Name", field: "inventory.productName" },
                    { title: "UOM", field: "inventory.uom" },
                    {
                      title: "Material Quantity",
                      field: "inventory.currentBlnc",
                    },
                    { title: "Product Price", field: "inventory.productPrice" },
                  ]}
                  //data={materiaFinal}
                  data={getMaterialData}
                />
              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    // getOrderArticleCall: (data) => dispatch(getOrderArticleCall(data)),
    getArticleByOrder: (data) => dispatch(getArticleByOrder(data)),
    getOrderCall: () => dispatch(getOrderCall()),
    getDataByOrderArticle: (data) => dispatch(getDataByOrderArticle(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  orderInfo: state.OrderCreationReducer.orderInfo,
  orderArticleInfo: state.OrderCreationReducer.orderArticleInfo,
  articleByOrderId: state.OrderCreationReducer.articleByOrderId,
  materialByArticleId: state.OrderCreationReducer.materialByArticleId,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddMaterialRequired);

// import React, { useEffect, useState } from "react";
// import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
// import { connect } from "react-redux";
// import { isEmpty, isUndefined, get } from "lodash";
// import Spinner from 'react-bootstrap/Spinner';
// import MaterialTable from 'material-table';
// import 'jspdf-autotable';
// import Select from 'react-select';
// import AddIcon from '@material-ui/icons/Add';
// import RemoveIcon from '@material-ui/icons/Remove';
// import { IconButton } from "@material-ui/core";
// import { getOrderCall, getOrderArticleCall } from "./OrderCreation.actions";

// const ArticleMaterialReq = (props) => {

//     const getOrder = props.orderInfo.map((x) => { let data = { label: x.orderId, value: x.orderId }; return data });

//     const [orderNum, setOrderNum] = useState("");

//     const setOrder = (selectedOption) => {
//         setOrderNum(selectedOption.value);
//     };

//     const [state, setState] = useState({
//         columns: [
//             { title: 'Article ID', field: 'orderId' },
//         ],
//     });

//     const dataArticle='01';

//     const dataMaterial = !isUndefined(props.orderArticleInfo) && (props.orderArticleInfo[0]);
//     // console.log(dataMaterial.order_articles, "kktt")

//     const [stateArticle, setStateArticle] = useState({
//         columns: [
//             { title: 'Article ID', field: 'articleId' },
//             { title: 'Material Name', field: 'materialName' },
//             { title: 'Material Quantity', field: 'materialQuantity' },
//             { title: 'Article Quantity', field: 'articleQuantity' },
//             { title: 'Total Material', field:"totalMaterial", render: rowData => (rowData.materialQuantity * rowData.articleQuantity) },
//         ],
//     });

//     console.log(!isUndefined(props.orderArticleInfo) && props.orderArticleInfo[0], "ffyy")

//     const sendData = () => {
//         props.getOrderArticleCall(orderNum)
//     }

//     useEffect(() => {
//         props.getOrderCall();
//     }, []);

//     return (
//         <Container fluid>
//             <div className="main">

//                 <div>
//                     <Form>
//                         <Row>
//                             <Col lg="12" md="12" xs="12" styles={{ zIndex: -1 }}>
//                                 <Form.Group className="mb-3">
//                                     <Form.Label><b>Order #:</b></Form.Label>
//                                     <Select
//                                         placeholder="Select Order #..."
//                                         onChange={setOrder}
//                                         options={getOrder}
//                                         onBlur={()=>sendData()}
//                                     />
//                                 </Form.Group>
//                             </Col>
//                         </Row>
// {/*
//                         <div className="sendDiv">
//                             <Button className="sendButton" onClick={() => sendData()}>Save</Button>
//                         </div> */}

//                         <MaterialTable
//                             title={`Order # ${!isUndefined(props.orderArticleInfo) && props.orderArticleInfo.orderId} Details`}
//                             columns={state.columns}
//                             data={dataMaterial}
//                             detailPanel={rowData => {
//                                 // console.log(rowData, "qqgg")
//                                 return (
//                                     <MaterialTable
//                                         columns={stateArticle.columns}
//                                         // data={rowData.order_articles}
//                                         // data={dataMaterial}
//                                         title="Article Preview"
//                                     />
//                                 )
//                             }}
//                         />
//                     </Form>

//                 </div>

//             </div>

//         </Container>

//     )
// }

// const mapDispatchToProps = (dispatch) => {
//     return {
//         getOrderArticleCall: (data) => dispatch(getOrderArticleCall(data)),
//         getOrderCall: () => dispatch(getOrderCall()),
//     };
// };
// const mapStateToProps = (state, ownProps) => ({
//     orderInfo: state.OrderCreationReducer.orderInfo,
//     orderArticleInfo: state.OrderCreationReducer.orderArticleInfo,
// });

// export default connect(mapStateToProps, mapDispatchToProps)(ArticleMaterialReq);
