import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import { addColor } from "./color.action";

const AddColor = (props) => {
  const [color, setColor] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {}, []);

  const sendData = () => {
    props.addColor({
      colorName: color,
      colorDescription: description,
    });
    setColor("");
    setDescription("");
  };

  return (
    <Container fluid>
      <div className="main">
        <Form>
          <Row>
            <Col lg="12" md="12" xs="12">
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>Enter Color *</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="warehouseName"
                  placeholder="Color...."
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg="12" md="12" xs="12">
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>Description</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="colorDescription"
                  placeholder="Description...."
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <div className="sendDiv">
            {props.isFetchingAddColor ? (
              <div className="loader-div">
                <Spinner
                  variant="primary"
                  animation="grow"
                  size="xl"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            ) : (
              <Button
                className="sendButton"
                disabled={isEmpty(color)}
                style={{
                  marginLeft: "2%",
                  color: "white",
                  width: "20%",
                  backgroundColor: "black",
                  border: "1px solid black",
                }}
                onClick={() => sendData()}
              >
                Save
              </Button>
            )}
          </div>
        </Form>
      </div>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addColor: (data) => dispatch(addColor(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingAddColor: state.colorReducer.isFetchingAddColor,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddColor);
