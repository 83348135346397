import API from "../../../../../global/api";
import { forEach } from "lodash";

//Add UOM Call

export const addUOMCall = (data) => {
  return (dispatch) => {
    dispatch(requestAddUOM());
    API.post("uom/uom-add-data", data)
      .then((res) => {
        dispatch(successAddUOM(data));
      })
      .catch((error) => {
        dispatch(errorAddUOM());
      });
  };
};

export const requestAddUOM = () => {
  return {
    type: "REQUEST_ADD_UOM",
  };
};

export const successAddUOM = (data) => {
  return {
    type: "SUCCESS_ADD_UOM",
    payload: data,
  };
};

export const errorAddUOM = () => {
  return {
    type: "ERROR_ADD_UOM",
  };
};
//Get UOM Call

export const getUOMCall = (data) => {
  return (dispatch) => {
    dispatch(requestGetUOM());
    API.get("uom/get-uom-data", data)
      .then((res) => {
        dispatch(successGetUOM(res.data));
      })
      .catch((error) => {
        dispatch(errorGetUOM());
      });
  };
};

export const requestGetUOM = () => {
  return {
    type: "REQUEST_GET_UOM",
  };
};

export const successGetUOM = (data) => {
  return {
    type: "SUCCESS_GET_UOM",
    payload: data,
  };
};

export const errorGetUOM = () => {
  return {
    type: "ERROR_GET_UOM",
  };
};

export const requestDeleteUom = () => {
  return {
    type: "REQUEST_DELETE_UOM",
  };
};

export const successDeleteUom = (uomId) => {
  return {
    type: "SUCCESS_DELETE_UOM",
    uomId: uomId,
  };
};

export const errorDeleteUom = () => {
  return {
    type: "ERROR_DELETE_UOM",
  };
};
export const deleteUom = (uomId) => {
  return (dispatch) => {
    dispatch(requestDeleteUom());
    API.delete(`/Uom/${uomId}`)
      .then((res) => {
        dispatch(getUOMCall());
        dispatch(successDeleteUom(uomId));
      })
      .catch((error) => {
        dispatch(errorDeleteUom());
      });
  };
};

// update

export const requestUpdateUom = () => {
  return {
    type: "REQUEST_UPDATE_UOM",
  };
};

export const successUpdateUom = (data) => {
  return {
    type: "SUCCESS_UPDATE_UOM",
    payload: data,
  };
};

export const errorUpdateUom = () => {
  return {
    type: "ERROR_UPDATE_UOM",
  };
};

export const updateUom = (newData, oldData) => {
  return (dispatch) => {
    dispatch(requestUpdateUom());
    API.put(`/update/${oldData.uomId}`, {
      uomName: newData.uomName,
      uomDescription: newData.uomDescription,
    })
      .then((res) => {
        dispatch(getUOMCall());
        dispatch(successUpdateUom([newData]));
      })
      .catch((error) => {
        dispatch(errorUpdateUom());
      });
  };
};
