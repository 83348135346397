import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import ImageUploader from "react-images-upload";
import {
  deleteDivision,
  getDivisionCall,
  updateDivision,
} from "./division.action";

const ViewDivision = (props) => {
  console.log(props.divisionInfo, "aaaaaaaa1");

  useEffect(() => {
    props.getDivisionCall();
  }, []);

  return (
    <>
      {props.isFetchingGetDivision ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialTable
              title="Division Details"
              columns={[
                { title: "Division Id", field: "division_id" },
                { title: "Division Name", field: "division_name" },
                { title: "Division Description", field: "division_desc" },
              ]}
              data={props.divisionInfo}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                exportButton: true,
                exportAllData: true,
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  division: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.updateDivision(newData, oldData);
                    }, 600);
                  }),

                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteDivision(oldData.divisionId);
                    }, 600);
                  }),
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDivisionCall: () => dispatch(getDivisionCall()),
    deleteDivision: (divisionId) => dispatch(deleteDivision(divisionId)),
    updateDivision: (newData, oldData) =>
      dispatch(updateDivision(newData, oldData)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  divisionInfo: state.divisionReducer.divisionInfo,
  isFetchingGetDivision: state.divisionReducer.isFetchingGetDivision,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewDivision);
