import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import { addBrandCall } from "./brand.action";

const AddBrand = (props) => {
  const [brand, setBrand] = useState("");
  const [description, setDescription] = useState("");

  function sendData() {
    props.addBrandCall({
      brandName: brand,
      brandDescription: description,
    });
    setBrand("");
    setDescription("");
  }

  useEffect(() => {}, []);

  return (
    <Container fluid>
      <div className="main">
        <Form>
          <Row>
            <Col lg="12" md="12" xs="12">
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>Enter Brand *</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="brandDetail"
                  placeholder="Brand...."
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg="12" md="12" xs="12">
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>Description</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="brandDescription"
                  placeholder="Description...."
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <div className="sendDiv">
            {props.isFetchingAddBrand ? (
              <div className="loader-div">
                <Spinner
                  variant="primary"
                  animation="grow"
                  size="xl"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            ) : (
              <Button
                className="sendButton"
                onClick={() => sendData()}
                disabled={isEmpty(brand)}
                style={{
                  marginLeft: "2%",
                  color: "white",
                  width: "20%",
                  backgroundColor: "black",
                  border: "1px solid black",
                }}
              >
                Save
              </Button>
            )}
          </div>
        </Form>
      </div>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addBrandCall: (data) => dispatch(addBrandCall(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingAddBrand: state.brandReducer.isFetchingAddBrand,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddBrand);
