import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import Select from "react-select";
import { rotateAndSkewTextDegreesAndTranslate } from "pdf-lib";
import { findAllByAltText } from "@testing-library/dom";
import { addVendor } from "./vendor.action";
import Swal from "sweetalert";
const AddVendor = (props) => {
  const [vendorName, setVendorName] = useState("");
  const [vendorPhone, setVendorPhone] = useState();
  const [vendorAddress, setVendorAddress] = useState("");
  const [vendorCNIC, setVendorCNIC] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Vendor Added Successfully",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };

  const sendData = () => {
    props.addVendor({
      vendor_name: vendorName,
      vendor_phone:vendorPhone,
      vendor_address:vendorAddress,
      vendor_cnic:vendorCNIC
    },
    handleVisible,
    handleDangerVisible
  );
    setVendorAddress("")
    setVendorCNIC("")
    setVendorName("")
    setVendorPhone("")
  };

  // useEffect(() => {
  //   props.getDivisionCall();
  // }, []);

  return (
    <>
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <Row>
                  <Col lg="6" md="6" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Vendor Name *</b>
                      </Form.Label>
                      <Form.Control
                        value={vendorName}
                        type="text"
                        id="personName"
                        placeholder="Vendor Name...."
                        onChange={(e) => setVendorName(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Phone No. *</b>
                      </Form.Label>
                      <Form.Control
                        value={vendorPhone}
                        type="number"
                        id="phone"
                        placeholder="Vendor Phone...."
                        onChange={(e) => setVendorPhone(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                <Col lg="6" md="6" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Vendor CNIC *</b>
                      </Form.Label>
                      <Form.Control
                        value={vendorCNIC}
                        type="number"
                        id="country"
                        placeholder="Vendor CNIC...."
                        onChange={(e) => setVendorCNIC(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Address </b>
                      </Form.Label>
                      <Form.Control
                        value={vendorAddress}
                        type="text"
                        id="city"
                        placeholder="Vendor Address...."
                        onChange={(e) => setVendorAddress(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  
                </Row>

                <div className="sendDiv">
                  {props.isFetchingAddVendor ? (
                    <div className="loader-div">
                      <Spinner
                        variant="primary"
                        animation="grow"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                      />
                    </div>
                  ) : (
                    <Button
                      disabled={
                        isEmpty(vendorName) ||
                        isEmpty(vendorPhone) ||isEmpty(vendorCNIC)
                      }
                      className="sendButton"
                      onClick={() => sendData()}
                      style={{
                        marginLeft: "2%",
                        color: "white",
                        width: "20%",
                        backgroundColor: "black",
                        border: "1px solid black",
                      }}
                    >
                      Save
                    </Button>
                  )}
                </div>
              </Form>
            </div>
          </div>
        </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addVendor:(data,handleVisible,
      handleDangerVisible)=>dispatch(addVendor(data,handleVisible,
        handleDangerVisible))
  };
};
const mapStateToProps = (state, ownProps) => ({
  // vendorInfo: state.vendorReducer.vendorInfo,
  isFetchingAddVendor: state.vendorReducer.isFetchingAddVendor,
  // divisionInfo: state.divisionReducer.divisionInfo,
  // isFetchingGetDivision: state.divisionReducer.isFetchingGetDivision,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddVendor);
