const initialState = {
    isFetchingAddSubProduct: false,
    isFetchingGetSubProduct: false,
    subProductDetail: {
        product: "",
        subProduct: "",
    },
    subProductInfo: [],

}


const subProductReducer = (state = initialState, action) => {
    switch (action.type) {
     //Sub Product
     case "REQUEST_ADD_SUB_PRODUCT":
        return { ...state, isFetchingAddSubProduct: true };

    case "SUCCESS_ADD_SUB_PRODUCT":
        return {
            ...state, isFetchingAddSubProduct: false,
            subProductDetail: {
                product: action.payload.name,
                subProduct: action.payload.description,
            },
        };

    case "ERROR_ADD_SUB_PRODUCT":
        return { ...state, isFetchingAddSubProduct: false };

    //Get Sub Product
    case "REQUEST_GET_SUB_PRODUCT":
        return { ...state, isFetchingGetSubProduct: true };

    case "SUCCESS_GET_SUB_PRODUCT":
        return {
            ...state, isFetchingGetSubProduct: false,
            subProductInfo: action.payload.data,
        };

    case "ERROR_GET_SUB_PRODUCT":
        return { ...state, isFetchingGetSubProduct: false };




        default:
            return state;
    }
}

export default subProductReducer;
