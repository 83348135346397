import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { isNull } from "lodash";
import ProductionDetail from "./ProductionDetail";
import ViewProduction from "./viewProductionDetail";

const TotalProductionDetail = (props) => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  return (
    <>
      {/* <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 92) ?
                    "AddProduct" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 93) ?
                        "ProductList" :  ""} id="AddProduct" className="mb-3"> */}
      <Tabs
        defaultActiveKey={"ProductionDetail"}
        id="ProductionDetail"
        className="mb-3"
      >
        <Tab
          eventKey="ProductionDetail"
          title="Production Detail"
          // disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 92) ? false : true}
        >
          <ProductionDetail />
        </Tab>
        <Tab
          eventKey="ProductionDetailList"
          title="Production Detail List"
          //    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 93) ? false : true}
        >
          <ViewProduction />
        </Tab>
      </Tabs>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mapStateToProps = (state, ownProps) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TotalProductionDetail);
