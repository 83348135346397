import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import { addSizeCall } from "./size.action";

const AddSize = (props) => {
  const [size, setSize] = useState("");
  const [description, setDescription] = useState("");

  function sendData() {
    props.addSizeCall({
      sizeName: size,
      sizeDescription: description,
    });
    setSize("");
    setDescription("");
  }

  useEffect(() => {}, []);

  return (
    <Container fluid>
      <div className="main">
        <Form>
          <Row>
            <Col lg="12" md="12" xs="12">
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>Enter Size *</b>
                </Form.Label>
                <Form.Control
                  value={size}
                  type="text"
                  id="sizeDetail"
                  placeholder="Size...."
                  onChange={(e) => setSize(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg="12" md="12" xs="12">
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>Description</b>
                </Form.Label>
                <Form.Control
                  value={description}
                  type="text"
                  id="sizeDescription"
                  placeholder="Description...."
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <div className="sendDiv">
            {props.isFetchingAddSize ? (
              <div className="loader-div">
                <Spinner
                  variant="primary"
                  animation="grow"
                  size="xl"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            ) : (
              <Button
                disabled={isEmpty(size)}
                className="sendButton"
                onClick={() => sendData()}
                style={{
                  marginLeft: "2%",
                  color: "white",
                  width: "20%",
                  backgroundColor: "black",
                  border: "1px solid black",
                }}
              >
                Save
              </Button>
            )}
          </div>
        </Form>
      </div>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSizeCall: (data) => dispatch(addSizeCall(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingAddSize: state.sizeReducer.isFetchingAddSize,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSize);
