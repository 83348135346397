import React, { useEffect, useState } from "react";
import { Row, Col, Form, Table, Container, Button, FormGroup, Alert, Tab, Tabs } from "react-bootstrap";
import "./add-details.css";
import { ModalBody,Modal} from "reactstrap";
import { getPurchase, getApproveDemand, addIssuance, getAllInventory, getIssuance, searchIssuanceReport
} from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, find, isUndefined } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import WareHouseModal from "./WareHouseModal";
import MaterialTable from 'material-table';
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Select from 'react-select';
import Swal from 'sweetalert';

const IssuanceDetail = (props) => {

    const wareHouseData = props.IssuanceList;
   /* const [state, setState] = React.useState({
        viewColumns: [
            { title: 'Issuance Number', field: 'issuanceId' },
            { title: 'Requistion', field: 'requsition' },
            { title: 'Department', field: 'department' },
            { title: 'Production Order', field: 'productionOrder' },
            { title: 'Supervisor', field: 'supervisor' },
            { title: 'Issued By', field: 'issuedBy' },
            { title: 'Received By', field: 'receivedBy' },
            { title: 'Requistion Type', field: 'requsitionType' },
        ],
        columnsData: [
            { title: 'Inventory Code', field: 'inventory', render: rowData => rowData.inventory.warehouse.whKey + "-" + rowData.inventory.category.cateKey + "-" + rowData.inventory.sub_category.subcateKey + "-" + rowData.inventory.invKey },
            { title: 'Inventory Name', field: 'inventory.productName' },
            { title: 'Product Quantity', field: 'productQuantity' },
        ],
    });*/
    useEffect(() => {
        props.getIssuance();
    }, []);
    return (
        <>
                    {props.isFetchingIssuance ? (
                        <div className="loader-div">
                            <Button variant="info" disabled>
                                <Spinner
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    variant="info"
                                />
                                {/* {props.isFetchingSectorsSummary ? 'Loading...' : 'Loading...'} */}

                            </Button>
                        </div>
                    ) : (
                        <Container fluid>
                            <div className="main">
                                <MaterialTable
                                    title="Issuance Details"
                                    columns={[
                                        { title: 'Requistion', field: 'requsition' },
                                        { title: 'Department', field: 'department' },
                                        { title: 'Production Order', field: 'productionOrder' },
                                        { title: 'Details/Supervisor', field: 'supervisor' },
                                        { title: 'Issued By', field: 'issuedBy' },
                                        { title: 'Received By', field: 'receivedBy' },
                                        { title: 'Requistion Type', field: 'requsitionType' },
                                    ]}
                                    data={wareHouseData}
                                    detailPanel={rowData => {
                                        return (
                                            <MaterialTable
                                                columns={[
                                                    { title: 'Inventory Code', field: 'inventory', render: rowData => rowData.inventory.warehouse.whKey + "-" + rowData.inventory.category.cateKey + "-" + rowData.inventory.sub_category.subcateKey + "-" + rowData.inventory.invKey },
                                                    { title: 'Inventory Name', field: 'inventory.productName' },
                                                    { title: 'Product Quantity', field: 'productQuantity' },
                                                ]}
                                                data={rowData.issuance_data}
                                                options={{
                                                    actionsColumnIndex: -1,
                                                    toolbar: false,
                                                    exportButton: true,
                                                    sorting: true,
                                                    paging:true,
                                                    pageSize:200,       // make initial page size
                                                    emptyRowsWhenPaging: false,   // To avoid of having empty rows
                                                    pageSizeOptions:[50,100,150,200],    // rows selection options
                                                    headerStyle: {
                                                        position: 'sticky', top: 0,
                                                        color: '#00BBBB',
                                                        fontWeight: '550',
                                                        onRowAdd: 'none',
                                                    }
                                                }}

                                            />
                                        )
                                    }}
                                    options={{
                                        actionsColumnIndex: -1,
                                        filtering: true,
                                        exportButton: true,
                                        paging:true,
                                        pageSize:200,       // make initial page size
                                        emptyRowsWhenPaging: false,   // To avoid of having empty rows
                                        pageSizeOptions:[50,100,150,200], 
                                        headerStyle: {
                                            position: 'sticky', top: 0,
                                            color: '#00BBBB',
                                            fontWeight: '550',
                                            onRowAdd: 'none',
                                        }
                                    }}
                                    onRowClick={(event, rowData, togglePanel) => togglePanel()}
                                />
                            </div>
                        </Container>
                    )}
              
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        getPurchase: () => dispatch(getPurchase()),
        getApproveDemand: () => dispatch(getApproveDemand()),
        addIssuance: (data, handleVisible, handleDangerVisible) => dispatch(addIssuance(data, handleVisible, handleDangerVisible)),
        getAllInventory: (data) => dispatch(getAllInventory(data)),
        getIssuance: () => dispatch(getIssuance()),
        searchIssuanceReport: (data, issuanceName, startDate, endDate) => dispatch(searchIssuanceReport(data, issuanceName, startDate, endDate))
    };
};
const mapStateToProps = (state, ownProps) => ({
    allInventory: state.AddDataReducer.allInventory,
    isFetchingDemand: state.AddDataReducer.isFetchingDemand,
    isFetchingInventoryAll: state.AddDataReducer.isFetchingInventoryAll,
    purchaseList1: state.AddDataReducer.purchaseList1,
    approveDemandList: state.AddDataReducer.approveDemandList,
    isAddingPurchase: state.AddDataReducer.isAddingPurchase,
    notAddPurchase: state.AddDataReducer.notAddPurchase,
    IssuanceList: state.AddDataReducer.IssuanceList,
    isFetchingIssuance: state.AddDataReducer.isFetchingIssuance,
    isFetchingSearchIssuanceList: state.AddDataReducer.isFetchingSearchIssuanceList
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IssuanceDetail);