import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import Select from "react-select";
import {
  addCustomerCall,
  getCustomerCall,
  getDivisionCall,
  updateCustomerCall,
  deleteCustomerCall,
} from "./customer.action";
import { rotateAndSkewTextDegreesAndTranslate } from "pdf-lib";
import { findAllByAltText } from "@testing-library/dom";

const ViewCustomer = (props) => {
  useEffect(() => {
    props.getCustomerCall();
  }, []);

  return (
    <>
      {props.isFetchingGetCustomer ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialTable
              title="Department Details"
              columns={[
                { title: "Name", field: "customer_name" },
                { title: "Company Name", field: "customerCompany" },
                { title: "Email", field: "customer_email" },
                { title: "Web", field: "customerWeb" },
                { title: "Phone", field: "customer_phone" },
                { title: "City", field: "customerCity" },
                { title: "Country", field: "customerContry" },

                {
                  title: "Division",
                  field: "division_id",
                  lookup: {
                    1: "Americas",
                    2: "Europe",
                    3: "Asia",
                    4: "Africa",
                    5: "Central America",
                    6: "Eastern Europe",
                    7: "South America",
                  },
                },
              ]}
              data={props.customerInfo}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                exportButton: true,
                exportAllData: true,
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      {
                        props.updateCustomerCall(newData, oldData);
                      }
                    }, 600);
                  }),

                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteCustomerCall(oldData.customer_id);
                    }, 600);
                  }),
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerCall: () => dispatch(getCustomerCall()),
    updateCustomerCall: (newData, oldData) =>
      dispatch(updateCustomerCall(newData, oldData)),
    deleteCustomerCall: (id) => dispatch(deleteCustomerCall(id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  customerInfo: state.customerReducer.customerInfo,
  isFetchingGetCustomer: state.customerReducer.isFetchingGetCustomer,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewCustomer);
