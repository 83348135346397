import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
// import { getCustomerCall } from "../production.actions";
import { getCustomerCall } from "../Customer/customer.action";
import { getArticleCall } from "../AddArticleFolder/AddArticles.actions";
import { addOrderCall, getOrderCall } from "./OrderCreation.actions";

const Parent = (props) => {
  const [inputField, setInputField] = useState([
    { articleId: "", quantity: "" },
  ]);
  const getCustomer = props.customerInfo.map((x) => {
    let data = { label: x.customer_name, value: x.customer_id };
    return data;
  });
  const getArticle = props.articleInfo.map((x) => {
    let data = { label: x.itemName, value: x.articleId };
    return data;
  });

  const [manualOrderNum, setManualOrderNum] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerID, setCustomerID] = useState("");
  const [customerOrderNum, setCustomerOrderNum] = useState();
  const [invoiceNum, setInvoiceNum] = useState("");
  const [description, setDescription] = useState("");
  const [orderDate, setOrderDate] = useState("");

  const [articleName, setArticleName] = useState();

  console.log(inputField, "qqoo");
  console.log(articleName, "qqooll");

  const setCustomer = (selectedOption) => {
    setCustomerID(selectedOption.value);
    setCustomerName(selectedOption.label);
  };

  const setArticle = (selectedOption) => {
    setArticleName(selectedOption.value);
  };

  const handleChangeInput = (index, event, value) => {
    const values = [...inputField];
    values[index][
      event.target.id.startsWith("react-select") ? "articleId" : event.target.id
    ] = value;
    setInputField(values);
    console.log(event, "ppff");
  };

  const handleAddFields = () => {
    setInputField([...inputField, { articleId: "", quantity: "" }]);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputField];
    if ((index = 1)) values.splice(index, 1);
    setInputField(values);
  };

  const sendData = () => {
    props.addOrderCall({
      manualOrderId: manualOrderNum,
      customerId: customerName,
      customerOrder: customerOrderNum,
      orderInvoice: invoiceNum,
      orderDescription: description,
      orderDeliveryDate: orderDate,
      data:
        !isEmpty(inputField) &&
        inputField.map((v) => ({
          ...v,
          articleId: v.articleId,
          quantity: v.quantity,
        })),
    });
    setManualOrderNum("");
    setCustomerName("");
    setCustomerOrderNum();
    setInvoiceNum("");
    setDescription("");
    setOrderDate("");
    setArticleName("");
    setInputField([{ articleId: "", quantity: "" }]);
  };

  useEffect(() => {
    props.getArticleCall();
    props.getCustomerCall();
    props.getOrderCall();
  }, []);

  return (
    <>
      {props.isFetchingGetCustomer || props.isFetchingGetArticle ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <Row>
                  <Col lg="6" md="6" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Manual Order *</b>
                      </Form.Label>
                      <Form.Control
                        value={manualOrderNum}
                        type="text"
                        id="manualOrder"
                        placeholder="Manual Order #...."
                        onChange={(e) => setManualOrderNum(e.target.value)}
                      />
                    </Form.Group>
                  </Col>

                  <Col lg="6" md="6" xs="12" styles={{ zIndex: -1 }}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Customer Name *</b>
                      </Form.Label>
                      <Select
                        value={{ label: customerName }}
                        placeholder="Select Customer..."
                        onChange={(e) => {
                          setCustomer(e);
                        }}
                        options={getCustomer}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6" md="6" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Customer Order *</b>
                      </Form.Label>
                      <Form.Control
                        value={customerOrderNum}
                        type="text"
                        id="customerOrderNu"
                        placeholder="Customer Order #...."
                        onChange={(e) => setCustomerOrderNum(e.target.value)}
                      />
                    </Form.Group>
                  </Col>

                  <Col lg="6" md="6" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Invoice *</b>
                      </Form.Label>
                      <Form.Control
                        value={invoiceNum}
                        type="text"
                        id="invoice"
                        placeholder="Invoice #...."
                        onChange={(e) => setInvoiceNum(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6" md="6" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Description</b>
                      </Form.Label>
                      <Form.Control
                        value={description}
                        type="text"
                        id="description"
                        placeholder="Description...."
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </Form.Group>
                  </Col>

                  <Col lg="6" md="6" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Order Delivery Date *</b>
                      </Form.Label>
                      <Form.Control
                        value={orderDate}
                        type="date"
                        id="orderDate"
                        placeholder="Order Delivery Date...."
                        onChange={(e) => setOrderDate(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Label>
                  <b>Array of Articles:</b>
                </Form.Label>
                {inputField.map((inputField, index) => (
                  <div key={index}>
                    <Row>
                      <Col lg="6" md="6" xs="12">
                        <Form.Group className="mb-3">
                          <Form.Label>
                            <b>Article Name *</b>
                          </Form.Label>
                          <Select
                            id="articleId"
                            placeholder="Select Article..."
                            onChange={(e) => {
                              setArticle(e);
                            }}
                            options={getArticle}
                            onBlur={(e) =>
                              handleChangeInput(index, e, articleName)
                            }
                          />
                        </Form.Group>
                      </Col>

                      <Col lg="6" md="6" xs="12">
                        <Form.Group className="mb-3">
                          <Form.Label>
                            <b>Quantity *</b>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            id="quantity"
                            placeholder="Quantity...."
                            onChange={(e) =>
                              handleChangeInput(index, e, e.target.value)
                            }
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <IconButton
                          onClick={() => handleAddFields()}
                          style={{
                            float: "right",
                            color: "black",
                            marginTop: "15px",
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleRemoveFields(index)}
                          style={{
                            float: "right",
                            color: "black",
                            marginTop: "15px",
                          }}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </Col>
                    </Row>
                  </div>
                ))}

                <div className="sendDiv">
                  {props.isFetchingAddOrder ? (
                    <div className="loader-div">
                      <Spinner
                        variant="primary"
                        animation="grow"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                      />
                    </div>
                  ) : (
                    <Button
                      disabled={
                        isEmpty(manualOrderNum) ||
                        isEmpty(invoiceNum) ||
                        isEmpty(orderDate) ||
                        isEmpty(customerName) ||
                        isEmpty(customerOrderNum) ||
                        inputField[0].articleId == "" ||
                        inputField[0].quantity == ""
                      }
                      className="sendButton"
                      onClick={() => sendData()}
                      style={{
                        marginLeft: "2%",
                        color: "white",
                        width: "20%",
                        backgroundColor: "black",
                        border: "1px solid black",
                      }}
                    >
                      Save
                    </Button>
                  )}
                </div>

                <MaterialTable
                  title="Order Details"
                  columns={[
                    { title: "Order ID", field: "orderId" },
                    { title: "Invoice Number", field: "orderInvoice" },
                    { title: "Description", field: "orderDescription" },
                    { title: "Delivery Date", field: "orderDeliveryDate" },
                    { title: "Order Status", field: "orderStatus" },
                  ]}
                  data={props.orderInfo}
                  detailPanel={(rowData) => {
                    console.log(rowData, "qqgg");
                    return (
                      <MaterialTable
                        columns={[
                          { title: "Article ID", field: "articleId" },
                          { title: "Quantity", field: "quantity" },
                        ]}
                        data={rowData.order_articles}
                        title="Article Preview"
                      />
                    );
                  }}
                />
              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addOrderCall: (data) => dispatch(addOrderCall(data)),
    getCustomerCall: () => dispatch(getCustomerCall()),
    getArticleCall: () => dispatch(getArticleCall()),
    getOrderCall: () => dispatch(getOrderCall()),
    // deleteCustomerCall: (id) => dispatch(deleteCustomerCall(id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  customerInfo: state.customerReducer.customerInfo,
  isFetchingGetCustomer: state.customerReducer.isFetchingGetCustomer,
  articleInfo: state.AddArticleReducer.articleInfo,
  isFetchingGetArticle: state.AddArticleReducer.isFetchingGetArticle,
  orderInfo: state.OrderCreationReducer.orderInfo,
  isFetchingAddOrder: state.OrderCreationReducer.isFetchingAddOrder,
  isFetchingGetOrder: state.OrderCreationReducer.isFetchingGetOrder,
});

export default connect(mapStateToProps, mapDispatchToProps)(Parent);
