import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import { getArticleCall } from "../AddArticleFolder/AddArticles.actions";
import { getOrderCall, addChildOrderCall } from "./OrderCreation.actions";

const Child = (props) => {
  const [inputField, setInputField] = useState([
    { articleId: "", quantity: "" },
  ]);
  const getOrder = props.orderInfo.map((x) => {
    let data = { label: x.orderId, value: x.orderId };
    return data;
  });
  const getArticle = props.articleInfo.map((x) => {
    let data = { label: x.itemName, value: x.articleId };
    return data;
  });

  const [orderNum, setOrderNum] = useState("");

  const [articleName, setArticleName] = useState();

  console.log(inputField, "qqoo");
  console.log(articleName, "qqooll");

  const setOrder = (selectedOption) => {
    setOrderNum(selectedOption.value);
  };

  const setArticle = (selectedOption) => {
    setArticleName(selectedOption.value);
  };

  const handleChangeInput = (index, e, type) => {
    const values = [...inputField];
    if (type == "article") values[index]["articleId"] = e.value;
    if (type == "articleQuantity") values[index]["quantity"] = e.target.value;
    //values[index][event.target.id] = value;
    setInputField(values);
    //console.log(event, "ppff")
  };

  const handleAddFields = () => {
    setInputField([...inputField, { articleId: "", quantity: "" }]);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputField];
    if ((index = 1)) values.splice(index, 1);
    setInputField(values);
  };

  const sendData = () => {
    props.addChildOrderCall({
      orderId: orderNum,
      // customerId: customerName,
      // customerOrder: customerOrderNum,
      // orderInvoice: invoiceNum,
      // orderDescription: description,
      // orderDeliveryDate: orderDate,
      // data: !isEmpty(inputField) && inputField.map((v) => ({
      //     ...v,
      //     articleId: v.articleId,
      //     quantity: v.quantity,
      // }))
      data: inputField,
    });
    setOrderNum("");
    inputField([{ articleId: "", quantity: "" }]);
  };

  useEffect(() => {
    props.getArticleCall();
    // props.getCustomerCall();
    props.getOrderCall();
  }, []);

  return (
    <>
      {props.isFetchingGetArticle || props.isFetchingGetArticle ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <Row>
                  <Col lg="12" md="12" xs="12" styles={{ zIndex: -1 }}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Order *</b>
                      </Form.Label>
                      <Select
                        placeholder="Select Order #..."
                        value={orderNum}
                        onChange={(e) => {
                          setOrder(e);
                        }}
                        options={getOrder}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Label>
                  <b>Array of Articles:</b>
                </Form.Label>
                {inputField.map((inputField, index) => (
                  <div key={index}>
                    <Row>
                      <Col lg="6" md="6" xs="12">
                        <Form.Group className="mb-3">
                          <Form.Label>
                            <b>Article Name *</b>
                          </Form.Label>
                          <Select
                            id="articleId"
                            placeholder="Select Article..."
                            value={{ label: inputField.articleName }}
                            // onChange={setArticle}
                            options={getArticle}
                            onChange={(e) =>
                              handleChangeInput(index, e, "article")
                            }
                          />
                        </Form.Group>
                      </Col>

                      <Col lg="6" md="6" xs="12">
                        <Form.Group className="mb-3">
                          <Form.Label>
                            <b>Quantity *</b>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            value={inputField.quantity}
                            id="quantity"
                            placeholder="Quantity...."
                            onChange={(e) =>
                              handleChangeInput(index, e, "articleQuantity")
                            }
                          />
                        </Form.Group>
                      </Col>

                      <Col>
                        <IconButton
                          onClick={() => handleAddFields()}
                          style={{
                            float: "right",
                            color: "black",
                            marginTop: "15px",
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleRemoveFields(index)}
                          style={{
                            float: "right",
                            color: "black",
                            marginTop: "15px",
                          }}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </Col>
                    </Row>
                  </div>
                ))}

                <div className="sendDiv">
                  {props.isFetchingAddChildOrder ? (
                    <div className="loader-div">
                      <Spinner
                        variant="primary"
                        animation="grow"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                      />
                    </div>
                  ) : (
                    <Button
                      disabled={
                        isEmpty(orderNum) ||
                        //   inputField[0].articleId == "" ||
                        inputField[0].quantity == ""
                      }
                      className="sendButton"
                      onClick={() => sendData()}
                      style={{
                        marginLeft: "2%",
                        color: "white",
                        width: "20%",
                        backgroundColor: "black",
                        border: "1px solid black",
                      }}
                    >
                      Save
                    </Button>
                  )}
                </div>

                <MaterialTable
                  title="Order Details"
                  columns={[
                    { title: "Order ID", field: "orderId" },
                    { title: "Invoice Number", field: "orderInvoice" },
                    { title: "Description", field: "orderDescription" },
                    { title: "Delivery Date", field: "orderDeliveryDate" },
                    { title: "Order Status", field: "orderStatus" },
                  ]}
                  data={props.orderInfo}
                  detailPanel={(rowData) => {
                    console.log(rowData, "qqgg");
                    return (
                      <MaterialTable
                        columns={[
                          { title: "Article ID", field: "articleId" },
                          { title: "Quantity", field: "quantity" },
                        ]}
                        data={rowData.order_articles}
                        title="Article Preview"
                      />
                    );
                  }}
                />
              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addChildOrderCall: (data) => dispatch(addChildOrderCall(data)),
    getOrderCall: () => dispatch(getOrderCall()),
    getArticleCall: () => dispatch(getArticleCall()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  articleInfo: state.AddArticleReducer.articleInfo,
  isFetchingGetArticle: state.AddArticleReducer.isFetchingGetArticle,
  orderInfo: state.OrderCreationReducer.orderInfo,
  isFetchingGetOrder: state.OrderCreationReducer.isFetchingGetOrder,
  isFetchingAddChildOrder: state.OrderCreationReducer.isFetchingAddChildOrder,
});

export default connect(mapStateToProps, mapDispatchToProps)(Child);
