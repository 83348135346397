import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import { getUOMCall, deleteUom, updateUom } from "./uom.action";

const ViewUom = (props) => {
  useEffect(() => {
    props.getUOMCall();
  }, []);

  return (
    <>
      {props.isFetchingGetUOM ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialTable
              title="UOM Details"
              columns={[
                { title: "UOM", field: "uomName" },
                { title: "UOM Description", field: "uomDescription" },
              ]}
              data={props.uomInfo}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                exportButton: true,
                exportAllData: true,
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.updateUom(newData, oldData);
                    }, 600);
                  }),

                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteUom(oldData.uomId);
                    }, 600);
                  }),
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUOMCall: () => dispatch(getUOMCall()),
    deleteUom: (uomId) => dispatch(deleteUom(uomId)),
    updateUom: (newData, oldData) => dispatch(updateUom(newData, oldData)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  uomInfo: state.uomReducer.uomInfo,
  isFetchingGetUOM: state.uomReducer.isFetchingGetUOM,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewUom);
