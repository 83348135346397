import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { isNull } from "lodash";
import AddCustomer from "./addCustomer";
import ViewCustomer from "./viewCustomer";

const TotalCustomerData = (props) => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  return (
    <>
      {/* <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 92) ?
                    "AddColor" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 93) ?
                        "ColorList" :  ""} id="AddColor" className="mb-3"> */}
      <Tabs defaultActiveKey={"AddCustomer"} id="AddCustomer" className="mb-3">
        <Tab
          eventKey="AddCustomer"
          title="Add Customer"
          // disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 92) ? false : true}
        >
          <AddCustomer />
        </Tab>
        <Tab
          eventKey="CustomerList"
          title="Cutomer List"
          //    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 93) ? false : true}
        >
          <ViewCustomer />
        </Tab>
      </Tabs>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TotalCustomerData);
