import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup } from "react-bootstrap";
import "../add-details.css";
import {
  getAllInventory,
  addReceiving,
  getWarehouseData,
  getProductByVendor,
} from "../AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, find, isUndefined, filter, sumBy } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import Swal from "sweetalert";
import moment from "moment";
import { getVendor } from "../Vendor/vendor.action";

const AddReceving = (props) => {
  const [inputField, setInputField] = useState([
    {
      inventoryId: "",
      productQuantityCount: "",
      inventoryPrice: "",
      productQuantity: 0,
      inventoryLabel: "",
    },
  ]);
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [wareHouseId, setwareHouseId] = useState("");
  const [wareHouselabel, setwareHouseLabel] = useState("");
  const [maximumQuantity, setMaximumQuantity] = useState(0);
  const [requistion, setRequistion] = useState();
  const [requistionLabel, setRequistionLabel] = useState();
  const [department, setdepartment] = useState();
  const [issuedBy, setIssuedBy] = useState();
  const [receivedBy, setReceivedBy] = useState();
  const [requistionType, setRequistionType] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [vendorName, setVendorName] = useState("");
  const [vendorId, setVendorId] = useState();

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Reciving Added Successfully",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };

  const handleChangeWareHouse = (selectedOption) => {
    setwareHouseId(selectedOption.value);
    setwareHouseLabel(selectedOption.label);
    console.log(filteredInventory, "before filter");
  };

  function uploadPurchase() {
    props.addReceiving(
      {
        receiving_date: date,
        receivedBy: receivedBy,
        reciving_description: requistionType,
        data: inputField,
        vendor_id: vendorId,
        receiving_total: sumBy(inputField, 'total')
      },
      handleVisible,
      handleDangerVisible
    );
    setRequistionType("");
    setReceivedBy("");
    setIssuedBy("");
    setRequistionLabel("");
    setdepartment("");
    setRequistion("-1");
    setInputField([
      {
        inventoryId: "",
        productQuantityCount: "",
        inventoryPrice: "",
        productQuantity: "",
        inventoryLabel: "",
      },
    ]);
  }
  const inventory = !isEmpty(props.productByVendorList) && props.productByVendorList.map((x) => {
    let data = {
      value: x.inventoryId,
      label:
        x.warehouse.whKey +
        "-" +
        x.category.cateKey +
        "-" +
        x.invKey +
        " " +
        x.productName,
      // whId: x.warehouse.wareHouseId,
    };
    return data;
  });
  const getWareHouseList = props.warehouseList.map((x) => {
    let data = { value: x.wareHouseId, label: x.wareHouseName };
    return data;
  });
  const filteredInventory =
    wareHouseId === "" ? [] : filter(inventory, { whId: wareHouseId });

  console.dir(filteredInventory, "init filter");

  const handleChangeDemand = (e, index, type, item) => {
    let z = find(props.productByVendorList, (x) => x.inventoryId == item);

    const { name, value } = e;
    const list = [...inputField];
    if (type == "intname") list[index]["inventoryId"] = e.value;
    if (type == "intname") list[index]["inventoryLabel"] = e.label;
    if (type == "intname")
      list[index]["productQuantity"] = Number(z.currentBlnc);
    if (type == "intname") setMaximumQuantity(Number(z.currentBlnc));
    if (type == "intquantity") {
      list[index]["productQuantity"] =
        e.target.value < 0 ? alert("cannot added less than 0") : e.target.value;
      list[index]["total"] = Number(list[index]['inventoryPrice']) * Number(e.target.value)
    }
    if (type == 'intPrice') {
      list[index]['inventoryPrice'] = Number(e.target.value)
      list[index]["total"] = Number(list[index]['productQuantity']) * Number(e.target.value)
    }

    setInputField(list);
  };

  const handleChange = (e, index, type) => {
    let z = find(props.productByVendorList, (x) => x.inventoryId == e.value);
    const { name, value } = e;
    console.log(z, "testttttttttttttt")
    const list = [...inputField];
    if (type == "intname") list[index]["inventoryId"] = e.value;
    if (type == "intname") list[index]["inventoryLabel"] = e.label;
    if (type == "intname") list[index]["inventoryPrice"] = z.productPrice;
    if (type == "intname")
      list[index]["productQuantityCount"] = Number(z.currentBlnc);
    if (type == "intname") setMaximumQuantity(Number(z.currentBlnc));
    if (type == "intquantity") list[index]["productQuantity"] = e.target.value;
    setInputField(list);
    // list[index]['productQuantity'] = "";
  };

  const handleAddInput = () => {
    setInputField([
      ...inputField,
      { inventoryId: "", productQuantityCount: "", inventoryPrice: "", productQuantity: "" },
    ]);
  };

  const handleRemoveInput = (index) => {
    const list = [...inputField];
    list.splice(index, 1);
    setInputField(list);
  };

  const getRequistion = [
    { value: "Manual", label: "Manual" },
    { value: "After Production", label: "After Production" },
  ];
  const getVendorList =
    !isEmpty(props.vendorInfo) &&
    props.vendorInfo.map((x) => {
      let data = { value: x.vendor_id, label: x.vendor_name };
      return data;
    })
  const handleChangeRequistion = (selectedOption) => {
    setRequistion(selectedOption.value);
    setRequistionLabel(selectedOption.label);
  };
  const handleChangeVendor = (selectedOption) => {
    setVendorId(selectedOption.value);
    setVendorName(selectedOption.label);
    props.getProductByVendor(selectedOption.value)
  };

  useEffect(() => {
    // props.getAllInventory({
    //   startedDate: "",
    //   endDate: "",
    //   wareHouseId: "",
    //   subCategoryId: "",
    //   categoryId: "",
    // });
    props.getVendor();

  }, []);
  return (
    <>
      {props.isFetchingInventoryAll || props.isFetchingGetVendor ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {/* {props.isFetchingSectorsSummary ? 'Loading...' : 'Loading...'} */}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col xl="12" lg="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Date <span style={{ fontSize: '25px', fontWeight: 'bold', color: 'red' }}>*</span></b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Date...."
                    name="Date"
                    value={date}
                    onChange={(e) =>
                      setDate(moment(e.target.value).format("YYYY-MM-DD"))
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Select Vendor <span style={{ fontSize: '25px', fontWeight: 'bold', color: 'red' }}>*</span></b>
                  </Form.Label>
                  <Select
                    isDisabled={
                      isEmpty(getVendorList)}
                    value={{ label: vendorName }}
                    placeholder="Select Vendor..."
                    onChange={(e) => handleChangeVendor(e)}
                    options={getVendorList}
                  // onBlur={() => setStateValue(false)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Received By:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Received By...."
                    name="receivedBy"
                    value={receivedBy}
                    onChange={(e) => setReceivedBy(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Description:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Description...."
                    name="requistionType"
                    value={requistionType}
                    onChange={(e) => setRequistionType(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            {inputField.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <Row>
                    <Col xl="3" lg="3" md="12" sm="12">
                      <Form.Group>
                        <Form.Label>
                          <b> Select Inventory <span style={{ fontSize: '25px', fontWeight: 'bold', color: 'red' }}>*</span></b>
                        </Form.Label>
                        <Select
                          value={{ label: item.inventoryLabel }}
                          placeholder="Select Inventory..."
                          name="inventoryId"
                          onChange={(e) => handleChange(e, i, "intname")}
                          // options={isEmpty(inventory) || (wareHouseId === "") ? [] : filter(inventory, { whId: wareHouseId })}
                          options={
                            isEmpty(inventory) || vendorName == ""
                              ? []
                              : inventory
                          }
                          isDisabled={isEmpty(inventory) || vendorName == ""}
                          isOptionDisabled={(option) =>
                            !isEmpty(
                              find(
                                inputField,
                                (x) => x.inventoryId == option.value
                              )
                            )
                          }
                        />
                      </Form.Group>
                    </Col>

                    <Col xl="3" lg="3" md="6" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Inventories Quantity <span style={{ fontSize: '25px', fontWeight: 'bold', color: 'red' }}>*</span></b>
                        </Form.Label>
                        <Form.Control
                          placeholder="Inventories Quantity...."
                          name="productQuantityCount"
                          disabled
                          value={item.productQuantityCount}
                        />
                      </Form.Group>
                    </Col>
                    <Col xl="3" lg="3" md="6" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Inventory Price <span style={{ fontSize: '25px', fontWeight: 'bold', color: 'red' }}>*</span></b>
                        </Form.Label>
                        <Form.Control
                          placeholder="Inventory Price...."
                          type="number"
                          // disabled
                          value={item.inventoryPrice}
                          onChange={(e) =>
                            handleChangeDemand(
                              e,
                              i,
                              "intPrice",
                              item.inventoryId
                            )
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col xl="3" lg="3" md="6" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Add Quantity <span style={{ fontSize: '25px', fontWeight: 'bold', color: 'red' }}>*</span></b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Add Quantity...."
                          name="productQuantity"
                          min={0}
                          value={item.productQuantity}
                          onChange={(e) =>
                            handleChangeDemand(
                              e,
                              i,
                              "intquantity",
                              item.inventoryId
                            )
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Row>
                      <Col lg="12">
                        {inputField.length - 1 == i && (
                          <IconButton
                            title="Click to Add Demand"
                            onClick={handleAddInput}
                            style={{
                              float: "right",
                              color: "black",
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        )}

                        {inputField.length !== 1 && (
                          <IconButton
                            title="Click to Remove Demand"
                            onClick={() => handleRemoveInput(i)}
                            style={{ float: "right", color: "black" }}
                          >
                            <RemoveIcon />
                          </IconButton>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                </React.Fragment>
              );
            })}
            <div className="sendDiv">
              {
                // isUndefined(requistion) ||
                vendorName == "" ||
                  date == "" ||
                  inputField[0].inventoryId == "" ||
                  inputField[0].productQuantity == 0 ? (
                  <Button
                    disabled
                    style={{ color: "black" }}
                    className="sendButton"
                  >
                    Save
                  </Button>
                ) : (
                  <Button className="sendButton" onClick={uploadPurchase}>
                    Save
                  </Button>
                )
              }
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    addReceiving: (data, handleVisible, handleDangerVisible) =>
      dispatch(addReceiving(data, handleVisible, handleDangerVisible)),
    getAllInventory: (data) => dispatch(getAllInventory(data)),
    getWarehouseData: () => dispatch(getWarehouseData()),
    getVendor: () => dispatch(getVendor()),
    getProductByVendor: (id) => dispatch(getProductByVendor(id))
  };
};
const mapStateToProps = (state, ownProps) => ({
  allInventory: state.AddDataReducer.allInventory,
  isFetchingInventoryAll: state.AddDataReducer.isFetchingInventoryAll,
  warehouseList: state.AddDataReducer.warehouseList,
  isFetchingwareHouseList: state.AddDataReducer.isFetchingwareHouseList,
  productByVendorList: state.AddDataReducer.productByVendorList,
  vendorInfo: state.vendorReducer.vendorInfo,
  isFetchingGetVendor: state.vendorReducer.isFetchingGetVendor,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddReceving);
