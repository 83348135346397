import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  FormGroup,
  Modal,
  Alert,
} from "react-bootstrap";
import "../production-styles.css";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import { find, isEmpty } from "lodash";
import {
  getArticle,
  getEmployeeData,
  getProcessData,
  addLabourData,
  getProcessCategoryData,
  getProcessDataByCategory,
} from "./AddLabour.actions";
import { getArticleIds } from "../AddArticleMaterialFolder/AddArticleMaterial.actions";

const AddLabour = (props) => {
  const [inputField, setInputField] = useState([
    {
      processId: "-1",
      processRate: 0,
    },
  ]);
  const [selectCategory, setSelectCategory] = useState();
  console.log(selectCategory, "1111111111");
  const [articleId, setArticleId] = useState();
  const [employeeId, setEmployeeId] = useState();
  const [show, setShow] = useState(false);
  console.log(show, "ssssssssssss");
  // const processList = [{ value: "1", label: "process1" }, { value: "2", label: "process2" },];
  const [checkedState, setCheckedState] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  //var total = 0;
  var total = !isEmpty(checkedState)
    ? checkedState.reduce((a, b) => (a = a + Number(b.rate)), 0)
    : 0;
  console.log(total, "tttt");

  console.log(checkedState, "checkkkkkkkkkkkk");
  const getArticleListData = props.getArticleList.map((x) => {
    let data = { value: x.articleId, label: x.itemName };
    return data;
  });
  const getEmployeeListData = props.employeeList.map((x) => {
    let data = { value: x.employeeId, label: x.Name };
    return data;
  });
  const getProcessListData = !isEmpty(props.processListByCategoty)
    ? props.processListByCategoty[0].processes.map((x) => {
        let data = { value: x.processId, label: x.processName };
        return data;
      })
    : "";
  console.log(getProcessListData, "ppppppppppp");
  const getCategoryListData = props.categoryProcessList.map((x) => {
    let data = { value: x.categoryProcessId, label: x.categoryProcessName };
    return data;
  });
  //  const processList = [{ value: "1", label: "process1" }, { value: "2", label: "process2" },];

  //  const getCategoryListData = [{ value: 1, label: "category" }];
  // const getEmployeeListData = [{ value: 1, label: "employee1" }];

  const handleChangeCategory = (selectedOption) => {
    setShow(true);
    setSelectCategory(selectedOption.value);
    props.getProcessDataByCategory(selectedOption.value);
  };
  const handleChangeArticle = (selectedOption) => {
    setArticleId(selectedOption.value);
  };

  const handleChangeEmployee = (selectedOption) => {
    setEmployeeId(selectedOption.value);
  };

  function saveArticleMaterial() {
    props.addLabourData({
      // articleId: articleId,
      // // employeeId: employeeId,
      // categoryProcessId: selectCategory,
      // totalCost: !isEmpty(checkedState) ? checkedState.reduce((a, b) => a = a + Number(b.rate), 0) : 0,
      // // data: inputField.map((x) => { let data = { labourRate: x.processId, labourProcess: x.processRate }; return data })
      // data: !isEmpty(checkedState) ? checkedState.map((x) => { let data = { labourProcess: x.processId, labourRate: x.rate, employeeId: x.employeeId }; return data }) : checkedState.map((x) => { let data = { labourRate: "", labourProcess: "", employeeId: "" }; return data })
      articleData: props.ArticleIds.map((x) => {
        let data = {
          articleId: x,
          categoryProcessId: selectCategory,
          totalCost: !isEmpty(checkedState)
            ? checkedState.reduce((a, b) => (a = a + Number(b.rate)), 0)
            : 0,
          data: !isEmpty(checkedState)
            ? checkedState.map((x) => {
                let data = {
                  labourProcess: x.processId,
                  labourRate: x.rate,
                  employeeId: x.employeeId,
                };
                return data;
              })
            : checkedState.map((x) => {
                let data = {
                  labourRate: "",
                  labourProcess: "",
                  employeeId: "",
                };
                return data;
              }),
        };
        return data;
      }),
    });
  }
  const handleOnChangeRate = (position, e, type) => {
    console.log(e, "eeeeeeeeeeeeeee");
    let employeeIdValue;
    console.log(e.value, "ooooooooo");

    if (isEmpty(find(checkedState, (x) => x.id == position))) {
      // it dont exist
      if (document.getElementById(`custom-checkbox-${position}`).checked) {
        let z = checkedState;

        // let obj = [];
        if (type == "employee") employeeIdValue = e.value;

        z.push({
          id: position,
          processId: type == "checkBoxValue" && e.target.value,
          rate: type == "intRate" && e.target.value,
          employeeId: (type == "employee" && e.target.value) || e.value,
        });
        setCheckedState(z);
        // setTotalCost(checkedState.reduce((a, b) => a = Number(a) + Number(b.rate), 0));
      } else {
        let z = checkedState;
        let filterarray = z.filter(function (ele) {
          return ele.id != position;
        });
        console.log(filterarray, "aaaaaa");
        setCheckedState(filterarray);
        // setTotalCost(checkedState.reduce((a, b) => a = Number(b.rate) - Number(a), 0));
      }
    } else {
      //it already exist
      if (document.getElementById(`custom-checkbox-${position}`).checked) {
        let z = checkedState;
        console.log(e.value, "ooooooooo");
        if (type == "employee") employeeIdValue = e.value;
        z.map((x) => {
          if (x.id == position) {
            if (type == "employee") x["rate"] = x["rate"];
            else x["rate"] = e.target.value;
            if (type == "employee") x["employeeId"] = employeeIdValue;
            else x["employeeId"] = x["employeeId"];
          } else {
            return x;
          }
        });
        // setTotalCost(checkedState.reduce((a, b) => a = Number(a) + Number(b.rate), 0));
      } else {
        let z = checkedState;
        let filterarray = z.filter(function (ele) {
          return ele.id != position;
        });

        setCheckedState(filterarray);
        // setTotalCost(checkedState.reduce((a, b) => a = Number(b.rate) - console.log(Number(a), "ooooooo"), 0));
      }
    }
    console.log(checkedState, "checkkkkkkkkkkkk");
    // console.log(totalCost, "cccccccc");
    total = !isEmpty(checkedState)
      ? checkedState.reduce((a, b) => (a = Number(a) + Number(b.rate)), 0)
      : 0;
    console.log(total, "tttt");
    setTotalCost(total);
    console.log(totalCost, "cccccccc");
  };

  const handleChange = (e, index, type) => {
    const { name, value } = e;
    const list = [...inputField];
    let cost;
    if (type == "intname") list[index]["processId"] = e.value;
    if (type == "intquantity") list[index]["processRate"] = e.target.value;
    // console.log(list[index]['productCost'], "ccccccccccccc")
    // var holder = {};
    // list.forEach(function (d) {
    //     if (holder.hasOwnProperty(d.inventoryId)) {
    //         holder[d.inventoryId] = Number(d.productQuantity) + Number(d.productQuantity);
    //     } else {
    //         holder[d.inventoryId] = Number(d.productQuantity);
    //     }
    // });
    // var obj2 = [];
    // for (var prop in holder) {
    //     obj2.push({ inventoryId: prop, productQuantity: Number(holder[prop]), productCost: list[index]['productCost']});
    // }
    // setInputField(obj2);

    setInputField(list);
  };

  const handleAddInput = () => {
    setInputField([...inputField, { processId: "-1", processRate: 0 }]);
  };

  const handleRemoveInput = (index) => {
    const list = [...inputField];
    list.splice(index, 1);
    setInputField(list);
  };

  useEffect(() => {
    props.getArticle();
    props.getEmployeeData();
    props.getProcessData();
    props.getProcessCategoryData();
    props.getArticleIds();
  }, []);
  return (
    <>
      {props.isFetchingDemand || props.isFetchingInventoryAll ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {/* {props.isFetchingSectorsSummary ? 'Loading...' : 'Loading...'} */}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            {props.isAddingLabourData && (
              <Alert variant={"success"}>Added Data...</Alert>
            )}
            {/* <Row>
                            <Col xl="12" lg="12" md="12" md="12" sm="12">
                                <Form.Group>
                                    <Form.Label>
                                        <b>Select Article</b>
                                    </Form.Label>
                                    <Select
                                        placeholder="Select Article..."
                                        onChange={handleChangeArticle}
                                        options={getArticleListData}
                                    />
                                </Form.Group>
                            </Col>
                        </Row> */}

            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Select Category *</b>
                  </Form.Label>
                  <Select
                    placeholder="Select Category..."
                    onChange={(e) => {
                      handleChangeCategory(e);
                    }}
                    options={getCategoryListData}
                  />
                </Form.Group>
              </Col>
            </Row>
            {show == true
              ? !isEmpty(getProcessListData) &&
                getProcessListData.map((x, index) => {
                  console.log(x, "xdataaaaa");
                  return (
                    <React.Fragment key={index}>
                      <Row>
                        <Col xl="4" lg="4" md="6" sm="6">
                          <input
                            type="checkbox"
                            id={`custom-checkbox-${index}`}
                            name="checkedStateValue"
                            value={x.value}
                            // checked={checkedState[index]}
                            onChange={(e) =>
                              handleOnChangeRate(index, e, "checkBoxValue")
                            }
                          />
                          <span style={{ paddingLeft: "2%" }}>{x.label}</span>
                        </Col>

                        <Col xl="4" lg="4" md="12" sm="12">
                          <Form.Group className="mb-3">
                            <Form.Label>
                              <b>Add Rate:</b>
                            </Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Add Rate...."
                              name="rate"
                              id={`custom-rate-${index}`}
                              onChange={(e) =>
                                handleOnChangeRate(index, e, "intRate")
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col xl="4" lg="4" md="12" sm="12">
                          <Form.Group>
                            <Form.Label>
                              <b>Select Employee</b>
                            </Form.Label>
                            <Select
                              placeholder="Select Employee..."
                              onChange={(e) =>
                                handleOnChangeRate(index, e, "employee")
                              }
                              options={getEmployeeListData}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </React.Fragment>
                  );
                })
              : ""}
            {/* {inputField.map((item, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <Row>

                                        <Col xl="6" lg="6" md="12" md="12" sm="12">
                                            <Form.Group>
                                                <Form.Label>
                                                    <b> Select Process</b>
                                                </Form.Label>
                                                <Select
                                                    //value={wareHouseId}
                                                    placeholder="Select Process..."
                                                    name="inventoryId"
                                                    onChange={(e) =>
                                                        handleChange(e, i, 'intname')}
                                                    options={getProcessListData}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col xl="6" lg="6" md="12" md="12" sm="12">
                                            <Form.Group className="mb-3">
                                                <Form.Label><b>Add Rate:</b></Form.Label>
                                                <Form.Control type="number" placeholder="Add Rate...." name="processRate" value={item.processRate} onChange={e => handleChange(e, i, 'intquantity')} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <FormGroup>
                                        <Row>

                                            <Col lg="12">
                                                {inputField.length - 1 == i && <IconButton title="Click to Add Demand"
                                                    onClick={handleAddInput}
                                                    style={{
                                                        float: "right",
                                                        color: "black",
                                                    }}
                                                >
                                                    <AddIcon />
                                                </IconButton>}

                                                {inputField.length !== 1 && <IconButton title="Click to Remove Demand"
                                                    onClick={() => handleRemoveInput(i)}
                                                    style={{ float: "right", color: "black" }}
                                                >
                                                    <RemoveIcon />
                                                </IconButton>}
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </React.Fragment>
                            );
                        })} */}

            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Total Cost:</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Cost...."
                    name="productCost"
                    value={
                      !isEmpty(checkedState)
                        ? checkedState.reduce(
                            (a, b) => (a = a + Number(b.rate)),
                            0
                          )
                        : 0
                    }
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv">
              {props.isAddingArticleMaterial ? (
                <div className="loader-div">
                  <Spinner
                    variant="primary"
                    animation="grow"
                    size="xl"
                    role="status"
                    aria-hidden="true"
                  />
                </div>
              ) : (
                <Button
                  className="sendButton"
                  style={{
                    marginLeft: "2%",
                    color: "white",
                    width: "20%",
                    backgroundColor: "black",
                    border: "1px solid black",
                  }}
                  onClick={() => saveArticleMaterial()}
                >
                  Save
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getArticle: () => dispatch(getArticle()),
    getEmployeeData: () => dispatch(getEmployeeData()),
    getProcessData: () => dispatch(getProcessData()),
    addLabourData: (data) => dispatch(addLabourData(data)),
    getProcessCategoryData: () => dispatch(getProcessCategoryData()),
    getProcessDataByCategory: (data) =>
      dispatch(getProcessDataByCategory(data)),
    getArticleIds: () => dispatch(getArticleIds()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  getArticleList: state.AddLabourReducer.getArticleList,
  employeeList: state.AddLabourReducer.employeeList,
  processList: state.AddLabourReducer.processList,
  categoryProcessList: state.AddLabourReducer.categoryProcessList,
  processListByCategoty: state.AddLabourReducer.processListByCategoty,
  //  ArticleIds: state.productionReducer.ArticleIds,
  ArticleIds: state.AddArticleReducer.ArticleIds,
  isAddingLabourData: state.AddLabourReducer.isAddingLabourData,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddLabour);
