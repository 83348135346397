const initialState = {
    isFetchingAddProduct: false,
    isFetchingGetProduct: false,
    productDetail: {
        product: "",
    },
    productInfo: [],

}


const productReducer = (state = initialState, action) => {
    switch (action.type) {
             //Product
        case "REQUEST_ADD_PRODUCT":
            return { ...state, isFetchingAddProduct: true };

        case "SUCCESS_ADD_PRODUCT":
            return {
                ...state, isFetchingAddProduct: false,
                productDetail: {
                    product: action.payload.name,
                },
            };

        case "ERROR_ADD_PRODUCT":
            return { ...state, isFetchingAddProduct: false };

        //Get Product
        case "REQUEST_GET_PRODUCT":
            return { ...state, isFetchingGetProduct: true };

        case "SUCCESS_GET_PRODUCT":
            return {
                ...state, isFetchingGetProduct: false,
                productInfo: action.payload.data,
            };

        case "ERROR_GET_PRODUCT":
            return { ...state, isFetchingGetProduct: false };

        default:
            return state;
    }
}

export default productReducer;
