import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import moment from 'moment'
import { Redirect } from 'react-router-dom';
import Swal from 'sweetalert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { isEmpty, filter, find } from 'lodash'
import { Row, Col, Form, Container, Button, Alert, Modal } from "react-bootstrap";
import { getUsers, getPermissions, addPermissions } from "./UserPermissions.actions";
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';

const UserPermissions = (props) => { 

    const userData = props.usersList;

    const [show, setShow] = useState(false);

    const [users, setUsers] = useState("");
    const [mainCategory1, setMainCategory1] = useState();
    const [mainCategory2, setMainCategory2] = useState();
    const [mainCategory3, setMainCategory3] = useState();
    const [mainCategory4, setMainCategory4] = useState();

    const [checkedData, setChecked] = useState([]);
    const [checkedData1, setChecked1] = useState([]);
    const [checkedData2, setChecked2] = useState([]);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
    const handleChangeUsers = (selectedOption) => {
        setUsers(selectedOption.value);
    };

   
    const handleCheck = (event) => {
        var updatedList = [...checkedData];
        if (event.target.checked) {
            let data = !isEmpty(props.permissionsList) && props.permissionsList[0].permission_cats.find(x => x.permissions_cat_id == event.target.value).permission_sub_cats.map((y) => { return y.permissions_sub_cat_id })
            isEmpty(data) ?
                updatedList.push({
                    id: event.target.value,
                    main_id: props.permissionsList[0].permissions_id,
                })
                :
                data.map((z) => {
                    updatedList.push({
                        id: event.target.value,
                        main_id: props.permissionsList[0].permissions_id,
                        sub_id: z
                    });
                });
        } else {
            updatedList = updatedList.filter(e => e.id != `${event.target.value}`);
        }
        setChecked(updatedList);
    };

    const handleCheckSub = (event, pt) => {
        var updatedList = [...checkedData];
        if (event.target.checked) {
            updatedList.push({
                id: pt.permissions_cat_id,
                main_id: props.permissionsList[0].permissions_id,
                sub_id: event.target.value
            });

        } else {
            updatedList = updatedList.filter(e => e.sub_id != `${event.target.value}`);
        }
        setChecked(updatedList);
    };

    const handleCheck1 = (event) => {
        var updatedList = [...checkedData];
        if (event.target.checked) {
            let data = !isEmpty(props.permissionsList) && props.permissionsList[1].permission_cats.find(x => x.permissions_cat_id == event.target.value).permission_sub_cats.map((y) => { return y.permissions_sub_cat_id })
            data.map((z) => {
                updatedList.push({
                    id: event.target.value,
                    main_id: props.permissionsList[1].permissions_id,
                    sub_id: z
                });
            });
        } else {
            updatedList = updatedList.filter(e => e.id != `${event.target.value}`);
        }
        setChecked(updatedList);
    };

    const handleCheckSub1 = (event, pt) => {
        var updatedList = [...checkedData];
        if (event.target.checked) {
            updatedList.push({
                id: pt.permissions_cat_id,
                main_id: props.permissionsList[1].permissions_id,
                sub_id: event.target.value
            });

        } else {
            updatedList = updatedList.filter(e => e.sub_id != `${event.target.value}`);
        }
        setChecked(updatedList);
    };

    const handleCheck2 = (event, pt) => {
        var updatedList = [...checkedData];
        if (event.target.checked) {
            let data = !isEmpty(props.permissionsList) && props.permissionsList[2].permission_cats.find(x => x.permissions_cat_id == event.target.value).permission_sub_cats.map((y) => { return y.permissions_sub_cat_id })
            data.map((z) => {
                updatedList.push({
                    id: event.target.value,
                    main_id: props.permissionsList[2].permissions_id,
                    sub_id: z
                });
            });
        } else {
            updatedList = updatedList.filter(e => e.id != `${event.target.value}`);
        }
        setChecked(updatedList);
    };

    const handleCheckSub2 = (event, pt) => {
        var updatedList = [...checkedData];
        if (event.target.checked) {
            updatedList.push({
                id: pt.permissions_cat_id,
                main_id: props.permissionsList[2].permissions_id,
                sub_id: event.target.value
            });

        } else {
            updatedList = updatedList.filter(e => e.sub_id != `${event.target.value}`);
        }
        setChecked(updatedList);
    };
    const handleInventory = (event) => {
        setMainCategory1(event.target.value);
        var updatedList = [...checkedData];
        if (event.target.checked) {
            !isEmpty(props.permissionsList) && props.permissionsList[0].permission_cats.map((x) => {
                 updatedList.push({
                     id: x.permissions_cat_id,
                     main_id: props.permissionsList[0].permissions_id, 
                 });
                !isEmpty(x.permission_sub_cats) && x.permission_sub_cats.map((sub) => {
                    updatedList.push({
                        id: x.permissions_cat_id,
                        main_id: props.permissionsList[0].permissions_id,
                        sub_id: sub.permissions_sub_cat_id,
                    });
                });
            });
             updatedList.push({
                 id: event.target.value,
             });
        } else {
            !isEmpty(props.permissionsList) && props.permissionsList[0].permission_cats.map((x) => {
                !isEmpty(x.permission_sub_cats) && x.permission_sub_cats.map((sub) => {
                    updatedList = updatedList.filter(e => e.id != x.permissions_cat_id)
                    updatedList = updatedList.filter(e => e.id != sub.permissions_sub_cat_id)
                });
            });
            updatedList = updatedList.filter(e => e.id != `${event.target.value}`);
        }
        setChecked(updatedList);
    };

    const handleAccounts = (event) => {
        setMainCategory2(event.target.value);
        var updatedList = [...checkedData];
        if (event.target.checked) {
            !isEmpty(props.permissionsList) && props.permissionsList[1].permission_cats.map((x) => {
                 updatedList.push({
                     id: x.permissions_cat_id,
                     main_id: props.permissionsList[1].permissions_id, 
                 });
                !isEmpty(x.permission_sub_cats) && x.permission_sub_cats.map((sub) => {
                    updatedList.push({
                        id: x.permissions_cat_id,
                        main_id: props.permissionsList[1].permissions_id,
                        sub_id: sub.permissions_sub_cat_id,
                    });
                });
            });
             updatedList.push({
                 id: event.target.value,
             });
        } else {
            !isEmpty(props.permissionsList) && props.permissionsList[1].permission_cats.map((x) => {
                !isEmpty(x.permission_sub_cats) && x.permission_sub_cats.map((sub) => {
                    updatedList = updatedList.filter(e => e.id != x.permissions_cat_id)
                    updatedList = updatedList.filter(e => e.id != sub.permissions_sub_cat_id)
                });
            });
            updatedList = updatedList.filter(e => e.id != `${event.target.value}`);
        }
        setChecked(updatedList);
    };


    const handleHR = (event) => {
        setMainCategory3(event.target.value);
        var updatedList = [...checkedData];
        if (event.target.checked) {
            !isEmpty(props.permissionsList) && props.permissionsList[2].permission_cats.map((x) => {
                 updatedList.push({
                     id: x.permissions_cat_id,
                     main_id: props.permissionsList[2].permissions_id, 
                 });
                !isEmpty(x.permission_sub_cats) && x.permission_sub_cats.map((sub) => {
                    updatedList.push({
                        id: x.permissions_cat_id,
                        main_id: props.permissionsList[2].permissions_id,
                        sub_id: sub.permissions_sub_cat_id,
                    });
                });
            });
             updatedList.push({
                 id: event.target.value,
             });
        } else {
            !isEmpty(props.permissionsList) && props.permissionsList[2].permission_cats.map((x) => {
                !isEmpty(x.permission_sub_cats) && x.permission_sub_cats.map((sub) => {
                    updatedList = updatedList.filter(e => e.id != x.permissions_cat_id)
                    updatedList = updatedList.filter(e => e.id != sub.permissions_sub_cat_id)
                });
            });
            updatedList = updatedList.filter(e => e.id != `${event.target.value}`);
        }
        setChecked(updatedList);
    };
    const handleMarquee = (event) => {
        setMainCategory4(event.target.value);
        var updatedList = [...checkedData];
        if (event.target.checked) {
            !isEmpty(props.permissionsList) && props.permissionsList[3].permission_cats.map((x) => {
                 updatedList.push({
                     id: x.permissions_cat_id,
                     main_id: props.permissionsList[3].permissions_id, 
                 });
                !isEmpty(x.permission_sub_cats) && x.permission_sub_cats.map((sub) => {
                    updatedList.push({
                        id: x.permissions_cat_id,
                        main_id: props.permissionsList[3].permissions_id,
                        sub_id: sub.permissions_sub_cat_id,
                    });
                });
            });
             updatedList.push({
                 id: event.target.value,
             });
        } else {
            !isEmpty(props.permissionsList) && props.permissionsList[3].permission_cats.map((x) => {
                !isEmpty(x.permission_sub_cats) && x.permission_sub_cats.map((sub) => {
                    updatedList = updatedList.filter(e => e.id != x.permissions_cat_id)
                    updatedList = updatedList.filter(e => e.id != sub.permissions_sub_cat_id)
                });
            });
            updatedList = updatedList.filter(e => e.id != `${event.target.value}`);
        }
        setChecked(updatedList);
    };
    const handleCheck3 = (event, pt) => {
        var updatedList = [...checkedData];
        if (event.target.checked) {
            let data = !isEmpty(props.permissionsList) && props.permissionsList[3].permission_cats.find(x => x.permissions_cat_id == event.target.value).permission_sub_cats.map((y) => { return y.permissions_sub_cat_id })
            data.map((z) => {
                updatedList.push({
                    id: event.target.value,
                    main_id: props.permissionsList[3].permissions_id,
                    sub_id: z
                });
            });
        } else {
            updatedList = updatedList.filter(e => e.id != `${event.target.value}`);
        }
        setChecked(updatedList);
    };

    const handleCheckSub3 = (event, pt) => {
        var updatedList = [...checkedData];
        if (event.target.checked) {
            updatedList.push({
                id: pt.permissions_cat_id,
                main_id: props.permissionsList[3].permissions_id,
                sub_id: event.target.value
            });

        } else {
            updatedList = updatedList.filter(e => e.sub_id != `${event.target.value}`);
        }
        setChecked(updatedList);
    };

    //   const handleInventory = (event) => {
    //     setMainCategory1(event.target.value);
    //     let arrayData = arr;
    //     if(!isEmpty(filter(arrayData, x => x.id == `${event.target.value}`))) {
    //       
    //         var filteredArray=arrayData;
    //         data.inventory.map((x) => {
    //          filteredArray = filteredArray.filter(e => e.id != x.id)
    //         });
    //         filteredArray = filteredArray.filter(e => e.id != `${event.target.value}`)
    //         
    //         setArr(filteredArray)
    //     }
    //     else {
    //        
    //         data.inventory.map((x) => {
    //             arrayData.push({
    //                 id: x.id,
    //             });
    //         });
    //         arrayData.push({id: event.target.value})
    //       
    //         setArr(arrayData);
    //     }
    //  };


    const [state, setState] = React.useState({
        columns: [
            { title: 'User Name', field: 'username' },
        ],

        data: [
            { username: 'Zahra' }
        ]
    });

    const data = {

        MainCategory1: "Inventory",
        MainCategory1Id: 1,
        MainCategory2: "Accounts",
        MainCategory3: "HR",
        inventory: [
            {
                id: "2",
                name: "warehouse",
                arr: [
                    {
                        id: "2",
                        name: "warehouse"
                    },
                    {
                        id: "2",
                        name: "warehouse"
                    }
                ]
            },
            {
                id: "3",
                name: "Category"
            },
        ],
        accounts: [
            {
                id: "4",
                name: "Level 2"
            },
            {
                id: "5",
                name: "Level 3"
            },
        ],
        hr: [
            {
                id: "6",
                name: "Employee"
            }
        ],
    };
    const handleVisible = () => {
        setAlertVisible(true)
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: "Added successfully...",
            icon: "success",
            button: "Ok",
        });
    }

    const handleDangerVisible = () => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: "Something went wrong...",
            icon: "error",
            button: "Ok",
        });
    }
    useEffect(() => {
        props.getUsers();
        props.getPermissions();
    }, []);

    const sendData = () => {
        props.addPermissions({
            userId: users,
            permissions: checkedData
        },handleVisible,handleDangerVisible);
    };

    const getUsersData = props.usersList.map((x) => { let data = { value: x.id, label: x.username }; return data });

    return (

        <>
            {props.isFetchingUsersList || props.isFetchingPermissionsList ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {props.isSavingLevel2 ? "Saving" : "Loading"}
                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">

                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>
                                        <b> Select User</b>
                                    </Form.Label>
                                    <Select
                                        placeholder="Select User..."
                                        // value={{label:level1label}}
                                        onChange={(e) => handleChangeUsers(e)}
                                        options={getUsersData}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <h2>User Permissions</h2>


                        <Row>
                            <Col lg="3" md="3" sm="12">
                                <div style={{ marginLeft: "5%" }}>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexCheckDefault"
                                        value={!isEmpty(props.permissionsList) && props.permissionsList[0].permissions_id}
                                        onChange={(event) => handleInventory(event)}
                                    />
                                    <label className="form-check-label" for="flexCheckDefault">
                                        {!isEmpty(props.permissionsList) && props.permissionsList[0].permissions_name}
                                    </label>
                                </div>
                                {!isEmpty(props.permissionsList) && props.permissionsList[0].permission_cats.map((inv, index) => {
                                    return (
                                        <Row>
                                            <Col lg="12" md="12" sm="12">
                                                <div style={{ marginLeft: "15%" }}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={inv.permissions_cat_id}
                                                        checked={!isEmpty(checkedData) ? find(checkedData, x => x.id == inv.permissions_cat_id) : false}
                                                        id="flexCheckDefault"
                                                        onChange={(e) => handleCheck(e)}
                                                    />
                                                    <label className="form-check-label" for="flexCheckDefault">
                                                        {inv.permissions_cat_name}
                                                    </label>
                                                    {!isEmpty(inv.permission_sub_cats) && inv.permission_sub_cats.map((sub, index) => {
                                                        return (
                                                            <Row>
                                                                <Col lg="12" md="12" sm="12">
                                                                    <div style={{ marginLeft: "15%" }}>
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            value={sub.permissions_sub_cat_id}
                                                                            checked={!isEmpty(checkedData) ? find(checkedData, x => x.sub_id == sub.permissions_sub_cat_id) : false}
                                                                            id="flexCheckDefault"
                                                                            onChange={(e) => handleCheckSub(e, inv)}
                                                                        />
                                                                        <label className="form-check-label" for="flexCheckDefault">
                                                                            {sub.permissions_sub_cat_name}
                                                                        </label>

                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        );
                                                    })}
                                                </div>
                                            </Col>
                                        </Row>
                                    );
                                })}
                            </Col>

                            <Col lg="3" md="3" sm="12">
                                <div style={{ marginLeft: "5%" }}>
                                    <input
                                        className="form-check-input"
                                        type="checkbox" value={!isEmpty(props.permissionsList) && props.permissionsList[1].permissions_id}
                                        id={""}
                                        onChange={(event) => handleAccounts(event)}
                                    />
                                    <label className="form-check-label" for="flexCheckDefault">
                                        {!isEmpty(props.permissionsList) && props.permissionsList[1].permissions_name}
                                    </label>
                                </div>
                                {!isEmpty(props.permissionsList) && props.permissionsList[1].permission_cats.map((acc, index) => {
                                    return (
                                        <Row>
                                            <Col lg="12" md="12" sm="12">
                                                <div style={{ marginLeft: "15%" }}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={acc.permissions_cat_id}
                                                        id="flexCheckDefault"
                                                        checked={!isEmpty(checkedData) ? find(checkedData, x => x.id == acc.permissions_cat_id) : false}
                                                        onChange={(e) => handleCheck1(e)}
                                                    />
                                                    <label className="form-check-label" for="flexCheckDefault">
                                                        {acc.permissions_cat_name}
                                                    </label>
                                                    {!isEmpty(acc.permission_sub_cats) && acc.permission_sub_cats.map((sub, index) => {
                                                        return (
                                                            <Row>
                                                                <Col lg="12" md="12" sm="12">
                                                                    <div style={{ marginLeft: "15%" }}>
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            value={sub.permissions_sub_cat_id}
                                                                            checked={!isEmpty(checkedData) ? find(checkedData, x => x.sub_id == sub.permissions_sub_cat_id) : false}
                                                                            id="flexCheckDefault"
                                                                            onChange={(e) => handleCheckSub1(e, acc)}
                                                                        />
                                                                        <label className="form-check-label" for="flexCheckDefault">
                                                                            {sub.permissions_sub_cat_name}
                                                                        </label>

                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        );
                                                    })}
                                                </div>
                                            </Col>
                                        </Row>
                                    );
                                })}
                            </Col>

                            <Col lg="3" md="3" sm="12">
                                <div style={{ marginLeft: "5%" }}>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={!isEmpty(props.permissionsList) && props.permissionsList[2].permissions_id}
                                        id="flexCheckDefault"
                                        onChange={(event) => handleHR(event)}
                                    />
                                    <label className="form-check-label" for="flexCheckDefault">
                                        {!isEmpty(props.permissionsList) && props.permissionsList[2].permissions_name}
                                    </label>
                                </div>
                                {!isEmpty(props.permissionsList) && props.permissionsList[2].permission_cats.map((val, index) => {
                                    return (
                                        <Row>
                                            <Col lg="12" md="12" sm="12">
                                                <div style={{ marginLeft: "15%" }}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={val.permissions_cat_id}
                                                        id="flexCheckDefault"
                                                        checked={!isEmpty(checkedData) ? find(checkedData, x => x.id == val.permissions_cat_id) : false}
                                                        onChange={(e) => handleCheck2(e)}
                                                    />
                                                    <label className="form-check-label" for="flexCheckDefault">
                                                        {val.permissions_cat_name}
                                                    </label>
                                                    {!isEmpty(val.permission_sub_cats) && val.permission_sub_cats.map((sub, index) => {
                                                        return (
                                                            <Row>
                                                                <Col lg="12" md="12" sm="12">
                                                                    <div style={{ marginLeft: "15%" }}>
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            value={sub.permissions_sub_cat_id}
                                                                            checked={!isEmpty(checkedData) ? find(checkedData, x => x.sub_id == sub.permissions_sub_cat_id) : false}
                                                                            id="flexCheckDefault"
                                                                            onChange={(e) => handleCheckSub2(e, val)}
                                                                        />
                                                                        <label className="form-check-label" for="flexCheckDefault">
                                                                            {sub.permissions_sub_cat_name}
                                                                        </label>

                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        );
                                                    })}
                                                </div>
                                            </Col>
                                        </Row>
                                    );
                                })}
                            </Col>
                            <Col lg="3" md="3" sm="12">
                                <div style={{ marginLeft: "5%" }}>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={!isEmpty(props.permissionsList) && props.permissionsList[3].permissions_id}
                                        id="flexCheckDefault"
                                        onChange={(event) => handleMarquee(event)}
                                    />
                                    <label className="form-check-label" for="flexCheckDefault">
                                        {!isEmpty(props.permissionsList) && props.permissionsList[3].permissions_name}
                                    </label>
                                </div>
                                {!isEmpty(props.permissionsList) && props.permissionsList[3].permission_cats.map((val, index) => {
                                    return (
                                        <Row>
                                            <Col lg="12" md="12" sm="12">
                                                <div style={{ marginLeft: "15%" }}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={val.permissions_cat_id}
                                                        id="flexCheckDefault"
                                                        checked={!isEmpty(checkedData) ? find(checkedData, x => x.id == val.permissions_cat_id) : false}
                                                        onChange={(e) => handleCheck3(e)}
                                                    />
                                                    <label className="form-check-label" for="flexCheckDefault">
                                                        {val.permissions_cat_name}
                                                    </label>
                                                    {!isEmpty(val.permission_sub_cats) && val.permission_sub_cats.map((sub, index) => {
                                                        return (
                                                            <Row>
                                                                <Col lg="12" md="12" sm="12">
                                                                    <div style={{ marginLeft: "15%" }}>
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            value={sub.permissions_sub_cat_id}
                                                                            checked={!isEmpty(checkedData) ? find(checkedData, x => x.sub_id == sub.permissions_sub_cat_id) : false}
                                                                            id="flexCheckDefault"
                                                                            onChange={(e) => handleCheckSub3(e, val)}
                                                                        />
                                                                        <label className="form-check-label" for="flexCheckDefault">
                                                                            {sub.permissions_sub_cat_name}
                                                                        </label>

                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        );
                                                    })}
                                                </div>
                                            </Col>
                                        </Row>
                                    );
                                })}
                            </Col>
                        </Row>


                        <Row style={{textAlign:'center'}}>
                            <Col lg="12" md="12" sm="12">
                                <Button disabled={users==""?true:false} onClick={() => sendData()}>Send</Button>
                            </Col>
                        </Row>

                    </div>

                </Container>

            )}
        </>

        // <>
        //     {props.isFetchingUsersList || props.isFetchingPermissionsList ? (
        //         <div className="loader-div">
        //             <Button variant="info" disabled>
        //                 <Spinner
        //                     animation="grow"
        //                     size="sm"
        //                     role="status"
        //                     aria-hidden="true"
        //                     variant="info"
        //                 />
        //                 {props.isSavingLevel2 ? "Saving" : "Loading"}
        //             </Button>
        //         </div>
        //     ) : (
        //         <Container fluid>
        //             <div className="main">
        //                 <MaterialTable
        //                     title="Users"
        //                     columns={state.columns}
        //                     data={userData}
        //                     //data={state.data}
        //                     actions={[
        //                         {
        //                             tooltip: 'User Permissions',
        //                             onClick: (event, rowData) => alert("You saved")
        //                         }
        //                     ]}
        //                     components={{
        //                         Action: props => (
        //                             <Button
        //                                 onClick={() => handleShow()}
        //                             >
        //                                 Permissions
        //                             </Button> 
        //                         ),
        //                     }}
        //                     options={{
        //                         actionsColumnIndex: -1,
        //                         paging: true,
        //                         pageSize: 200,       // make initial page size
        //                         emptyRowsWhenPaging: false,   // To avoid of having empty rows
        //                         pageSizeOptions: [50, 100, 150, 200],
        //                         headerStyle: {
        //                             position: 'sticky', top: 0,
        //                             color: '#00BBBB',
        //                             fontWeight: '550',
        //                             onRowAdd: 'none',
        //                         }
        //                     }} 
        //                 />

        //                 <Modal size="lg" show={show} onHide={handleClose} animation={true}>
        //                     <Modal.Header closeButton>
        //                         <Modal.Title>User Permissions</Modal.Title>
        //                     </Modal.Header>
        //                     <Modal.Body>
        //                         <Row>
        //                             <Col lg="4" md="6" sm="12">
        //                                 <div style={{ marginLeft: "5%" }}>
        //                                     <input
        //                                         className="form-check-input"
        //                                         type="checkbox"
        //                                         id="flexCheckDefault"
        //                                         value={!isEmpty(props.permissionsList) && props.permissionsList[0].permissions_id}
        //                                         onChange={(event) => handleInventory(event)}
        //                                     />
        //                                     <label className="form-check-label" for="flexCheckDefault">
        //                                     {!isEmpty(props.permissionsList) && props.permissionsList[0].permissions_name}
        //                                     </label>
        //                                 </div>
        //                                 {!isEmpty(props.permissionsList) && props.permissionsList[0].permission_cats.map((inv, index) => {
        //                                     return (
        //                                         <Row>
        //                                             <Col lg="12" md="12" sm="12">
        //                                                 <div style={{ marginLeft: "15%" }}>
        //                                                     <input
        //                                                         className="form-check-input"
        //                                                         type="checkbox"
        //                                                         value={inv.permissions_cat_id}
        //                                                         checked={!isEmpty(checkedData) ? find(checkedData, x => x.id == inv.permissions_cat_id) : false}
        //                                                         id="flexCheckDefault"
        //                                                         onChange={(e) => handleCheck(e)}
        //                                                     />
        //                                                     <label className="form-check-label" for="flexCheckDefault">
        //                                                         {inv.permissions_cat_name}
        //                                                     </label>
        //                                                 </div>
        //                                             </Col>
        //                                         </Row>
        //                                     );
        //                                 })}
        //                             </Col>

        //                             <Col lg="4" md="6" sm="12">
        //                                 <div style={{ marginLeft: "5%" }}>
        //                                     <input
        //                                         className="form-check-input"
        //                                         type="checkbox" value={!isEmpty(props.permissionsList) && props.permissionsList[1].permissions_id}
        //                                         id={""} 
        //                                         onChange={(event) => handleAccounts(event)}
        //                                         />
        //                                     <label className="form-check-label" for="flexCheckDefault">
        //                                         {!isEmpty(props.permissionsList) && props.permissionsList[1].permissions_name}
        //                                     </label>
        //                                 </div>
        //                                 {!isEmpty(props.permissionsList) && props.permissionsList[1].permission_cats.map((acc, index) => {
        //                                     return (
        //                                         <Row>
        //                                             <Col lg="12" md="12" sm="12">
        //                                                 <div style={{ marginLeft: "15%" }}>
        //                                                     <input
        //                                                         className="form-check-input"
        //                                                         type="checkbox"
        //                                                         value={acc.permissions_cat_id}
        //                                                         id="flexCheckDefault"
        //                                                         checked={!isEmpty(checkedData) ? find(checkedData, x => x.id == acc.permissions_cat_id) : false}
        //                                                         onChange={(e) => handleCheck1(e)}
        //                                                     />
        //                                                     <label className="form-check-label" for="flexCheckDefault">
        //                                                         {acc.permissions_cat_name}
        //                                                     </label>
        //                                                 </div>
        //                                             </Col>
        //                                         </Row>
        //                                     );
        //                                 })}
        //                             </Col>

        //                             <Col lg="4" md="6" sm="12">
        //                                 <div style={{ marginLeft: "5%" }}>
        //                                     <input
        //                                         className="form-check-input"
        //                                         type="checkbox"
        //                                         value={!isEmpty(props.permissionsList) && props.permissionsList[2].permissions_id}
        //                                         id="flexCheckDefault"
        //                                         onChange={(event) => handleHR(event)}
        //                                     />
        //                                     <label className="form-check-label" for="flexCheckDefault">
        //                                         {!isEmpty(props.permissionsList) && props.permissionsList[2].permissions_name}
        //                                     </label>
        //                                 </div>
        //                                 {!isEmpty(props.permissionsList) && props.permissionsList[2].permission_cats.map((val, index) => {
        //                                     return (
        //                                         <Row>
        //                                             <Col lg="12" md="12" sm="12">
        //                                                 <div style={{ marginLeft: "15%" }}>
        //                                                     <input
        //                                                         className="form-check-input"
        //                                                         type="checkbox"
        //                                                         value={val.permissions_cat_id}
        //                                                         id="flexCheckDefault"
        //                                                         checked={!isEmpty(checkedData) ? find(checkedData, x => x.id == val.permissions_cat_id) : false}
        //                                                         onChange={(e) => handleCheck2(e)}
        //                                                     />
        //                                                     <label className="form-check-label" for="flexCheckDefault">
        //                                                         {val.permissions_cat_name}
        //                                                     </label>
        //                                                 </div>
        //                                             </Col>
        //                                         </Row>
        //                                     );
        //                                 })}
        //                             </Col>
        //                         </Row>


        //                     </Modal.Body>
        //                     <Modal.Footer>
        //                         <Button variant="secondary" onClick={handleClose}>
        //                             Close
        //                         </Button>
        //                         <Button variant="primary" onClick={handleClose}>
        //                             Send
        //                         </Button>
        //                     </Modal.Footer>
        //                 </Modal>
        //             </div>

        //         </Container>

        //     )}
        // </>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        getUsers: () => dispatch(getUsers()),
        getPermissions: () => dispatch(getPermissions()),
        addPermissions: (data,handleVisible, handleDangerVisible) => dispatch(addPermissions(data,handleVisible, handleDangerVisible))
    }
}

const mapStateToProps = (state, ownProps) => ({
    usersList: state.permissionReducer.usersList,
    permissionsList: state.permissionReducer.permissionsList,
    isFetchingUsersList: state.permissionReducer.isFetchingUsersList,
    isFetchingPermissionsList: state.permissionReducer.isFetchingPermissionsList
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPermissions);