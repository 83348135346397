import React, { useEffect, useState } from "react";
import { Container, Button } from "react-bootstrap";
import "./admin-details.css";
import { connect } from "react-redux";
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import AdminDemandModel from "./AdminDemandModel";
import MaterialTable from 'material-table';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { getAdminPurchase, updatePurchaseStatus, updatePurchaseStatus1, updatePurchase, deletePurchaseMaterial, deletePurchase } from "./adminDetails.action";
import Swal from "sweetalert";
const PurchaseOrderVerification = (props) => {
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
    const handleDangerVisible = (msg) => {
        setDangerVisibleAlert(true);
        setTimeout(() => {
          setDangerVisibleAlert(false);
        }, 9000);
        Swal({
          title: "Error",
          text: `${msg}`,
          icon: "error",
          button: "Ok",
        });
      };

    const purchaseDetails = props.purchaseAdminList;
    const [state, setState] = React.useState({
        columns: [
            { title: 'Purchase Name', field: 'purchaseName' },
            { title: 'Purchase Description', field: 'PODescription' },
            { title: 'Status', field: 'status' },
            { title: 'Demand Name', field: 'create_demand.demandName' },
            { title: 'Created Date', field: 'createdAt', render: rowData => moment(rowData.createdAt).format("YYYY-MM-DD") },

        ],
        columnsData: [
            { title: 'Inventory Code', field: 'inventory', render: rowData => rowData.inventory.warehouse.whKey + "-" + rowData.inventory.category.cateKey + "-" + rowData.inventory.sub_category.subcateKey + "-" + rowData.inventory.invKey, editable: 'never' },
            { title: 'Inventory Name', field: 'inventory.productName', editable: 'never' },
            { title: 'Product Quantity', field: 'productQuantity' },
            { title: 'Remaining Quantity', field: 'remainingQuantity', editable: 'never' },
        ],
    });
    useEffect(() => {
        props.getAdminPurchase();
    }, []);
    return (
        <>
            {props.isFetchingadminPurchaseList ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />

                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <MaterialTable
                            title="Purchase Verification"
                            columns={[
                                { title: 'Purchase Order#', field: 'purchaseOrderId' },
                                { title: "Status", field: "status" },
                                { title: 'Purchase Manual#', field: 'purchaseOrderId' },
                                { title: 'Purchaser Name', field: 'purchaseName' },
                                { title: 'Description', field: 'PODescription' },
                                { title: 'Demand A/M', field: 'create_demand.demandName' },
                                { title: 'Tax', field: 'purchaseTax' },
                                // { title: 'Creation Date', field: 'createdAt', render: rowData => moment(rowData.createdAt).format("YYYY-MM-DD") },
                            ]}
                            data={purchaseDetails.reverse()}
                            detailPanel={rowData => {
                                console.log(rowData, "rowwwwwww");
                                return (
                                    <MaterialTable
                                        title="Product Details"
                                        columns={[
                                            { title: 'Inventory Code', field: 'inventory', render: rowData => rowData.inventory.warehouse.whKey + "-" + rowData.inventory.category.cateKey + "-" + rowData.inventory.sub_category.subcateKey + "-" + rowData.inventory.invKey, editable: 'never' },
                                            { title: 'Inventory Name', field: 'inventory.productName', editable: 'never' },
                                            { title: 'Product Quantity', field: 'productQuantity' },
                                            { title: 'Remaining Quantity', field: 'remainingQuantity', editable: 'never' },
                                        ]}
                                        data={rowData.purchase_data}
                                        options={{
                                            actionsColumnIndex: -1,
                                            toolbar: false,
                                            exportButton: true,
                                            sorting: true,
                                            paging: false,
                                            pageSize: 200,       // make initial page size
                                            emptyRowsWhenPaging: false,   // To avoid of having empty rows
                                            pageSizeOptions: [50, 100, 150, 200],
                                            headerStyle: {
                                                position: 'sticky', top: 0,
                                                color: '#00BBBB',
                                                fontWeight: '550',
                                                onRowAdd: 'none',
                                            }
                                        }}
                                        editable={{
                                            isEditable: x => rowData.status == "Approve" ? false : true,
                                            onRowUpdate: (newData, oldData) =>
                                                new Promise((resolve) => {
                                                    setTimeout(() => {
                                                        resolve();
                                                        {
                                                            props.updatePurchase(newData, oldData,handleDangerVisible)
                                                        }
                                                    }, 600);
                                                }),
                                            isDeletable: x => rowData.status == "Approve" ? false : true,
                                            onRowDelete: (data) =>
                                                new Promise((resolve) => {
                                                    setTimeout(() => {
                                                        resolve();
                                                        props.deletePurchaseMaterial(data);
                                                    }, 600);
                                                }),
                                        }}

                                    />
                                )
                            }}
                            actions={[
                                (rowData) => {
                                    return rowData.status !== "pending"
                                        ? { icon: DoneIcon, disabled: true }
                                        : { icon: DoneIcon, disabled: false, onClick: (event, rowData) => { props.updatePurchaseStatus(rowData) } }
                                },
                                (rowData) => {
                                    return rowData.status !== "pending"
                                        ? { icon: ClearIcon, disabled: true }
                                        : { icon: ClearIcon, disabled: false, onClick: (event, rowData) => { props.updatePurchaseStatus1(rowData) } }
                                },
                            ]}
                            editable={{
                                isDeletable: rowData => rowData.status == "Approve" ? false : true,
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.deletePurchase(oldData.purchaseOrderId);
                                        }, 600);
                                    }),
                            }}
                            options={{
                                actionsColumnIndex: -1,
                                filtering: true,
                                exportButton: true,
                                paging: true,
                                pageSize: 200,       // make initial page size
                                emptyRowsWhenPaging: false,   // To avoid of having empty rows
                                pageSizeOptions: [50, 100, 150, 200],
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                }
                            }}
                            onRowClick={(event, rowData, togglePanel) => togglePanel()}

                        />
                    </div>
                </Container>
            )}

        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        getAdminPurchase: () => dispatch(getAdminPurchase()),
        updatePurchaseStatus1: (data) => dispatch(updatePurchaseStatus1(data)),
        updatePurchaseStatus: (data) => dispatch(updatePurchaseStatus(data)),
        updatePurchase: (newData, oldData,handleDangerVisible) => dispatch(updatePurchase(newData, oldData,handleDangerVisible)),
        deletePurchaseMaterial: (data) => dispatch(deletePurchaseMaterial(data)),
        deletePurchase: (purchaseOrderId) => dispatch(deletePurchase(purchaseOrderId))
    };
};
const mapStateToProps = (state, ownProps) => ({
    purchaseAdminList: state.adminDemandDetailReducer.purchaseAdminList,
    isFetchingadminPurchaseList: state.adminDemandDetailReducer.isFetchingadminPurchaseList
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PurchaseOrderVerification);