const initialState = {
    isFetchingAddColor: false,
    isFetchingGetColor: false,
    colorDetail: {
        color: "",
        description: "",
    },
    colorInfo: [],

}


const colorReducer = (state = initialState, action) => {
    switch (action.type) {
             //Color
        case "REQUEST_ADD_COLOR":
            return { ...state, isFetchingAddColor: true };

        case "SUCCESS_ADD_COLOR":
            return {
                ...state, isFetchingAddColor: false,
                colorDetail: {
                    color: action.payload.colorName,
                    description: action.payload.colorDescription,
                }
            };

        case "ERROR_ADD_COLOR":
            return { ...state, isFetchingAddColor: false };

        //Get Color
        case "REQUEST_GET_COLOR":
            return { ...state, isFetchingGetColor: true };

        case "SUCCESS_GET_COLOR":
            return {
                ...state, isFetchingGetColor: false,
                colorInfo: action.payload.data,
            };

        case "ERROR_GET_COLOR":
            return { ...state, isFetchingGetColor: false };

        default:
            return state;
    }
}

export default colorReducer;
