import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import {
  getProcessCall,
  updateProcess,
  deleteProcess,
} from "./addProcess.action";

const ViewProcess = (props) => {
  useEffect(() => {
    props.getProcessCall();
  }, []);

  return (
    <>
      {props.isFetchingGetProcess ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialTable
              title="Process Details"
              columns={[
                { title: "Category Name", field: "categoryProcessName" },
                { title: "Process Name", field: "processName" },
                { title: "Process Description", field: "processDescription" },
              ]}
              data={props.processInfo}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                exportButton: true,
                exportAllData: true,
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.updateProcess(newData, oldData);
                    }, 600);
                  }),

                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteProcess(oldData.categoryProcessId);
                    }, 600);
                  }),
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProcessCall: () => dispatch(getProcessCall()),
    updateProcess: (newData, oldData) =>
      dispatch(updateProcess(newData, oldData)),
    deleteProcess: (categoryProcessId) =>
      dispatch(deleteProcess(categoryProcessId)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  processInfo: state.processReducer.processInfo,
  isFetchingGetProcess: state.processReducer.isFetchingGetProcess,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewProcess);
