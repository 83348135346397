import AddArticleMaterial from "./AddArticleMaterial";

const initialState = {
  isFetchingGetInventoryAllData: false,
  getInventoryAllData: [],
  isFetchingArticle: false,
  getArticleList: [],
  isAddingArticleMaterial: false,
  addArticleMaterial: [],
};

const AddArticleMaterialReducer = (state = initialState, action) => {
  switch (action.type) {
    //Size
    case "REQUEST_GET_ALL_INVENTORY_DATA":
      return { ...state, isFetchingGetInventoryAllData: true };

    case "SUCCESS_GET_ALL_INVENTORY_DATA":
      return {
        ...state,
        isFetchingGetInventoryAllData: false,
        getInventoryAllData: action.payload,
      };

    case "ERROR_GET_ALL_INVENTORY_DATA":
      return { ...state, isFetchingGetInventoryAllData: false };

    case "REQUEST_GET_ARTICLE":
      return { ...state, isFetchingArticle: true };

    case "SUCCESS_GET_ARTICLE":
      return {
        ...state,
        isFetchingArticle: false,
        getArticleList: action.payload,
      };

    case "ERROR_GET_ARTICLE":
      return { ...state, isFetchingArticle: false };

    case "REQUEST_ADD_ARTICLE_MATERIAL":
      return { ...state, isAddingArticleMaterial: false };

    case "SUCCESS_ADD_ARTICLE_MATERIAL":
      return {
        ...state,
        isAddingArticleMaterial: true,
        addArticleMaterial: action.payload,
      };

    case "ERROR_ADD_ARTICLE_MATERIAL":
      return { ...state, isAddingArticleMaterial: false };

    // case "REQUEST_GET_ARTICLE_IDS":
    //     return { ...state, isFetchingArticleIds: true };

    case "SUCCESS_GET_ARTICLE_IDS":
      return {
        ...state,
        isFetchingArticleIds: false,
        ArticleIds: [1, 2, 3],
      };

    // case "ERROR_GET_ARTICLE_IDS":
    //     return { ...state, isFetchingArticleIds: false };

    default:
      return state;
  }
};

export default AddArticleMaterialReducer;
