import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import ImageUploader from "react-images-upload";
import { addCatalogCall } from "./catalog.action";
import { useEffect, useState } from "react";

const AddCatelog = (props) => {
  const [catalog, setCatalog] = useState("");
  const [frontImages, setFrontImages] = useState();
  const [backImages, setBackImages] = useState();

  /* const [state, setState] = useState({
        columns: [
            { title: 'Catalog #', field: 'name' },
            // { title: 'Brand Description', field: 'description' },
        ],
    });*/

  const onDrop = (pictureFiles, pictureDataURLs) => {
    setFrontImages(pictureFiles);
  };

  const onDropBack = (pictureFilesBack, pictureDataURLs) => {
    setBackImages(pictureFilesBack);
  };

  function sendData() {
    props.addCatalogCall({
      name: catalog,
      imagefront: frontImages,
      imageback: backImages,
    });
    setCatalog("");
    setFrontImages("");
    setBackImages("");
  }

  console.log(frontImages, "kkjj");

  useEffect(() => {
    // props.getCatalogCall();
  }, []);

  return (
    <Container fluid>
      <div className="main">
        <Row>
          <Col lg="12" md="12" xs="12">
            <Form.Group className="mb-3">
              <Form.Label>
                <b>Enter Catalog *</b>
              </Form.Label>
              <Form.Control
                value={catalog}
                type="text"
                id="catalogDetail"
                placeholder="Catalog ...."
                onChange={(e) => setCatalog(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>

        <div>
          <Row>
            <Col lg="6" md="12" xs="12">
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>Choose Front Image</b>
                </Form.Label>
                <ImageUploader
                  value={frontImages}
                  withIcon={true}
                  buttonText="Choose Front Image"
                  onChange={onDrop}
                  imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                  maxFileSize={999999999999999999999}
                  singleImage={true}
                  withPreview={true}
                />
              </Form.Group>
            </Col>

            <Col lg="6" md="12" xs="12">
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>Choose Back Image</b>
                </Form.Label>
                <ImageUploader
                  value={backImages}
                  withIcon={true}
                  buttonText="Choose Back Image"
                  onChange={onDropBack}
                  imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                  maxFileSize={999999999999999999999}
                  singleImage={true}
                  withPreview={true}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>

        <div className="sendDiv">
          {props.isFetchingAddCatalog ? (
            <div className="loader-div">
              <Spinner
                variant="primary"
                animation="grow"
                size="xl"
                role="status"
                aria-hidden="true"
              />
            </div>
          ) : (
            <Button
              disabled={isEmpty(catalog)}
              className="sendButton"
              onClick={() => sendData()}
              style={{
                marginLeft: "2%",
                color: "white",
                width: "20%",
                backgroundColor: "black",
                border: "1px solid black",
              }}
            >
              Save
            </Button>
          )}
        </div>
      </div>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCatalogCall: (data) => dispatch(addCatalogCall(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingAddCatalog: state.catalogReducer.isFetchingAddCatalog,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCatelog);
