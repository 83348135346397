const initialState = {
  isFetchingAddVendor: false,
  vendorDetail: {
    name: "",
    cnic: "",
    address:"",
    phone: "",
  },
  isFetchingGetVendor: false,
  vendorInfo: [],
  isFetchingUpdateVendor: false,
};
const vendorReducer = (state = initialState, action) => {
  switch (action.type) {
    //Vendor
    case "REQUEST_ADD_VENDOR":
      return { ...state, isFetchingAddVendor: true };

    case "SUCCESS_ADD_VENDOR":
      return {
        ...state,
        isFetchingAddVendor: false,
        vendorDetail: {
          name: action.payload.vendor_name,
          phone:action.payload.vendor_phone,
          cnic:action.payload.vendor_cnic,
          address:action.payload.vendor_address
        },
      };

    case "ERROR_ADD_VENDOR":
      return { ...state, isFetchingAddVendor: false };

    //Get Vendor
    case "REQUEST_GET_VENDOR":
      return { ...state, isFetchingGetVendor: true };

    case "SUCCESS_GET_VENDOR":
      return {
        ...state,
        isFetchingGetVendor: false,
        vendorInfo: action.payload.result,
      };

    case "ERROR_GET_VENDOR":
      return { ...state, isFetchingGetVendor: false };

    //Update Vendor
    case "REQUEST_UPDATE_VENDOR":
      return { ...state, isFetchingUpdateVendor: true };

    case "SUCCESS_UPDATE_VENDOR":
      return {
        ...state,
        isFetchingUpdateVendor: false,
        vendorInfo: action.payload.data,
      };

    case "ERROR_UPDATE_VENDOR":
      return { ...state, isFetchingUpdateVendor: false };

    default:
      return state;
  }
};

export default vendorReducer;
