const initialState = {
  isFetchingAddCustomer: false,
  customerDetail: {
    name: "",
    company: "",
    email: "",
    web: "",
    phone: "",
    city: "",
    country: "",
  },
  isFetchingGetCustomer: false,
  customerInfo: [],
  isFetchingUpdateCustomer: false,
};
const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    //Customer
    case "REQUEST_ADD_CUSTOMER":
      return { ...state, isFetchingAddCustomer: true };

    case "SUCCESS_ADD_CUSTOMER":
      return {
        ...state,
        isFetchingAddCustomer: false,
        customerDetail: {
          name: action.payload.customerPerson,
          company: action.payload.customerCompany,
          email: action.payload.customerEmail,
          web: action.payload.customerWeb,
          phone: action.payload.customerPhone,
          city: action.payload.customerCity,
          country: action.payload.customerContry,
        },
      };

    case "ERROR_ADD_CUSTOMER":
      return { ...state, isFetchingAddCustomer: false };

    //Get Customer
    case "REQUEST_GET_CUSTOMER":
      return { ...state, isFetchingGetCustomer: true };

    case "SUCCESS_GET_CUSTOMER":
      return {
        ...state,
        isFetchingGetCustomer: false,
        customerInfo: action.payload.data,
      };

    case "ERROR_GET_CUSTOMER":
      return { ...state, isFetchingGetCustomer: false };

    //Update Customer
    case "REQUEST_UPDATE_CUSTOMER":
      return { ...state, isFetchingUpdateCustomer: true };

    case "SUCCESS_UPDATE_CUSTOMER":
      return {
        ...state,
        isFetchingUpdateCustomer: false,
        customerInfo: action.payload.data,
      };

    case "ERROR_UPDATE_CUSTOMER":
      return { ...state, isFetchingUpdateCustomer: false };

    default:
      return state;
  }
};

export default customerReducer;
