import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import {isNull } from "lodash";
import AddBrand from "./addBrand";
import ViewBrand from "./viewBrand";





const TotalBrandData = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            {/* <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 92) ?
                    "AddBrand" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 93) ?
                        "BrandList" :  ""} id="AddBrand" className="mb-3"> */}
                        <Tabs defaultActiveKey={
                    "AddBrand" } id="AddBrand" className="mb-3">
                <Tab eventKey="AddBrand" title="Add Brand"
                // disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 92) ? false : true}
                >  
                    <AddBrand />
                </Tab>
                <Tab eventKey="BrandList" title="Brand List"
            //    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 93) ? false : true}
               > 
                    <ViewBrand/>
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalBrandData);