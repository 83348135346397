import API from "../../../../../global/api";
import { forEach } from "lodash";
import { handleDangerVisible, handleVisible } from "../../../../../global/globalFunctions";




//Add Size Call

export const addSizeCall = (data) => {
  return (dispatch) => {
    dispatch(requestAddSize());
    API.post("size-color/size-add-data", data)
      .then((res) => {
        dispatch(successAddSize(data));
        dispatch(getSizeCall());
        handleVisible()
      })
      .catch((error) => {
        dispatch(errorAddSize());
        handleDangerVisible()
      });
  };
};

export const requestAddSize = () => {
  return {
    type: "REQUEST_ADD_SIZE",
  };
};

export const successAddSize = (data) => {
  return {
    type: "SUCCESS_ADD_SIZE",
    payload: data,
  };
};

export const errorAddSize = () => {
  return {
    type: "ERROR_ADD_SIZE",
  };
};

//Get Size Call

export const getSizeCall = () => {
  return (dispatch) => {
    dispatch(requestGetSize());
    API.get("size-color/get-size-data")
      .then((res) => {
        dispatch(successGetSize(res.data));
      })
      .catch((error) => {
        dispatch(errorGetSize());
      });
  };
};

export const requestGetSize = () => {
  return {
    type: "REQUEST_GET_SIZE",
  };
};

export const successGetSize = (data) => {
  return {
    type: "SUCCESS_GET_SIZE",
    payload: data,
  };
};

export const errorGetSize = () => {
  return {
    type: "ERROR_GET_SIZE",
  };
};

export const requestDeleteSize = () => {
  return {
    type: "REQUEST_DELETE_SIZE",
  };
};

export const successDeleteSize = (sizeId) => {
  return {
    type: "SUCCESS_DELETE_SIZE",
    sizeId: sizeId,
  };
};

export const errorDeleteSize = () => {
  return {
    type: "ERROR_DELETE_SIZE",
  };
};
export const deleteSize = (sizeId) => {
  return (dispatch) => {
    dispatch(requestDeleteSize());
    API.delete(`/size/${sizeId}`)
      .then((res) => {
        dispatch(getSizeCall());
        dispatch(successDeleteSize(sizeId));
      })
      .catch((error) => {
        dispatch(errorDeleteSize());
      });
  };
};

// update

export const requestUpdateSize = () => {
  return {
    type: "REQUEST_UPDATE_SIZE",
  };
};

export const successUpdateSize = (data) => {
  return {
    type: "SUCCESS_UPDATE_SIZE",
    payload: data,
  };
};

export const errorUpdateSize = () => {
  return {
    type: "ERROR_UPDATE_SIZE",
  };
};

export const updateSize = (newData, oldData) => {
  return (dispatch) => {
    dispatch(requestUpdateSize());
    API.put(`/update/${oldData.sizeId}`, {
      sizeName: newData.sizeName,
      sizeDescription: newData.sizeDescription,
    })
      .then((res) => {
        dispatch(getSizeCall());
        dispatch(successUpdateSize([newData]));
      })
      .catch((error) => {
        dispatch(errorUpdateSize());
      });
  };
};
