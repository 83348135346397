import React, { useState } from 'react'
import { Container, Form, Row, Col, Button, FormGroup, FormControl } from 'react-bootstrap';
import { addMenu, getMenu, deleteMenu, getMasterControl,/*UpdateHRMenu */ 
SearchMarqueeMenuReport,
UpdateMenu} from './MarqueeDetails.actions';
import { connect } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import { useEffect } from 'react';
import Swal from 'sweetalert';
import { isEmpty, find,sumBy } from "lodash";
import jsPDF from "jspdf";
import logo from "../../../../../Images/logo.png";
import Select from 'react-select';
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { Link } from 'react-router-dom';
import moment from 'moment';
const DateWiseReportTable = (props) => {
    const subEventsData = isEmpty(props.customerByIdList) ? [] : props.customerByIdList;
   
    useEffect(() => {
        console.log(props.customerByIdList,"ttttttttttttttttttttttttttttttttttttttttttt")
    //     props.getMenu(2);

    }, []);
    return (
        <>
                <Container fluid>


                    {props.isFetchingprofitByDate ? (
                        <div className="loader-div">
                            <Button variant="info" disabled>
                                <Spinner
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    variant="info"
                                />
                                loading
                            </Button>
                        </div>
                    ) : (
                        <>
                        <div className="sendDiv">
                        <Link to="/user/reports">
                            <Button className="sendButton"> Back to Search</Button>
                        </Link>
                        </div>
                        <div>
              <MaterialTable
                title="Profit By Booking"
                columns={[
                  { title: "Total Cost", field: "total_cost" },
                  { title: "Total Expense", field: "total_expense" },
                  { title: "Total Profit", field: "total_profit" },
                ]}
                data={[props.profitByDate]}
                options={{
                  actionsColumnIndex: -1,
                  filtering: false,
                  exportButton: true,
                  paging: true,
                  pageSize: 5, // make initial page size
                  emptyRowsWhenPaging: false, // To avoid of having empty rows
                  pageSizeOptions: [50, 100, 150, 200],
                  exportPdf: (columns, data) => {
                    const doc = new jsPDF();

                    const columnTitles = columns.map(
                      (columnDef) => columnDef.title
                    );

                    const pdfData = data.map((rowData) =>
                      columns.map(
                        (columnDef) => (
                          console.log(rowData[columnDef.field], "eeeee"),
                          columnDef.field == "createdAt"
                            ? moment(rowData[columnDef.field]).format(
                                "YYYY-MM-DD"
                              )
                            : rowData[columnDef.field]
                        )
                      )
                    );
                    let content = {
                      startY: 50,
                      startX: 5,
                      head: [columnTitles],
                      body: pdfData,
                    };
                    const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                    const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
                    const companyNTN = `STRN#${process.env.REACT_APP_COMPANY_NTN}`;

                    doc.addImage(logo, "PNG", 10, 11, 30, 30);
                    // doc.text(`Company Name: ${companyName}`, 75, 20, 0, 20);

                    doc.setFont("Times New Roman", "bolditalic");
                    // doc.setFontT("bolditalic");
                    doc.text(companyName, 75, 15, 0, 20);
                    doc.setFontSize(9);
                    doc.setFont("Times New Roman", "normal");
                    doc.text(companyAddress, 75, 20, 0, 20);
                    doc.text(companyNTN, 75, 25, 0, 20);
                    doc.setFontSize(14);
                    doc.setFont("Times New Roman", "normal");
                    doc.text("Datewise Sale Report", 75, 46, 0, 20);
                    doc.autoTable(content);

                    doc.save(`ByDateProfitReport.pdf`);
                  },
                  headerStyle: {
                    position: "sticky",
                    top: 0,
                    color: "#00BBBB",
                    fontWeight: "550",
                    onRowAdd: "none",
                  },
                }}
              />
            </div>
                        </>
                     
                          ) 
                     } 


                </Container>
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {

    };
};
const mapStateToProps = (state, ownProps) => ({
    isFetchingprofitByDate: state.MarqueeReducer.isFetchingprofitByDate,
    profitByDate: state.MarqueeReducer.profitByDate,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DateWiseReportTable);

