import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Tabs, Tab, Alert } from "react-bootstrap";

import { connect } from "react-redux";
import { isEmpty, get, find, chain, uniqBy, isNull } from "lodash";
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import jsPDF from 'jspdf';
import Select from 'react-select';
import Swal from 'sweetalert';
import { resetReducer, searchInventoryReport } from "../AddDetails.actions";
import { inoviceSummaryReport } from "./InvoiceDetails.actions";

const InvoiceSaleSummary = (props) => {
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [visibleAlert, setAlertVisible] = useState(false);

    const handleVisible = () => {
        setAlertVisible(true)
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Data Not Found",
            text: "Data Not Found",
            icon: "info",
            button: "Ok",
        });
    }

    const exportPDF = () => {
        props.inoviceSummaryReport({
            startedDate: !isEmpty(startDate) ? startDate : "",
            endDate: !isEmpty(endDate) ? endDate : "",
        }, startDate, endDate, handleVisible);

    }

    return (
        <>
            { props.isFetchingSearchInventoryList ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {props.isFetchingSearchInventoryList ? 'Downloading PDF...' : 'Loading...'}

                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <Row>
                            <Col >
                                <FormGroup>
                                    <Form.Label>
                                        <b>Start Date :</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    >
                                    </Form.Control>
                                </FormGroup>
                            </Col>
                            <Col >
                                <FormGroup>
                                    <Form.Label>
                                        <b>End Date</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    >
                                    </Form.Control>
                                </FormGroup>
                            </Col>
                           
                        </Row>
                        <div className="sendDiv">
                            {isEmpty(startDate)||isEmpty(endDate) ? <Button disabled style={{ marginLeft: "2%", color: "white", width: "20%", backgroundColor: "black", border: "1px solid black" }}>Generate Report</Button> : props.isFetchingSearchInventoryList ? <Button className="sendButton">Downloading PDF</Button> : <Button className="sendButton" onClick={() => exportPDF()}>Generate Report</Button>}
                        </div>
                    </div>
                </Container>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        resetState: () => dispatch(resetReducer()),
        inoviceSummaryReport: (data, startDate, endDate, handleVisible) => dispatch(inoviceSummaryReport(data, startDate, endDate, handleVisible))
    };
};
const mapStateToProps = (state, ownProps) => ({
    warehouseList: state.AddDataReducer.warehouseList,
    searchInventoryList: state.AddDataReducer.searchInventoryList,
    isFetchingSearchInventoryList: state.AddDataReducer.isFetchingSearchInventoryList
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvoiceSaleSummary);