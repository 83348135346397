import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  FormGroup,
  Modal,
  Alert,
} from "react-bootstrap";
import "../production-styles.css";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import { find } from "lodash";
import {
  getAllInventoryData,
  getArticle,
  addArticleMaterial,
  getArticleIds,
} from "./AddArticleMaterial.actions";

const AddArticleMaterial = (props) => {
  const [inputField, setInputField] = useState([
    {
      inventoryId: "-1",
      productQuantity: 0,
      productCost: 0,
      pricePerUnit: 0,
    },
  ]);
  let totalCost = 0;
  totalCost = inputField.reduce((a, b) => (a = a + b.productCost), 0);
  console.log(totalCost, "tttttttttttt");
  console.log(inputField, "fffffffffff");
  const [maximumQuantity, setMaximumQuantity] = useState(0);
  const [price, setPrice] = useState(0);
  const [articleId, setArticleId] = useState();
  console.log(price, "eeeeeeeeeee");
  const inventory = props.getInventoryAllData.map((x) => {
    let data = { value: x.inventoryId, label: x.productName };
    return data;
  });
  const getArticleListData = props.getArticleList.map((x) => {
    let data = { value: x.articleId, label: x.itemName };
    return data;
  });
  const handleChangeArticle = (selectedOption) => {
    setArticleId(selectedOption.value);
  };
  props.ArticleIds.map((x) => {
    console.log(x, "xidsssssss");
  });
  function saveArticleMaterial() {
    // let MaterialData = props.ArticleIds.map((x) => { let data = { articleId: x, totalCost: totalCost, materialData: inputField.map((x) => { let data = { inventoryId: x.inventoryId, materialQuantity: x.productQuantity, materialCost: x.productCost }; return data }) }; return data });
    // console.log(MaterialData, "materiallllllllll");
    props.addArticleMaterial({
      articleData: props.ArticleIds.map((x) => {
        let data = {
          articleId: x,
          totalCost: totalCost,
          materialData: inputField.map((x) => {
            let data = {
              inventoryId: x.inventoryId,
              materialQuantity: x.productQuantity,
              materialCost: x.productCost,
            };
            return data;
          }),
        };
        return data;
      }),
    });
    setInputField([
      {
        inventoryId: "-1",
        productQuantity: 0,
        productCost: 0,
        pricePerUnit: 0,
      },
    ]);
  }

  const handleChangeDemand = (e, index, maxVal, type) => {
    let z = find(props.getInventoryAllData, (x) => x.inventoryId == e.value);
    const { name, value } = e;
    const list = [...inputField];
    if (type == "intname") list[index]["inventoryId"] = e.value;
    if (type == "intname")
      list[index]["productQuantity"] = Number(z.currentBlnc);
    if (type == "intquantity") list[index]["productQuantity"] = e.target.value;
    //if(type == 'intname') setPrice(Number(z.productPrice));
    if (type == "intname") list[index]["pricePerUnit"] = Number(z.productPrice);
    if (type == "intquantity")
      list[index]["productCost"] = e.target.value * price;
    console.log(list[index]["productCost"], "ccccccccccccc");
    // var holder = {};
    // list.forEach(function (d) {
    //     if (holder.hasOwnProperty(d.inventoryId)) {
    //         holder[d.inventoryId] = Number(d.productQuantity) + Number(d.productQuantity);
    //     } else {
    //         holder[d.inventoryId] = Number(d.productQuantity);
    //     }
    // });
    // var obj2 = [];
    // for (var prop in holder) {
    //     obj2.push({ inventoryId: prop, productQuantity: Number(holder[prop]), productCost: list[index]['productCost']});
    // }
    // setInputField(obj2);
    setInputField(list);
  };
  const handleChange = (e, index, type) => {
    let z = find(props.getInventoryAllData, (x) => x.inventoryId == e.value);
    const { name, value } = e;
    const list = [...inputField];
    let cost;
    console.log(inputField.productQuantity, "llllllllll");
    if (type == "intname") list[index]["inventoryId"] = e.value;
    //  if (type == 'intname') list[index]['productQuantity'] = Number(z.currentBlnc);
    //  if (type == 'intname') list[index]['productPrice'] = Number(z.inventory.productPrice);
    if (type == "intname") setMaximumQuantity(Number(z.currentBlnc));
    if (type == "intquantity") list[index]["productQuantity"] = e.target.value;
    if (type == "intname") setPrice(Number(z.productPrice));
    if (type == "intname") list[index]["pricePerUnit"] = Number(z.productPrice);
    // if (type == 'intname') list[index]['productCost'] = Number(list.map((x) => x.productQuantity) * Number(z.productPrice));
    //if (type == 'intquantity') list[index]['productCost'] = e.target.value * Number(z.productPrice);
    // console.log(list[index]['productCost'], "ccccccccccccc")
    // var holder = {};
    // list.forEach(function (d) {
    //     if (holder.hasOwnProperty(d.inventoryId)) {
    //         holder[d.inventoryId] = Number(d.productQuantity) + Number(d.productQuantity);
    //     } else {
    //         holder[d.inventoryId] = Number(d.productQuantity);
    //     }
    // });
    // var obj2 = [];
    // for (var prop in holder) {
    //     obj2.push({ inventoryId: prop, productQuantity: Number(holder[prop]), productCost: list[index]['productCost']});
    // }
    // setInputField(obj2);

    setInputField(list);
  };

  const handleAddInput = () => {
    setInputField([
      ...inputField,
      { inventoryId: "-1", productQuantity: 0, productCost: 0 },
    ]);
  };

  const handleRemoveInput = (index) => {
    const list = [...inputField];
    list.splice(index, 1);
    setInputField(list);
  };

  useEffect(() => {
    props.getAllInventoryData();
    props.getArticle();
    props.getArticleIds();
  }, []);
  return (
    <>
      {props.isFetchingDemand || props.isFetchingInventoryAll ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {/* {props.isFetchingSectorsSummary ? 'Loading...' : 'Loading...'} */}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            {props.isAddingArticleMaterial && (
              <Alert variant={"success"}>Added Data...</Alert>
            )}
            {/* <Row>
                            <Col xl="12" lg="12" md="12" md="12" sm="12">
                                <Form.Group>
                                    <Form.Label>
                                        <b>Select Article</b>
                                    </Form.Label>
                                    <Select
                                        placeholder="Select Article..."
                                        onChange={handleChangeArticle}
                                        options={getArticleListData}
                                    />
                                </Form.Group>
                            </Col>
                        </Row> */}
            {inputField.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <Row>
                    <Col xl="3" lg="3" md="12" sm="12">
                      <Form.Group>
                        <Form.Label>
                          <b> Select Material *</b>
                        </Form.Label>
                        <Select
                          value={item.inventoryId}
                          placeholder="Select Material..."
                          name="inventoryId"
                          onChange={(e) => handleChange(e, i, "intname")}
                          options={inventory}
                        />
                      </Form.Group>
                    </Col>

                    <Col xl="3" lg="3" md="12" sm="12">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Add Quantity *</b>
                        </Form.Label>
                        <Form.Control
                          min={0}
                          type="number"
                          placeholder="Add Quantity...."
                          name="productQuantity"
                          value={item.productQuantity}
                          onChange={(e) =>
                            handleChangeDemand(
                              e,
                              i,
                              maximumQuantity,
                              "intquantity"
                            )
                          }
                        />
                      </Form.Group>
                    </Col>

                    <Col xl="3" lg="3" md="12" sm="12">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Price per unit</b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="price...."
                          name="pricePerUnit"
                          value={item.pricePerUnit}
                          readOnly
                        />
                      </Form.Group>
                    </Col>

                    <Col xl="3" lg="3" md="12" sm="12">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Material Cost</b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Cost...."
                          name="productCost"
                          value={item.productCost}
                          readOnly
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Row>
                      <Col lg="12">
                        {inputField.length - 1 == i && (
                          <IconButton
                            title="Click to Add Article Material"
                            onClick={handleAddInput}
                            style={{
                              float: "right",
                              color: "black",
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        )}

                        {inputField.length !== 1 && (
                          <IconButton
                            title="Click to Remove Article Material"
                            onClick={() => handleRemoveInput(i)}
                            style={{ float: "right", color: "black" }}
                          >
                            <RemoveIcon />
                          </IconButton>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                </React.Fragment>
              );
            })}

            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Total Cost</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Cost...."
                    name="productCost"
                    value={totalCost}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv">
              {props.isAddingArticleMaterial ? (
                <div className="loader-div">
                  <Spinner
                    variant="primary"
                    animation="grow"
                    size="xl"
                    role="status"
                    aria-hidden="true"
                  />
                </div>
              ) : (
                <Button
                  disabled={
                    inputField[0].inventoryId == "" ||
                    inputField[0].productQuantity == ""
                  }
                  className="sendButton"
                  style={{
                    marginLeft: "2%",
                    color: "white",
                    width: "20%",
                    backgroundColor: "black",
                    border: "1px solid black",
                  }}
                  onClick={() => saveArticleMaterial()}
                >
                  Save
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllInventoryData: () => dispatch(getAllInventoryData()),
    getArticle: () => dispatch(getArticle()),
    addArticleMaterial: (data) => dispatch(addArticleMaterial(data)),
    getArticleIds: () => dispatch(getArticleIds()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  getInventoryAllData: state.AddArticleMaterialReducer.getInventoryAllData,
  getArticleList: state.AddArticleMaterialReducer.getArticleList,
  // ArticleIds: state.productionReducer.ArticleIds,
  ArticleIds: state.AddArticleReducer.ArticleIds,
  isAddingArticleMaterial:
    state.AddArticleMaterialReducer.isAddingArticleMaterial,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddArticleMaterial);
