import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Tabs, Tab, Alert } from "react-bootstrap";
import "./add-details.css";
import { deleteInvMasterControl, getInvMasterControl, updateInvMasterControl } from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, get, find, chain, uniqBy, isNull } from "lodash";
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import Select from 'react-select';
import { Link } from 'react-router-dom';

const ViewMasterControlInventory = (props) => {
    const tableRef = useRef();
    function updateTableQuery() {
        tableRef.current.onQueryChange()
    };
    // const wareHouseData = props.allInventory.map(function (el) {
    //     var o = Object.assign({}, el);
    //     o.key = el.warehouse.whKey + " " + '-' + " " + el.category.cateKey + " " + '-' + " " + el.sub_category.subcateKey + " " + '-' + " " + el.invKey;
    //     return o;
    // });
    // const getDataList=props.invColorList.concat(props.invModalList,props.invSizeList)

    useEffect(() => {
        props.getInvMasterControl();

    }, []);

    return (
        <div>
            {props.isFetchingInvMasterControl ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />

                    </Button>
                </div>
            ) : (
                <div className="main">
                    <MaterialTable
                        title="Inventory Details"
                        columns={[
                            { title: 'Type', field: 'type', editable: 'never' },
                            { title: 'Value', field: 'name', },
                        ]}
                        data={props.invMasterControlList}
                        options={{
                            actionsColumnIndex: -1,
                            filtering: true,
                            exportButton: true,
                            paging: true,
                            pageSize: 200,       // make initial page size
                            emptyRowsWhenPaging: false,   // To avoid of having empty rows
                            pageSizeOptions: [50, 100, 150, 200],    // rows selection options
                            headerStyle: {
                                position: 'sticky', top: 0,
                                color: '#00BBBB',
                                fontWeight: '550',
                                onRowAdd: 'none',
                            }
                        }}
                        editable={{
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve) => {
                                    setTimeout(() => {
                                        resolve();
                                        if (oldData) {
                                            props.updateInvMasterControl(newData, oldData)

                                        }
                                    }, 600);
                                }),
                            onRowDelete: (oldData) =>
                                new Promise((resolve) => {
                                    setTimeout(() => {
                                        resolve();
                                        props.deleteInvMasterControl(oldData.id);
                                    }, 600);
                                }),
                        }}
                    />
                </div>
            )}

        </div>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        getInvMasterControl: () => dispatch(getInvMasterControl()),
        updateInvMasterControl:(newData, oldData)=>dispatch(updateInvMasterControl(newData, oldData)),
        deleteInvMasterControl:(id)=>dispatch(deleteInvMasterControl(id))
        // getInvSize: () => dispatch(getInvSize()),
        // getInvModal: () => dispatch(getInvModal()),
    };
};
const mapStateToProps = (state, ownProps) => ({
    // isFetchingInvSize: state.AddDataReducer.isFetchingInvSize,
    // isFetchingInvModal: state.AddDataReducer.isFetchingInvModal,
    isFetchingInvMasterControl: state.AddDataReducer.isFetchingInvMasterControl,
    invMasterControlList: state.AddDataReducer.invMasterControlList,
    // invModalList: state.AddDataReducer.invModalList,
    // invColorList: state.AddDataReducer.invColorList,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewMasterControlInventory);