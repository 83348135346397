import API from "../../../../global/api";
import { get, forEach, isEmpty,sumBy } from "lodash";
import jsPDF from "jspdf";
import moment from "moment";



export const setSetting = (data) => {
    return {
        type: "SET_SETTING",
        payload: data
    };
};
export const requestAddSetting = () => {
    return {
        type: "REQUEST_ADD_SETTING",
    };
};
export const successAddSetting = (data) => {
    return {
        type: "SUCCESS_ADD_SETTING",
        payload: data,
    };
};
export const errorAddSetting = () => {
    return {
        type: "ERROR_ADD_SETTING",
    };
};

export const addSetting = (data) => {
    return (dispatch) => {
        dispatch(requestAddSetting());
        API.post(`/setting`, data).then((res) => {
            let getLeaveData = res.data;
             dispatch(GetSetting());
            dispatch(successAddSetting(getLeaveData));
        }).catch((error) => {
            dispatch(errorAddSetting());
        })
    };
};
export const requestGetSetting = () => {
    return {
        type: "REQUEST_GET_SETTING",
    };
};
export const successGetSetting = (data) => {
    return {
        type: "SUCCESS_GET_SETTING",
        payload: data,
    };
};
export const errorGetSetting = () => {
    return {
        type: "ERROR_GET_SETTING",
    };
};

export const GetSetting = () => {
    return (dispatch) => {
        dispatch(requestGetSetting());
        API.get(`/setting`).then((res) => {
            let getLeaveData = res.data;
           
            dispatch(successGetSetting(getLeaveData));
        }).catch((error) => {
            dispatch(errorGetSetting());
        })
    };
};
