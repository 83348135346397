import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import { addProductCall } from "./addProduct.action";

const AddProduct = (props) => {
  const [product, setProduct] = useState("");

  function sendData() {
    props.addProductCall({
      name: product,
      // description: description,
    });
    setProduct("");
  }

  useEffect(() => {}, []);

  return (
    <Container fluid>
      <div className="main">
        <Form>
          <Row>
            <Col lg="12" md="12" xs="12">
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>Enter Product *</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="productDetail"
                  placeholder="Product...."
                  value={product}
                  onChange={(e) => setProduct(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          {/* <Row>
                            <Col lg="12" md="12" xs="12">
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Description:</b></Form.Label>
                                    <Form.Control type="text" id="productDescription" placeholder="Description...."
                                        onBlur={(e) => setDescription(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row> */}

          <div className="sendDiv">
            {props.isFetchingAddProduct ? (
              <div className="loader-div">
                <Spinner
                  variant="primary"
                  animation="grow"
                  size="xl"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            ) : (
              <Button
                className="sendButton"
                onClick={() => sendData()}
                disabled={isEmpty(product)}
                style={{
                  marginLeft: "2%",
                  color: "white",
                  width: "20%",
                  backgroundColor: "black",
                  border: "1px solid black",
                }}
              >
                Save
              </Button>
            )}
          </div>
        </Form>
      </div>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addProductCall: (data) => dispatch(addProductCall(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingAddProduct: state.productReducer.isFetchingAddProduct,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProduct);
