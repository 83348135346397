import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Alert, Tab, Tabs } from "react-bootstrap";
import { ModalBody, Modal } from "reactstrap";
import "./add-details.css";
import {
    getProductByDemand, getPurchase, addPurchase, getApproveDemand, searchPurchaseReport, getApprovePurchase, purchaseById, purchaseFromDemandCall
} from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, find } from "lodash";
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import WareHouseModal from "./WareHouseModal";
import MaterialTable from 'material-table';
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Select from 'react-select';
import Swal from 'sweetalert';


const PurchaseDetaill = (props) => {

    const wareHouseData = props.purchaseList1;

    // const [state, setState] = React.useState({
    //     viewColumns: [
    //         { title: 'Purchase Order#', field: 'purchaseOrderId' },
    //         { title: "Status", field: "status" },
    //         { title: 'Purchase Manual#', field: 'purchaseOrderId' },
    //         { title: 'Purchaser Name', field: 'purchaseName' },
    //         { title: 'Description', field: 'PODescription' },
    //         { title: 'Demand A/M', field: 'create_demand.demandName' },
    //         { title: 'Tax', field: 'purchaseTax' },
    //         { title: 'Creation Date', field: 'createdAt', render: rowData => moment(rowData.createdAt).format("YYYY-MM-DD") },
    //     ],
    //     columnsData: [
    //         { title: 'Inventory Code', field: 'inventory', render: rowData => rowData.inventory.warehouse.whKey + "-" + rowData.inventory.category.cateKey + "-" + rowData.inventory.invKey },
    //         { title: 'Inventory Name', field: 'inventory.productName' },
    //         { title: 'Product Quantity', field: 'productQuantity' },
    //         { title: 'Remaining Quantity', field: 'remainingQuantity' },
    //         // {title: "Approve Quantity", field: "approvedQuantity"}
    //     ],
    // });
    useEffect(() => {
        props.getPurchase();
        props.getApprovePurchase();
        props.getApproveDemand();
    }, []);
    return (
        <>
            {props.isFetchingPurchaseList ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {/* {props.isFetchingSectorsSummary ? 'Loading...' : 'Loading...'} */}

                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <MaterialTable
                            title="Purchase Order Details"
                            // columns={state.viewColumns}
                            columns={[
                                { title: 'Purchase Order#', field: 'purchaseOrderId' },
                                { title: "Status", field: "status" },
                                { title: 'Purchase Manual#', field: 'purchaseOrderId' },
                                { title: 'Purchaser Name', field: 'purchaseName' },
                                { title: 'Description', field: 'PODescription' },
                                { title: 'Demand A/M', field: 'create_demand.demandName' },
                                { title: 'Tax', field: 'purchaseTax' },
                                { title: 'Creation Date', field: 'createdAt', render: rowData => moment(rowData.createdAt).format("YYYY-MM-DD") },
                            ]}
                            data={isEmpty(wareHouseData) ? [] : wareHouseData.reverse()}
                            detailPanel={rowData => {
                                return (
                                    <MaterialTable
                                        // title="Product Details"
                                        // columns={state.columnsData}
                                        columns={[
                                            { title: 'Inventory Code', field: 'inventory', render: rowData => rowData.inventory.warehouse.whKey + "-" + rowData.inventory.category.cateKey + "-" + rowData.inventory.invKey },
                                            { title: 'Inventory Name', field: 'inventory.productName' },
                                            { title: 'Product Quantity', field: 'productQuantity' },
                                            { title: 'Remaining Quantity', field: 'remainingQuantity' },
                                            // {title: "Approve Quantity", field: "approvedQuantity"}
                                        ]}
                                        data={rowData.purchase_data}
                                        options={{
                                            actionsColumnIndex: -1,
                                            toolbar: false,
                                            exportButton: true,
                                            sorting: true,
                                            paging: true,
                                            pageSize: 200,       // make initial page size
                                            emptyRowsWhenPaging: false,   // To avoid of having empty rows
                                            pageSizeOptions: [50, 100, 150, 200],
                                            headerStyle: {
                                                position: 'sticky', top: 0,
                                                color: '#00BBBB',
                                                fontWeight: '550',
                                                onRowAdd: 'none',
                                            }
                                        }}

                                    />
                                )
                            }}
                            options={{
                                actionsColumnIndex: -1,
                                filtering: true,
                                exportButton: true,
                                paging: true,
                                pageSize: 200,       // make initial page size
                                emptyRowsWhenPaging: false,   // To avoid of having empty rows
                                pageSizeOptions: [50, 100, 150, 200],
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                }
                            }}
                            onRowClick={(event, rowData, togglePanel) => togglePanel()}
                        />
                    </div>
                </Container>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        getPurchase: () => dispatch(getPurchase()),
        addPurchase: (data, handleVisible, handleDangerVisible) => dispatch(addPurchase(data, handleVisible, handleDangerVisible)),
        getProductByDemand: (demandId) => dispatch(getProductByDemand(demandId)),
        getApproveDemand: () => dispatch(getApproveDemand()),
        searchPurchaseReport: (data, purchaseName, startDate, endDate, demandName) => dispatch(searchPurchaseReport(data, purchaseName, startDate, endDate, demandName)),
        getApprovePurchase: () => dispatch(getApprovePurchase()),
        purchaseById: (data) => dispatch(purchaseById(data)),
        purchaseFromDemandCall: (data) => dispatch(purchaseFromDemandCall(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    warehouseList: state.AddDataReducer.warehouseList,
    categoryList: state.AddDataReducer.categoryList,
    subCategoryList: state.AddDataReducer.subCategoryList,
    inventoryList: state.AddDataReducer.inventoryList,
    demandList: state.AddDataReducer.demandList,
    allInventory: state.AddDataReducer.allInventory,
    isFetchingDemand: state.AddDataReducer.isFetchingDemand,
    isFetchingInventoryAll: state.AddDataReducer.isFetchingInventoryAll,
    porductListDemand: state.AddDataReducer.porductListDemand,
    purchaseList1: state.AddDataReducer.purchaseList1,
    approveDemandList: state.AddDataReducer.approveDemandList,
    isAddingPurchase: state.AddDataReducer.isAddingPurchase,
    notAddPurchase: state.AddDataReducer.notAddPurchase,
    approvePurchaseList: state.AddDataReducer.approvePurchaseList,
    purchaseListById: state.AddDataReducer.purchaseListById,
    isFetchingSearchPurchaseList: state.AddDataReducer.isFetchingSearchPurchaseList,
    isFetchingPurchaseList: state.AddDataReducer.isFetchingPurchaseList,
    purchaseFromDemandData: state.AddDataReducer.purchaseFromDemandData,
    isFetchingProductByDemand: state.AddDataReducer.isFetchingProductByDemand,
    isFetchningApproveDemandList: state.AddDataReducer.isFetchningApproveDemandList,
    isFetchingPurchaseFromDemand: state.AddDataReducer.isFetchingPurchaseFromDemand

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PurchaseDetaill);
