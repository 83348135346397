import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull, isUndefined } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";

import Select from "react-select";
import swal from "sweetalert";
import { GetSetting, addSetting, setSetting } from "./AccountConfiguration.actions";

const AccountConfiguration = (props) => {
  const [employeeId, setEmployeeId] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [mleaves, setMLeaves] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    swal({
      title: "Success",
      text: "Leaves Added Successfully",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };
  const handleChangeMenu = (selectedOption) => {
    props.setSetting(selectedOption.label)
   
  };
  const listData=[
    {value:1,
    label:"Yes"},
    {value:2,
    label:"No"}
  ]
  const getMenuList =
    !isEmpty(listData) &&
    listData.map((x) => {
      let data = { value: x.value, label: x.label };
      return data;
    });
  useEffect(() => {
     props.GetSetting();
    // props.getEmployeeData();
  }, []);
  // const getHRPolicy = props.
  function saveLeave() {
    props.addSetting({
      // employee_id: employeeId,     //AddBeforeTesting

      invoiceVoucher: props.setting,
    },
    handleVisible,handleDangerVisible);
  }
  return (
    <>
      {props.isFetchingSettingData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {/* {props.isFetchingwareHouseList ? 'Loading...' : 'Loading...'} */}
          </Button>
        </div>
      ) : (
        <div>
       

          <Form>
       
            <Row>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Invoice Voucher :</b>
                  </Form.Label>
                  {/* <Form.Control
                    type="text"
                    //  value={props.casualLeaves}
                    defaultValue={"test"}
                    placeholder="Allowed Casual Leaves...."
                    // onChange={(e) => {
                    //   props.setCasualLeaves(e.target.value);
                    //   console.log(props.casualLeaves, "props.casualLeaves");
                    // }}
                  /> */}
                   <Select
                        // isDisabled={isEmpty(props.menuList)}
                        placeholder="Select Invoice Voucher..."
                        defaultValue={props.setting}
                        value={{label:props.setting}}
                         onChange={handleChangeMenu}
                         options={getMenuList}
                      />
                </Form.Group>
              </Col>
              {/* <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Allowed Sick Leaves :</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={props.sickLeaves}
                    placeholder="Allowed Sick Leaves...."
                    onChange={(e) => props.setSickLeaves(e.target.value)}
                  />
                </Form.Group>
              </Col> */}
            </Row>

            {/* <Row>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Allowed Other Leaves :</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Allowed Other Leaves...."
                    defaultValue={props.otherLeaves}
                    onChange={(e) => props.setOtherLeaves(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Allowed Maternity Leaves :</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={props.maternityLeaves}
                    placeholder="Allowed Maternity Leaves...."
                    onChange={(e) => props.setMaternityLeaves(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Allowed Annual Leaves :</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={props.annualLeaves}
                    placeholder="Allowed Annual Leaves...."
                    onChange={(e) => props.setAnnualLeaves(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Allowed Paternity Leaves :</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={props.paternityLeaves}
                    placeholder="Allowed Paternity Leaves...."
                    onChange={(e) => props.setPaternityLeaves(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Allowed Short Leaves :</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={props.shortLeaves}
                    placeholder="Allowed Short Leaves...."
                    onChange={(e) => props.setShortLeaves(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Allowed Special Leaves :</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={props.specialLeaves}
                    placeholder="Allowed Special Leaves...."
                    onChange={(e) => props.setSpecialLeaves(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row> */}

            <div className="sendDiv">
              <Button className="sendButton" onClick={saveLeave}>
                Save
              </Button>
            </div>
          </Form>
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSetting:(data) => dispatch(setSetting(data)),
    addSetting:(data)=>dispatch(addSetting(data)),
    GetSetting:()=>dispatch(GetSetting()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  setting: state.AccountConfiguration.setting,
  isFetchingSettingData: state.AccountConfiguration.isFetchingSettingData,
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountConfiguration);
