import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import "jspdf-autotable";
import { addProcessCall } from "./addProcess.action";

const AddProcess = (props) => {
  const [inputField, setInputField] = useState([
    { processName: "", processDescription: "" },
  ]);
  const [categoryName, setCategoryName] = useState("");

  const handleChangeInput = (index, event) => {
    const values = [...inputField];
    values[index][event.target.id] = event.target.value;
    setInputField(values);
  };

  const handleAddFields = () => {
    setInputField([...inputField, { processName: "", processDescription: "" }]);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputField];
    if ((index = 1)) values.splice(index, 1);
    setInputField(values);
  };

  function sendData() {
    props.addProcessCall({
      categoryProcessName: categoryName,
      data:
        !isEmpty(inputField) &&
        inputField.map((v) => ({
          ...v,
          processName: v.processName,
          processDescription: v.processDescription,
        })),
    });
    setInputField([
      {
        processName: "",
        processDescription: "",
      },
    ]);
    setCategoryName("");
  }

  console.log(inputField, "qqjjss");

  useEffect(() => {}, []);

  return (
    <Container fluid>
      <div className="main">
        <Form>
          <Row>
            <Col lg="12" md="12" xs="12">
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>Category Name *</b>
                </Form.Label>
                <Form.Control
                  value={categoryName}
                  type="text"
                  id="categoryName"
                  placeholder="Category Name...."
                  onChange={(e) => setCategoryName(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          {inputField.map((inputField, index) => (
            <div key={index}>
              <Row>
                <Col lg="6" md="6" xs="12">
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <b>Process *</b>
                    </Form.Label>
                    <Form.Control
                      value={inputField.processName}
                      type="text"
                      id="processName"
                      placeholder="Process...."
                      onChange={(e) => handleChangeInput(index, e)}
                    />
                  </Form.Group>
                </Col>

                <Col lg="6" md="6" xs="12">
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <b>Description</b>
                    </Form.Label>
                    <Form.Control
                      value={inputField.processDescription}
                      type="text"
                      id="processDescription"
                      placeholder="Description...."
                      onChange={(e) => handleChangeInput(index, e)}
                    />
                  </Form.Group>
                </Col>

                <Col>
                  <IconButton
                    onClick={() => handleAddFields()}
                    style={{
                      float: "right",
                      color: "black",
                      marginTop: "15px",
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleRemoveFields(index)}
                    style={{
                      float: "right",
                      color: "black",
                      marginTop: "15px",
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>
                </Col>
              </Row>
            </div>
          ))}

          <div className="sendDiv">
            {props.isFetchingAddProcess ? (
              <div className="loader-div">
                <Spinner
                  variant="primary"
                  animation="grow"
                  size="xl"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            ) : (
              <Button
                disabled={
                  isEmpty(categoryName) || inputField[0].processName == ""
                }
                className="sendButton"
                onClick={() => sendData()}
                style={{
                  marginLeft: "2%",
                  color: "white",
                  width: "20%",
                  backgroundColor: "black",
                  border: "1px solid black",
                }}
              >
                Save
              </Button>
            )}
          </div>
        </Form>
      </div>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addProcessCall: (data) => dispatch(addProcessCall(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingAddProcess: state.processReducer.isFetchingAddProcess,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProcess);
