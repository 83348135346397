const initialState = {
    isFetchingAddProcess: false,
    isFetchingGetProcess: false,
    processDetail: {
        category: "",
        processes: [],
    },
    processInfo: [],

}


const processReducer = (state = initialState, action) => {
    switch (action.type) {
             //Process
        case "REQUEST_ADD_PROCESS":
            return { ...state, isFetchingAddProcess: true };

        case "SUCCESS_ADD_PROCESS":
            return {
                ...state, isFetchingAddProcess: false,
                processDetail: {
                    category: action.payload.categoryProcessName,
                    processes: action.payload.data,
                }
            };

        case "ERROR_ADD_PROCESS":
            return { ...state, isFetchingAddProcess: false };

        //Get Process
        case "REQUEST_GET_PROCESS":
            return { ...state, isFetchingGetProcess: true };

        case "SUCCESS_GET_PROCESS":
            return {
                ...state, isFetchingGetProcess: false,
                processInfo: action.payload.data,
            };

        case "ERROR_GET_PROCESS":
            return { ...state, isFetchingGetProcess: false };


        default:
            return state;
    }
}

export default processReducer;
