import API from "../../../global/api.js";

export const requestAdminDemand = () => {
    return {
        type: "REQUEST_ADMIN_DEMAND",
    };
};
export const successAdminDemand = (data) => {
    return {
        type: "SUCCESS_ADMIN_DEMAND",
        payload: data,
    };
};
export const errorAdminDemand = () => {
    return {
        type: "ERROR_ADMIN_DEMAND",
    };
};

export const getAdminDemand = () => {
    return (dispatch) => {
        dispatch(requestAdminDemand());
        API.get(`quantity/quantity_get_admin_data`).then((res) => {
            let getData = res.data;
            dispatch(successAdminDemand(getData));
        }).catch((error) => {
            dispatch(errorAdminDemand());
        })
    };
};


export const requestStatusUpdate = () => {
    return {
        type: "REQUEST_STATUS_UPDATE",
    };
};
export const successStatusUpdate = (data) => {
    return {
        type: "SUCCESS_STATUS_UPDATE",
        payload: data,
    };
};
export const errorStatusUpdate = () => {
    return {
        type: "ERROR_STATUS_UPDATE",
    };
};

export const updateAdminDemand = (data) => {
    return dispatch => {
        console.log(data, "dddddddddddddddddddddddddd");
        dispatch(requestStatusUpdate());
        API.put(`demand/update_Approved_status/${data.demandId}`,
            {
                status: "Approve",
                data: data.demand_quantities.map((x) => { let purchaseData = { demandQuantityId: Number(x.demandQuantityId), approvedQuantity: x.productQuantity }; return purchaseData }),
                // demandQuantityId: data.demand_quantities.map((x) =>{return x.demandQuantityId}),
                // approvedQuantity: data.demand_quantities.map((x) => {return x.productQuantity}),
            }, {
        })
            .then(res => {
                dispatch(getAdminDemand());
                dispatch(successStatusUpdate([data]))
            }).catch((error) =>
                dispatch(errorStatusUpdate()));
    }
};


export const updateAdminDemand1 = (data) => {
    return dispatch => {
        dispatch(requestStatusUpdate());
        API.put(`/demand/update_demand/${data.demandId}`,
            {
                status: "Disapprove",
            }, {
        })
            .then(res => {
                dispatch(getAdminDemand());
                dispatch(successStatusUpdate([data]))
            }).catch((error) =>
                dispatch(errorStatusUpdate()));
    }
};


export const requestAdminVoucher = () => {
    return {
        type: "REQUEST_ADMIN_VOUCHER",
    };
};
export const successAdminVoucher = (data) => {
    return {
        type: "SUCCESS_ADMIN_VOUCHER",
        payload: data,
    };
};
export const errorAdminVoucher = () => {
    return {
        type: "ERROR_ADMIN_VOUCHER",
    };
};

export const getAdminVoucher = () => {
    return (dispatch) => {
        dispatch(requestAdminVoucher());
        API.get(`/voucher/get_all_voucher_admin`).then((res) => {
            let getData = res.data;
            dispatch(successAdminVoucher(getData));
        }).catch((error) => {
            dispatch(errorAdminVoucher());
        })
    };
};



export const requestStatusUpdateVoucher = () => {
    return {
        type: "REQUEST_STATUS_UPDATE_VOUCHER",
    };
};
export const successStatusUpdateVoucher = (data) => {
    return {
        type: "SUCCESS_STATUS_UPDATE_VOUCHER",
        payload: data,
    };
};
export const errorStatusUpdateVoucher = () => {
    return {
        type: "ERROR_STATUS_UPDATE_VOUCHER",
    };
};

export const updateVoucherStatus = (data) => {
    return dispatch => {
        dispatch(requestStatusUpdateVoucher());
        API.put(`/voucher/update-status/${data.voucherId}`,
            {
                status: "Approve",
            }, {
        })
            .then(res => {
                dispatch(getAdminVoucher());
                dispatch(successStatusUpdateVoucher([data]))
            }).catch((error) =>
                dispatch(errorStatusUpdateVoucher()));
    }
};


export const updateVoucherStatus1 = (data) => {
    return dispatch => {
        dispatch(requestStatusUpdateVoucher());
        API.put(`/voucher/update-status/${data.voucherId}`,
            {
                status: "Disapprove",
            }, {
        })
            .then(res => {
                dispatch(getAdminVoucher());
                dispatch(successStatusUpdateVoucher([data]))
            }).catch((error) =>
                dispatch(errorStatusUpdateVoucher()));
    }
};


export const requestAdminPurchase = () => {
    return {
        type: "REQUEST_ADMIN_PURCHASE",
    };
};
export const successAdminPurchase = (data) => {
    return {
        type: "SUCCESS_ADMIN_PURCHASE",
        payload: data,
    };
};
export const errorAdminPurchase = () => {
    return {
        type: "ERROR_ADMIN_PURCHASE",
    };
};

export const getAdminPurchase = () => {
    return (dispatch) => {
        dispatch(requestAdminPurchase());
        API.get(`/purchase/get_purchase_admin`).then((res) => {
            let getData = res.data;
            dispatch(successAdminPurchase(getData));
        }).catch((error) => {
            dispatch(errorAdminPurchase());
        })
    };
};



export const requestStatusUpdatePurchase = () => {
    return {
        type: "REQUEST_STATUS_UPDATE_PURCHASE",
    };
};
export const successStatusUpdatePurchase = (data) => {
    return {
        type: "SUCCESS_STATUS_UPDATE_PURCHASE",
        payload: data,
    };
};
export const errorStatusUpdatePurchase = () => {
    return {
        type: "ERROR_STATUS_UPDATE_PURCHASE",
    };
};

export const updatePurchaseStatus = (data) => {
    return dispatch => {
        dispatch(requestStatusUpdatePurchase());
        API.put(`purchase/update_purchase_approved_status/${data.purchaseOrderId}`,
            {
                //                 approvedQuantity: data.create_demand.purchase_data.map((x) =>{return Number(x.productQuantity)}),
                // purchaseDataId: data.create_demand.purchase_data.map((x) =>{return x.purchaseDataId}),
                status: "Approve",
                data: data.purchase_data.map((x) => { let purchaseData = { approvedQuantity: Number(x.productQuantity), purchaseDataId: x.purchaseDataId }; return purchaseData }),
            }, {
        })
            .then(res => {
                dispatch(getAdminPurchase());
                dispatch(successStatusUpdatePurchase([data]))
            }).catch((error) =>
                dispatch(errorStatusUpdatePurchase()));
    }
};


export const updatePurchaseStatus1 = (data) => {
    return dispatch => {
        dispatch(requestStatusUpdatePurchase());
        API.put(`purchase/update_purchase_order/${data.purchaseOrderId}`,
            {
                status: "Disapprove",
            }, {
        })
            .then(res => {
                dispatch(getAdminPurchase());
                dispatch(successStatusUpdatePurchase([data]))
            }).catch((error) =>
                dispatch(errorStatusUpdatePurchase()));
    }
};


export const UpdateRequestDemand = () => {
    return {
        type: "UPDATE_REQUEST_DEMAND",
    };
};
export const UpdateSuccessDemand = (data) => {
    return {
        type: "UPDATE_SUCCESS_DEMAND",
        payload: data,
    };
};

export const UpdateErrorDemand = () => {
    return {
        type: "UPDATE_ERROR_DEMAND",
    };
};

export const updateDemand = (data, oldData) => {
    return dispatch => {
        dispatch(UpdateRequestDemand());
        API.put(`/quantity/update-demand-quantity/${oldData.demandId}`,
            {
                demandQuantityId: data.demandQuantityId,
                productQuantity: data.productQuantity
            }, {
        })
            .then(res => {
                dispatch(getAdminDemand());
                dispatch(UpdateSuccessDemand([data]))
            }).catch((error) =>
                dispatch(UpdateErrorDemand()));
    }
};



export const DeleteRequestDemand = () => {
    return {
        type: "DELETE_REQUEST_DEMAND",
    };
};
export const DeleteSuccessDemand = (demandId) => {
    return {
        type: "DELETE_SUCCESS_DEMAND",
        demandId: demandId,
    };
};
export const DeleteErrorDemand = () => {
    return {
        type: "DELETE_ERROR_DEMAND",
    };
};

export const deleteDemand = (demandId) => {
    return dispatch => {
        dispatch(DeleteRequestDemand());
        API.delete(`/quantity/quantity_delete_by_admin/${demandId}`)
            .then(res => {
                dispatch(getAdminDemand());
                dispatch(DeleteSuccessDemand(demandId))
            }).catch((error) =>
                dispatch(DeleteErrorDemand()));
    }
};

export const DeleteRequestDemandMaterial = () => {
    return {
        type: "DELETE_REQUEST_DEMAND_MATERIAL",
    };
};
export const DeleteSuccessDemandMaterial = (demandId) => {
    return {
        type: "DELETE_SUCCESS_DEMAND_MATERIAL",
        demandId: demandId,
    };
};
export const DeleteErrorDemandMaterial = () => {
    return {
        type: "DELETE_ERROR_DEMAND_MATERIAL",
    };
};

export const deleteDemandMaterial = (data) => {
    return dispatch => {
        console.log(data, "dataaaaaaaaaaa");
        dispatch(DeleteRequestDemandMaterial());
        API.delete(`/quantity/quantityData_delete/${data.demandId}`,
            {
                data: { demandQuantityId: data.demandQuantityId }
                //demandQuantityId : data.demandQuantityId,
            }, {
        })
            .then(res => {
                dispatch(getAdminDemand());
                dispatch(DeleteSuccessDemandMaterial([data]))
            }).catch((error) =>
                dispatch(DeleteErrorDemandMaterial()));
    }
};


export const DeleteRequestPurchase = () => {
    return {
        type: "DELETE_REQUEST_PURCHASE",
    };
};
export const DeleteSuccessPurchase = (purchaseOrderId) => {
    return {
        type: "DELETE_SUCCESS_PURCHASE",
        purchaseOrderId: purchaseOrderId,
    };
};
export const DeleteErrorPurchase = () => {
    return {
        type: "DELETE_ERROR_PURCHASE",
    };
};

export const deletePurchase = (purchaseOrderId) => {
    return dispatch => {
        dispatch(DeleteRequestPurchase());
        API.delete(`/purchase/purchase_delete_by_admin/${purchaseOrderId}`)
            .then(res => {
                dispatch(getAdminPurchase());
                dispatch(DeleteSuccessPurchase(purchaseOrderId))
            }).catch((error) =>
                dispatch(DeleteErrorPurchase()));
    }
};



export const DeleteRequestPurchaseMaterial = () => {
    return {
        type: "DELETE_REQUEST_PURCHASE_MATERIAL",
    };
};
export const DeleteSuccessPurchaseMaterial = (purchaseOrderId) => {
    return {
        type: "DELETE_SUCCESS_PURCHASE_MATERIAL",
        purchaseOrderId: purchaseOrderId,
    };
};
export const DeleteErrorPurchaseMaterial = () => {
    return {
        type: "DELETE_ERROR_PURCHASE_MATERIAL",
    };
};

export const deletePurchaseMaterial = (data) => {
    return dispatch => {
        dispatch(DeleteRequestPurchaseMaterial());
        API.delete(`/purchase/purchaseData_delete/${data.purchaseOrderId}`,
            {
                data: { purchaseDataId: data.purchaseDataId }
                //demandQuantityId : data.demandQuantityId,
            }, {
        })
            .then(res => {
                dispatch(getAdminPurchase());
                dispatch(DeleteSuccessPurchaseMaterial([data]))
            }).catch((error) =>
                dispatch(DeleteErrorPurchaseMaterial()));
    }
};


export const UpdateRequestPurchase = () => {
    return {
        type: "UPDATE_REQUEST_PURCHASE",
    };
};
export const UpdateSuccessPurchase = (data) => {
    return {
        type: "UPDATE_SUCCESS_PURCHASE",
        payload: data,
    };
};

export const UpdateErrorPurchase = () => {
    return {
        type: "UPDATE_ERROR_PURCHASE",
    };
};

export const updatePurchase = (data, oldData,handleDangerVisible) => {
    return dispatch => {
        dispatch(UpdateRequestPurchase());
        API.put(`/purchase/update-purchase-quantity/${oldData.purchaseOrderId}`,
            {
                purchaseDataId: data.purchaseDataId,
                productQuantity: Number(data.productQuantity),
                previousQuantity: Number(oldData.productQuantity),
                inventoryId:data.inventoryId,
                demandId:data.demandId
            }, {
        })
            .then(res => {
                dispatch(getAdminPurchase());
                dispatch(UpdateSuccessPurchase([data]))
            }).catch((error) =>
            {
                handleDangerVisible("Quantity Is Not Greater Than Demanded Quantity")
                dispatch(UpdateErrorPurchase())
            }
            );
    }
};


export const saveId = (data) => {
    return {
        type: "SAVE_ID",
        payload: data
    };
};