import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import {isNull } from "lodash";

import ViewSubProduct from "./viewSubProduct";
import AddSubProduct from "./addSubProduct";






const TotalSubSubProductData = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            {/* <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 92) ?
                    "AddSubProduct" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 93) ?
                        "SubProductList" :  ""} id="AddSubProduct" className="mb-3"> */}
                        <Tabs defaultActiveKey={
                    "AddSubProduct" } id="AddSubProduct" className="mb-3">
                <Tab eventKey="AddSubProduct" title="Add SubProduct"
                // disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 92) ? false : true}
                >  
                    <AddSubProduct />
                </Tab>
                <Tab eventKey="SubProductList" title="SubProduct List"
            //    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 93) ? false : true}
               > 
                    <ViewSubProduct/>
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalSubSubProductData);