import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { isNull } from "lodash";
import AddUom from "./addUom";
import ViewUom from "./viewUom";

const TotalUomData = (props) => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  return (
    <>
      {/* <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 92) ?
                    "AddUom" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 93) ?
                        "UomList" :  ""} id="AddUom" className="mb-3"> */}
      <Tabs defaultActiveKey={"AddUom"} id="AddUom" className="mb-3">
        <Tab
          eventKey="AddUom"
          title="Add UOM"
          // disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 92) ? false : true}
        >
          <AddUom />
        </Tab>
        <Tab
          eventKey="UomList"
          title="UOM List"
          //    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 93) ? false : true}
        >
          <ViewUom />
        </Tab>
      </Tabs>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TotalUomData);
