import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup } from "react-bootstrap";
import "./add-details.css";
import { connect } from "react-redux";
import { isEmpty, find, filter, isUndefined } from "lodash";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import Swal from "sweetalert";
import { addInvMasterControl } from "./AddDetails.actions";

const AddMasterControlInventory = (props) => {
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [type, setType] = useState("");
  const [value, setValue] = useState("")
  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Master Control Added Successfully",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };
  // const getPurchaseList = props.approvePurchaseList.map((x) => {let data = {value: x.purchaseOrderId, label: x.purchaseName}; return data});
  // const getDemandList1 = [isEmpty(props.purchaseListById) ? [] : { value: props.purchaseListById[0].purchase_data[0].create_demand.demandId, label: props.purchaseListById[0].purchase_data[0].create_demand.demandName }];/*
  function uploadPurchase() {
    props.addInvMasterControl({
      type: type,
      name: value
    }, handleVisible, handleDangerVisible)
    setType("")
    setValue("")
  }
  const data = [
    {
      id: '1',
      value: 'Model'
    },
    {
      id: '2',
      value: 'Color'
    },
    {
      id: '3',
      value: 'Size'
    },
  ]
  const gettypeList = data.map((x) => {
    let data = { value: x.id, label: x.value };
    return data;
  });
  const handleChangeType = (selectedOption) => {
    setType(selectedOption.label);
  };





  useEffect(() => {
  }, []);
  return (
    <>

      <Container fluid>
        <div className="main">
          <Row>
            <Col xl="6" lg="6" md="6" sm="6">
              <Form.Group>
                <Form.Label>
                  <b>Select Type
                    {/* <span style={{fontSize:'25px',fontWeight:'bold',color:'red'}}>*</span> */}
                  </b>
                </Form.Label>
                <Select
                  value={{ label: type }}
                  placeholder="Select Type..."
                  onChange={(e) => handleChangeType(e)}
                  options={gettypeList}
                //isOptionDisabled={(option) => !isEmpty(props.approveDemandList) && getChecked(filter(props.approveDemandList, y => y.demandId == option.value))}
                />
              </Form.Group>
            </Col>
            {type=='Size'?
            <Col xl="6" lg="6" md="6" sm="6">
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>Enter Value
                    {/* <span style={{fontSize:'25px',fontWeight:'bold',color:'red'}}>*</span> */}
                  </b>
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Value...."
                  name="Value#"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </Form.Group>
            </Col>:

            <Col xl="6" lg="6" md="6" sm="6">
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>Enter Value
                    {/* <span style={{fontSize:'25px',fontWeight:'bold',color:'red'}}>*</span> */}
                  </b>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Value...."
                  name="Value#"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </Form.Group>
            </Col>}
          </Row>
          {props.isAddingInvMasterControl ? (
            <div className="loader-div">
              <Button variant="info" disabled>
                <Spinner
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  variant="info"
                />
                {props.isAddingInvMasterControl ? "Saving..." : "Saving..."}
              </Button>
            </div>
          ) : (
            <div className="sendDiv">
              {type == "" ||
                value == ""
                ? (
                  <Button
                    disabled
                    style={{
                      marginLeft: "2%",
                      color: "white",
                      width: "20%",
                      backgroundColor: "black",
                      border: "1px solid black",
                    }}
                  >
                    Save
                  </Button>
                ) : (
                  <Button className="sendButton" onClick={uploadPurchase}>
                    Save
                  </Button>
                )}
            </div>
          )}
        </div>
      </Container>

    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    addInvMasterControl: (data, handleVisible, handleDangerVisible) => dispatch(addInvMasterControl(data, handleVisible, handleDangerVisible))
  };
};
const mapStateToProps = (state, ownProps) => ({
  isAddingInvMasterControl: state.AddDataReducer.isAddingInvMasterControl,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMasterControlInventory);
