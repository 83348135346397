import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup } from "react-bootstrap";
import "../add-details.css";
import {
  getAllInventory,
  addReceiving,
  getWarehouseData,
  getProductByVendor,
  setEditFields,
  updateReceiving,
} from "../AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, find, isUndefined, filter, sumBy } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import Swal from "sweetalert";
import moment from "moment";
import { getVendor } from "../Vendor/vendor.action";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const EditReceving = (props) => {
  const history = useHistory();
  const [inputField, setInputField] = useState([
    {
      inventoryId: "",
      productQuantityCount: "",
      inventoryPrice: "",
      productQuantity: 0,
      inventoryLabel: "",
    },
  ]);
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [wareHouseId, setwareHouseId] = useState("");
  const [wareHouselabel, setwareHouseLabel] = useState("");
  const [maximumQuantity, setMaximumQuantity] = useState(0);
  const [requistion, setRequistion] = useState();
  const [requistionLabel, setRequistionLabel] = useState();
  const [department, setdepartment] = useState();
  const [issuedBy, setIssuedBy] = useState();
  const [receivedBy, setReceivedBy] = useState();
  const [requistionType, setRequistionType] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [vendorName, setVendorName] = useState("");
  const [vendorId, setVendorId] = useState();

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
      history.push('/user/receiving')
    }, 4000);
    Swal({
      title: "Success",
      text: "Reciving Added Successfully",
      icon: "success",
      button: "Ok",
    });

  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };

  function uploadPurchase() {
    props.updateReceiving(
      props.receivingById[0].receiving_id,
      {
        receiving_date: props.receivingById[0].receiving_date,
        receivedBy: props.receivingById[0].receivedBy,
        reciving_description: props.receivingById[0].reciving_description,
        data: props.receivingById[0].receiving_data,
        vendor_id: props.receivingById[0].vendor_id,
        receiving_total: sumBy(props.receivingById[0].receiving_data, 'total_price'),
        voucher_Id:props.receivingById[0].voucher_Id
      },
      handleVisible,
      handleDangerVisible
    );
    setRequistionType("");
    setReceivedBy("");
    setIssuedBy("");
    setRequistionLabel("");
    setdepartment("");
    setRequistion("-1");
    setInputField([
      {
        inventoryId: "",
        productQuantityCount: "",
        inventoryPrice: "",
        productQuantity: "",
        inventoryLabel: "",
      },
    ]);
  }
  const inventory = !isEmpty(props.productByVendorList) && props.productByVendorList.map((x) => {
    let data = {
      value: x.inventoryId,
      label:
        x.warehouse.whKey +
        "-" +
        x.category.cateKey +
        "-" +
        x.invKey +
        " " +
        x.productName,
    };
    return data;
  });

  const handleChangeDemand = (e, index, type, item) => {
    let z = find(props.productByVendorList, (x) => x.inventoryId == item);

    const { name, value } = e;
    const list = [...props.receivingById[0].receiving_data];
    if (type == "intname") list[index]["inventoryId"] = e.value;
    if (type == "intname") list[index]["inventoryLabel"] = e.label;
    if (type == "intname")
      list[index]["productQuantity"] = Number(z.currentBlnc);
    if (type == "intname") setMaximumQuantity(Number(z.currentBlnc));
    if (type == "intquantity") {
      list[index]["productQuantity"] =
        e.target.value < 0 ? alert("cannot added less than 0") : e.target.value;
      list[index]["total_price"] = Number(list[index]['inventoryPrice']) * Number(e.target.value)
    }
    if (type == 'intPrice') {
      list[index]['inventoryPrice'] = Number(e.target.value)
      list[index]["total_price"] = Number(list[index]['productQuantity']) * Number(e.target.value)
    }
    props.setEditFields({
      key: "receiving_data",
      value: list
    })

    setInputField(list);
  };

  const handleChange = (e, index, type) => {
    let z = find(props.productByVendorList, (x) => x.inventoryId == e.value);
    const { name, value } = e;
    console.log(z, "testttttttttttttt")
    const list = [...props.receivingById[0].receiving_data];
    if (type == "intname") list[index]["inventoryId"] = e.value;
    if (type == "intname") list[index]["inventoryLabel"] = e.label;
    if (type == "intname") list[index]["inventoryPrice"] = z.productPrice;
    if (type == "intname")
      list[index]["total_product_quantity_count"] = Number(z.currentBlnc);
    if (type == "intname") setMaximumQuantity(Number(z.currentBlnc));
    if (type == "intquantity") list[index]["productQuantity"] = e.target.value;
    setInputField(list);
    props.setEditFields({
      key: "receiving_data",
      value: list
    })
    // list[index]['productQuantity'] = "";
  };

  const handleAddInput = () => {
    props.setEditFields({
      key: "receiving_data",
      value: [
        ...props.receivingById[0].receiving_data,
        { inventoryId: "", inventoryLabel: "", productQuantityCount: "", inventoryPrice: "", productQuantity: "" },
      ]
    })
  };

  const handleRemoveInput = (index) => {
    const list = [...props.receivingById[0].receiving_data];
    list.splice(index, 1);
    props.setEditFields({
      key: "receiving_data",
      value: list
    })
  };
  const getVendorList =
    !isEmpty(props.vendorInfo) &&
    props.vendorInfo.map((x) => {
      let data = { value: x.vendor_id, label: x.vendor_name };
      return data;
    })
  const handleChangeVendor = (selectedOption) => {
    // setVendorId(selectedOption.value);
    props.setEditFields({ key: "vendor_id", value: selectedOption.value })
    props.setEditFields({ key: "vendor_name", value: selectedOption.label })
    // setVendorName(selectedOption.label);
    props.getProductByVendor(selectedOption.value)
  };

  useEffect(() => {
    !isEmpty(props.receivingById) ? props.getProductByVendor(props.receivingById[0].vendor_id) : props.getProductByVendor(2)
    props.getVendor();

  }, []);
  return (
    <>
      {props.isFetchingInventoryAll || props.isFetchingGetVendor || props.isFetchingReceivingById ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {/* {props.isFetchingSectorsSummary ? 'Loading...' : 'Loading...'} */}
          </Button>
        </div>
      ) : (
        <Container fluid>
          {console.log(props.receivingById, 'uiuiui')}
          <div className="main">
            <Row>
              <Col xl="12" lg="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Date <span style={{ fontSize: '25px', fontWeight: 'bold', color: 'red' }}>*</span></b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Date...."
                    name="Date"
                    value={!isEmpty(props.receivingById) && props.receivingById[0].receiving_date}
                    // onChange={(e) =>
                    //   setDate(moment(e.target.value).format("YYYY-MM-DD"))
                    // }
                    onChange={(e) => { props.setEditFields({ key: "receiving_date", value: moment(e.target.value).format("YYYY-MM-DD") }); }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b>Select Vendor <span style={{ fontSize: '25px', fontWeight: 'bold', color: 'red' }}>*</span></b>
                  </Form.Label>
                  <Select
                    isDisabled={
                      isEmpty(getVendorList)}
                    value={{ label: !isEmpty(props.receivingById) && props.receivingById[0].vendor_name }}
                    placeholder="Select Vendor..."
                    onChange={(e) => handleChangeVendor(e)}
                    options={getVendorList}
                  // onBlur={() => setStateValue(false)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Received By:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Received By...."
                    name="receivedBy"
                    value={!isEmpty(props.receivingById) && props.receivingById[0].receivedBy}
                    onChange={(e) => { props.setEditFields({ key: "receivedBy", value: e.target.value }); }}
                  // onChange={(e) => setReceivedBy(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Description:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Description...."
                    name="requistionType"
                    value={!isEmpty(props.receivingById) && props.receivingById[0].reciving_description}
                    onChange={(e) => { props.setEditFields({ key: "reciving_description", value: e.target.value }); }}
                  // onChange={(e) => setRequistionType(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            {!isEmpty(props.receivingById) && props.receivingById[0].receiving_data.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <Row>
                    <Col xl="6" lg="6" md="12" sm="12">
                      <Form.Group>
                        <Form.Label>
                          <b> Select Inventory <span style={{ fontSize: '25px', fontWeight: 'bold', color: 'red' }}>*</span></b>
                        </Form.Label>
                        <Select
                          value={{ label: item.inventoryLabel }}
                          placeholder="Select Inventory..."
                          name="inventoryId"
                          onChange={(e) => handleChange(e, i, "intname")}
                          // options={isEmpty(inventory) || (wareHouseId === "") ? [] : filter(inventory, { whId: wareHouseId })}
                          options={
                            isEmpty(inventory)
                              ? []
                              : inventory
                          }
                          isDisabled={isEmpty(inventory)}
                          isOptionDisabled={(option) =>
                            !isEmpty(
                              find(
                                !isEmpty(props.receivingById) && props.receivingById[0].receiving_data,
                                (x) => x.inventoryId == option.value
                              )
                            )
                          }
                        />
                      </Form.Group>
                    </Col>

                    {/* <Col xl="3" lg="3" md="6" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Inventories Quantity <span style={{ fontSize: '25px', fontWeight: 'bold', color: 'red' }}>*</span></b>
                        </Form.Label>
                        <Form.Control
                          placeholder="Inventories Quantity...."
                          name="productQuantityCount"
                          disabled
                          value={item.total_product_quantity_count}
                        />
                      </Form.Group>
                    </Col> */}
                    <Col xl="3" lg="3" md="6" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Inventory Price <span style={{ fontSize: '25px', fontWeight: 'bold', color: 'red' }}>*</span></b>
                        </Form.Label>
                        <Form.Control
                          placeholder="Inventory Price...."
                          type="number"
                          // disabled
                          value={item.inventoryPrice}
                          onChange={(e) =>
                            handleChangeDemand(
                              e,
                              i,
                              "intPrice",
                              item.inventoryId
                            )
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col xl="3" lg="3" md="6" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Add Quantity <span style={{ fontSize: '25px', fontWeight: 'bold', color: 'red' }}>*</span></b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Add Quantity...."
                          name="productQuantity"
                          min={0}
                          value={item.productQuantity}
                          onChange={(e) =>
                            handleChangeDemand(
                              e,
                              i,
                              "intquantity",
                              item.inventoryId
                            )
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Row>
                      <Col lg="12">
                        {!isEmpty(props.receivingById) && props.receivingById[0].receiving_data.length - 1 == i && (
                          <IconButton
                            title="Click to Add Demand"
                            onClick={handleAddInput}
                            style={{
                              float: "right",
                              color: "black",
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        )}

                        {!isEmpty(props.receivingById) && props.receivingById[0].receiving_data.length !== 1 && (
                          <IconButton
                            title="Click to Remove Demand"
                            onClick={() => handleRemoveInput(i)}
                            style={{ float: "right", color: "black" }}
                          >
                            <RemoveIcon />
                          </IconButton>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                </React.Fragment>
              );
            })}
            <div className="sendDiv">
              {/* {
                // isUndefined(requistion) ||
                vendorName == "" ||
                  date == "" ||
                  inputField[0].inventoryId == "" ||
                  inputField[0].productQuantity == 0 ? (
                  <Button
                    disabled
                    style={{ color: "black" }}
                    className="sendButton"
                  >
                    Save
                  </Button>
                ) : ( */}
                  <Button className="sendButton" onClick={uploadPurchase}>
                    Save
                  </Button>
                {/* )
              } */}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    addReceiving: (data, handleVisible, handleDangerVisible) =>
      dispatch(addReceiving(data, handleVisible, handleDangerVisible)),
    updateReceiving: (id,data, handleVisible, handleDangerVisible) =>
      dispatch(updateReceiving(id,data, handleVisible, handleDangerVisible)),
    
    getAllInventory: (data) => dispatch(getAllInventory(data)),
    getWarehouseData: () => dispatch(getWarehouseData()),
    getVendor: () => dispatch(getVendor()),
    getProductByVendor: (id) => dispatch(getProductByVendor(id)),
    setEditFields: (data) => dispatch(setEditFields(data))

  };
};
const mapStateToProps = (state, ownProps) => ({
  allInventory: state.AddDataReducer.allInventory,
  isFetchingInventoryAll: state.AddDataReducer.isFetchingInventoryAll,
  warehouseList: state.AddDataReducer.warehouseList,
  isFetchingwareHouseList: state.AddDataReducer.isFetchingwareHouseList,
  productByVendorList: state.AddDataReducer.productByVendorList,
  vendorInfo: state.vendorReducer.vendorInfo,
  isFetchingGetVendor: state.vendorReducer.isFetchingGetVendor,
  isFetchingReceivingById: state.AddDataReducer.isFetchingReceivingById,
  receivingById: state.AddDataReducer.receivingById,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditReceving);
