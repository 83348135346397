import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import {isNull } from "lodash";
import AddColor from "./addColor";
import ViewColor from "./viewColor";




const TotalColorData = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            {/* <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 92) ?
                    "AddColor" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 93) ?
                        "ColorList" :  ""} id="AddColor" className="mb-3"> */}
                        <Tabs defaultActiveKey={
                    "AddColor" } id="AddColor" className="mb-3">
                <Tab eventKey="AddColor" title="Add Color"
                // disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 92) ? false : true}
                >  
                    <AddColor />
                </Tab>
                <Tab eventKey="ColorList" title="Color List"
            //    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 93) ? false : true}
               > 
                    <ViewColor/>
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalColorData);