import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Tabs, Tab, Alert } from "react-bootstrap";
import "./add-details.css";
import {
    getCategoryData, addCategoryName, getWarehouseData, getsubCategoryData, getInventory
    , deleteInventory, updateInventory, resetReducer, getAllInventory, categoryByWareHouse, subCategoryByCategory,
    getInvMasterControl
} from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, get, find, chain, uniqBy, filter } from "lodash";
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import Select from 'react-select';
import { Link } from 'react-router-dom';

const InventoryMaterialTable = (props) => {
    const tableRef = useRef();
    function updateTableQuery() {
        tableRef.current.onQueryChange()
    };

    const getInvMasterControlList = props.invMasterControlList.map((x) => {
        let data = { value: x.id, label: x.name, type: x.type };
        return data;
    });
    const modelArry = filter(getInvMasterControlList, ['type', 'Model'])
    const colorArry = filter(getInvMasterControlList, ['type', 'Color'])
    const sizeArry = filter(getInvMasterControlList, ['type', 'Size'])

    const wareHouseData = props.allInventory.map(function (el) {
        var o = Object.assign({}, el);
        o.key = el.warehouse.whKey + " " + '-' + " " + el.category.cateKey + " " + '-' + " " + el.sub_category.subcateKey + " " + '-' + " " + el.invKey;
        return o;
    });
    useEffect(() => {

        props.getInvMasterControl()
    }, []);

    return (
        <div>
            {props.isFetchingwareHouseList || props.isFetchingcategoryList || props.isFetchingsubCategoryList || props.isFetchingInventoryGetdata ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />

                    </Button>
                </div>
            ) : (
                <div >
                    <div className="main">
                        {/* {stateValue == true ?  */}

                        <div className="sendDiv">
                            <Link to="/user/add-inventory">
                                <Button className="sendButton"> Back to Search</Button>
                            </Link>
                        </div>

                        <MaterialTable
                            title="Inventory Details"
                            icons={{ Filter: () => <div /> }}
                            columns={uniqBy([
                                { title: 'Inventory Code', field: 'key', editable: 'never' },
                                { title: 'WareHouse', field: 'warehouse.wareHouseName', editable: 'never' },
                                { title: 'Category', field: 'category.categoryName', editable: 'never' },
                                { title: 'SubCategory', field: 'sub_category.subCategoryName', editable: 'never' },
                                { title: 'Company Name', field: 'companyName' },
                                { title: 'Inventory Field', field: 'inventoryField' },
                                { title: 'Product Type', field: 'productType' },
                                {
                                    title: 'Inventory Name', field: 'productName', render: rowData => <div style={{
                                        backgroundColor: Number(rowData.currentBlnc) <= Number(rowData.minBlnc) ? '#ff8d8d' : Number(rowData.currentBlnc) >= Number(rowData.maxBlnc) ? '#addfac' : 'transparent'
                                    }}> {rowData.productName}</div>
                                },
                                { title: 'Product Price', field: 'productPrice' },
                                { title: 'Currency', field: 'currency' },
                                { title: 'Arrival Date', field: 'arrival_date', render: rowData => moment(rowData.arrival_date).format("YYYY-MM-DD") },
                                { title: 'Vendor Name', field: 'vendor.vendor_name' },
                                { title: 'Current Balance', field: 'currentBlnc' },
                                { title: 'Unit of Measure', field: 'uom' },
                                // { title: 'Minimum Balance', field: 'minBlnc' },
                                // { title: 'Maximum Balance', field: 'maxBlnc' },
                                // { title: 'Size', field: 'invSize' },
                                // { title: 'Color', field: 'invColor' },
                                {
                                    title: 'Model', field: 'inv_variation_model.name',
                                    editComponent: ({ value, onChange }) => (
                                        <select onChange={(e) => onChange(e.target.value)} style={{ padding: '10px' }}>
                                            <option selected value={value}>
                                                {value}
                                            </option>
                                            {modelArry.map(

                                                (item) =>

                                                    item.label !== value && (
                                                        console.log(item, value, "tttttttt"),
                                                        <option key={item.value} value={item.label}>
                                                            {item.label}
                                                        </option>
                                                    )
                                            )}
                                        </select>
                                    )
                                },
                                { title: 'Size', field: 'inv_variation_size.name',
                                    editComponent: ({ value, onChange }) => (
                                        <select onChange={(e) => onChange(e.target.value)} style={{ padding: '10px' }}>
                                            <option selected value={value}>
                                                {value}
                                            </option>
                                            {sizeArry.map(

                                                (item) =>

                                                    item.label !== value && (
                                                        console.log(item, value, "tttttttt"),
                                                        <option key={item.value} value={item.label}>
                                                            {item.label}
                                                        </option>
                                                    )
                                            )}
                                        </select>
                                    )
                                 },
                                { title: 'Color', field: 'inv_variation_colour.name',
                                    editComponent: ({ value, onChange }) => (
                                        <select onChange={(e) => onChange(e.target.value)} style={{ padding: '10px' }}>
                                            <option selected value={value}>
                                                {value}
                                            </option>
                                            {colorArry.map(

                                                (item) =>

                                                    item.label !== value && (
                                                        console.log(item, value, "tttttttt"),
                                                        <option key={item.value} value={item.label}>
                                                            {item.label}
                                                        </option>
                                                    )
                                            )}
                                        </select>
                                    )
                                 },
                                // { title: 'Lenght', field: 'length' },
                                // { title: 'Height', field: 'height' },
                                // { title: 'Width', field: 'width' },
                                // { title: 'Thickness', field: 'thickness' },
                                // { title: 'Rack Position', field: 'rackPosition' },
                                // { title: 'Box No', field: 'box' },
                                // { title: 'HS Code', field: 'hsCode' },
                                { title: 'Invertory Description', field: 'invertoryDesc1' },
                                // { title: 'Invertory Description 2', field: 'invertoryDesc2' },
                                // { title: 'Invertory Description 3', field: 'invertoryDesc3' },
                                // { title: 'Invertory Description 4', field: 'invertoryDesc4' },
                            ], 'title')}
                            // data={(!isUndefined(startDate) && !isUndefined(endDate)) ? wareHouseData.filter(item => item.arrival_date >= startDate && item.arrival_date <= endDate) : wareHouseData}
                            data={wareHouseData}
                            options={{
                                actionsColumnIndex: -1,
                                filtering: true,
                                exportButton: true,
                                paging: true,
                                pageSize: 200,       // make initial page size
                                emptyRowsWhenPaging: false,   // To avoid of having empty rows
                                pageSizeOptions: [50, 100, 150, 200],    // rows selection options
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                }
                            }}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            if (oldData) {
                                                props.updateInventory(newData, oldData, getInvMasterControlList)

                                            }
                                        }, 600);
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.deleteInventory(oldData.inventoryId);
                                        }, 600);
                                    }),
                            }}
                        />



                    </div>
                </div>
            )}

        </div>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        addCategoryName: (data) => dispatch(addCategoryName(data)),
        getCategoryData: () => dispatch(getCategoryData()),
        getWarehouseData: () => dispatch(getWarehouseData()),
        getsubCategoryData: () => dispatch(getsubCategoryData()),
        getInventory: (id) => dispatch(getInventory(id)),
        deleteInventory: (inventoryId) => dispatch(deleteInventory(inventoryId)),
        updateInventory: (newData, oldData, getInvMasterControlList) => dispatch(updateInventory(newData, oldData, getInvMasterControlList)),
        resetState: () => dispatch(resetReducer()),
        getAllInventory: (data) => dispatch(getAllInventory(data)),
        categoryByWareHouse: (data) => dispatch(categoryByWareHouse(data)),
        subCategoryByCategory: (data) => dispatch(subCategoryByCategory(data)),
        getInvMasterControl: () => dispatch(getInvMasterControl()),
    };
};
const mapStateToProps = (state, ownProps) => ({
    warehouseList: state.AddDataReducer.warehouseList,
    categoryList: state.AddDataReducer.categoryList,
    subCategoryList: state.AddDataReducer.subCategoryList,
    inventoryList: state.AddDataReducer.inventoryList,
    isFetchingwareHouseList: state.AddDataReducer.isFetchingwareHouseList,
    isFetchingcategoryList: state.AddDataReducer.isFetchingcategoryList,
    isFetchingsubCategoryList: state.AddDataReducer.isFetchingsubCategoryList,
    isFetchingInventoryGetdata: state.AddDataReducer.isFetchingInventoryAll,
    allInventory: state.AddDataReducer.allInventory,
    categoryByWareHouseValue: state.AddDataReducer.categoryByWareHouseValue,
    subCategoryByCategoryValue: state.AddDataReducer.subCategoryByCategoryValue,
    isFetchingInvMasterControl: state.AddDataReducer.isFetchingInvMasterControl,
    invMasterControlList: state.AddDataReducer.invMasterControlList,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InventoryMaterialTable);