import API from '../../../../../global/api';
import { forEach } from "lodash";

//Add Brand Call

export const addBrandCall = (data) => {
    return (dispatch) => {
        dispatch(requestAddBrand())
        API.post('brand/add_brand', data).then((res) => {
            dispatch(getBrandCall())
            dispatch(successAddBrand(data))
        }).catch((error) => {
            dispatch(errorAddBrand())
        })
    }
}

export const requestAddBrand = () => {
    return {
        type: "REQUEST_ADD_BRAND"
    }
}

export const successAddBrand = (data) => {
    return {
        type: "SUCCESS_ADD_BRAND",
        payload: data,
    }
}

export const errorAddBrand = () => {
    return {
        type: "ERROR_ADD_BRAND"
    }
}



//Get Brand Call

export const getBrandCall = () => {
    return (dispatch) => {
        dispatch(requestGetBrand())
        API.get('brand/show_brand').then((res) => {
            dispatch(successGetBrand(res.data))
        }).catch((error) => {
            dispatch(errorGetBrand())
        })
    }
}

export const requestGetBrand = () => {
    return {
        type: "REQUEST_GET_BRAND"
    }
}

export const successGetBrand = (data) => {
    return {
        type: "SUCCESS_GET_BRAND",
        payload: data
    }
}

export const errorGetBrand = () => {
    return {
        type: "ERROR_GET_BRAND"
    }
}
// delete
export const requestDeleteBrand = () => {
    return {
      type: "REQUEST_DELETE_BRAND",
    };
  };
  
  export const successDeleteBrand = (brandId) => {
    return {
      type: "SUCCESS_DELETE_BRAND",
      brandId: brandId,
    };
  };
  
  export const errorDeleteBrand = () => {
    return {
      type: "ERROR_DELETE_BRAND",
    };
  };
  export const deleteBrand = (brandId) => {
    return (dispatch) => {
      dispatch(requestDeleteBrand());
      API.delete(`/brand/${brandId}`)
        .then((res) => {
          dispatch(getBrandCall());
          dispatch(successDeleteBrand(brandId));
        })
        .catch((error) => {
          dispatch(errorDeleteBrand());
        });
    };
  };
  
  // update
  
  export const requestUpdateBrand = () => {
    return {
      type: "REQUEST_UPDATE_BRAND",
    };
  };
  
  export const successUpdateBrand = (data) => {
    return {
      type: "SUCCESS_UPDATE_BRAND",
      payload: data,
    };
  };
  
  export const errorUpdateBrand = () => {
    return {
      type: "ERROR_UPDATE_BRAND",
    };
  };
  
  export const updateBrand = (newData, oldData) => {
    return (dispatch) => {
      dispatch(requestUpdateBrand());
      API.put(`/update/${oldData.brandId}`, {
        brandName: newData.brandName,
        brandDescription: newData.brandDescription,
      })
        .then((res) => {
          dispatch(getBrandCall());
          dispatch(successUpdateBrand([newData]));
        })
        .catch((error) => {
          dispatch(errorUpdateBrand());
        });
    };
  };