const initialState = {
   
    isFetchingAddUOM: false,
    isFetchingGetUOM: false,
    uomDetail: {
        uom: "",
        description: "",
    },
    uomInfo: [],

}


const uomReducer = (state = initialState, action) => {
    switch (action.type) {
     //UOM
     case "REQUEST_ADD_UOM":
        return { ...state, isFetchingAddUOM: true };

    case "SUCCESS_ADD_UOM":
        return {
            ...state, isFetchingAddUOM: false,
            uomDetail: {
                uom: action.payload.uomName,
                description: action.payload.uomDescription,
            },
        };

    case "ERROR_ADD_UOM":
        return { ...state, isFetchingAddUOM: false };

    //Get UOM
    case "REQUEST_GET_UOM":
        return { ...state, isFetchingGetUOM: true };

    case "SUCCESS_GET_UOM":
        return {
            ...state, isFetchingGetUOM: false,
            uomInfo: action.payload.data,
        };

    case "ERROR_GET_UOM":
        return { ...state, isFetchingGetUOM: false };


        default:
            return state;
    }
}

export default uomReducer;
