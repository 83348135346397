import API from '../../../../../global/api';
import { forEach } from "lodash";

//Add Product Call

export const addProductCall = (data) => {
    return (dispatch) => {
        dispatch(requestAddProduct())
        API.post('product/add_mein_product', data).then((res) => {
            dispatch(getProductCall())
            dispatch(successAddProduct(data))
        }).catch((error) => {
            dispatch(errorAddProduct())
        })
    }
}

export const requestAddProduct = () => {
    return {
        type: "REQUEST_ADD_PRODUCT"
    }
}

export const successAddProduct = (data) => {
    return {
        type: "SUCCESS_ADD_PRODUCT",
        payload: data,
    }
}

export const errorAddProduct = () => {
    return {
        type: "ERROR_ADD_PRODUCT"
    }
}



//Get Product Call

export const getProductCall = () => {
    return (dispatch) => {
        dispatch(requestGetProduct())
        API.get('product/show_all_main_product').then((res) => {
            dispatch(successGetProduct(res.data))
        }).catch((error) => {
            dispatch(errorGetProduct())
        })
    }
}

export const requestGetProduct = () => {
    return {
        type: "REQUEST_GET_PRODUCT"
    }
}

export const successGetProduct = (data) => {
    return {
        type: "SUCCESS_GET_PRODUCT",
        payload: data
    }
}

export const errorGetProduct = () => {
    return {
        type: "ERROR_GET_PRODUCT"
    }
}
export const requestDeleteProduct = () => {
    return {
      type: "REQUEST_DELETE_PRODUCT",
    };
  };
  
  export const successDeleteProduct = (productId) => {
    return {
      type: "SUCCESS_DELETE_PRODUCT",
      productId: productId,
    };
  };
  
  export const errorDeleteProduct = () => {
    return {
      type: "ERROR_DELETE_PRODUCT",
    };
  };
  export const deleteProduct = (productId) => {
    return (dispatch) => {
      dispatch(requestDeleteProduct());
      API.delete(`/product/${productId}`)
        .then((res) => {
          dispatch(getProductCall());
          dispatch(successDeleteProduct(productId));
        })
        .catch((error) => {
          dispatch(errorDeleteProduct());
        });
    };
  };
  
  // update
  
  export const requestUpdateProduct = () => {
    return {
      type: "REQUEST_UPDATE_PRODUCT",
    };
  };
  
  export const successUpdateProduct = (data) => {
    return {
      type: "SUCCESS_UPDATE_PRODUCT",
      payload: data,
    };
  };
  
  export const errorUpdateProduct = () => {
    return {
      type: "ERROR_UPDATE_PRODUCT",
    };
  };
  
  export const updateProduct = (newData, oldData) => {
    return (dispatch) => {
      dispatch(requestUpdateProduct());
      API.put(`/update/${oldData.mainProId
      }`, {
        name: newData.name,
        // productDescription: newData.productDescription,
      })
        .then((res) => {
          dispatch(getProductCall());
          dispatch(successUpdateProduct([newData]));
        })
        .catch((error) => {
          dispatch(errorUpdateProduct());
        });
    };
  };