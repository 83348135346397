import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import {
  getSubProductCall,
  deleteSubProduct,
  updateSubProduct,
} from "./addSubProduct.action";

const ViewSubProduct = (props) => {
  useEffect(() => {
    props.getSubProductCall();
  }, []);

  return (
    <>
      {props.isFetchingGetSubProduct ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialTable
              title="Sub Product Details"
              columns={[
                {
                  title: "Product",
                  field: "mainProId",
                  render: (rowData) => rowData.main_product.name,
                },
                { title: "Sub Product", field: "name" },
              ]}
              data={props.subProductInfo}
              options={{
                actionsColumnIndex: -1,
                filtering: true,
                exportButton: true,
                exportAllData: true,
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.updateSubProduct(newData, oldData);
                    }, 600);
                  }),

                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteSubProduct(oldData.subProId);
                    }, 600);
                  }),
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubProductCall: () => dispatch(getSubProductCall()),
    deleteSubProduct: (subProId) => dispatch(deleteSubProduct(subProId)),
    updateSubProduct: (newData, oldData) =>
      dispatch(updateSubProduct(newData, oldData)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  subProductInfo: state.subProductReducer.subProductInfo,
  isFetchingGetSubProduct: state.subProductReducer.isFetchingGetSubProduct,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewSubProduct);
