import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import {
  isEmpty,
  isUndefined,
  get,
  flatMap,
  map,
  groupBy,
  difference,
} from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import Select from "react-select";
import { getOrderCall, getOrderArticleCall } from "./OrderCreation.actions";

const ArticleMaterialReq = (props) => {
  const getOrder = props.orderInfo.map((x) => {
    let data = { label: x.orderId, value: x.orderId };
    return data;
  });

  const [orderNum, setOrderNum] = useState("");

  const setOrder = (selectedOption) => {
    setOrderNum(selectedOption.value);
    props.getOrderArticleCall(selectedOption.value);
  };
  const dataArticle = "01";

  // const dataMaterial = !isUndefined(props.orderArticleInfo) && (props.orderArticleInfo[0]);
  //const dataMaterial = !isEmpty(props.orderArticleInfo) ? props.orderArticleInfo[0].order_articles : [];

  //    const dataMaterial = !isEmpty(props.orderArticleInfo) ? props.orderArticleInfo[0].order_articles.map(function (el) {
  //     console.log(el, "ellelelelelele");
  //     var o = Object.assign({}, el);
  //     o.materialName = el.article.material_costs.map((x) => {console.log(x, "xxxxxxxxxxxx")});
  //     return o;
  // }) : [];
  const dataMaterial = flatMap(
    !isEmpty(props.orderArticleInfo) &&
      props.orderArticleInfo[0].order_articles,
    ({ quantity, article }) =>
      map(article.material_costs, (tag) => ({ quantity, ...tag }))
  );

  const dataMaterialResults = !isEmpty(dataMaterial)
    ? dataMaterial.map(function (el) {
        console.log(el, "ellelelelelele");
        var o = Object.assign({}, el);
        o.mr = el.materialQuantity * el.quantity;
        return o;
      })
    : [];

  const dataMaterialGroupBy = groupBy(
    !isEmpty(dataMaterialResults) && dataMaterialResults,
    function (x) {
      return x.inventory.inventoryId;
    }
  );
  console.log(dataMaterialGroupBy, "eeeeeeee1");
  console.log(difference(dataMaterialGroupBy["1"]), "eeeeeeee");
  let x1;
  const materialKeys = !isEmpty(dataMaterialGroupBy)
    ? Object.keys(dataMaterialGroupBy)
    : [];
  const materialKeysData =
    !isEmpty(materialKeys) &&
    materialKeys.map((x) => {
      x1 = get(dataMaterialGroupBy, `${x}`);
    });

  let materiaFinal = [];
  materiaFinal.push(!isEmpty(x1) ? x1[0] : []);

  console.log(
    !isEmpty(props.orderArticleInfo) &&
      props.orderArticleInfo[0].order_articles,
    "aaaaaaaaaaa"
  );
  console.log(dataMaterial, "dddddddddddddd");
  console.log(dataMaterialResults, "reeeeeeeeeeeee");
  console.log(dataMaterialGroupBy, "grpppppppppp");
  console.log(materialKeys, "kkkkkkkkkkkkkkkk");
  console.log(materialKeysData, "mmmmmmmmmmmmmmmmmm");
  console.log(x1, "x1x1x1x1x1x1xx1x1x1x1x1x");
  console.log(materiaFinal, "ffffffffffffffff");

  /*  const [stateArticle, setStateArticle] = useState({
        columns: [
            { title: 'Material Name', field: 'inventory.productName' },
            { title: 'UOM', field: 'inventory.uom' },
            { title: 'Material Quantity', field: 'materialQuantity' },
            { title: 'Article Quantity', field: 'quantity' },
            { title: 'Total Material', field:"totalMaterial", render: rowData => (Number(rowData.materialQuantity) * Number(rowData.quantity)) },
        ],
    });*/

  const sendData = () => {
    props.getOrderArticleCall(orderNum);
  };

  useEffect(() => {
    props.getOrderCall();
  }, []);

  return (
    <>
      {props.isFetchingGetOrder ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <Row>
                  <Col lg="12" md="12" xs="12" styles={{ zIndex: -1 }}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Order *</b>
                      </Form.Label>
                      <Select
                        value={{ label: orderNum }}
                        placeholder="Select Order #..."
                        onChange={(e) => {
                          setOrder(e);
                        }}
                        options={getOrder}
                        // onBlur={()=>sendData()}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <MaterialTable
                  title={`Order # ${
                    !isUndefined(props.orderArticleInfo) &&
                    props.orderArticleInfo.orderId
                  } Details`}
                  columns={[
                    { title: "Material Name", field: "inventory.productName" },
                    { title: "UOM", field: "inventory.uom" },
                    { title: "Material Quantity", field: "materialQuantity" },
                    { title: "Article Quantity", field: "quantity" },
                    {
                      title: "Total Material",
                      field: "totalMaterial",
                      render: (rowData) =>
                        Number(rowData.materialQuantity) *
                        Number(rowData.quantity),
                    },
                  ]}
                  //data={materiaFinal}
                  data={x1}
                />
              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrderArticleCall: (data) => dispatch(getOrderArticleCall(data)),
    getOrderCall: () => dispatch(getOrderCall()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  orderInfo: state.OrderCreationReducer.orderInfo,
  isFetchingGetOrder: state.OrderCreationReducer.isFetchingGetOrder,
  orderArticleInfo: state.OrderCreationReducer.orderArticleInfo,
  isFetchingGetOrderArticle:
    state.OrderCreationReducer.isFetchingGetOrderArticle,
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleMaterialReq);

// import React, { useEffect, useState } from "react";
// import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
// import { connect } from "react-redux";
// import { isEmpty, isUndefined, get } from "lodash";
// import Spinner from 'react-bootstrap/Spinner';
// import MaterialTable from 'material-table';
// import 'jspdf-autotable';
// import Select from 'react-select';
// import AddIcon from '@material-ui/icons/Add';
// import RemoveIcon from '@material-ui/icons/Remove';
// import { IconButton } from "@material-ui/core";
// import { getOrderCall, getOrderArticleCall } from "./OrderCreation.actions";

// const ArticleMaterialReq = (props) => {

//     const getOrder = props.orderInfo.map((x) => { let data = { label: x.orderId, value: x.orderId }; return data });

//     const [orderNum, setOrderNum] = useState("");

//     const setOrder = (selectedOption) => {
//         setOrderNum(selectedOption.value);
//     };

//     const [state, setState] = useState({
//         columns: [
//             { title: 'Article ID', field: 'orderId' },
//         ],
//     });

//     const dataArticle='01';

//     const dataMaterial = !isUndefined(props.orderArticleInfo) && (props.orderArticleInfo[0]);
//     // console.log(dataMaterial.order_articles, "kktt")

//     const [stateArticle, setStateArticle] = useState({
//         columns: [
//             { title: 'Article ID', field: 'articleId' },
//             { title: 'Material Name', field: 'materialName' },
//             { title: 'Material Quantity', field: 'materialQuantity' },
//             { title: 'Article Quantity', field: 'articleQuantity' },
//             { title: 'Total Material', field:"totalMaterial", render: rowData => (rowData.materialQuantity * rowData.articleQuantity) },
//         ],
//     });

//     console.log(!isUndefined(props.orderArticleInfo) && props.orderArticleInfo[0], "ffyy")

//     const sendData = () => {
//         props.getOrderArticleCall(orderNum)
//     }

//     useEffect(() => {
//         props.getOrderCall();
//     }, []);

//     return (
//         <Container fluid>
//             <div className="main">

//                 <div>
//                     <Form>
//                         <Row>
//                             <Col lg="12" md="12" xs="12" styles={{ zIndex: -1 }}>
//                                 <Form.Group className="mb-3">
//                                     <Form.Label><b>Order #:</b></Form.Label>
//                                     <Select
//                                         placeholder="Select Order #..."
//                                         onChange={setOrder}
//                                         options={getOrder}
//                                         onBlur={()=>sendData()}
//                                     />
//                                 </Form.Group>
//                             </Col>
//                         </Row>
// {/*
//                         <div className="sendDiv">
//                             <Button className="sendButton" onClick={() => sendData()}>Save</Button>
//                         </div> */}

//                         <MaterialTable
//                             title={`Order # ${!isUndefined(props.orderArticleInfo) && props.orderArticleInfo.orderId} Details`}
//                             columns={state.columns}
//                             data={dataMaterial}
//                             detailPanel={rowData => {
//                                 // console.log(rowData, "qqgg")
//                                 return (
//                                     <MaterialTable
//                                         columns={stateArticle.columns}
//                                         // data={rowData.order_articles}
//                                         // data={dataMaterial}
//                                         title="Article Preview"
//                                     />
//                                 )
//                             }}
//                         />
//                     </Form>

//                 </div>

//             </div>

//         </Container>

//     )
// }

// const mapDispatchToProps = (dispatch) => {
//     return {
//         getOrderArticleCall: (data) => dispatch(getOrderArticleCall(data)),
//         getOrderCall: () => dispatch(getOrderCall()),
//     };
// };
// const mapStateToProps = (state, ownProps) => ({
//     orderInfo: state.OrderCreationReducer.orderInfo,
//     orderArticleInfo: state.OrderCreationReducer.orderArticleInfo,
// });

// export default connect(mapStateToProps, mapDispatchToProps)(ArticleMaterialReq);
