import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Modal, Alert } from "react-bootstrap";
import "./add-details.css";
import {getsubCategoryData, deletesubCategory, updatesubCategory } from "./AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, find, isNull } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';

const SubCategoryTableList = (props) => {
    const wareHouseData = isEmpty(props.subCategoryList) ? [] :props.subCategoryList.map(function (el) {
        var o = Object.assign({}, el);
        o.key = el.category.warehouse.whKey + " " + '-' + " " + el.category.cateKey + " " + '-' + " " + el.subcateKey;
        return o;
    });
   /* const [state, setState] = React.useState({
        columns: [
            { title: 'WareHouse Name', field: 'category.warehouse.wareHouseName', editable: 'never' },
            { title: 'Category Name', field: 'category.categoryName', editable: 'never' },
            { title: 'Sub Category Code', field: 'key', editable: 'never' },
            { title: 'Sub Category Name', field: 'subCategoryName' },
        ],
    });*/
    useEffect(() => {
        props.getsubCategoryData();
    }, []);
    return (
        <>
            {props.isFetchingsubCategoryList ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {/* {props.isFetchingSectorsSummary ? 'Loading...' : 'Loading...'} */}

                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <MaterialTable
                            title="Sub Category Details"
                            columns={[
                                { title: 'WareHouse Name', field: 'category.warehouse.wareHouseName', editable: 'never' },
                                { title: 'Category Name', field: 'category.categoryName', editable: 'never' },
                                { title: 'Sub Category Code', field: 'key', editable: 'never' },
                                { title: 'Sub Category Name', field: 'subCategoryName' },
                            ]}
                            data={wareHouseData}
                            options={{
                                actionsColumnIndex: -1,
                                filtering: true,
                                exportButton: true,
                                paging:true,
        pageSize:200,       // make initial page size
        emptyRowsWhenPaging: false,   // To avoid of having empty rows
        pageSizeOptions:[50,100,150,200],    // rows selection options
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                }
                            }}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            {
                                                props.updatesubCategory(newData, oldData)
                                            }
                                        }, 600);
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.deletesubCategory(oldData.subCategoryId);
                                        }, 600);
                                    }),
                            }}
                        />
                    </div>
                </Container>
            )}

        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        getsubCategoryData: () => dispatch(getsubCategoryData()),
        deletesubCategory: (subCategoryId) => dispatch(deletesubCategory(subCategoryId)),
        updatesubCategory: (newData, oldData) => dispatch(updatesubCategory(newData, oldData)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    subCategoryList: state.AddDataReducer.subCategoryList,
    isFetchingsubCategoryList: state.AddDataReducer.isFetchingsubCategoryList,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubCategoryTableList);