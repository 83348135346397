import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import 'jspdf-autotable';
import ImageUploader from 'react-images-upload';
import { deleteColor, getColorCall, updateColor } from "./color.action";




const ViewColor = (props) => {


    useEffect(() => {
      props.getColorCall()
    }, []);

    return (
      <>
      {props.isFetchingGetColor ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
            <div className="main">
            <MaterialTable
                  title="Color Details"
                  columns={[
                    { title: 'Color', field: 'colorName'},
                    { title: 'Color Description', field: 'colorDescription' },
                  ]}
                  data={props.colorInfo}
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    exportButton: true,
                    exportAllData: true,
                    headerStyle: {
                      position: 'sticky', top: 0,
                      color: '#00BBBB',
                      fontWeight: '550',
                      onRowAdd: 'none',
                    },

                  }}
                  editable={{
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                            props.updateColor(newData, oldData)
                        }, 600);
                      }),
                      
                    onRowDelete: (oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                          props.deleteColor(oldData.colorId);
                        }, 600);
                      }),
                  }}
                />

            </div>

        </Container>
                )}
                </>

    )
}

const mapDispatchToProps = (dispatch) => {
    return {
      getColorCall: () => dispatch(getColorCall()),
      deleteColor: (colorId) => dispatch(deleteColor(colorId)),
      updateColor: (newData, oldData) => dispatch(updateColor(newData, oldData)),
    };
};
const mapStateToProps = (state, ownProps) => ({
  colorInfo: state.colorReducer.colorInfo,
  isFetchingGetColor: state.colorReducer.isFetchingGetColor,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewColor);