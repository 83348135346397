const initialState = {
  isFetchingAddDivision: false,
  isFetchingGetDivision: false,
  divisionDetail: {
    division: "",
    description: "",
  },
  divisionInfo: [],
};

const divisionReducer = (state = initialState, action) => {
  switch (action.type) {
    //Division
    case "REQUEST_ADD_DIVISION":
      return { ...state, isFetchingAddDivision: true };

    case "SUCCESS_ADD_DIVISION":
      return {
        ...state,
        isFetchingAddDivision: false,
        divisionDetail: {
          division: action.payload.divisionName,
          description: action.payload.divisionDescription,
        },
      };

    case "ERROR_ADD_DIVISION":
      return { ...state, isFetchingAddDivision: false };

    //Get Division
    case "REQUEST_GET_DIVISION":
      return { ...state, isFetchingGetDivision: true };

    case "SUCCESS_GET_DIVISION":
      console.log(action.payload, "bb");
      return {
        ...state,
        isFetchingGetDivision: false,
        divisionInfo: action.payload.result,
      };

    case "ERROR_GET_DIVISION":
      return { ...state, isFetchingGetDivision: false };

    default:
      return state;
  }
};

export default divisionReducer;
