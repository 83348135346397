import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import { addUOMCall } from "./uom.action";

const AddUOM = (props) => {
  const [UOM, setUOM] = useState("");
  const [description, setDescription] = useState("");

  const sendData = () => {
    props.addUOMCall({
      uomName: UOM,
      uomDescription: description,
    });
    setUOM("");
    setDescription("");
  };

  useEffect(() => {}, []);

  return (
    <Container fluid>
      <div className="main">
        <Form>
          <Row>
            <Col lg="12" md="12" xs="12">
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>Enter UOM *</b>
                </Form.Label>
                <Form.Control
                  value={UOM}
                  type="text"
                  id="UOMDetail"
                  placeholder="UOM...."
                  onChange={(e) => setUOM(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg="12" md="12" xs="12">
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>Description</b>
                </Form.Label>
                <Form.Control
                  value={description}
                  type="text"
                  id="uomDescription"
                  placeholder="Description...."
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <div className="sendDiv">
            {props.isFetchingAddUOM ? (
              <div className="loader-div">
                <Spinner
                  variant="primary"
                  animation="grow"
                  size="xl"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            ) : (
              <Button
                disabled={isEmpty(UOM)}
                className="sendButton"
                onClick={() => sendData()}
                style={{
                  marginLeft: "2%",
                  color: "white",
                  width: "20%",
                  backgroundColor: "black",
                  border: "1px solid black",
                }}
              >
                Save
              </Button>
            )}
          </div>
        </Form>
      </div>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUOMCall: (data) => dispatch(addUOMCall(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingAddUOM: state.uomReducer.isFetchingAddUOM,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUOM);
