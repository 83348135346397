import API from "../../../../../global/api";
import { forEach } from "lodash";

//Add Division Call

export const addDivision = (data) => {
  return (dispatch) => {
    dispatch(requestAddDivision());
    API.post("/division/add_division", data)
      .then((res) => {
        dispatch(successAddDivision(data));
        dispatch(getDivisionCall());
      })
      .catch((error) => {
        dispatch(errorAddDivision());
      });
  };
};

export const requestAddDivision = () => {
  return {
    type: "REQUEST_ADD_DIVISION",
  };
};

export const successAddDivision = (data) => {
  return {
    type: "SUCCESS_ADD_DIVISION",
    payload: data,
  };
};

export const errorAddDivision = () => {
  return {
    type: "ERROR_ADD_DIVISION",
  };
};

//Get Division Call

export const getDivisionCall = () => {
  return (dispatch) => {
    dispatch(requestGetDivision());
    API.get("/division/get_division")
      .then((res) => {
        dispatch(successGetDivision(res.data));
      })
      .catch((error) => {
        dispatch(errorGetDivision());
      });
  };
};

export const requestGetDivision = () => {
  return {
    type: "REQUEST_GET_DIVISION",
  };
};

export const successGetDivision = (data) => {
  console.log(data, "aaaaaaaa");
  return {
    type: "SUCCESS_GET_DIVISION",
    payload: data,
  };
};

export const errorGetDivision = () => {
  return {
    type: "ERROR_GET_DIVISION",
  };
};
// delete division
export const requestDeleteDivision = () => {
  return {
    type: "REQUEST_DELETE_DIVISION",
  };
};

export const successDeleteDivision = (divisionId) => {
  return {
    type: "SUCCESS_DELETE_DIVISION",
    divisionId: divisionId,
  };
};

export const errorDeleteDivision = () => {
  return {
    type: "ERROR_DELETE_DIVISION",
  };
};
export const deleteDivision = (divisionId) => {
  return (dispatch) => {
    dispatch(requestDeleteDivision());
    API.delete(`/division/delete_division/${divisionId}`)
      .then((res) => {
        dispatch(getDivisionCall());
        dispatch(successDeleteDivision(divisionId));
      })
      .catch((error) => {
        dispatch(errorDeleteDivision());
      });
  };
};

// update

export const requestUpdateDivision = () => {
  return {
    type: "REQUEST_UPDATE_DIVISION",
  };
};

export const successUpdateDivision = (data) => {
  return {
    type: "SUCCESS_UPDATE_DIVISION",
    payload: data,
  };
};

export const errorUpdateDivision = () => {
  return {
    type: "ERROR_UPDATE_DIVISION",
  };
};

export const updateDivision = (newData, oldData) => {
  return (dispatch) => {
    dispatch(requestUpdateDivision());
    API.put(`/division/update_division/${oldData.division_id}`, {
      divisionName: newData.divisionName,
      divisionDescription: newData.divisionDescription,
    })
      .then((res) => {
        dispatch(getDivisionCall());
        dispatch(successUpdateDivision([newData]));
      })
      .catch((error) => {
        dispatch(errorUpdateDivision());
      });
  };
};
