import React, { useState } from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useEffect } from "react";
import Swal from "sweetalert";
import { isEmpty, upperCase } from "lodash";
import { addDivision } from "./division.action";

const AddDivision = (props) => {
  const [divisionName, setDivisionName] = useState();
  const [divisionDescription, setDivisionDescription] = useState();

  const [submit, setSubmit] = useState(false);

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  function uploadDivision() {
    props.addDivision(
      {
        division_name: upperCase(divisionName),
        division_desc: upperCase(divisionDescription),
      },
      handleVisible,
      handleDangerVisible
    );
  }

  /* const [state, setState] = React.useState({
        columns: [
            { title: 'Division Name', field: 'division_name' },
            { title: 'Division Description', field: 'division_desc' },
        ],
    });*/
  const clearStates = () => {
    setDivisionName();
    setDivisionDescription();
    setSubmit(false);
  };
  const handleVisible = () => {
    setAlertVisible(true);
    clearStates();
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };

  useEffect(() => {
    // props.getHRDivision();
  }, []);
  return (
    <>
      {props.isFetchingAddDivision ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Form>
              <Row>
                <Col lg="12" md="12" xs="12">
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Enter Division Name *</b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Division Name...."
                      onChange={(e) => setDivisionName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xl="12" lg="12" md="12" sm="12" xs="12">
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <b>Enter Division Description</b>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Division Description....."
                      onBlur={(e) => setDivisionDescription(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <div className="sendDiv">
                {isEmpty(divisionName) ? (
                  <Button
                    className="sendButton"
                    style={{ backgroundColor: "Black" }}
                    disabled
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    className="sendButton"
                    onClick={() => {
                      uploadDivision();
                      setSubmit(true);
                    }}
                  >
                    Save
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addDivision: (data, handleVisible, handleDangerVisible) =>
      dispatch(addDivision(data, handleVisible, handleDangerVisible)),
    // getHRDivision: () => dispatch(getHRDivision()),
    // UpdateHRDivision: (newData, oldData) => dispatch(UpdateHRDivision(newData, oldData)),
    // deleteHRDivision: (division_id) => dispatch(deleteHRDivision(division_id))
  };
};
const mapStateToProps = (state, ownProps) => ({
  // departmentList: state.HrReducer.departmentList,
  // isFetchingDivisionData: state.HrReducer.isFetchingDivisionData,
  isFetchingAddDivision: state.divisionReducer.isFetchingAddDivision,
  // divisionList: state.HrReducer.divisionList
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDivision);
