import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import Select from "react-select";
import { addSubProductCall } from "./addSubProduct.action";
import { getProductCall } from "../Product/addProduct.action";

const SubProduct = (props) => {
  const getProduct = props.productInfo.map((x) => {
    let data = { label: x.name, value: x.mainProId };
    return data;
  });

  const [product, setProduct] = useState("");
  const [productName, setProductName] = useState("");
  const [subProduct, setSubProduct] = useState("");

  const setProductInfo = (selectedOption) => {
    setProduct(selectedOption.value);
    setProductName(selectedOption.label);
  };

  function sendData() {
    props.addSubProductCall({
      mainProId: product,
      name: subProduct,
    });
    setProduct("");
    setProductName("");
    setSubProduct("");
  }

  useEffect(() => {
    props.getProductCall();
  }, []);

  return (
    <Container fluid>
      {props.isFetchingGetProduct ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <div className="main">
          <Form>
            <Row>
              <Col lg="12" md="12" xs="12" styles={{ zIndex: -1 }}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Select Product *</b>
                  </Form.Label>
                  <Select
                    placeholder="Select Product..."
                    value={{ label: productName }}
                    onChange={(e) => {
                      setProductInfo(e);
                    }}
                    options={getProduct}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg="12" md="12" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Enter Sub Product *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="subProductDetail"
                    placeholder="Sub Product...."
                    disabled={isEmpty(productName)}
                    value={subProduct}
                    onChange={(e) => setSubProduct(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv">
              {props.isFetchingAddSubProduct ? (
                <div className="loader-div">
                  <Spinner
                    variant="primary"
                    animation="grow"
                    size="xl"
                    role="status"
                    aria-hidden="true"
                  />
                </div>
              ) : (
                <Button
                  disabled={isEmpty(productName) || isEmpty(subProduct)}
                  className="sendButton"
                  onClick={() => sendData()}
                  style={{
                    marginLeft: "2%",
                    color: "white",
                    width: "20%",
                    backgroundColor: "black",
                    border: "1px solid black",
                  }}
                >
                  Save
                </Button>
              )}
            </div>
          </Form>
        </div>
      )}
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSubProductCall: (data) => dispatch(addSubProductCall(data)),
    getProductCall: () => dispatch(getProductCall()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  productInfo: state.productReducer.productInfo,
  isFetchingGetProduct: state.productReducer.isFetchingGetProduct,
  isFetchingAddSubProduct: state.subProductReducer.isFetchingAddSubProduct,
});

export default connect(mapStateToProps, mapDispatchToProps)(SubProduct);
