import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { isNull } from "lodash";
import AddSize from "./addSize";
import ViewSize from "./viewSize";

const TotalSizeData = (props) => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  return (
    <>
      {/* <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 92) ?
                    "AddSize" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 93) ?
                        "SizeList" :  ""} id="AddSize" className="mb-3"> */}
      <Tabs defaultActiveKey={"AddSize"} id="AddSize" className="mb-3">
        <Tab
          eventKey="AddSize"
          title="Add Size"
          // disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 92) ? false : true}
        >
          <AddSize />
        </Tab>
        <Tab
          eventKey="SizeList"
          title="Size List"
          //    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 93) ? false : true}
        >
          <ViewSize />
        </Tab>
      </Tabs>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TotalSizeData);
