import React from "react";
import { Card, Tabs, Tab, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import MonthlyProfit from "./monthlyProfit";
import ProfitByBooking from "./profitByBooking";
import { isNull } from "lodash";
import DashboardReport from "./DashboardReport";
const Reports = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    return (
        <>
            <Tabs
                defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 109) ?
                "BookingWise" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 110) ?
                    "DateWise" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 110) ?
                    "totalBooking" :  ""}
            >
                <Tab
                    title="Booking Wise"
                    eventKey="BookingWise"
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 109) ? false : true}
                >
                    <ProfitByBooking />
                </Tab>
                <Tab
                    eventKey="DateWise"
                    title="Date Wise"
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 110) ? false : true}
                >
                    <MonthlyProfit />
                </Tab>
                <Tab
                    eventKey="totalBooking"
                    title="Total Profit"
                    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 110) ? false : true}
                >
                    <DashboardReport/>
                </Tab>
            </Tabs>

        </>
    );
}


const mapDispatchToProps = (dispatch) => {
    return {
        //     addExpense: (data, handleVisible, handleDangerVisible) => dispatch(addExpense(data, handleVisible, handleDangerVisible)),
        //     getExpense: (id) => dispatch(getExpense(id)),
        //     getMasterControl: (id) => dispatch(getMasterControl(id)),
        //     getBooking: () => dispatch(getBooking()),
        //    // UpdateHRExpense: (newData, oldData) => dispatch(UpdateHRExpense(newData, oldData)),
        //     deleteExpense: (menu_id) => dispatch(deleteExpense(menu_id)),
        //     SearchMarqueeExpenseReport:(id)=>dispatch(SearchMarqueeExpenseReport(id)),
        //     getBookingById:(id) => dispatch(getBookingById(id)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    // departmentList: state.MarqueeReducer.departmentList,
    // isFetchingExpenseData: state.MarqueeReducer.isFetchingExpenseData,
    // isAddingExpenseData: state.MarqueeReducer.isAddingExpenseData,
    // expenseList: state.MarqueeReducer.expenseList,
    // masterControlsList: state.MarqueeReducer.masterControlsList,
    // bookingList: state.MarqueeReducer.bookingList,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Reports);

