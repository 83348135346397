import React, { useEffect, useRef, useState } from "react";
import { Container, Button } from "react-bootstrap";
import "../add-details.css";
import { deleteReceiving, getReceiving, getReceivingById } from "../AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, find } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { baseURL, headers } from "../../../../../global/api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const RecevingDetails = (props) => {
    // const navigate = useNavigate();
    const history = useHistory();
  const wareHouseData = props.ReceivingList;
  const tableRef = useRef();
  function updateTableQuery() {
      tableRef.current.onQueryChange()
  };
  useEffect(() => {
    // props.getReceiving();
  }, []);
  return (
    <>
      {props.isFetchingReceiving ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {/* {props.isFetchingSectorsSummary ? 'Loading...' : 'Loading...'} */}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialTable
              title="Good Received Note Details"
              //columns={state.viewColumns}
              columns={[
                { title: "Sr Number", field: "receiving_id" },
                { title: "Date", field: "receiving_date" },
                // { title: "Company", field: "department" },
                // { title: "Issued By", field: "issuedBy" },
                {title:'Vendor Name',field:'vendor.vendor_name'},
                { title: "Received By", field: "receivedBy" },
                { title: "Description", field: "reciving_description" },
                { title: "Receiving Total", field: "receiving_total" },
                
              ]}
              // data={isEmpty(wareHouseData) ? [] : wareHouseData.reverse()}
              data={(query) =>
                new Promise((resolve, reject) => {
                  console.log(query, "qr");
                  let url = `${baseURL}receiving/get-all-receiving?`;
                  url += "size=" + query.pageSize;
                  url += "&page=" + (query.page + 1);
                  url += "&search=" + query.search;
                  fetch(url, {
                    method: "GET",
                    headers: headers,
                  })
                    .then((response) => response.json())
                    .then((result) => {
                      resolve({
                        data: result.data,
                        page: result.page - 1,
                        totalCount: result.total_results,
                      });
                    });
                })
              }
              actions={[
                {
                icon: 'edit',
                  tooltip: 'Edit GRN/PO',
                  onClick: (event, rowData) => {
                    // Do save operation
                  //   setcallEdit(true);
                  props.getReceivingById(rowData.receiving_id)
                  history.push('/user/edit-receiving')
                  //  props.selectedUser(rowData)
                  }
                }
            ]}
              editable={{
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteReceiving(oldData.receiving_id,updateTableQuery);
                    }, 600);
                  }),
              }}
              detailPanel={(rowData) => {
                return (
                  <MaterialTable
                    //columns={state.columnsData}
                    columns={[
                      {
                        title: "Inventory Code",
                        field: "inventory",
                        render: (rowData) =>
                          rowData.inventory.warehouse.whKey +
                          "-" +
                          rowData.inventory.category.cateKey +
                          "-" +
                          rowData.inventory.invKey,
                      },
                      {
                        title: "Inventory Name",
                        field: "inventory.productName",
                      },
                      { title: "Inventory Price", field: "inventoryPrice" },
                      { title: "Inventory Quantity", field: "productQuantity" },
                    ]}
                    data={rowData.receiving_data}
                    options={{
                      actionsColumnIndex: -1,
                      toolbar: false,
                      exportButton: true,
                      sorting: true,
                      paging: true,
                      pageSize: 200, // make initial page size
                      emptyRowsWhenPaging: false, // To avoid of having empty rows
                      pageSizeOptions: [50, 100, 150, 200], // rows selection options
                      headerStyle: {
                        position: "sticky",
                        top: 0,
                        color: "#00BBBB",
                        fontWeight: "550",
                        onRowAdd: "none",
                      },
                    }}
                  />
                );
              }}
              options={{
                actionsColumnIndex: -1,
                filtering: false,
                exportButton: true,
                paging: true,
                pageSize: 200, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [50, 100, 150, 200],
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              onRowClick={(event, rowData, togglePanel) => togglePanel()}
            />
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getReceiving: () => dispatch(getReceiving()),
    deleteReceiving: (id,updateTableQuery) => dispatch(deleteReceiving(id,updateTableQuery)),
    getReceivingById: (id) => dispatch(getReceivingById(id)),
    
  };
};
const mapStateToProps = (state, ownProps) => ({
  allInventory: state.AddDataReducer.allInventory,
  isFetchingDemand: state.AddDataReducer.isFetchingDemand,
  isFetchingInventoryAll: state.AddDataReducer.isFetchingInventoryAll,
  purchaseList1: state.AddDataReducer.purchaseList1,
  approveDemandList: state.AddDataReducer.approveDemandList,
  isAddingPurchase: state.AddDataReducer.isAddingPurchase,
  notAddPurchase: state.AddDataReducer.notAddPurchase,
  ReceivingList: state.AddDataReducer.ReceivingList,
  isFetchingIssuance: state.AddDataReducer.isFetchingIssuance,
  isFetchingSearchIssuanceList:
    state.AddDataReducer.isFetchingSearchIssuanceList,
});

export default connect(mapStateToProps, mapDispatchToProps)(RecevingDetails);
