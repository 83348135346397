import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import {isNull } from "lodash";
import AddProduct from "./addProduct";
import ViewProduct from "./viewProduct";





const TotalProductData = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            {/* <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 92) ?
                    "AddProduct" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 93) ?
                        "ProductList" :  ""} id="AddProduct" className="mb-3"> */}
                        <Tabs defaultActiveKey={
                    "AddProduct" } id="AddProduct" className="mb-3">
                <Tab eventKey="AddProduct" title="Add Product"
                // disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 92) ? false : true}
                >  
                    <AddProduct />
                </Tab>
                <Tab eventKey="ProductList" title="Product List"
            //    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 93) ? false : true}
               > 
                    <ViewProduct/>
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalProductData);