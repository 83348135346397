import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import "jspdf-autotable";
import Select from "react-select";
import { addCustomerCall } from "./customer.action";
import { rotateAndSkewTextDegreesAndTranslate } from "pdf-lib";
import { findAllByAltText } from "@testing-library/dom";
import { getDivisionCall } from "../Division/division.action";

const AddCustomer = (props) => {
  const getDivision =
    !isEmpty(props.divisionInfo) &&
    props.divisionInfo.map((x) => {
      let data = { label: x.division_name, value: x.division_id };
      return data;
    });

  const [customerCompany, setCustomerCompany] = useState("");
  const [customerPerson, setCustomerPerson] = useState("");
  const [customerPhone, setCustomerPhone] = useState();
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerWeb, setCustomerWeb] = useState("");
  const [customerContry, setCustomerContry] = useState("");
  const [customerCity, setCustomerCity] = useState("");
  const [customerDivision, setCustomerDivision] = useState("");
  const [customerDivisionName, setCustomerDivisionName] = useState("");

  const setDevision = (selectedOption) => {
    setCustomerDivision(selectedOption.value);
    setCustomerDivisionName(selectedOption.label);
  };

  const sendData = () => {
    props.addCustomerCall({
      customerCompany: customerCompany,
      customer_name: customerPerson,
      customer_phone: customerPhone,
      customer_email: customerEmail,
      customerWeb: customerWeb,
      customerContry: customerContry,
      customerCity: customerCity,
      division_id: customerDivision,
    });
    setCustomerCompany("");
    setCustomerPerson("");
    setCustomerPhone("");
    setCustomerEmail("");
    setCustomerWeb("");
    setCustomerContry("");
    setCustomerCity("");
    setCustomerDivision("");
    setCustomerDivisionName("");
  };

  useEffect(() => {
    props.getDivisionCall();
  }, []);

  return (
    <>
      {props.isFetchingGetDivision ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <Row>
                  <Col lg="6" md="6" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Name *</b>
                      </Form.Label>
                      <Form.Control
                        value={customerPerson}
                        type="text"
                        id="personName"
                        placeholder="Name...."
                        onChange={(e) => setCustomerPerson(e.target.value)}
                      />
                    </Form.Group>
                  </Col>

                  <Col lg="6" md="6" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Company Name *</b>
                      </Form.Label>
                      <Form.Control
                        value={customerCompany}
                        type="text"
                        id="companyName"
                        placeholder="Company Name...."
                        onChange={(e) => setCustomerCompany(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6" md="6" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Email</b>
                      </Form.Label>
                      <Form.Control
                        value={customerEmail}
                        type="email"
                        id="email"
                        placeholder="Email...."
                        onChange={(e) => setCustomerEmail(e.target.value)}
                      />
                    </Form.Group>
                  </Col>

                  <Col lg="6" md="6" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Web</b>
                      </Form.Label>
                      <Form.Control
                        value={customerWeb}
                        type="text"
                        id="web"
                        placeholder="Web...."
                        onChange={(e) => setCustomerWeb(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6" md="6" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Phone No. *</b>
                      </Form.Label>
                      <Form.Control
                        value={customerPhone}
                        type="number"
                        id="phone"
                        placeholder="Phone...."
                        onChange={(e) => setCustomerPhone(e.target.value)}
                      />
                    </Form.Group>
                  </Col>

                  <Col lg="6" md="6" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>City *</b>
                      </Form.Label>
                      <Form.Control
                        value={customerCity}
                        type="text"
                        id="city"
                        placeholder="City...."
                        onChange={(e) => setCustomerCity(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6" md="6" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Country *</b>
                      </Form.Label>
                      <Form.Control
                        value={customerContry}
                        type="text"
                        id="country"
                        placeholder="Country...."
                        onChange={(e) => setCustomerContry(e.target.value)}
                      />
                    </Form.Group>
                  </Col>

                  <Col lg="6" md="6" xs="12" styles={{ zIndex: -1 }}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b>Division *</b>
                      </Form.Label>
                      <Select
                        value={{ label: customerDivisionName }}
                        placeholder="Select Division..."
                        onChange={(e) => {
                          setDevision(e);
                        }}
                        options={getDivision}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <div className="sendDiv">
                  {props.isFetchingAddCustomer ? (
                    <div className="loader-div">
                      <Spinner
                        variant="primary"
                        animation="grow"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                      />
                    </div>
                  ) : (
                    <Button
                      disabled={
                        isEmpty(customerCompany) ||
                        isEmpty(customerPerson) ||
                        isEmpty(customerPhone) ||
                        isEmpty(customerContry) ||
                        isEmpty(customerCity) ||
                        isEmpty(customerDivisionName)
                      }
                      className="sendButton"
                      onClick={() => sendData()}
                      style={{
                        marginLeft: "2%",
                        color: "white",
                        width: "20%",
                        backgroundColor: "black",
                        border: "1px solid black",
                      }}
                    >
                      Save
                    </Button>
                  )}
                </div>
              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCustomerCall: (data) => dispatch(addCustomerCall(data)),
    getDivisionCall: () => dispatch(getDivisionCall()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  customerInfo: state.customerReducer.customerInfo,
  isFetchingAddCustomer: state.customerReducer.isFetchingAddCustomer,
  divisionInfo: state.divisionReducer.divisionInfo,
  isFetchingGetDivision: state.divisionReducer.isFetchingGetDivision,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomer);
