import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import {isNull } from "lodash";
import AddProcess from "./addProcess";
import ViewProcess from "./viewProcess";






const TotalProcessData = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return (
        <>
            {/* <Tabs defaultActiveKey={!isNull(permissions) && permissions.some(p => p['sub_id'] === 92) ?
                    "AddProcess" : !isNull(permissions) && permissions.some(p => p['sub_id'] === 93) ?
                        "ProcessList" :  ""} id="AddProcess" className="mb-3"> */}
                        <Tabs defaultActiveKey={
                    "AddProcess" } id="AddProcess" className="mb-3">
                <Tab eventKey="AddProcess" title="Add Process"
                // disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 92) ? false : true}
                >  
                    <AddProcess />
                </Tab>
                <Tab eventKey="ProcessList" title="Process List"
            //    disabled={!isNull(permissions) && permissions.some(p => p['sub_id'] === 93) ? false : true}
               > 
                    <ViewProcess/>
                </Tab>
            </Tabs>
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TotalProcessData);