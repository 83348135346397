import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Select from 'react-select';
import { isEmpty, isNull, find, filter } from "lodash";
import { getEmployeeData, getEmployeeDataWithStatus } from "./HRDetails.actions";
import { addAdjustment, addAttendance, getAdjustment } from '../salary/salary.actions';
import { connect } from "react-redux";
import HrReducer from "./HRDetails.reducers"
import Swal from 'sweetalert';
import Spinner from 'react-bootstrap/Spinner';
import { appendNonSignificantZeros } from '../../../../../global/globalFunctions';
import moment from 'moment';
import MaterialTable from 'material-table';



const Adjustment = (props) => {


    const [employeeId, setEmployeeId] = useState('');
    const [employeeName, setEmployeeName] = useState('');
    const [AdjustmentAmount, setAdjustmentAmount] = useState(0);
    const [AdjustmentDesc, setAdjustmentDesc] = useState("");
    const [AdjustmentId, setAdjustmentId] = useState("");
    const [AdjustmentName, setAdjustmentName] = useState("");

    const [NoAttendance, setNoAttendance] = useState(false);

    const [AlertVisible, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

    //moment().subtract(1, 'months').format('YYYY-MM')
    const [month, setMonth] = useState('');
    // const getEmployeeList = !isEmpty(props.employeeList) && props.employeeList.map((x) => { let data = { value: x.employeeId, label: `${appendNonSignificantZeros(x.employeeId)}-${x.name}` }; return data })

    const getEmployeeList = !isEmpty(props.activeEmployeeList) && props.activeEmployeeList.map((x) => { let data = { value: x.employeeId, label: `${appendNonSignificantZeros(x.employeeId)}-${x.name}` }; return data })
    console.log('props.employeeList', props.employeeList)

    console.log('props.activeEmployeeList', props.activeEmployeeList);

    const handleChangeEmployee = (selectedOption) => {
        setEmployeeId(selectedOption.value);
        setEmployeeName(selectedOption.label);
        if (isEmpty(filter(props.adjustmentData, { employeeId: selectedOption.value }))) {
            setNoAttendance(true);
            console.log(true, props.adjustmentData);
            handleDangerVisible("No Attendance for this Employee");
        }
        else {
            setNoAttendance(false);
            console.log(false)
        }
    };

    const clearStates = () => {

        setEmployeeId('');
        setEmployeeName('');
        setAdjustmentAmount(0);
        setAdjustmentId("");
        setAdjustmentName("");
        setMonth('');
    }

    const handleVisible = (message = "Added successfully") => {
        setAlertVisible(true);
        clearStates();
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: `${message}...`,
            icon: "success",
            button: "Ok",
        });
    }

    const handleDangerVisible = (message = "Something went wrong") => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: `${message}...`,
            icon: "error",
            button: "Ok",
        });
    }
    const AdjustmentAmountList = [
        {
            // value: 1,
            label: "Days/Over Time adjustment",
            value: "over_time_adj",
        },
        {
            // value: 2,
            label: "Days/Late Time adjustment",
            value: "late_time_adj",
        },
        // {
        //     // value: 3,
        //     label: "Deduction",
        //     value: "deduction_adj",
        // },
        // {
        //     // value: 4,
        //     label: "Short term adjustment",
        //     value: "short_term_adj",
        // },
        // {
        //     // value: 5,
        //     label: "Long term adjustment",
        //     value: "long_term_adj"
        // },
    ];
    const handleChangeAdjustment = (selectedOption) => {
        console.log(selectedOption, "sop");
        setAdjustmentId(selectedOption.value);
        setAdjustmentName(selectedOption.label);
        console.log(props.adjustmentData, "props.adjustmentData");
        console.log(employeeId, "employeeId filter");
        console.log(filter(props.adjustmentData, { employeeId: employeeId }), "filter")
        // console.log(filter(props.adjustmentData,{employeeId: employeeId})[0][selectedOption.value], "filter")
    };
    const AdjustmentList =
        !isEmpty(AdjustmentAmountList) &&
        AdjustmentAmountList.map((x) => {
            let data = { value: x.value, label: x.label };
            return data;
        });


    const saveAdjustment = () => {
        props.addAdjustment(
            {
                attendance_month_year: month,
                employee_id: employeeId,
                over_time_adj: AdjustmentId === "over_time_adj" ? AdjustmentAmount : 0,
                late_time_adj: AdjustmentId === "late_time_adj" ? AdjustmentAmount : 0,
                deduction_adj: AdjustmentId === "deduction_adj" ? AdjustmentAmount : 0,
                short_term_adj: AdjustmentId === "short_term_adj" ? AdjustmentAmount : 0,
                long_term_adj: AdjustmentId === "long_term_adj" ? AdjustmentAmount : 0,
                adj_desc:AdjustmentDesc

            }, handleVisible, handleDangerVisible)
    }
    useEffect(() => {
        // props.getEmployeeData();
        props.getEmployeeDataWithStatus(1);
    }, []);

    //  const getShift = !isEmpty(props.employeeList) && (props.employeeList[0].salaries) && (props.employeeList[0].salaries[0].shift_id);
    //  console.log(getShift,'shift')

    return (
        <div className="main" >
            {(props.isFetchingActiveEmployeeData) ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div>
                        <Form>
                            <Row>
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Date:</b></Form.Label>
                                        <Form.Control type="date" id="warehouseName" defaultValue={month} min={moment().subtract(1, 'months').format('YYYY-MM')} max={moment().subtract(1, 'months').format('YYYY-MM')} placeholder="Date...."
                                            onChange={(e) => { setMonth(moment(e.target.value).format('YYYY-MM')); props.getAdjustment({ attendance_month_year: moment(e.target.value).format('YYYY-MM') }, handleVisible, handleDangerVisible); console.log(filter(getEmployeeList,{value: 47})[0].label) }} />
                                    </Form.Group>
                                </Col>

                            </Row>
                            {month != "" ?
                                <Row>

                                    <Col lg="12" md="12" xs="12">
                                        <Form.Group className="mb-3" controlid="garana" style={{zIndex:1}}>
                                            <Form.Label><b>Select Employee:</b></Form.Label>
                                            <Select
                                        
                                                isDisabled={isEmpty(getEmployeeList)}
                                                placeholder="Select Employee..."
                                                onChange={handleChangeEmployee}
                                                options={getEmployeeList}

                                            />
                                        </Form.Group>

                                    </Col>

                                </Row>
                                : null}
                            {(month != "" & employeeId != "" & !NoAttendance) ?
                                <Row>
                                    <Col lg="12" md="12" xs="12">
                                        <Form.Group className="mb-3" controlid="garana">
                                            <Form.Label><b>Select Adjustment:</b></Form.Label>
                                            <Select
                                                isDisabled={isEmpty(AdjustmentList)}
                                                placeholder="Select Adjustment..."
                                                onChange={handleChangeAdjustment}
                                                options={AdjustmentList}
                                            />
                                        </Form.Group>

                                    </Col>
                                </Row>
                                : null}
                            {(month != "" & employeeId != "" & !NoAttendance & AdjustmentId != "") ?

                                <Row>
                                    <Col lg="12" md="12" xs="12">
                                        <Form.Group className="mb-3" controlId="garana">
                                            <Form.Label>
                                                <b>Enter Adjustment Amount:</b>
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Adjustment Amount ...."
                                                defaultValue={(AdjustmentId != "" && employeeId != "" && !isEmpty(filter(props.adjustmentData, { employeeId: employeeId }))) ? filter(props.adjustmentData, { employeeId: employeeId })[0][AdjustmentId] : 0}
                                                // value={AdjustmentAmount}
                                                onChange={(e) => setAdjustmentAmount(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="12" md="12" xs="12">
                                        <Form.Group className="mb-3" controlId="garana">
                                            <Form.Label>
                                                <b>Enter Description:</b>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Description ...."
                                                // defaultValue={(AdjustmentId != "" && employeeId != "" && !isEmpty(filter(props.adjustmentData, { employeeId: employeeId }))) ? filter(props.adjustmentData, { employeeId: employeeId })[0][AdjustmentId] : 0}
                                                // value={AdjustmentAmount}
                                                onChange={(e) => setAdjustmentDesc(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                : null}
                            <div className="sendDiv">
                            {month === "" || employeeId === "" || AdjustmentId === ""?
                                <Button className="sendButton"
                                    onClick={() => { (saveAdjustment()); }}
                                    disabled
                                    style={{backgroundColor:'black'}}
                                >Save</Button>:
                                  <Button className="sendButton"
                                    onClick={() => { (saveAdjustment()); }}
                                 
                                >Save</Button>}
                            </div>

                        </Form>
                        <div>
                        <MaterialTable style={{zIndex:0}}
                            title="Adjustments"
                            columns={[
                                { title: 'Employee Code', field:'employeeId', editable: 'never' },
                                { title: 'Employee Name', field:'employee.name', editable: 'never' },
                                { title: 'Over Time', field: 'over_time_adj', editable: 'never' },
                                { title: 'Late Time', field: 'late_time_adj', editable: 'never'  },
                                { title: 'Description', field: 'adj_desc', editable: 'never'  },
                                // { title: 'Short Term', field: 'short_term_adj', editable: 'never'  },
                                // { title: 'Long Term', field: 'long_term_adj', editable: 'never'  },

                            ]}
                            data={isEmpty(props.adjustmentData) ? [] : props.adjustmentData}
                            options={{
                                actionsColumnIndex: -1,
                                filtering: true,
                                exportButton: true,
                                paging: true,
                                pageSize: 200,       // make initial page size
                                emptyRowsWhenPaging: false,   // To avoid of having empty rows
                                pageSizeOptions: [50, 100, 150, 200],    // rows selection options
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                }
                            }}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            {
                                                // props.updateCategory(newData, oldData)
                                            }
                                        }, 600);
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            // props.deleteCategory(oldData.categoryId);
                                        }, 600);
                                    }),
                            }}
                        />

                        </div>
                    </div>
                </Container>
            )}
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        addAdjustment: (data, handleVisible, handleDangerVisible) => dispatch(addAdjustment(data, handleVisible, handleDangerVisible)),
        getAdjustment: (data, handleVisible, handleDangerVisible) => dispatch(getAdjustment(data, handleVisible, handleDangerVisible)),
        getEmployeeData: () => dispatch(getEmployeeData()),
        getEmployeeDataWithStatus: (status) => dispatch(getEmployeeDataWithStatus(status)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    employeeList: state.HrReducer.employeeList,
    isFetchingEmployeeData: state.HrReducer.isFetchingEmployeeData,

    activeEmployeeList: state.HrReducer.activeEmployeeList,
    isFetchingActiveEmployeeData: state.HrReducer.isFetchingActiveEmployeeData,
    adjustmentData: state.SalaryReducer.adjustmentData,
    isAddingAdjustment: state.SalaryReducer.isAddingAdjustment,
    isGettingAdjustment: state.SalaryReducer.isGettingAdjustment,

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Adjustment);