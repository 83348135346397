import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import 'jspdf-autotable';
import ImageUploader from 'react-images-upload';

import { deleteProduct, getProductCall, updateProduct } from "./addProduct.action";




const ViewProduct = (props) => {


    useEffect(() => {
      props.getProductCall()
    }, []);

    return (
        <Container fluid>
                   {props.isFetchingGetProduct ? (
              <div className="loader-div">
                <Spinner
                  variant="primary"
                  animation="grow"
                  size="xl"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            ) : (
            <div className="main">
            <MaterialTable
                            title="Product Details"
                            columns={[
                                { title: 'Product', field: 'name' },
                                // { title: 'Brand Description', field: 'description' },
                            ]}
                            data={props.productInfo}
                            options={{
                                actionsColumnIndex: -1,
                                filtering: true,
                                exportButton: true,
                                exportAllData: true,
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                },

                            }}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.updateProduct(newData, oldData)
                                        }, 600);
                                    }),

                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                              props.deleteProduct(oldData.mainProId
                                              );
                                        }, 600);
                                    }),
                            }}
                        />

            </div>
            )}

        </Container>

    )
}

const mapDispatchToProps = (dispatch) => {
    return {
      getProductCall: () => dispatch(getProductCall()),
      deleteProduct: (productId) => dispatch(deleteProduct(productId)),
      updateProduct: (newData, oldData) => dispatch(updateProduct(newData, oldData)),
    };
};
const mapStateToProps = (state, ownProps) => ({
  productInfo: state.productReducer.productInfo,
  isFetchingGetProduct:state.productReducer.isFetchingGetProduct,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewProduct);