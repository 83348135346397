import React, { useState } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Route } from "react-router-dom";
import Container from "@material-ui/core/Container";
import LoginModal from "./LoginModal";
import { styled } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { getLogin } from "../../user_views/accounts/account.actions";
import { FormGroup, Alert } from "react-bootstrap";
import KeyboardEventHandler from "react-keyboard-event-handler";
import "./style.css";
import { Modal, ModalHeader, ModalFooter, ModalBody, Row } from "reactstrap";
import Swal from "sweetalert";
var bg = require("../../../../src/Images/bg.jpg");
var back = require(`../../../Images/bg.jpg`);
function Login(props) {
  //const classes = useStyles();

  const [email, setEmail] = React.useState("");
  const [pass, setPassword] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleTogglePassword = () =>
    setShowPassword((showPassword) => !showPassword);
  window.localStorage.clear();

  const [show, setShow] = React.useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  console.log(props.isLogin, "ppjjll");
  const handleVisible = (message) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: message,
      icon: "success",
      button: "Ok",
    });
  };
  // const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const handleDangerVisible = (message) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: message,
      icon: "error",
      button: "Ok",
    });
  };

  const handleLogin = () => {
    props.getLogin({
      username: email,
      password: pass,
    },handleDangerVisible);

    handleShow();
  };
  console.log(pass, "jjllkk");
  return (
    <div >
      <div className="login-block" >
        <div className="container">
          <div className="row">
            <div className="col-md-4 login-sec">
              <h2 className="text-center">User Login</h2>
              <div className="form-group">
                <label for="exampleInputEmail1" className="text-uppercase">
                  Username
                </label>
                <input
                  type="text"
                  id="standardbasic"
                  className="form-control"
                  placeholder=""
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label for="exampleInputPassword1" className="text-uppercase">
                  Password
                </label>

                <KeyboardEventHandler
                  handleKeys={["Enter"]}
                  onKeyEvent={(key, e) => handleLogin()}
                >
                  <div style={{ display: "grid" }} className="form-group">
                    <input
                      type="password"
                      id="standard-basic"
                      className="form-control"
                      placeholder=""
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </KeyboardEventHandler>
              </div>

              <div className="form-check">
                <button
                  onClick={() => handleLogin()}
                  className="btn btn-login cus-btn float-right"
                >
                  Sign In
                </button>
              </div>
              <Route
                render={({ history }) => (
                  <button
                    type="button"
                    style={{
                      color: "white",
                      background: "black",
                      borderRadius: "5px",
                      borderColor: "silver",
                      padding: "10px",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      history.push("/admin-login");
                    }}
                  >
                    Go to Admin Login
                  </button>
                )}
              />
            </div>
          </div>
        </div>

        {/* <div className="modal">
          {props.isWrongCredentials == true && (
            <Modal isOpen={show}>
              <ModalHeader>
                <h4>Login Unsuccessful</h4>
              </ModalHeader>
              <ModalBody>
                <h5>Something is Wrong</h5>
              </ModalBody>
              <ModalFooter>
                <Row className="close">
                  <Button onClick={handleClose}>Close</Button>
                </Row>
              </ModalFooter>
            </Modal>
          )}
        </div> */}
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    getLogin: (data,handleDangerVisible) => dispatch(getLogin(data,handleDangerVisible)),
  };
};

const mapStateToProps = (state, ownProps, number) => ({
  isWrongCredentials: state.userReducer.isWrongCredentials,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
