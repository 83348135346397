import API from "../../../../../global/api";
import { forEach } from "lodash";

//Add Process Call

export const addProcessCall = (data) => {
  return (dispatch) => {
    dispatch(requestAddProcess());
    API.post("categoryProcess/add_data", data)
      .then((res) => {
        dispatch(successAddProcess(data));
      })
      .catch((error) => {
        dispatch(errorAddProcess());
      });
  };
};

export const requestAddProcess = () => {
  return {
    type: "REQUEST_ADD_PROCESS",
  };
};

export const successAddProcess = (data) => {
  return {
    type: "SUCCESS_ADD_PROCESS",
    payload: data,
  };
};

export const errorAddProcess = () => {
  return {
    type: "ERROR_ADD_PROCESS",
  };
};

//Get Process Call

export const getProcessCall = () => {
  return (dispatch) => {
    dispatch(requestGetProcess());
    API.get("categoryProcess/get_all_data")
      .then((res) => {
        dispatch(successGetProcess(res.data));
      })
      .catch((error) => {
        dispatch(errorGetProcess());
      });
  };
};

export const requestGetProcess = () => {
  return {
    type: "REQUEST_GET_PROCESS",
  };
};

export const successGetProcess = (data) => {
  return {
    type: "SUCCESS_GET_PROCESS",
    payload: data,
  };
};

export const errorGetProcess = () => {
  return {
    type: "ERROR_GET_PROCESS",
  };
};

// delete Process
export const requestDeleteProcess = () => {
  return {
    type: "REQUEST_DELETE_PROCESS",
  };
};

export const successDeleteProcess = (categoryProcessId) => {
  return {
    type: "SUCCESS_DELETE_PROCESS",
    categoryProcessId: categoryProcessId,
  };
};

export const errorDeleteProcess = () => {
  return {
    type: "ERROR_DELETE_PROCESS",
  };
};
export const deleteProcess = (categoryProcessId) => {
  return (dispatch) => {
    dispatch(requestDeleteProcess());
    API.delete(`/Process/${categoryProcessId}`)
      .then((res) => {
        dispatch(getProcessCall());
        dispatch(successDeleteProcess(categoryProcessId));
      })
      .catch((error) => {
        dispatch(errorDeleteProcess());
      });
  };
};

// update

export const requestUpdateProcess = () => {
  return {
    type: "REQUEST_UPDATE_PROCESS",
  };
};

export const successUpdateProcess = (data) => {
  return {
    type: "SUCCESS_UPDATE_PROCESS",
    payload: data,
  };
};

export const errorUpdateProcess = () => {
  return {
    type: "ERROR_UPDATE_PROCESS",
  };
};

export const updateProcess = (newData, oldData) => {
  return (dispatch) => {
    dispatch(requestUpdateProcess());
    API.put(`/update/${oldData.categoryProcessId}`, {
      categoryProcessName: newData.categoryProcessName,
      processName: newData.processName,
      processDescription: newData.processDescription,
    })
      .then((res) => {
        dispatch(getProcessCall());
        dispatch(successUpdateProcess([newData]));
      })
      .catch((error) => {
        dispatch(errorUpdateProcess());
      });
  };
};
